(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.d4.gV === region.ev.gV)
	{
		return 'on line ' + region.d4.gV;
	}
	return 'on lines ' + region.d4.gV + ' through ' + region.ev.gV;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.gG,
		impl.h2,
		impl.hD,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		gZ: func(record.gZ),
		d5: record.d5,
		dS: record.dS
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.gZ;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.d5;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.dS) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.gG,
		impl.h2,
		impl.hD,
		function(sendToApp, initialModel) {
			var view = impl.h4;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.gG,
		impl.h2,
		impl.hD,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.dZ && impl.dZ(sendToApp)
			var view = impl.h4;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.f$);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.hU) && (_VirtualDom_doc.title = title = doc.hU);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.g5;
	var onUrlRequest = impl.g6;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		dZ: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.e9 === next.e9
							&& curr.eI === next.eI
							&& curr.e5.a === next.e5.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		gG: function(flags)
		{
			return A3(impl.gG, flags, _Browser_getUrl(), key);
		},
		h4: impl.h4,
		h2: impl.h2,
		hD: impl.hD
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { gz: 'hidden', f9: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { gz: 'mozHidden', f9: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { gz: 'msHidden', f9: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { gz: 'webkitHidden', f9: 'webkitvisibilitychange' }
		: { gz: 'hidden', f9: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		fh: _Browser_getScene(),
		fx: {
			h7: _Browser_window.pageXOffset,
			h8: _Browser_window.pageYOffset,
			fA: _Browser_doc.documentElement.clientWidth,
			eD: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		fA: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		eD: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			fh: {
				fA: node.scrollWidth,
				eD: node.scrollHeight
			},
			fx: {
				h7: node.scrollLeft,
				h8: node.scrollTop,
				fA: node.clientWidth,
				eD: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			fh: _Browser_getScene(),
			fx: {
				h7: x,
				h8: y,
				fA: _Browser_doc.documentElement.clientWidth,
				eD: _Browser_doc.documentElement.clientHeight
			},
			bG: {
				h7: x + rect.left,
				h8: y + rect.top,
				fA: rect.width,
				eD: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});




// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.ca.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.ca.b, xhr)); });
		$elm$core$Maybe$isJust(request.fr) && _Http_track(router, xhr, request.fr.a);

		try {
			xhr.open(request.bH, request.h3, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.h3));
		}

		_Http_configureRequest(xhr, request);

		request.f$.a && xhr.setRequestHeader('Content-Type', request.f$.a);
		xhr.send(request.f$.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.aT; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.aY.a || 0;
	xhr.responseType = request.ca.d;
	xhr.withCredentials = request.fR;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		h3: xhr.responseURL,
		hw: xhr.status,
		hx: xhr.statusText,
		aT: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			hn: event.loaded,
			hq: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			hd: event.loaded,
			hq: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}

// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.g$) { flags += 'm'; }
	if (options.f8) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}
var $author$project$Main$LinkClicked = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$UrlChanged = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.aF) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.aN),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.aN);
		} else {
			var treeLen = builder.aF * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.aO) : builder.aO;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.aF);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.aN) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.aN);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{aO: nodeList, aF: (len / $elm$core$Array$branchFactor) | 0, aN: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {eB: fragment, eI: host, dR: path, e5: port_, e9: protocol, fa: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $mdgriffith$elm_ui$Element$Desktop = 2;
var $author$project$Main$NotFound = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Element$Portrait = 0;
var $author$project$Main$Auth = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Main$GotSidebarMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$NotAuth = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$Main$Competition = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$CreateCompetition = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$GotCompetitionMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Main$GotCreateCompetitionMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Main$Home = {$: 0};
var $krisajenkins$remotedata$RemoteData$Loading = {$: 1};
var $author$project$Page$Competition$Matchday = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$Competition$GotTimezone = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Competition$GotMatchday = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Encode$int = _Json_wrap;
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$int = function (value) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(
		$elm$json$Json$Encode$int(value));
};
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required = F3(
	function (fieldName, raw, encode) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument,
			fieldName,
			encode(raw));
	});
var $dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$RawField$Composite = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite = F3(
	function (fieldName, args, fields) {
		return A3($dillonkearns$elm_graphql$Graphql$RawField$Composite, fieldName, args, fields);
	});
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $dillonkearns$elm_graphql$Graphql$Document$Hash$HashData = F4(
	function (shift, seed, hash, charsProcessed) {
		return {b5: charsProcessed, cf: hash, bY: seed, ct: shift};
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$c1 = 3432918353;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$c2 = 461845907;
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy = F2(
	function (b, a) {
		return ((a & 65535) * b) + ((((a >>> 16) * b) & 65535) << 16);
	});
var $elm$core$Basics$neq = _Utils_notEqual;
var $elm$core$Bitwise$or = _Bitwise_or;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy = F2(
	function (b, a) {
		return (a << b) | (a >>> (32 - b));
	});
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$finalize = function (data) {
	var acc = (!(!data.cf)) ? (data.bY ^ A2(
		$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
		$dillonkearns$elm_graphql$Graphql$Document$Hash$c2,
		A2(
			$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
			15,
			A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, $dillonkearns$elm_graphql$Graphql$Document$Hash$c1, data.cf)))) : data.bY;
	var h0 = acc ^ data.b5;
	var h1 = A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, 2246822507, h0 ^ (h0 >>> 16));
	var h2 = A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, 3266489909, h1 ^ (h1 >>> 13));
	return (h2 ^ (h2 >>> 16)) >>> 0;
};
var $elm$core$String$foldl = _String_foldl;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$mix = F2(
	function (h1, k1) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
			5,
			A2(
				$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
				13,
				h1 ^ A2(
					$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
					$dillonkearns$elm_graphql$Graphql$Document$Hash$c2,
					A2(
						$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
						15,
						A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, $dillonkearns$elm_graphql$Graphql$Document$Hash$c1, k1))))) + 3864292196;
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$hashFold = F2(
	function (c, data) {
		var res = data.cf | ((255 & $elm$core$Char$toCode(c)) << data.ct);
		var _v0 = data.ct;
		if (_v0 === 24) {
			return {
				b5: data.b5 + 1,
				cf: 0,
				bY: A2($dillonkearns$elm_graphql$Graphql$Document$Hash$mix, data.bY, res),
				ct: 0
			};
		} else {
			return {b5: data.b5 + 1, cf: res, bY: data.bY, ct: data.ct + 8};
		}
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$hashString = F2(
	function (seed, str) {
		return $dillonkearns$elm_graphql$Graphql$Document$Hash$finalize(
			A3(
				$elm$core$String$foldl,
				$dillonkearns$elm_graphql$Graphql$Document$Hash$hashFold,
				A4($dillonkearns$elm_graphql$Graphql$Document$Hash$HashData, 0, seed, 0, 0),
				str));
	});
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize = function (value) {
	switch (value.$) {
		case 0:
			var enumValue = value.a;
			return enumValue;
		case 1:
			var json = value.a;
			return A2($elm$json$Json$Encode$encode, 0, json);
		case 2:
			var values = value.a;
			return '[' + (A2(
				$elm$core$String$join,
				', ',
				A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize, values)) + ']');
		default:
			var keyValuePairs = value.a;
			return '{' + (A2(
				$elm$core$String$join,
				', ',
				A2(
					$elm$core$List$map,
					function (_v1) {
						var key = _v1.a;
						var objectValue = _v1.b;
						return key + (': ' + $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize(objectValue));
					},
					keyValuePairs)) + '}');
	}
};
var $dillonkearns$elm_graphql$Graphql$Document$Argument$argToString = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return name + (': ' + $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize(value));
};
var $dillonkearns$elm_graphql$Graphql$Document$Argument$serialize = function (args) {
	if (!args.b) {
		return '';
	} else {
		var nonemptyArgs = args;
		return '(' + (A2(
			$elm$core$String$join,
			', ',
			A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Document$Argument$argToString, nonemptyArgs)) + ')');
	}
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$maybeAliasHash = function (field) {
	return A2(
		$elm$core$Maybe$map,
		$dillonkearns$elm_graphql$Graphql$Document$Hash$hashString(0),
		function () {
			if (!field.$) {
				var name = field.a;
				var _arguments = field.b;
				var children = field.c;
				return $elm$core$List$isEmpty(_arguments) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(_arguments));
			} else {
				var typeString = field.a.h0;
				var fieldName = field.a.ey;
				var _arguments = field.b;
				return (fieldName === '__typename') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
					$elm$core$String$concat(
						A2(
							$elm$core$List$append,
							_List_fromArray(
								[typeString]),
							$elm$core$List$singleton(
								$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(_arguments)))));
			}
		}());
};
var $dillonkearns$elm_graphql$Graphql$RawField$name = function (field) {
	if (!field.$) {
		var fieldName = field.a;
		var argumentList = field.b;
		var fieldList = field.c;
		return fieldName;
	} else {
		var typeString = field.a.h0;
		var fieldName = field.a.ey;
		var argumentList = field.b;
		return fieldName;
	}
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$alias = function (field) {
	return A2(
		$elm$core$Maybe$map,
		function (aliasHash) {
			return _Utils_ap(
				$dillonkearns$elm_graphql$Graphql$RawField$name(field),
				$elm$core$String$fromInt(aliasHash));
		},
		$dillonkearns$elm_graphql$Graphql$Document$Field$maybeAliasHash(field));
};
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName = function (field) {
	return A2(
		$elm$core$Maybe$withDefault,
		$dillonkearns$elm_graphql$Graphql$RawField$name(field),
		$dillonkearns$elm_graphql$Graphql$Document$Field$alias(field));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField = F4(
	function (fieldName, args, _v0, decoderTransform) {
		var fields = _v0.a;
		var decoder = _v0.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite, fieldName, args, fields)
				]),
			A2(
				$elm$json$Json$Decode$field,
				$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite, fieldName, args, fields)),
				decoderTransform(decoder)));
	});
var $author$project$Gambling$Query$gambling_competitions_by_pk = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'gambling_competitions_by_pk',
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'id', requiredArgs____.a, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)
				]),
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
	});
var $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent = {$: 1};
var $author$project$Page$Competition$MatchdayData = F4(
	function (id, users, currentMatchday, currentMatches) {
		return {c0: currentMatchday, c1: currentMatches, a: id, cz: users};
	});
var $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present = function (a) {
	return {$: 0, a: a};
};
var $author$project$Gambling$InputObject$buildMatches_gambling_competitions_args = function (fillOptionals____) {
	var optionals____ = fillOptionals____(
		{ck: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
	return {ck: optionals____.ck};
};
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $dillonkearns$elm_graphql$Graphql$RawField$Leaf = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$leaf = F2(
	function (details, args) {
		return A2($dillonkearns$elm_graphql$Graphql$RawField$Leaf, details, args);
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField = F4(
	function (typeString, fieldName, args, decoder) {
		var newLeaf = A2(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$leaf,
			{ey: fieldName, h0: typeString},
			args);
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_List_fromArray(
				[newLeaf]),
			A2(
				$elm$json$Json$Decode$field,
				$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(newLeaf),
				decoder));
	});
var $author$project$Gambling$Object$Gambling_football_seasons$current_matchday = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Int)',
	'current_matchday',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $author$project$Page$Competition$Match = F6(
	function (id, dateTime, homeTeam, awayTeam, result, bets) {
		return {dl: awayTeam, cV: bets, bO: dateTime, dw: homeTeam, a: id, aM: result};
	});
var $author$project$Gambling$Object$Gambling_football_matches$away_team = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'away_team', _List_Nil, object____, $elm$core$Basics$identity);
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$parser$Parser$deadEndsToString = function (deadEnds) {
	return 'TODO deadEndsToString';
};
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {eq: col, gj: contextStack, e6: problem, ff: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.ff, s.eq, x, s.aj));
	});
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.hv),
			s.az) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.hv);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.az, offset) < 0,
					0,
					{eq: col, aj: s0.aj, ao: s0.ao, az: offset, ff: row, hv: s0.hv});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.az, s.ff, s.eq, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.az, s1.az, s0.hv),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$core$Basics$round = _Basics_round;
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$core$String$toFloat = _String_toFloat;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs = A2(
	$elm$parser$Parser$andThen,
	function (str) {
		if ($elm$core$String$length(str) <= 9) {
			var _v0 = $elm$core$String$toFloat('0.' + str);
			if (!_v0.$) {
				var floatVal = _v0.a;
				return $elm$parser$Parser$succeed(
					$elm$core$Basics$round(floatVal * 1000));
			} else {
				return $elm$parser$Parser$problem('Invalid float: \"' + (str + '\"'));
			}
		} else {
			return $elm$parser$Parser$problem(
				'Expected at most 9 digits, but got ' + $elm$core$String$fromInt(
					$elm$core$String$length(str)));
		}
	},
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompWhile($elm$core$Char$isDigit)));
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts = F6(
	function (monthYearDayMs, hour, minute, second, ms, utcOffsetMinutes) {
		return $elm$time$Time$millisToPosix((((monthYearDayMs + (((hour * 60) * 60) * 1000)) + (((minute - utcOffsetMinutes) * 60) * 1000)) + (second * 1000)) + ms);
	});
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$core$String$append = _String_append;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.az, s.hv);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{eq: 1, aj: s.aj, ao: s.ao, az: s.az + 1, ff: s.ff + 1, hv: s.hv}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{eq: s.eq + 1, aj: s.aj, ao: s.ao, az: newOffset, ff: s.ff, hv: s.hv}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt = function (quantity) {
	var helper = function (str) {
		if (_Utils_eq(
			$elm$core$String$length(str),
			quantity)) {
			var _v0 = $elm$core$String$toInt(str);
			if (!_v0.$) {
				var intVal = _v0.a;
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$Done,
					$elm$parser$Parser$succeed(intVal));
			} else {
				return $elm$parser$Parser$problem('Invalid integer: \"' + (str + '\"'));
			}
		} else {
			return A2(
				$elm$parser$Parser$map,
				function (nextChar) {
					return $elm$parser$Parser$Loop(
						A2($elm$core$String$append, str, nextChar));
				},
				$elm$parser$Parser$getChompedString(
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
		}
	};
	return A2($elm$parser$Parser$loop, '', helper);
};
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.az, s.ff, s.eq, s.hv);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{eq: newCol, aj: s.aj, ao: s.ao, az: newOffset, ff: newRow, hv: s.hv});
	};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear = 1970;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay = function (day) {
	return $elm$parser$Parser$problem(
		'Invalid day: ' + $elm$core$String$fromInt(day));
};
var $elm$core$Basics$modBy = _Basics_modBy;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear = function (year) {
	return (!A2($elm$core$Basics$modBy, 4, year)) && ((!(!A2($elm$core$Basics$modBy, 100, year))) || (!A2($elm$core$Basics$modBy, 400, year)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore = function (y1) {
	var y = y1 - 1;
	return (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay = 86400000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear = 31536000000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay = function (_v0) {
	var year = _v0.a;
	var month = _v0.b;
	var dayInMonth = _v0.c;
	if (dayInMonth < 0) {
		return $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth);
	} else {
		var succeedWith = function (extraMs) {
			var yearMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear * (year - $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear);
			var days = ((month < 3) || (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year))) ? (dayInMonth - 1) : dayInMonth;
			var dayMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay * (days + ($rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore(year) - $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore($rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear)));
			return $elm$parser$Parser$succeed((extraMs + yearMs) + dayMs);
		};
		switch (month) {
			case 1:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(0);
			case 2:
				return ((dayInMonth > 29) || ((dayInMonth === 29) && (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year)))) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(2678400000);
			case 3:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(5097600000);
			case 4:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(7776000000);
			case 5:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(10368000000);
			case 6:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(13046400000);
			case 7:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(15638400000);
			case 8:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(18316800000);
			case 9:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(20995200000);
			case 10:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(23587200000);
			case 11:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(26265600000);
			case 12:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(28857600000);
			default:
				return $elm$parser$Parser$problem(
					'Invalid month: \"' + ($elm$core$String$fromInt(month) + '\"'));
		}
	}
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs = A2(
	$elm$parser$Parser$andThen,
	$rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F3(
						function (year, month, day) {
							return _Utils_Tuple3(year, month, day);
						})),
				$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(4)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed($elm$core$Basics$identity),
							$elm$parser$Parser$symbol('-')),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
					]))),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed($elm$core$Basics$identity),
						$elm$parser$Parser$symbol('-')),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
				]))));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes = function () {
	var utcOffsetMinutesFromParts = F3(
		function (multiplier, hours, minutes) {
			return (multiplier * (hours * 60)) + minutes;
		});
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$map,
					function (_v0) {
						return 0;
					},
					$elm$parser$Parser$symbol('Z')),
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed(utcOffsetMinutesFromParts),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$map,
										function (_v1) {
											return 1;
										},
										$elm$parser$Parser$symbol('+')),
										A2(
										$elm$parser$Parser$map,
										function (_v2) {
											return -1;
										},
										$elm$parser$Parser$symbol('-'))
									]))),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$ignorer,
									$elm$parser$Parser$succeed($elm$core$Basics$identity),
									$elm$parser$Parser$symbol(':')),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
								$elm$parser$Parser$succeed(0)
							]))),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(0),
					$elm$parser$Parser$end)
				])));
}();
var $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601 = A2(
	$elm$parser$Parser$andThen,
	function (datePart) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed(
											$rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts(datePart)),
										$elm$parser$Parser$symbol('T')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$elm$parser$Parser$oneOf(
									_List_fromArray(
										[
											A2(
											$elm$parser$Parser$keeper,
											A2(
												$elm$parser$Parser$ignorer,
												$elm$parser$Parser$succeed($elm$core$Basics$identity),
												$elm$parser$Parser$symbol(':')),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
										]))),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$keeper,
										A2(
											$elm$parser$Parser$ignorer,
											$elm$parser$Parser$succeed($elm$core$Basics$identity),
											$elm$parser$Parser$symbol(':')),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
									]))),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$symbol('.')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs),
									$elm$parser$Parser$succeed(0)
								]))),
					A2($elm$parser$Parser$ignorer, $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes, $elm$parser$Parser$end)),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A6($rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts, datePart, 0, 0, 0, 0, 0)),
					$elm$parser$Parser$end)
				]));
	},
	$rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs);
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {eq: col, e6: problem, ff: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.ff, p.eq, p.e6);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{eq: 1, aj: _List_Nil, ao: 1, az: 0, ff: 1, hv: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime = function (str) {
	return A2($elm$parser$Parser$run, $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601, str);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		var _v0 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(str);
		if (_v0.$ === 1) {
			var deadEnds = _v0.a;
			return $elm$json$Json$Decode$fail(
				$elm$parser$Parser$deadEndsToString(deadEnds));
		} else {
			var time = _v0.a;
			return $elm$json$Json$Decode$succeed(time);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gambling$Scalar$Bigint = $elm$core$Basics$identity;
var $author$project$Gambling$Scalar$Date = $elm$core$Basics$identity;
var $author$project$Gambling$Scalar$Json = $elm$core$Basics$identity;
var $author$project$Gambling$Scalar$Timestamptz = $elm$core$Basics$identity;
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $elm$core$String$fromFloat = _String_fromNumber;
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			$elm$json$Json$Decode$string,
			A2($elm$json$Json$Decode$map, $elm$core$String$fromFloat, $elm$json$Json$Decode$float),
			A2($elm$json$Json$Decode$map, $elm$core$String$fromInt, $elm$json$Json$Decode$int),
			A2(
			$elm$json$Json$Decode$map,
			function (bool) {
				if (bool) {
					return 'true';
				} else {
					return 'false';
				}
			},
			$elm$json$Json$Decode$bool)
		]));
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Gambling$Scalar$defaultCodecs = {
	bC: {
		gm: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		cI: function (_v0) {
			var raw = _v0;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	a$: {
		gm: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		cI: function (_v1) {
			var raw = _v1;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	bD: {
		gm: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		cI: function (_v2) {
			var raw = _v2;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	ar: {
		gm: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		cI: function (_v3) {
			var raw = _v3;
			return $elm$json$Json$Encode$string(raw);
		}
	}
};
var $author$project$Gambling$Scalar$Codecs = $elm$core$Basics$identity;
var $author$project$Gambling$Scalar$defineCodecs = function (definitions) {
	return definitions;
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromMonth = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.d4, posixMinutes) < 0) {
					return posixMinutes + era.az;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$core$Basics$ge = _Utils_ge;
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		es: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		eV: month,
		fF: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).es;
	});
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMillis = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			1000,
			$elm$time$Time$posixToMillis(time));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).eV;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString = F2(
	function (digits, time) {
		return A3(
			$elm$core$String$padLeft,
			digits,
			'0',
			$elm$core$String$fromInt(time));
	});
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).fF;
	});
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime = function (time) {
	return A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		4,
		A2($elm$time$Time$toYear, $elm$time$Time$utc, time)) + ('-' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		$rtfeldman$elm_iso8601_date_strings$Iso8601$fromMonth(
			A2($elm$time$Time$toMonth, $elm$time$Time$utc, time))) + ('-' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toDay, $elm$time$Time$utc, time)) + ('T' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toHour, $elm$time$Time$utc, time)) + (':' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toMinute, $elm$time$Time$utc, time)) + (':' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toSecond, $elm$time$Time$utc, time)) + ('.' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		3,
		A2($elm$time$Time$toMillis, $elm$time$Time$utc, time)) + 'Z'))))))))))));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$encode = A2($elm$core$Basics$composeR, $rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime, $elm$json$Json$Encode$string);
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $author$project$ScalarCodecs$codecs = $author$project$Gambling$Scalar$defineCodecs(
	{
		bC: $author$project$Gambling$Scalar$defaultCodecs.bC,
		a$: $author$project$Gambling$Scalar$defaultCodecs.a$,
		bD: {gm: $elm$json$Json$Decode$value, cI: $elm$core$Basics$identity},
		ar: {gm: $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder, cI: $rtfeldman$elm_iso8601_date_strings$Iso8601$encode}
	});
var $author$project$Gambling$Scalar$unwrapCodecs = function (_v0) {
	var unwrappedCodecs = _v0;
	return unwrappedCodecs;
};
var $author$project$Gambling$Object$Gambling_football_matches$date = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Timestamptz',
	'date',
	_List_Nil,
	$author$project$Gambling$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).ar.gm);
var $author$project$Page$Competition$MatchResult = F2(
	function (homeTeamGoals, awayTeamGoals) {
		return {cE: awayTeamGoals, cJ: homeTeamGoals};
	});
var $author$project$Gambling$Object$Gambling_football_results$away_team_goals = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Int', 'away_team_goals', _List_Nil, $elm$json$Json$Decode$int);
var $author$project$Gambling$Object$Gambling_football_results$home_team_goals = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Int', 'home_team_goals', _List_Nil, $elm$json$Json$Decode$int);
var $dillonkearns$elm_graphql$Graphql$SelectionSet$map2 = F3(
	function (combine, _v0, _v1) {
		var selectionFields1 = _v0.a;
		var selectionDecoder1 = _v0.b;
		var selectionFields2 = _v1.a;
		var selectionDecoder2 = _v1.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_Utils_ap(selectionFields1, selectionFields2),
			A3($elm$json$Json$Decode$map2, combine, selectionDecoder1, selectionDecoder2));
	});
var $author$project$Page$Competition$footballMatchResultSelection = A3($dillonkearns$elm_graphql$Graphql$SelectionSet$map2, $author$project$Page$Competition$MatchResult, $author$project$Gambling$Object$Gambling_football_results$home_team_goals, $author$project$Gambling$Object$Gambling_football_results$away_team_goals);
var $author$project$Page$Competition$Team = F2(
	function (tla, crest) {
		return {$7: crest, av: tla};
	});
var $author$project$Gambling$Object$Gambling_football_teams$crest_url = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'crest_url', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gambling$Object$Gambling_football_teams$tla = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'tla', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Page$Competition$footballTeamSelection = A3($dillonkearns$elm_graphql$Graphql$SelectionSet$map2, $author$project$Page$Competition$Team, $author$project$Gambling$Object$Gambling_football_teams$tla, $author$project$Gambling$Object$Gambling_football_teams$crest_url);
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $author$project$Gambling$Object$Gambling_football_matches$home_team = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'home_team', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gambling$Object$Gambling_football_matches$id = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Int', 'id', _List_Nil, $elm$json$Json$Decode$int);
var $dillonkearns$elm_graphql$Graphql$SelectionSet$map = F2(
	function (mapFunction, _v0) {
		var selectionFields = _v0.a;
		var selectionDecoder = _v0.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			selectionFields,
			A2($elm$json$Json$Decode$map, mapFunction, selectionDecoder));
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$json$Json$Decode$map6 = _Json_map6;
var $dillonkearns$elm_graphql$Graphql$SelectionSet$map6 = F7(
	function (combine, _v0, _v1, _v2, _v3, _v4, _v5) {
		var selectionFields1 = _v0.a;
		var selectionDecoder1 = _v0.b;
		var selectionFields2 = _v1.a;
		var selectionDecoder2 = _v1.b;
		var selectionFields3 = _v2.a;
		var selectionDecoder3 = _v2.b;
		var selectionFields4 = _v3.a;
		var selectionDecoder4 = _v3.b;
		var selectionFields5 = _v4.a;
		var selectionDecoder5 = _v4.b;
		var selectionFields6 = _v5.a;
		var selectionDecoder6 = _v5.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			$elm$core$List$concat(
				_List_fromArray(
					[selectionFields1, selectionFields2, selectionFields3, selectionFields4, selectionFields5, selectionFields6])),
			A7($elm$json$Json$Decode$map6, combine, selectionDecoder1, selectionDecoder2, selectionDecoder3, selectionDecoder4, selectionDecoder5, selectionDecoder6));
	});
var $author$project$Page$Competition$Bet = F3(
	function (id, value, user) {
		return {a: id, y: user, an: value};
	});
var $author$project$Gambling$Enum$Gambling_bet_enum$Away = 0;
var $author$project$Gambling$Enum$Gambling_bet_enum$Home = 1;
var $author$project$Gambling$Enum$Gambling_bet_enum$X = 2;
var $author$project$Gambling$Enum$Gambling_bet_enum$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'AWAY':
				return $elm$json$Json$Decode$succeed(0);
			case 'HOME':
				return $elm$json$Json$Decode$succeed(1);
			case 'X':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('Invalid Gambling_bet_enum type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gambling$Object$Gambling_match_bets$bet = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.Gambling_bet_enum.Gambling_bet_enum', 'bet', _List_Nil, $author$project$Gambling$Enum$Gambling_bet_enum$decoder);
var $author$project$Gambling$Object$Gambling_match_bets$id = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Int', 'id', _List_Nil, $elm$json$Json$Decode$int);
var $elm$json$Json$Decode$map3 = _Json_map3;
var $dillonkearns$elm_graphql$Graphql$SelectionSet$map3 = F4(
	function (combine, _v0, _v1, _v2) {
		var selectionFields1 = _v0.a;
		var selectionDecoder1 = _v0.b;
		var selectionFields2 = _v1.a;
		var selectionDecoder2 = _v1.b;
		var selectionFields3 = _v2.a;
		var selectionDecoder3 = _v2.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			$elm$core$List$concat(
				_List_fromArray(
					[selectionFields1, selectionFields2, selectionFields3])),
			A4($elm$json$Json$Decode$map3, combine, selectionDecoder1, selectionDecoder2, selectionDecoder3));
	});
var $author$project$Gambling$Object$Gambling_match_bets$user = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'user', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Page$Competition$User = F4(
	function (id, authId, username, avatar) {
		return {bA: authId, K: avatar, a: id, ac: username};
	});
var $author$project$Gambling$Object$Gambling_users$auth0_id = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'auth0_id', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gambling$Object$Gambling_users$avatar = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'avatar',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gambling$Object$Gambling_users$id = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Int', 'id', _List_Nil, $elm$json$Json$Decode$int);
var $elm$json$Json$Decode$map4 = _Json_map4;
var $dillonkearns$elm_graphql$Graphql$SelectionSet$map4 = F5(
	function (combine, _v0, _v1, _v2, _v3) {
		var selectionFields1 = _v0.a;
		var selectionDecoder1 = _v0.b;
		var selectionFields2 = _v1.a;
		var selectionDecoder2 = _v1.b;
		var selectionFields3 = _v2.a;
		var selectionDecoder3 = _v2.b;
		var selectionFields4 = _v3.a;
		var selectionDecoder4 = _v3.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			$elm$core$List$concat(
				_List_fromArray(
					[selectionFields1, selectionFields2, selectionFields3, selectionFields4])),
			A5($elm$json$Json$Decode$map4, combine, selectionDecoder1, selectionDecoder2, selectionDecoder3, selectionDecoder4));
	});
var $author$project$Gambling$Object$Gambling_users$username = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'username', _List_Nil, $elm$json$Json$Decode$string);
var $dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault = function (_default) {
	return $dillonkearns$elm_graphql$Graphql$SelectionSet$map(
		$elm$core$Maybe$withDefault(_default));
};
var $author$project$Page$Competition$userSelection = A5(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$map4,
	$author$project$Page$Competition$User,
	$author$project$Gambling$Object$Gambling_users$id,
	$author$project$Gambling$Object$Gambling_users$auth0_id,
	$author$project$Gambling$Object$Gambling_users$username,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, '', $author$project$Gambling$Object$Gambling_users$avatar));
var $author$project$Page$Competition$matchBetSelection = A4(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$map3,
	$author$project$Page$Competition$Bet,
	$author$project$Gambling$Object$Gambling_match_bets$id,
	$author$project$Gambling$Object$Gambling_match_bets$bet,
	$author$project$Gambling$Object$Gambling_match_bets$user($author$project$Page$Competition$userSelection));
var $author$project$Gambling$InputObject$Gambling_match_bets_bool_exp = $elm$core$Basics$identity;
var $author$project$Gambling$InputObject$buildGambling_match_bets_bool_exp = function (fillOptionals____) {
	var optionals____ = fillOptionals____(
		{p: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ap: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, v: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, b: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, s: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, h: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, al: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, a: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, q: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, r: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, u: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, y: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, E: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
	return {p: optionals____.p, ap: optionals____.ap, v: optionals____.v, b: optionals____.b, s: optionals____.s, h: optionals____.h, al: optionals____.al, a: optionals____.a, q: optionals____.q, r: optionals____.r, u: optionals____.u, y: optionals____.y, E: optionals____.E};
};
var $author$project$Gambling$InputObject$buildInt_comparison_exp = function (fillOptionals____) {
	var optionals____ = fillOptionals____(
		{F: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, Q: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, R: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, G: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, H: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, S: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, T: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, I: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, J: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
	return {F: optionals____.F, Q: optionals____.Q, R: optionals____.R, G: optionals____.G, H: optionals____.H, S: optionals____.S, T: optionals____.T, I: optionals____.I, J: optionals____.J};
};
var $author$project$Page$Competition$matchBetsArgs = F2(
	function (competition_id, args) {
		return _Utils_update(
			args,
			{
				c: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
					$author$project$Gambling$InputObject$buildGambling_match_bets_bool_exp(
						function (o) {
							return _Utils_update(
								o,
								{
									b: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
										$author$project$Gambling$InputObject$buildInt_comparison_exp(
											function (ce) {
												return _Utils_update(
													ce,
													{
														F: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(competition_id)
													});
											}))
								});
						}))
			});
	});
var $elm$json$Json$Encode$bool = _Json_wrap;
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool = function (value) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(
		$elm$json$Json$Encode$bool(value));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$List = function (a) {
	return {$: 2, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$list = F2(
	function (toValue, value) {
		return $dillonkearns$elm_graphql$Graphql$Internal$Encode$List(
			A2($elm$core$List$map, toValue, value));
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$Object = function (a) {
	return {$: 3, a: a};
};
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject = function (maybeValues) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Object(
		A2(
			$elm$core$List$filterMap,
			function (_v0) {
				var key = _v0.a;
				var value = _v0.b;
				if (!value.$) {
					var actualValue = value.a;
					return $elm$core$Maybe$Just(
						_Utils_Tuple2(key, actualValue));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			},
			maybeValues));
};
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$null = $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json($elm$json$Json$Encode$null);
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$optional = F2(
	function (optionalValue, toValue) {
		switch (optionalValue.$) {
			case 0:
				var value = optionalValue.a;
				return $elm$core$Maybe$Just(
					toValue(value));
			case 1:
				return $elm$core$Maybe$Nothing;
			default:
				return $elm$core$Maybe$Just($dillonkearns$elm_graphql$Graphql$Internal$Encode$null);
		}
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$fromJson = function (jsonValue) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(jsonValue);
};
var $author$project$Gambling$Scalar$unwrapEncoder = F2(
	function (getter, _v0) {
		var unwrappedCodecs = _v0;
		return A2(
			$elm$core$Basics$composeR,
			getter(unwrappedCodecs).cI,
			$dillonkearns$elm_graphql$Graphql$Internal$Encode$fromJson);
	});
var $author$project$Gambling$InputObject$encodeDate_comparison_exp = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_eq',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.F,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.a$;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'_gt',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.Q,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.a$;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'_gte',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.R,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.a$;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'_in',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.G,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						A2(
							$author$project$Gambling$Scalar$unwrapEncoder,
							function ($) {
								return $.a$;
							},
							$author$project$ScalarCodecs$codecs)))),
				_Utils_Tuple2(
				'_is_null',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.H, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'_lt',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.S,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.a$;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'_lte',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.T,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.a$;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'_neq',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.I,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.a$;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'_nin',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.J,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						A2(
							$author$project$Gambling$Scalar$unwrapEncoder,
							function ($) {
								return $.a$;
							},
							$author$project$ScalarCodecs$codecs))))
			]));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$EnumValue = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$enum = F2(
	function (enumToString, value) {
		return $dillonkearns$elm_graphql$Graphql$Internal$Encode$EnumValue(
			enumToString(value));
	});
var $author$project$Gambling$Enum$Gambling_bet_enum$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'AWAY';
		case 1:
			return 'HOME';
		default:
			return 'X';
	}
};
var $author$project$Gambling$InputObject$encodeGambling_bet_enum_comparison_exp = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_eq',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.F,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Gambling_bet_enum$toString))),
				_Utils_Tuple2(
				'_in',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.G,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Gambling_bet_enum$toString)))),
				_Utils_Tuple2(
				'_is_null',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.H, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'_neq',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.I,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Gambling_bet_enum$toString))),
				_Utils_Tuple2(
				'_nin',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.J,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Gambling_bet_enum$toString))))
			]));
};
var $author$project$Gambling$InputObject$encodeInt_comparison_exp = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_eq',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.F, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'_gt',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.Q, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'_gte',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.R, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'_in',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.G,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($dillonkearns$elm_graphql$Graphql$Internal$Encode$int))),
				_Utils_Tuple2(
				'_is_null',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.H, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'_lt',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.S, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'_lte',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.T, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'_neq',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.I, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'_nin',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.J,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($dillonkearns$elm_graphql$Graphql$Internal$Encode$int)))
			]));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$string = function (value) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(
		$elm$json$Json$Encode$string(value));
};
var $author$project$Gambling$InputObject$encodeString_comparison_exp = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_eq',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.F, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'_gt',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.Q, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'_gte',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.R, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'_ilike',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ch, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'_in',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.G,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($dillonkearns$elm_graphql$Graphql$Internal$Encode$string))),
				_Utils_Tuple2(
				'_iregex',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ci, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'_is_null',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.H, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'_like',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.cj, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'_lt',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.S, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'_lte',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.T, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'_neq',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.I, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'_nilike',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.cm, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'_nin',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.J,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($dillonkearns$elm_graphql$Graphql$Internal$Encode$string))),
				_Utils_Tuple2(
				'_niregex',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.cn, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'_nlike',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.co, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'_nregex',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.cp, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'_nsimilar',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.cq, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'_regex',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.cs, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'_similar',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.cv, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string))
			]));
};
var $author$project$Gambling$InputObject$encodeTimestamptz_comparison_exp = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_eq',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.F,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.ar;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'_gt',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.Q,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.ar;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'_gte',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.R,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.ar;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'_in',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.G,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						A2(
							$author$project$Gambling$Scalar$unwrapEncoder,
							function ($) {
								return $.ar;
							},
							$author$project$ScalarCodecs$codecs)))),
				_Utils_Tuple2(
				'_is_null',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.H, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'_lt',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.S,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.ar;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'_lte',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.T,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.ar;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'_neq',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.I,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.ar;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'_nin',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.J,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						A2(
							$author$project$Gambling$Scalar$unwrapEncoder,
							function ($) {
								return $.ar;
							},
							$author$project$ScalarCodecs$codecs))))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_teams_bool_exp = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_and',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.p,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_football_teams_bool_exp))),
				_Utils_Tuple2(
				'_not',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.q, $author$project$Gambling$InputObject$encodeGambling_football_teams_bool_exp)),
				_Utils_Tuple2(
				'_or',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.r,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_football_teams_bool_exp))),
				_Utils_Tuple2(
				'crest_url',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.at, $author$project$Gambling$InputObject$encodeString_comparison_exp)),
				_Utils_Tuple2(
				'id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'last_updated',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.n, $author$project$Gambling$InputObject$encodeTimestamptz_comparison_exp)),
				_Utils_Tuple2(
				'name',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.o, $author$project$Gambling$InputObject$encodeString_comparison_exp)),
				_Utils_Tuple2(
				'short_name',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.au, $author$project$Gambling$InputObject$encodeString_comparison_exp)),
				_Utils_Tuple2(
				'tla',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.av, $author$project$Gambling$InputObject$encodeString_comparison_exp))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competition_user_bool_exp = function (_v7) {
	var input____ = _v7;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_and',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.p,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_competition_user_bool_exp))),
				_Utils_Tuple2(
				'_not',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.q, $author$project$Gambling$InputObject$encodeGambling_competition_user_bool_exp)),
				_Utils_Tuple2(
				'_or',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.r,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_competition_user_bool_exp))),
				_Utils_Tuple2(
				'competition',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.v, $author$project$Gambling$InputObject$encodeGambling_competitions_bool_exp)),
				_Utils_Tuple2(
				'competition_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.b, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'user',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.y, $author$project$Gambling$InputObject$encodeGambling_users_bool_exp)),
				_Utils_Tuple2(
				'user_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.e, $author$project$Gambling$InputObject$encodeInt_comparison_exp))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competitions_bool_exp = function (_v6) {
	var input____ = _v6;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_and',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.p,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_competitions_bool_exp))),
				_Utils_Tuple2(
				'_not',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.q, $author$project$Gambling$InputObject$encodeGambling_competitions_bool_exp)),
				_Utils_Tuple2(
				'_or',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.r,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_competitions_bool_exp))),
				_Utils_Tuple2(
				'competition_users',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.as, $author$project$Gambling$InputObject$encodeGambling_competition_user_bool_exp)),
				_Utils_Tuple2(
				'created_at',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.s, $author$project$Gambling$InputObject$encodeTimestamptz_comparison_exp)),
				_Utils_Tuple2(
				'current_matches',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.b7, $author$project$Gambling$InputObject$encodeGambling_football_matches_bool_exp)),
				_Utils_Tuple2(
				'current_matches_count',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a3, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'football_season',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.D, $author$project$Gambling$InputObject$encodeGambling_football_seasons_bool_exp)),
				_Utils_Tuple2(
				'football_season_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.d, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'match_bets',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.U, $author$project$Gambling$InputObject$encodeGambling_match_bets_bool_exp)),
				_Utils_Tuple2(
				'matchday_bets_count',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bf, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'name',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.o, $author$project$Gambling$InputObject$encodeString_comparison_exp)),
				_Utils_Tuple2(
				'updated_at',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.u, $author$project$Gambling$InputObject$encodeTimestamptz_comparison_exp)),
				_Utils_Tuple2(
				'users',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.cz, $author$project$Gambling$InputObject$encodeGambling_users_bool_exp)),
				_Utils_Tuple2(
				'users_count',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bl, $author$project$Gambling$InputObject$encodeInt_comparison_exp))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_competitions_bool_exp = function (_v5) {
	var input____ = _v5;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_and',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.p,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_football_competitions_bool_exp))),
				_Utils_Tuple2(
				'_not',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.q, $author$project$Gambling$InputObject$encodeGambling_football_competitions_bool_exp)),
				_Utils_Tuple2(
				'_or',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.r,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_football_competitions_bool_exp))),
				_Utils_Tuple2(
				'area_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ah, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'code',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aq, $author$project$Gambling$InputObject$encodeString_comparison_exp)),
				_Utils_Tuple2(
				'current_season',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aI, $author$project$Gambling$InputObject$encodeGambling_football_seasons_bool_exp)),
				_Utils_Tuple2(
				'current_season_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ak, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'football_seasons',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a8, $author$project$Gambling$InputObject$encodeGambling_football_seasons_bool_exp)),
				_Utils_Tuple2(
				'id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'last_updated',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.n, $author$project$Gambling$InputObject$encodeTimestamptz_comparison_exp)),
				_Utils_Tuple2(
				'name',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.o, $author$project$Gambling$InputObject$encodeString_comparison_exp))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_matches_bool_exp = function (_v4) {
	var input____ = _v4;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_and',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.p,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_football_matches_bool_exp))),
				_Utils_Tuple2(
				'_not',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.q, $author$project$Gambling$InputObject$encodeGambling_football_matches_bool_exp)),
				_Utils_Tuple2(
				'_or',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.r,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_football_matches_bool_exp))),
				_Utils_Tuple2(
				'away_team',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aH, $author$project$Gambling$InputObject$encodeGambling_football_teams_bool_exp)),
				_Utils_Tuple2(
				'away_team_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.f, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'date',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.N, $author$project$Gambling$InputObject$encodeTimestamptz_comparison_exp)),
				_Utils_Tuple2(
				'football_season',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.D, $author$project$Gambling$InputObject$encodeGambling_football_seasons_bool_exp)),
				_Utils_Tuple2(
				'home_team',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aK, $author$project$Gambling$InputObject$encodeGambling_football_teams_bool_exp)),
				_Utils_Tuple2(
				'home_team_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.j, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'last_updated',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.n, $author$project$Gambling$InputObject$encodeTimestamptz_comparison_exp)),
				_Utils_Tuple2(
				'match_bets',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.U, $author$project$Gambling$InputObject$encodeGambling_match_bets_bool_exp)),
				_Utils_Tuple2(
				'matchday',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.k, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'result',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aM, $author$project$Gambling$InputObject$encodeGambling_football_results_bool_exp)),
				_Utils_Tuple2(
				'result_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.l, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'season_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.m, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'status',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.Y, $author$project$Gambling$InputObject$encodeString_comparison_exp))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_results_bool_exp = function (_v3) {
	var input____ = _v3;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_and',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.p,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_football_results_bool_exp))),
				_Utils_Tuple2(
				'_not',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.q, $author$project$Gambling$InputObject$encodeGambling_football_results_bool_exp)),
				_Utils_Tuple2(
				'_or',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.r,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_football_results_bool_exp))),
				_Utils_Tuple2(
				'away_team_goals',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ai, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'football_match',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.al, $author$project$Gambling$InputObject$encodeGambling_football_matches_bool_exp)),
				_Utils_Tuple2(
				'home_team_goals',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.am, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a, $author$project$Gambling$InputObject$encodeInt_comparison_exp))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_seasons_bool_exp = function (_v2) {
	var input____ = _v2;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_and',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.p,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_football_seasons_bool_exp))),
				_Utils_Tuple2(
				'_not',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.q, $author$project$Gambling$InputObject$encodeGambling_football_seasons_bool_exp)),
				_Utils_Tuple2(
				'_or',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.r,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_football_seasons_bool_exp))),
				_Utils_Tuple2(
				'competitions',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a1, $author$project$Gambling$InputObject$encodeGambling_competitions_bool_exp)),
				_Utils_Tuple2(
				'current_matchday',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.g, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'end_date',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.P, $author$project$Gambling$InputObject$encodeDate_comparison_exp)),
				_Utils_Tuple2(
				'football_competition',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aJ, $author$project$Gambling$InputObject$encodeGambling_football_competitions_bool_exp)),
				_Utils_Tuple2(
				'football_competition_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.i, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'football_matches',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a7, $author$project$Gambling$InputObject$encodeGambling_football_matches_bool_exp)),
				_Utils_Tuple2(
				'id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'start_date',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.X, $author$project$Gambling$InputObject$encodeDate_comparison_exp))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_match_bets_bool_exp = function (_v1) {
	var input____ = _v1;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_and',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.p,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_match_bets_bool_exp))),
				_Utils_Tuple2(
				'_not',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.q, $author$project$Gambling$InputObject$encodeGambling_match_bets_bool_exp)),
				_Utils_Tuple2(
				'_or',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.r,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_match_bets_bool_exp))),
				_Utils_Tuple2(
				'bet',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ap, $author$project$Gambling$InputObject$encodeGambling_bet_enum_comparison_exp)),
				_Utils_Tuple2(
				'competition',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.v, $author$project$Gambling$InputObject$encodeGambling_competitions_bool_exp)),
				_Utils_Tuple2(
				'competition_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.b, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'created_at',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.s, $author$project$Gambling$InputObject$encodeTimestamptz_comparison_exp)),
				_Utils_Tuple2(
				'footbal_match_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.h, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'football_match',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.al, $author$project$Gambling$InputObject$encodeGambling_football_matches_bool_exp)),
				_Utils_Tuple2(
				'id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'updated_at',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.u, $author$project$Gambling$InputObject$encodeTimestamptz_comparison_exp)),
				_Utils_Tuple2(
				'user',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.y, $author$project$Gambling$InputObject$encodeGambling_users_bool_exp)),
				_Utils_Tuple2(
				'user_auth0_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.E, $author$project$Gambling$InputObject$encodeString_comparison_exp))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_users_bool_exp = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_and',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.p,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_users_bool_exp))),
				_Utils_Tuple2(
				'_not',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.q, $author$project$Gambling$InputObject$encodeGambling_users_bool_exp)),
				_Utils_Tuple2(
				'_or',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.r,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_users_bool_exp))),
				_Utils_Tuple2(
				'auth0_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.C, $author$project$Gambling$InputObject$encodeString_comparison_exp)),
				_Utils_Tuple2(
				'avatar',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.K, $author$project$Gambling$InputObject$encodeString_comparison_exp)),
				_Utils_Tuple2(
				'competition_users',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.as, $author$project$Gambling$InputObject$encodeGambling_competition_user_bool_exp)),
				_Utils_Tuple2(
				'email',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.O, $author$project$Gambling$InputObject$encodeString_comparison_exp)),
				_Utils_Tuple2(
				'id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'match_bets',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.U, $author$project$Gambling$InputObject$encodeGambling_match_bets_bool_exp)),
				_Utils_Tuple2(
				'username',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ac, $author$project$Gambling$InputObject$encodeString_comparison_exp))
			]));
};
var $author$project$Gambling$Enum$Order_by$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'asc';
		case 1:
			return 'asc_nulls_first';
		case 2:
			return 'asc_nulls_last';
		case 3:
			return 'desc';
		case 4:
			return 'desc_nulls_first';
		default:
			return 'desc_nulls_last';
	}
};
var $author$project$Gambling$InputObject$encodeGambling_competition_user_avg_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'user_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.e,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competition_user_max_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'user_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.e,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competition_user_min_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'user_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.e,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competition_user_stddev_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'user_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.e,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competition_user_stddev_pop_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'user_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.e,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competition_user_stddev_samp_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'user_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.e,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competition_user_sum_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'user_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.e,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competition_user_var_pop_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'user_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.e,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competition_user_var_samp_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'user_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.e,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competition_user_variance_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'user_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.e,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competition_user_aggregate_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'avg',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.L, $author$project$Gambling$InputObject$encodeGambling_competition_user_avg_order_by)),
				_Utils_Tuple2(
				'count',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.M,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'max',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.V, $author$project$Gambling$InputObject$encodeGambling_competition_user_max_order_by)),
				_Utils_Tuple2(
				'min',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.W, $author$project$Gambling$InputObject$encodeGambling_competition_user_min_order_by)),
				_Utils_Tuple2(
				'stddev',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.Z, $author$project$Gambling$InputObject$encodeGambling_competition_user_stddev_order_by)),
				_Utils_Tuple2(
				'stddev_pop',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____._, $author$project$Gambling$InputObject$encodeGambling_competition_user_stddev_pop_order_by)),
				_Utils_Tuple2(
				'stddev_samp',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aa, $author$project$Gambling$InputObject$encodeGambling_competition_user_stddev_samp_order_by)),
				_Utils_Tuple2(
				'sum',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ab, $author$project$Gambling$InputObject$encodeGambling_competition_user_sum_order_by)),
				_Utils_Tuple2(
				'var_pop',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ad, $author$project$Gambling$InputObject$encodeGambling_competition_user_var_pop_order_by)),
				_Utils_Tuple2(
				'var_samp',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ae, $author$project$Gambling$InputObject$encodeGambling_competition_user_var_samp_order_by)),
				_Utils_Tuple2(
				'variance',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.af, $author$project$Gambling$InputObject$encodeGambling_competition_user_variance_order_by))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_matches_avg_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'away_team_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.f,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'home_team_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.j,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'matchday',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.k,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'result_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.l,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'season_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.m,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_matches_max_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'away_team_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.f,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'date',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.N,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'home_team_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.j,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'last_updated',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.n,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'matchday',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.k,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'result_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.l,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'season_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.m,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'status',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.Y,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_matches_min_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'away_team_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.f,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'date',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.N,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'home_team_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.j,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'last_updated',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.n,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'matchday',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.k,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'result_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.l,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'season_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.m,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'status',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.Y,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_matches_stddev_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'away_team_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.f,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'home_team_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.j,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'matchday',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.k,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'result_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.l,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'season_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.m,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_matches_stddev_pop_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'away_team_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.f,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'home_team_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.j,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'matchday',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.k,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'result_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.l,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'season_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.m,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_matches_stddev_samp_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'away_team_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.f,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'home_team_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.j,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'matchday',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.k,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'result_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.l,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'season_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.m,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_matches_sum_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'away_team_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.f,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'home_team_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.j,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'matchday',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.k,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'result_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.l,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'season_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.m,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_matches_var_pop_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'away_team_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.f,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'home_team_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.j,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'matchday',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.k,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'result_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.l,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'season_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.m,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_matches_var_samp_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'away_team_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.f,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'home_team_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.j,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'matchday',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.k,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'result_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.l,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'season_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.m,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_matches_variance_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'away_team_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.f,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'home_team_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.j,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'matchday',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.k,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'result_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.l,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'season_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.m,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_matches_aggregate_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'avg',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.L, $author$project$Gambling$InputObject$encodeGambling_football_matches_avg_order_by)),
				_Utils_Tuple2(
				'count',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.M,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'max',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.V, $author$project$Gambling$InputObject$encodeGambling_football_matches_max_order_by)),
				_Utils_Tuple2(
				'min',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.W, $author$project$Gambling$InputObject$encodeGambling_football_matches_min_order_by)),
				_Utils_Tuple2(
				'stddev',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.Z, $author$project$Gambling$InputObject$encodeGambling_football_matches_stddev_order_by)),
				_Utils_Tuple2(
				'stddev_pop',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____._, $author$project$Gambling$InputObject$encodeGambling_football_matches_stddev_pop_order_by)),
				_Utils_Tuple2(
				'stddev_samp',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aa, $author$project$Gambling$InputObject$encodeGambling_football_matches_stddev_samp_order_by)),
				_Utils_Tuple2(
				'sum',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ab, $author$project$Gambling$InputObject$encodeGambling_football_matches_sum_order_by)),
				_Utils_Tuple2(
				'var_pop',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ad, $author$project$Gambling$InputObject$encodeGambling_football_matches_var_pop_order_by)),
				_Utils_Tuple2(
				'var_samp',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ae, $author$project$Gambling$InputObject$encodeGambling_football_matches_var_samp_order_by)),
				_Utils_Tuple2(
				'variance',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.af, $author$project$Gambling$InputObject$encodeGambling_football_matches_variance_order_by))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competitions_avg_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'football_season_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.d,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competitions_max_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'created_at',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.s,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'football_season_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.d,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'name',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.o,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'updated_at',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.u,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competitions_min_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'created_at',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.s,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'football_season_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.d,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'name',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.o,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'updated_at',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.u,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competitions_stddev_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'football_season_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.d,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competitions_stddev_pop_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'football_season_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.d,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competitions_stddev_samp_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'football_season_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.d,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competitions_sum_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'football_season_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.d,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competitions_var_pop_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'football_season_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.d,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competitions_var_samp_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'football_season_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.d,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competitions_variance_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'football_season_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.d,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competitions_aggregate_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'avg',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.L, $author$project$Gambling$InputObject$encodeGambling_competitions_avg_order_by)),
				_Utils_Tuple2(
				'count',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.M,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'max',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.V, $author$project$Gambling$InputObject$encodeGambling_competitions_max_order_by)),
				_Utils_Tuple2(
				'min',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.W, $author$project$Gambling$InputObject$encodeGambling_competitions_min_order_by)),
				_Utils_Tuple2(
				'stddev',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.Z, $author$project$Gambling$InputObject$encodeGambling_competitions_stddev_order_by)),
				_Utils_Tuple2(
				'stddev_pop',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____._, $author$project$Gambling$InputObject$encodeGambling_competitions_stddev_pop_order_by)),
				_Utils_Tuple2(
				'stddev_samp',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aa, $author$project$Gambling$InputObject$encodeGambling_competitions_stddev_samp_order_by)),
				_Utils_Tuple2(
				'sum',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ab, $author$project$Gambling$InputObject$encodeGambling_competitions_sum_order_by)),
				_Utils_Tuple2(
				'var_pop',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ad, $author$project$Gambling$InputObject$encodeGambling_competitions_var_pop_order_by)),
				_Utils_Tuple2(
				'var_samp',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ae, $author$project$Gambling$InputObject$encodeGambling_competitions_var_samp_order_by)),
				_Utils_Tuple2(
				'variance',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.af, $author$project$Gambling$InputObject$encodeGambling_competitions_variance_order_by))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_seasons_avg_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'current_matchday',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.g,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'football_competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.i,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_seasons_max_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'current_matchday',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.g,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'end_date',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.P,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'football_competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.i,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'start_date',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.X,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_seasons_min_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'current_matchday',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.g,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'end_date',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.P,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'football_competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.i,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'start_date',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.X,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_seasons_stddev_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'current_matchday',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.g,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'football_competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.i,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_seasons_stddev_pop_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'current_matchday',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.g,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'football_competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.i,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_seasons_stddev_samp_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'current_matchday',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.g,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'football_competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.i,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_seasons_sum_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'current_matchday',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.g,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'football_competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.i,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_seasons_var_pop_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'current_matchday',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.g,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'football_competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.i,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_seasons_var_samp_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'current_matchday',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.g,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'football_competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.i,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_seasons_variance_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'current_matchday',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.g,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'football_competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.i,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_seasons_aggregate_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'avg',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.L, $author$project$Gambling$InputObject$encodeGambling_football_seasons_avg_order_by)),
				_Utils_Tuple2(
				'count',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.M,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'max',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.V, $author$project$Gambling$InputObject$encodeGambling_football_seasons_max_order_by)),
				_Utils_Tuple2(
				'min',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.W, $author$project$Gambling$InputObject$encodeGambling_football_seasons_min_order_by)),
				_Utils_Tuple2(
				'stddev',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.Z, $author$project$Gambling$InputObject$encodeGambling_football_seasons_stddev_order_by)),
				_Utils_Tuple2(
				'stddev_pop',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____._, $author$project$Gambling$InputObject$encodeGambling_football_seasons_stddev_pop_order_by)),
				_Utils_Tuple2(
				'stddev_samp',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aa, $author$project$Gambling$InputObject$encodeGambling_football_seasons_stddev_samp_order_by)),
				_Utils_Tuple2(
				'sum',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ab, $author$project$Gambling$InputObject$encodeGambling_football_seasons_sum_order_by)),
				_Utils_Tuple2(
				'var_pop',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ad, $author$project$Gambling$InputObject$encodeGambling_football_seasons_var_pop_order_by)),
				_Utils_Tuple2(
				'var_samp',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ae, $author$project$Gambling$InputObject$encodeGambling_football_seasons_var_samp_order_by)),
				_Utils_Tuple2(
				'variance',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.af, $author$project$Gambling$InputObject$encodeGambling_football_seasons_variance_order_by))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_competitions_order_by = function (_v1) {
	var input____ = _v1;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'area_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.ah,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'code',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.aq,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'current_season',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aI, $author$project$Gambling$InputObject$encodeGambling_football_seasons_order_by)),
				_Utils_Tuple2(
				'current_season_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.ak,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'football_seasons_aggregate',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ce, $author$project$Gambling$InputObject$encodeGambling_football_seasons_aggregate_order_by)),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'last_updated',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.n,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'name',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.o,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_seasons_order_by = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competitions_aggregate',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.b6, $author$project$Gambling$InputObject$encodeGambling_competitions_aggregate_order_by)),
				_Utils_Tuple2(
				'current_matchday',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.g,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'end_date',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.P,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'football_competition',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aJ, $author$project$Gambling$InputObject$encodeGambling_football_competitions_order_by)),
				_Utils_Tuple2(
				'football_competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.i,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'football_matches_aggregate',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.cd, $author$project$Gambling$InputObject$encodeGambling_football_matches_aggregate_order_by)),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'start_date',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.X,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_match_bets_avg_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'footbal_match_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.h,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_match_bets_max_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'created_at',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.s,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'footbal_match_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.h,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'updated_at',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.u,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'user_auth0_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.E,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_match_bets_min_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'created_at',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.s,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'footbal_match_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.h,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'updated_at',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.u,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'user_auth0_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.E,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_match_bets_stddev_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'footbal_match_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.h,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_match_bets_stddev_pop_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'footbal_match_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.h,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_match_bets_stddev_samp_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'footbal_match_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.h,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_match_bets_sum_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'footbal_match_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.h,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_match_bets_var_pop_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'footbal_match_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.h,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_match_bets_var_samp_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'footbal_match_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.h,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_match_bets_variance_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'footbal_match_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.h,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_match_bets_aggregate_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'avg',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.L, $author$project$Gambling$InputObject$encodeGambling_match_bets_avg_order_by)),
				_Utils_Tuple2(
				'count',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.M,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'max',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.V, $author$project$Gambling$InputObject$encodeGambling_match_bets_max_order_by)),
				_Utils_Tuple2(
				'min',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.W, $author$project$Gambling$InputObject$encodeGambling_match_bets_min_order_by)),
				_Utils_Tuple2(
				'stddev',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.Z, $author$project$Gambling$InputObject$encodeGambling_match_bets_stddev_order_by)),
				_Utils_Tuple2(
				'stddev_pop',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____._, $author$project$Gambling$InputObject$encodeGambling_match_bets_stddev_pop_order_by)),
				_Utils_Tuple2(
				'stddev_samp',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aa, $author$project$Gambling$InputObject$encodeGambling_match_bets_stddev_samp_order_by)),
				_Utils_Tuple2(
				'sum',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ab, $author$project$Gambling$InputObject$encodeGambling_match_bets_sum_order_by)),
				_Utils_Tuple2(
				'var_pop',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ad, $author$project$Gambling$InputObject$encodeGambling_match_bets_var_pop_order_by)),
				_Utils_Tuple2(
				'var_samp',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ae, $author$project$Gambling$InputObject$encodeGambling_match_bets_var_samp_order_by)),
				_Utils_Tuple2(
				'variance',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.af, $author$project$Gambling$InputObject$encodeGambling_match_bets_variance_order_by))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_users_avg_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_users_max_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'auth0_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.C,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'avatar',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.K,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'email',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.O,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'username',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.ac,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_users_min_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'auth0_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.C,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'avatar',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.K,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'email',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.O,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'username',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.ac,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_users_stddev_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_users_stddev_pop_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_users_stddev_samp_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_users_sum_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_users_var_pop_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_users_var_samp_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_users_variance_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_users_aggregate_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'avg',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.L, $author$project$Gambling$InputObject$encodeGambling_users_avg_order_by)),
				_Utils_Tuple2(
				'count',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.M,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'max',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.V, $author$project$Gambling$InputObject$encodeGambling_users_max_order_by)),
				_Utils_Tuple2(
				'min',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.W, $author$project$Gambling$InputObject$encodeGambling_users_min_order_by)),
				_Utils_Tuple2(
				'stddev',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.Z, $author$project$Gambling$InputObject$encodeGambling_users_stddev_order_by)),
				_Utils_Tuple2(
				'stddev_pop',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____._, $author$project$Gambling$InputObject$encodeGambling_users_stddev_pop_order_by)),
				_Utils_Tuple2(
				'stddev_samp',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aa, $author$project$Gambling$InputObject$encodeGambling_users_stddev_samp_order_by)),
				_Utils_Tuple2(
				'sum',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ab, $author$project$Gambling$InputObject$encodeGambling_users_sum_order_by)),
				_Utils_Tuple2(
				'var_pop',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ad, $author$project$Gambling$InputObject$encodeGambling_users_var_pop_order_by)),
				_Utils_Tuple2(
				'var_samp',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ae, $author$project$Gambling$InputObject$encodeGambling_users_var_samp_order_by)),
				_Utils_Tuple2(
				'variance',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.af, $author$project$Gambling$InputObject$encodeGambling_users_variance_order_by))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competitions_order_by = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition_users_aggregate',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a0, $author$project$Gambling$InputObject$encodeGambling_competition_user_aggregate_order_by)),
				_Utils_Tuple2(
				'created_at',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.s,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'current_matches_aggregate',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.b8, $author$project$Gambling$InputObject$encodeGambling_football_matches_aggregate_order_by)),
				_Utils_Tuple2(
				'current_matches_count',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a3,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'football_season',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.D, $author$project$Gambling$InputObject$encodeGambling_football_seasons_order_by)),
				_Utils_Tuple2(
				'football_season_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.d,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'match_bets_aggregate',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aL, $author$project$Gambling$InputObject$encodeGambling_match_bets_aggregate_order_by)),
				_Utils_Tuple2(
				'matchday_bets_count',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.bf,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'name',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.o,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'updated_at',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.u,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'users_aggregate',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.cA, $author$project$Gambling$InputObject$encodeGambling_users_aggregate_order_by)),
				_Utils_Tuple2(
				'users_count',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.bl,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_results_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'away_team_goals',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.ai,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'football_match_aggregate',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.cc, $author$project$Gambling$InputObject$encodeGambling_football_matches_aggregate_order_by)),
				_Utils_Tuple2(
				'home_team_goals',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.am,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_teams_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'crest_url',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.at,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'last_updated',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.n,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'name',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.o,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'short_name',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.au,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'tla',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.av,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_matches_order_by = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'away_team',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aH, $author$project$Gambling$InputObject$encodeGambling_football_teams_order_by)),
				_Utils_Tuple2(
				'away_team_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.f,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'date',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.N,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'football_season',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.D, $author$project$Gambling$InputObject$encodeGambling_football_seasons_order_by)),
				_Utils_Tuple2(
				'home_team',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aK, $author$project$Gambling$InputObject$encodeGambling_football_teams_order_by)),
				_Utils_Tuple2(
				'home_team_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.j,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'last_updated',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.n,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'match_bets_aggregate',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aL, $author$project$Gambling$InputObject$encodeGambling_match_bets_aggregate_order_by)),
				_Utils_Tuple2(
				'matchday',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.k,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'result',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aM, $author$project$Gambling$InputObject$encodeGambling_football_results_order_by)),
				_Utils_Tuple2(
				'result_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.l,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'season_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.m,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'status',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.Y,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_users_order_by = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'auth0_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.C,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'avatar',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.K,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'competition_users_aggregate',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a0, $author$project$Gambling$InputObject$encodeGambling_competition_user_aggregate_order_by)),
				_Utils_Tuple2(
				'email',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.O,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'match_bets_aggregate',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aL, $author$project$Gambling$InputObject$encodeGambling_match_bets_aggregate_order_by)),
				_Utils_Tuple2(
				'username',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.ac,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_match_bets_order_by = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'bet',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.ap,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'competition',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.v, $author$project$Gambling$InputObject$encodeGambling_competitions_order_by)),
				_Utils_Tuple2(
				'competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'created_at',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.s,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'footbal_match_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.h,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'football_match',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.al, $author$project$Gambling$InputObject$encodeGambling_football_matches_order_by)),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'updated_at',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.u,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'user',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.y, $author$project$Gambling$InputObject$encodeGambling_users_order_by)),
				_Utils_Tuple2(
				'user_auth0_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.E,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $elm$json$Json$Decode$list = _Json_decodeList;
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional = F3(
	function (fieldName, maybeValue, toValue) {
		switch (maybeValue.$) {
			case 0:
				var value = maybeValue.a;
				return $elm$core$Maybe$Just(
					A2(
						$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument,
						fieldName,
						toValue(value)));
			case 1:
				return $elm$core$Maybe$Nothing;
			default:
				return $elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument, fieldName, $dillonkearns$elm_graphql$Graphql$Internal$Encode$null));
		}
	});
var $author$project$Gambling$Enum$Gambling_match_bets_select_column$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'bet';
		case 1:
			return 'competition_id';
		case 2:
			return 'created_at';
		case 3:
			return 'footbal_match_id';
		case 4:
			return 'id';
		case 5:
			return 'updated_at';
		default:
			return 'user_auth0_id';
	}
};
var $author$project$Gambling$Object$Gambling_football_matches$match_bets = F2(
	function (fillInOptionals____, object____) {
		var filledInOptionals____ = fillInOptionals____(
			{ax: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ay: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, az: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aA: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, c: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'distinct_on',
					filledInOptionals____.ax,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Gambling_match_bets_select_column$toString))),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'limit', filledInOptionals____.ay, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'offset', filledInOptionals____.az, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'order_by',
					filledInOptionals____.aA,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_match_bets_order_by)),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'where', filledInOptionals____.c, $author$project$Gambling$InputObject$encodeGambling_match_bets_bool_exp)
				]));
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'match_bets',
			optionalArgs____,
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
	});
var $author$project$Gambling$Object$Gambling_football_matches$result = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'result',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Page$Competition$footballMatchesSelection = function (competitionId) {
	return A7(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$map6,
		$author$project$Page$Competition$Match,
		$author$project$Gambling$Object$Gambling_football_matches$id,
		$author$project$Gambling$Object$Gambling_football_matches$date,
		$author$project$Gambling$Object$Gambling_football_matches$home_team($author$project$Page$Competition$footballTeamSelection),
		$author$project$Gambling$Object$Gambling_football_matches$away_team($author$project$Page$Competition$footballTeamSelection),
		$author$project$Gambling$Object$Gambling_football_matches$result($author$project$Page$Competition$footballMatchResultSelection),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
			function (bets) {
				return $elm$core$Dict$fromList(
					A2(
						$elm$core$List$map,
						function (b) {
							return _Utils_Tuple2(b.y.bA, b);
						},
						bets));
			},
			A2(
				$author$project$Gambling$Object$Gambling_football_matches$match_bets,
				$author$project$Page$Competition$matchBetsArgs(competitionId),
				$author$project$Page$Competition$matchBetSelection)));
};
var $author$project$Gambling$Object$Gambling_competitions$football_season = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'football_season', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gambling$Object$Gambling_competitions$id = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Int', 'id', _List_Nil, $elm$json$Json$Decode$int);
var $author$project$Gambling$InputObject$encodeMatches_gambling_competitions_args = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'm',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ck, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int))
			]));
};
var $author$project$Gambling$Enum$Gambling_football_matches_select_column$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'away_team_id';
		case 1:
			return 'date';
		case 2:
			return 'home_team_id';
		case 3:
			return 'id';
		case 4:
			return 'last_updated';
		case 5:
			return 'matchday';
		case 6:
			return 'result_id';
		case 7:
			return 'season_id';
		default:
			return 'status';
	}
};
var $author$project$Gambling$Object$Gambling_competitions$matches = F3(
	function (fillInOptionals____, requiredArgs____, object____) {
		var filledInOptionals____ = fillInOptionals____(
			{ax: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ay: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, az: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aA: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, c: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'distinct_on',
					filledInOptionals____.ax,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Gambling_football_matches_select_column$toString))),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'limit', filledInOptionals____.ay, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'offset', filledInOptionals____.az, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'order_by',
					filledInOptionals____.aA,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_football_matches_order_by)),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'where', filledInOptionals____.c, $author$project$Gambling$InputObject$encodeGambling_football_matches_bool_exp)
				]));
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'matches',
			_Utils_ap(
				optionalArgs____,
				_List_fromArray(
					[
						A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'args', requiredArgs____.ej, $author$project$Gambling$InputObject$encodeMatches_gambling_competitions_args)
					])),
			object____,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Basics$identity,
				A2($elm$core$Basics$composeR, $elm$json$Json$Decode$list, $elm$json$Json$Decode$nullable)));
	});
var $author$project$Gambling$Enum$Gambling_users_select_column$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'auth0_id';
		case 1:
			return 'avatar';
		case 2:
			return 'email';
		case 3:
			return 'id';
		default:
			return 'username';
	}
};
var $author$project$Gambling$Object$Gambling_competitions$users = F2(
	function (fillInOptionals____, object____) {
		var filledInOptionals____ = fillInOptionals____(
			{ax: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ay: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, az: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aA: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, c: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'distinct_on',
					filledInOptionals____.ax,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Gambling_users_select_column$toString))),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'limit', filledInOptionals____.ay, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'offset', filledInOptionals____.az, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'order_by',
					filledInOptionals____.aA,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_users_order_by)),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'where', filledInOptionals____.c, $author$project$Gambling$InputObject$encodeGambling_users_bool_exp)
				]));
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'users',
			optionalArgs____,
			object____,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Basics$identity,
				A2($elm$core$Basics$composeR, $elm$json$Json$Decode$list, $elm$json$Json$Decode$nullable)));
	});
var $author$project$Page$Competition$matchdaySelection = F2(
	function (id, maybeMatchday) {
		return A5(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$map4,
			$author$project$Page$Competition$MatchdayData,
			$author$project$Gambling$Object$Gambling_competitions$id,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault,
				_List_Nil,
				A2($author$project$Gambling$Object$Gambling_competitions$users, $elm$core$Basics$identity, $author$project$Page$Competition$userSelection)),
			$author$project$Gambling$Object$Gambling_competitions$football_season(
				A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, 0, $author$project$Gambling$Object$Gambling_football_seasons$current_matchday)),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
				$elm$core$Maybe$withDefault(_List_Nil),
				A3(
					$author$project$Gambling$Object$Gambling_competitions$matches,
					$elm$core$Basics$identity,
					{
						ej: $author$project$Gambling$InputObject$buildMatches_gambling_competitions_args(
							function (_v0) {
								if (maybeMatchday.$ === 1) {
									return {ck: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent};
								} else {
									var matchday = maybeMatchday.a;
									return {
										ck: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(matchday)
									};
								}
							})
					},
					$author$project$Page$Competition$footballMatchesSelection(id))));
	});
var $krisajenkins$remotedata$RemoteData$Failure = function (a) {
	return {$: 2, a: a};
};
var $krisajenkins$remotedata$RemoteData$Success = function (a) {
	return {$: 3, a: a};
};
var $krisajenkins$remotedata$RemoteData$fromResult = function (result) {
	if (result.$ === 1) {
		var e = result.a;
		return $krisajenkins$remotedata$RemoteData$Failure(e);
	} else {
		var x = result.a;
		return $krisajenkins$remotedata$RemoteData$Success(x);
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$Query = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Http$Request = $elm$core$Basics$identity;
var $dillonkearns$elm_graphql$Graphql$Document$decoder = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($elm$json$Json$Decode$field, 'data', decoder_);
};
var $dillonkearns$elm_graphql$Graphql$Http$queryRequest = F2(
	function (baseUrl, query) {
		return {
			cF: baseUrl,
			c2: A2($dillonkearns$elm_graphql$Graphql$Http$Query, $elm$core$Maybe$Nothing, query),
			ca: $dillonkearns$elm_graphql$Graphql$Document$decoder(query),
			aT: _List_Nil,
			cr: $elm$core$Maybe$Nothing,
			bV: _List_Nil,
			aY: $elm$core$Maybe$Nothing,
			b3: false
		};
	});
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {fc: reqs, fn: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.fr;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.fc));
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.fn)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					fR: r.fR,
					f$: r.f$,
					ca: A2(_Http_mapExpect, func, r.ca),
					aT: r.aT,
					bH: r.bH,
					aY: r.aY,
					fr: r.fr,
					h3: r.h3
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{fR: false, f$: r.f$, ca: r.ca, aT: r.aT, bH: r.bH, aY: r.aY, fr: r.fr, h3: r.h3}));
};
var $elm$http$Http$riskyRequest = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{fR: true, f$: r.f$, ca: r.ca, aT: r.aT, bH: r.bH, aY: r.aY, fr: r.fr, h3: r.h3}));
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Http$HttpError = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$convertResult = function (httpResult) {
	if (!httpResult.$) {
		var successOrError = httpResult.a;
		if (!successOrError.$) {
			var value = successOrError.a;
			return $elm$core$Result$Ok(value);
		} else {
			var _v2 = successOrError.a;
			var possiblyParsedData = _v2.a;
			var error = _v2.b;
			return $elm$core$Result$Err(
				A2($dillonkearns$elm_graphql$Graphql$Http$GraphqlError, possiblyParsedData, error));
		}
	} else {
		var httpError = httpResult.a;
		return $elm$core$Result$Err(
			$dillonkearns$elm_graphql$Graphql$Http$HttpError(httpError));
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$BadPayload = function (a) {
	return {$: 4, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$BadStatus = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$NetworkError = {$: 2};
var $dillonkearns$elm_graphql$Graphql$Http$Timeout = {$: 1};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $dillonkearns$elm_graphql$Graphql$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			function (response) {
				switch (response.$) {
					case 0:
						var url = response.a;
						return $elm$core$Result$Err(
							$dillonkearns$elm_graphql$Graphql$Http$BadUrl(url));
					case 1:
						return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$Timeout);
					case 2:
						return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$NetworkError);
					case 3:
						var metadata = response.a;
						var body = response.b;
						return $elm$core$Result$Err(
							A2($dillonkearns$elm_graphql$Graphql$Http$BadStatus, metadata, body));
					default:
						var metadata = response.a;
						var body = response.b;
						var _v1 = A2($elm$json$Json$Decode$decodeString, decoder, body);
						if (!_v1.$) {
							var value = _v1.a;
							return $elm$core$Result$Ok(value);
						} else {
							var err = _v1.a;
							return $elm$core$Result$Err(
								$dillonkearns$elm_graphql$Graphql$Http$BadPayload(err));
						}
				}
			});
	});
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Get = 0;
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Post = 1;
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$maxLength = 2000;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation = F2(
	function (replacements, _v0) {
		var match = _v0.gY;
		var ordinalString = A2(
			$elm$core$Basics$composeL,
			$elm$core$String$dropLeft(1),
			$elm$core$String$dropRight(1))(match);
		return A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$andThen,
				function (value) {
					return A2($elm$core$Array$get, value, replacements);
				},
				$elm$core$String$toInt(ordinalString)));
	});
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{aO: nodeList, aF: nodeListSize, aN: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {gF: index, gY: match, g1: number, hC: submatches};
	});
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{f8: false, g$: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $lukewestby$elm_string_interpolate$String$Interpolate$interpolationRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('\\{\\d+\\}'));
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $lukewestby$elm_string_interpolate$String$Interpolate$interpolate = F2(
	function (string, args) {
		var asArray = $elm$core$Array$fromList(args);
		return A3(
			$elm$regex$Regex$replace,
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolationRegex,
			$lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation(asArray),
			string);
	});
var $dillonkearns$elm_graphql$Graphql$Document$Indent$spaces = function (n) {
	return (n > 0) ? (' ' + $dillonkearns$elm_graphql$Graphql$Document$Indent$spaces(n - 1)) : '';
};
var $dillonkearns$elm_graphql$Graphql$Document$Indent$generate = function (indentationLevel) {
	return $dillonkearns$elm_graphql$Graphql$Document$Indent$spaces(indentationLevel * 2);
};
var $dillonkearns$elm_graphql$Graphql$RawField$typename = A2(
	$dillonkearns$elm_graphql$Graphql$RawField$Leaf,
	{ey: '__typename', h0: ''},
	_List_Nil);
var $dillonkearns$elm_graphql$Graphql$Document$Field$nonemptyChildren = function (children) {
	return $elm$core$List$isEmpty(children) ? A2($elm$core$List$cons, $dillonkearns$elm_graphql$Graphql$RawField$typename, children) : children;
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$serialize = F3(
	function (aliasName, mIndentationLevel, field) {
		var prefix = function () {
			if (!aliasName.$) {
				var aliasName_ = aliasName.a;
				return _Utils_ap(
					aliasName_,
					function () {
						if (!mIndentationLevel.$) {
							return ': ';
						} else {
							return ':';
						}
					}());
			} else {
				return '';
			}
		}();
		return A2(
			$elm$core$Maybe$map,
			function (string) {
				return _Utils_ap(
					$dillonkearns$elm_graphql$Graphql$Document$Indent$generate(
						A2($elm$core$Maybe$withDefault, 0, mIndentationLevel)),
					_Utils_ap(prefix, string));
			},
			function () {
				if (!field.$) {
					var fieldName = field.a;
					var args = field.b;
					var children = field.c;
					if (mIndentationLevel.$ === 1) {
						return $elm$core$Maybe$Just(
							(fieldName + ($dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args) + ('{' + A2($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren, $elm$core$Maybe$Nothing, children)))) + '}');
					} else {
						var indentationLevel = mIndentationLevel.a;
						return $elm$core$Maybe$Just(
							(fieldName + ($dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args) + (' {\n' + A2(
								$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren,
								$elm$core$Maybe$Just(indentationLevel),
								children)))) + ('\n' + ($dillonkearns$elm_graphql$Graphql$Document$Indent$generate(indentationLevel) + '}')));
					}
				} else {
					var fieldName = field.a.ey;
					var args = field.b;
					return $elm$core$Maybe$Just(
						_Utils_ap(
							fieldName,
							$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args)));
				}
			}());
	});
var $dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren = F2(
	function (indentationLevel, children) {
		return A2(
			$elm$core$String$join,
			function () {
				if (!indentationLevel.$) {
					return '\n';
				} else {
					return ' ';
				}
			}(),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				A2(
					$elm$core$List$map,
					function (field) {
						return A3(
							$dillonkearns$elm_graphql$Graphql$Document$Field$serialize,
							$dillonkearns$elm_graphql$Graphql$Document$Field$alias(field),
							A2(
								$elm$core$Maybe$map,
								$elm$core$Basics$add(1),
								indentationLevel),
							field);
					},
					$dillonkearns$elm_graphql$Graphql$Document$Field$nonemptyChildren(children))));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serialize = F2(
	function (operationType, queries) {
		return A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			'{0} {\n{1}\n}',
			_List_fromArray(
				[
					operationType,
					A2(
					$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren,
					$elm$core$Maybe$Just(0),
					queries)
				]));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeQuery = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($dillonkearns$elm_graphql$Graphql$Document$serialize, 'query', fields);
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrl = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return '{' + (A2($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren, $elm$core$Maybe$Nothing, fields) + '}');
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrlWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return 'query ' + (operationName + (' {' + (A2($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren, $elm$core$Maybe$Nothing, fields) + '}')));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName = F3(
	function (operationType, operationName, queries) {
		return A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			'{0} {1} {\n{2}\n}',
			_List_fromArray(
				[
					operationType,
					operationName,
					A2(
					$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren,
					$elm$core$Maybe$Just(0),
					queries)
				]));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return A3($dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName, 'query', operationName, fields);
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$replace = F2(
	function (old, _new) {
		return A2(
			$elm$core$Basics$composeR,
			$elm$core$String$split(old),
			$elm$core$String$join(_new));
	});
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape = A2(
	$elm$core$Basics$composeR,
	$elm$url$Url$percentEncode,
	A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$replace, '%20', '+'));
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape(key) + ('=' + $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape(value));
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$joinUrlEncoded = function (args) {
	return A2(
		$elm$core$String$join,
		'&',
		A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryPair, args));
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams = F2(
	function (queryParams, url) {
		return $elm$core$List$isEmpty(queryParams) ? url : (url + ('?' + $dillonkearns$elm_graphql$Graphql$Http$QueryParams$joinUrlEncoded(queryParams)));
	});
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$build = F5(
	function (forceMethod, url, queryParams, maybeOperationName, queryDocument) {
		var _v0 = function () {
			if (!maybeOperationName.$) {
				var operationName = maybeOperationName.a;
				return _Utils_Tuple2(
					A2($dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrlWithOperationName, operationName, queryDocument),
					_List_fromArray(
						[
							_Utils_Tuple2('operationName', operationName)
						]));
			} else {
				return _Utils_Tuple2(
					$dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrl(queryDocument),
					_List_Nil);
			}
		}();
		var serializedQueryForGetRequest = _v0.a;
		var operationNameParamForGetRequest = _v0.b;
		var urlForGetRequest = A2(
			$dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams,
			_Utils_ap(
				queryParams,
				_Utils_ap(
					_List_fromArray(
						[
							_Utils_Tuple2('query', serializedQueryForGetRequest)
						]),
					operationNameParamForGetRequest)),
			url);
		if (_Utils_eq(
			forceMethod,
			$elm$core$Maybe$Just(1)) || ((_Utils_cmp(
			$elm$core$String$length(urlForGetRequest),
			$dillonkearns$elm_graphql$Graphql$Http$QueryHelper$maxLength) > -1) && (!_Utils_eq(
			forceMethod,
			$elm$core$Maybe$Just(0))))) {
			var _v2 = function () {
				if (!maybeOperationName.$) {
					var operationName = maybeOperationName.a;
					return _Utils_Tuple2(
						A2($dillonkearns$elm_graphql$Graphql$Document$serializeQueryWithOperationName, operationName, queryDocument),
						_List_fromArray(
							[
								_Utils_Tuple2(
								'operationName',
								$elm$json$Json$Encode$string(operationName))
							]));
				} else {
					return _Utils_Tuple2(
						$dillonkearns$elm_graphql$Graphql$Document$serializeQuery(queryDocument),
						_List_Nil);
				}
			}();
			var serializedQuery = _v2.a;
			var operationNameParamForPostRequest = _v2.b;
			return {
				f$: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						_Utils_ap(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'query',
									$elm$json$Json$Encode$string(serializedQuery))
								]),
							operationNameParamForPostRequest))),
				bH: 1,
				h3: A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams, _List_Nil, url)
			};
		} else {
			return {f$: $elm$http$Http$emptyBody, bH: 0, h3: urlForGetRequest};
		}
	});
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$ParsedData = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$GraphqlError = F3(
	function (message, locations, details) {
		return {c2: details, gW: locations, gZ: message};
	});
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$Location = F2(
	function (line, column) {
		return {gf: column, gV: line};
	});
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$locationDecoder = A3(
	$elm$json$Json$Decode$map2,
	$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$Location,
	A2($elm$json$Json$Decode$field, 'line', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'column', $elm$json$Json$Decode$int));
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$decoder = A2(
	$elm$json$Json$Decode$field,
	'errors',
	$elm$json$Json$Decode$list(
		A4(
			$elm$json$Json$Decode$map3,
			$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$GraphqlError,
			A2($elm$json$Json$Decode$field, 'message', $elm$json$Json$Decode$string),
			$elm$json$Json$Decode$maybe(
				A2(
					$elm$json$Json$Decode$field,
					'locations',
					$elm$json$Json$Decode$list($dillonkearns$elm_graphql$Graphql$Http$GraphqlError$locationDecoder))),
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$Dict$remove('locations'),
				A2(
					$elm$json$Json$Decode$map,
					$elm$core$Dict$remove('message'),
					$elm$json$Json$Decode$dict($elm$json$Json$Decode$value))))));
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData = function (data) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Result$Err,
		A2(
			$elm$json$Json$Decode$map,
			$elm$core$Tuple$pair(data),
			$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$decoder));
};
var $dillonkearns$elm_graphql$Graphql$Http$nullJsonValue = function (a) {
	nullJsonValue:
	while (true) {
		var _v0 = A2($elm$json$Json$Decode$decodeString, $elm$json$Json$Decode$value, 'null');
		if (!_v0.$) {
			var value = _v0.a;
			return value;
		} else {
			var $temp$a = 0;
			a = $temp$a;
			continue nullJsonValue;
		}
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$errorDecoder = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				A2($elm$json$Json$Decode$map, $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$ParsedData, decoder)),
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				A2(
					$elm$json$Json$Decode$map,
					$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData,
					A2($elm$json$Json$Decode$field, 'data', $elm$json$Json$Decode$value))),
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				$elm$json$Json$Decode$succeed(
					$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData(
						$dillonkearns$elm_graphql$Graphql$Http$nullJsonValue(0))))
			]));
};
var $dillonkearns$elm_graphql$Graphql$Http$decoderOrError = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$dillonkearns$elm_graphql$Graphql$Http$errorDecoder(decoder),
				A2($elm$json$Json$Decode$map, $elm$core$Result$Ok, decoder)
			]));
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeMutation = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($dillonkearns$elm_graphql$Graphql$Document$serialize, 'mutation', fields);
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeMutationWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return A3($dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName, 'mutation', operationName, fields);
	});
var $dillonkearns$elm_graphql$Graphql$Http$toReadyRequest = function (_v0) {
	var request = _v0;
	var _v1 = request.c2;
	if (!_v1.$) {
		var forcedRequestMethod = _v1.a;
		var querySelectionSet = _v1.b;
		var queryRequestDetails = A5(
			$dillonkearns$elm_graphql$Graphql$Http$QueryHelper$build,
			function () {
				if (!forcedRequestMethod.$) {
					if (!forcedRequestMethod.a) {
						var _v4 = forcedRequestMethod.a;
						return $elm$core$Maybe$Just(0);
					} else {
						var _v5 = forcedRequestMethod.a;
						return $elm$core$Maybe$Nothing;
					}
				} else {
					return $elm$core$Maybe$Just(1);
				}
			}(),
			request.cF,
			request.bV,
			request.cr,
			querySelectionSet);
		return {
			f$: queryRequestDetails.f$,
			gm: $dillonkearns$elm_graphql$Graphql$Http$decoderOrError(request.ca),
			aT: request.aT,
			bH: function () {
				var _v2 = queryRequestDetails.bH;
				if (!_v2) {
					return 'GET';
				} else {
					return 'Post';
				}
			}(),
			aY: request.aY,
			h3: queryRequestDetails.h3
		};
	} else {
		var mutationSelectionSet = _v1.a;
		var serializedMutation = function () {
			var _v6 = request.cr;
			if (!_v6.$) {
				var operationName = _v6.a;
				return A2($dillonkearns$elm_graphql$Graphql$Document$serializeMutationWithOperationName, operationName, mutationSelectionSet);
			} else {
				return $dillonkearns$elm_graphql$Graphql$Document$serializeMutation(mutationSelectionSet);
			}
		}();
		return {
			f$: $elm$http$Http$jsonBody(
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'query',
							$elm$json$Json$Encode$string(serializedMutation))
						]))),
			gm: $dillonkearns$elm_graphql$Graphql$Http$decoderOrError(request.ca),
			aT: request.aT,
			bH: 'POST',
			aY: request.aY,
			h3: A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams, request.bV, request.cF)
		};
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$toHttpRequestRecord = F2(
	function (resultToMessage, fullRequest) {
		var request = fullRequest;
		return function (readyRequest) {
			return {
				f$: readyRequest.f$,
				ca: A2(
					$dillonkearns$elm_graphql$Graphql$Http$expectJson,
					A2($elm$core$Basics$composeR, $dillonkearns$elm_graphql$Graphql$Http$convertResult, resultToMessage),
					readyRequest.gm),
				aT: readyRequest.aT,
				bH: readyRequest.bH,
				aY: readyRequest.aY,
				fr: $elm$core$Maybe$Nothing,
				h3: readyRequest.h3
			};
		}(
			$dillonkearns$elm_graphql$Graphql$Http$toReadyRequest(fullRequest));
	});
var $dillonkearns$elm_graphql$Graphql$Http$send = F2(
	function (resultToMessage, fullRequest) {
		var request = fullRequest;
		return (request.b3 ? $elm$http$Http$riskyRequest : $elm$http$Http$request)(
			A2($dillonkearns$elm_graphql$Graphql$Http$toHttpRequestRecord, resultToMessage, fullRequest));
	});
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $dillonkearns$elm_graphql$Graphql$Http$withHeader = F3(
	function (key, value, _v0) {
		var request = _v0;
		return _Utils_update(
			request,
			{
				aT: A2(
					$elm$core$List$cons,
					A2($elm$http$Http$header, key, value),
					request.aT)
			});
	});
var $author$project$Api$sendQuery = F3(
	function (user, toMsg, query) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, toMsg),
			A3(
				$dillonkearns$elm_graphql$Graphql$Http$withHeader,
				'Authorization',
				'Bearer ' + user.fq,
				A2($dillonkearns$elm_graphql$Graphql$Http$queryRequest, '/v1/graphql', query)));
	});
var $author$project$Page$Competition$getMatchday = F3(
	function (user, id, maybeMatchday) {
		var query = A2(
			$author$project$Gambling$Query$gambling_competitions_by_pk,
			{a: id},
			A2($author$project$Page$Competition$matchdaySelection, id, maybeMatchday));
		return A3(
			$author$project$Api$sendQuery,
			user,
			$author$project$Page$Competition$GotMatchday(maybeMatchday),
			query);
	});
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$here = _Time_here(0);
var $author$project$Page$Competition$getMatchdayAndTimezone = F2(
	function (user, id) {
		return $elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A3($author$project$Page$Competition$getMatchday, user, id, $elm$core$Maybe$Nothing),
					A2($elm$core$Task$perform, $author$project$Page$Competition$GotTimezone, $elm$time$Time$here)
				]));
	});
var $author$project$Page$Competition$init = F2(
	function (id, user) {
		return _Utils_Tuple2(
			{
				bx: id,
				aR: A2($author$project$Page$Competition$Matchday, $elm$core$Maybe$Nothing, $krisajenkins$remotedata$RemoteData$Loading),
				cx: $elm$time$Time$millisToPosix(0),
				cM: $elm$time$Time$utc,
				y: user
			},
			A2($author$project$Page$Competition$getMatchdayAndTimezone, user, id));
	});
var $author$project$Page$CreateCompetition$Model = F3(
	function (form, user, competitions) {
		return {a1: competitions, bb: form, y: user};
	});
var $author$project$Page$CreateCompetition$GotCompetitions = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$CreateCompetition$FootballCompetition = F3(
	function (id, code, name) {
		return {aq: code, a: id, o: name};
	});
var $author$project$Gambling$Object$Gambling_football_competitions$code = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'code',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gambling$Object$Gambling_football_competitions$id = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Int', 'id', _List_Nil, $elm$json$Json$Decode$int);
var $author$project$Gambling$Object$Gambling_football_competitions$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Page$CreateCompetition$footballCompetitionSelection = A4($dillonkearns$elm_graphql$Graphql$SelectionSet$map3, $author$project$Page$CreateCompetition$FootballCompetition, $author$project$Gambling$Object$Gambling_football_competitions$id, $author$project$Gambling$Object$Gambling_football_competitions$code, $author$project$Gambling$Object$Gambling_football_competitions$name);
var $author$project$Gambling$Enum$Gambling_football_competitions_select_column$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'area_id';
		case 1:
			return 'code';
		case 2:
			return 'current_season_id';
		case 3:
			return 'id';
		case 4:
			return 'last_updated';
		default:
			return 'name';
	}
};
var $author$project$Gambling$Query$gambling_football_competitions = F2(
	function (fillInOptionals____, object____) {
		var filledInOptionals____ = fillInOptionals____(
			{ax: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ay: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, az: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aA: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, c: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'distinct_on',
					filledInOptionals____.ax,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Gambling_football_competitions_select_column$toString))),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'limit', filledInOptionals____.ay, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'offset', filledInOptionals____.az, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'order_by',
					filledInOptionals____.aA,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_football_competitions_order_by)),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'where', filledInOptionals____.c, $author$project$Gambling$InputObject$encodeGambling_football_competitions_bool_exp)
				]));
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'gambling_football_competitions',
			optionalArgs____,
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
	});
var $author$project$Page$CreateCompetition$getFootballCompetitions = function (user) {
	var query = A2($author$project$Gambling$Query$gambling_football_competitions, $elm$core$Basics$identity, $author$project$Page$CreateCompetition$footballCompetitionSelection);
	return A3($author$project$Api$sendQuery, user, $author$project$Page$CreateCompetition$GotCompetitions, query);
};
var $author$project$Ui$Select$State = $elm$core$Basics$identity;
var $author$project$Ui$Select$init = function (id) {
	return {bs: 0, a: id, bI: false, bq: _List_Nil, hE: ''};
};
var $author$project$Page$CreateCompetition$init = function (user) {
	return _Utils_Tuple2(
		A3(
			$author$project$Page$CreateCompetition$Model,
			{
				cG: $author$project$Ui$Select$init('competition-select'),
				o: '',
				cR: $author$project$Ui$Select$init('users-select')
			},
			user,
			$krisajenkins$remotedata$RemoteData$Loading),
		$author$project$Page$CreateCompetition$getFootballCompetitions(user));
};
var $elm$core$Platform$Cmd$map = _Platform_map;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Main$changeRouteTo = F2(
	function (route, user) {
		switch (route.$) {
			case 0:
				return _Utils_Tuple2($author$project$Main$Home, $elm$core$Platform$Cmd$none);
			case 1:
				var _v1 = $author$project$Page$CreateCompetition$init(user);
				var createCompetitionModel = _v1.a;
				var createCompetitionCmdMsg = _v1.b;
				return _Utils_Tuple2(
					$author$project$Main$CreateCompetition(createCompetitionModel),
					A2($elm$core$Platform$Cmd$map, $author$project$Main$GotCreateCompetitionMsg, createCompetitionCmdMsg));
			default:
				var id = route.a;
				var _v2 = A2($author$project$Page$Competition$init, id, user);
				var competitionModel = _v2.a;
				var competitionCmdMsg = _v2.b;
				return _Utils_Tuple2(
					$author$project$Main$Competition(competitionModel),
					A2($elm$core$Platform$Cmd$map, $author$project$Main$GotCompetitionMsg, competitionCmdMsg));
		}
	});
var $author$project$Sidebar$GotCompetitions = $elm$core$Basics$identity;
var $author$project$Gambling$InputObject$Gambling_competition_user_bool_exp = $elm$core$Basics$identity;
var $author$project$Gambling$InputObject$buildGambling_competition_user_bool_exp = function (fillOptionals____) {
	var optionals____ = fillOptionals____(
		{p: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, v: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, b: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, a: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, q: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, r: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, y: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, e: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
	return {p: optionals____.p, v: optionals____.v, b: optionals____.b, a: optionals____.a, q: optionals____.q, r: optionals____.r, y: optionals____.y, e: optionals____.e};
};
var $author$project$Sidebar$buildCompetitionOptFields = function (args) {
	return _Utils_update(
		args,
		{
			as: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
				$author$project$Gambling$InputObject$buildGambling_competition_user_bool_exp($elm$core$Basics$identity))
		});
};
var $author$project$Gambling$Enum$Order_by$Desc = 3;
var $author$project$Gambling$InputObject$Gambling_football_seasons_order_by = $elm$core$Basics$identity;
var $author$project$Gambling$InputObject$buildGambling_football_seasons_order_by = function (fillOptionals____) {
	var optionals____ = fillOptionals____(
		{b6: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, g: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, P: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aJ: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, i: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, cd: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, a: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, X: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
	return {b6: optionals____.b6, g: optionals____.g, P: optionals____.P, aJ: optionals____.aJ, i: optionals____.i, cd: optionals____.cd, a: optionals____.a, X: optionals____.X};
};
var $author$project$Sidebar$buildCompetitionOrderBy = function (fields) {
	return _Utils_update(
		fields,
		{
			D: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
				$author$project$Gambling$InputObject$buildGambling_football_seasons_order_by(
					function (season_fields) {
						return _Utils_update(
							season_fields,
							{
								X: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(3)
							});
					}))
		});
};
var $author$project$Gambling$InputObject$Gambling_competitions_bool_exp = $elm$core$Basics$identity;
var $author$project$Gambling$InputObject$buildGambling_competitions_bool_exp = function (fillOptionals____) {
	var optionals____ = fillOptionals____(
		{p: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, as: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, s: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, b7: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, a3: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, D: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, d: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, a: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, U: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, bf: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, o: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, q: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, r: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, u: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, cz: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, bl: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
	return {p: optionals____.p, as: optionals____.as, s: optionals____.s, b7: optionals____.b7, a3: optionals____.a3, D: optionals____.D, d: optionals____.d, a: optionals____.a, U: optionals____.U, bf: optionals____.bf, o: optionals____.o, q: optionals____.q, r: optionals____.r, u: optionals____.u, cz: optionals____.cz, bl: optionals____.bl};
};
var $author$project$Gambling$InputObject$Gambling_competitions_order_by = $elm$core$Basics$identity;
var $author$project$Gambling$InputObject$buildGambling_competitions_order_by = function (fillOptionals____) {
	var optionals____ = fillOptionals____(
		{a0: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, s: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, b8: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, a3: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, D: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, d: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, a: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aL: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, bf: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, o: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, u: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, cA: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, bl: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
	return {a0: optionals____.a0, s: optionals____.s, b8: optionals____.b8, a3: optionals____.a3, D: optionals____.D, d: optionals____.d, a: optionals____.a, aL: optionals____.aL, bf: optionals____.bf, o: optionals____.o, u: optionals____.u, cA: optionals____.cA, bl: optionals____.bl};
};
var $author$project$Sidebar$buildCompetitionArgs = function (args) {
	return _Utils_update(
		args,
		{
			aA: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
				$elm$core$List$singleton(
					$author$project$Gambling$InputObject$buildGambling_competitions_order_by($author$project$Sidebar$buildCompetitionOrderBy))),
			c: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
				$author$project$Gambling$InputObject$buildGambling_competitions_bool_exp($author$project$Sidebar$buildCompetitionOptFields))
		});
};
var $author$project$Sidebar$Competition = F6(
	function (id, name, season, myBetsCount, matchesCount, usersCount) {
		return {a: id, eT: matchesCount, eX: myBetsCount, o: name, dd: season, fu: usersCount};
	});
var $author$project$Gambling$Object$Gambling_competitions$current_matches_count = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Int)',
	'current_matches_count',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $author$project$Sidebar$FootballSeason = F3(
	function (id, currentMatchday, footballCompetition) {
		return {c0: currentMatchday, dr: footballCompetition, a: id};
	});
var $author$project$Sidebar$FootballCompetition = F3(
	function (id, code, name) {
		return {aq: code, a: id, o: name};
	});
var $author$project$Sidebar$footballCompetitionSelection = A4($dillonkearns$elm_graphql$Graphql$SelectionSet$map3, $author$project$Sidebar$FootballCompetition, $author$project$Gambling$Object$Gambling_football_competitions$id, $author$project$Gambling$Object$Gambling_football_competitions$code, $author$project$Gambling$Object$Gambling_football_competitions$name);
var $author$project$Gambling$Object$Gambling_football_seasons$football_competition = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'football_competition', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gambling$Object$Gambling_football_seasons$id = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Int', 'id', _List_Nil, $elm$json$Json$Decode$int);
var $author$project$Sidebar$footballSeasonSelection = A4(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$map3,
	$author$project$Sidebar$FootballSeason,
	$author$project$Gambling$Object$Gambling_football_seasons$id,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, 0, $author$project$Gambling$Object$Gambling_football_seasons$current_matchday),
	$author$project$Gambling$Object$Gambling_football_seasons$football_competition($author$project$Sidebar$footballCompetitionSelection));
var $author$project$Gambling$Object$Gambling_competitions$matchday_bets_count = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Int)',
	'matchday_bets_count',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $author$project$Gambling$Object$Gambling_competitions$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gambling$Object$Gambling_competitions$users_count = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Int)',
	'users_count',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $author$project$Sidebar$competitionSelection = A7(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$map6,
	$author$project$Sidebar$Competition,
	$author$project$Gambling$Object$Gambling_competitions$id,
	$author$project$Gambling$Object$Gambling_competitions$name,
	$author$project$Gambling$Object$Gambling_competitions$football_season($author$project$Sidebar$footballSeasonSelection),
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, 0, $author$project$Gambling$Object$Gambling_competitions$matchday_bets_count),
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, 0, $author$project$Gambling$Object$Gambling_competitions$current_matches_count),
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, 0, $author$project$Gambling$Object$Gambling_competitions$users_count));
var $author$project$Gambling$Enum$Gambling_competitions_select_column$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'created_at';
		case 1:
			return 'football_season_id';
		case 2:
			return 'id';
		case 3:
			return 'name';
		default:
			return 'updated_at';
	}
};
var $author$project$Gambling$Query$gambling_competitions = F2(
	function (fillInOptionals____, object____) {
		var filledInOptionals____ = fillInOptionals____(
			{ax: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ay: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, az: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aA: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, c: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'distinct_on',
					filledInOptionals____.ax,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Gambling_competitions_select_column$toString))),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'limit', filledInOptionals____.ay, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'offset', filledInOptionals____.az, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'order_by',
					filledInOptionals____.aA,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_competitions_order_by)),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'where', filledInOptionals____.c, $author$project$Gambling$InputObject$encodeGambling_competitions_bool_exp)
				]));
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'gambling_competitions',
			optionalArgs____,
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
	});
var $author$project$Sidebar$getUserCompetitions = function (user) {
	var query = A2($author$project$Gambling$Query$gambling_competitions, $author$project$Sidebar$buildCompetitionArgs, $author$project$Sidebar$competitionSelection);
	return A3($author$project$Api$sendQuery, user, $elm$core$Basics$identity, query);
};
var $author$project$Sidebar$init = F3(
	function (user, key, activeId) {
		return _Utils_Tuple2(
			{cS: activeId, a1: $krisajenkins$remotedata$RemoteData$Loading, dF: key, y: user},
			$author$project$Sidebar$getUserCompetitions(user));
	});
var $author$project$Main$checkAuth = F4(
	function (maybeUser, route, key, device) {
		if (!maybeUser.$) {
			var user = maybeUser.a;
			var _v1 = function () {
				var activeCompetitionId = function () {
					if (route.$ === 2) {
						var id = route.a;
						return $elm$core$Maybe$Just(id);
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				return A3($author$project$Sidebar$init, user, key, activeCompetitionId);
			}();
			var sidebar = _v1.a;
			var sidebarCmdMsg = _v1.b;
			var _v3 = A2($author$project$Main$changeRouteTo, route, user);
			var content = _v3.a;
			var contentCmdMsg = _v3.b;
			return _Utils_Tuple2(
				A2(
					$author$project$Main$Auth,
					content,
					{bF: device, c9: key, b_: sidebar, cu: false, y: user}),
				$elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							A2($elm$core$Platform$Cmd$map, $author$project$Main$GotSidebarMsg, sidebarCmdMsg),
							contentCmdMsg
						])));
		} else {
			return _Utils_Tuple2(
				A3($author$project$Main$NotAuth, route, key, device),
				$elm$core$Platform$Cmd$none);
		}
	});
var $mdgriffith$elm_ui$Element$BigDesktop = 3;
var $mdgriffith$elm_ui$Element$Landscape = 1;
var $mdgriffith$elm_ui$Element$Phone = 0;
var $mdgriffith$elm_ui$Element$Tablet = 1;
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $mdgriffith$elm_ui$Element$classifyDevice = function (window) {
	return {
		ga: function () {
			var shortSide = A2($elm$core$Basics$min, window.fA, window.eD);
			var longSide = A2($elm$core$Basics$max, window.fA, window.eD);
			return (shortSide < 600) ? 0 : ((longSide <= 1200) ? 1 : (((longSide > 1200) && (longSide <= 1920)) ? 2 : 3));
		}(),
		g7: (_Utils_cmp(window.fA, window.eD) < 0) ? 0 : 1
	};
};
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {bP: frag, bT: params, bL: unvisited, an: value, b1: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.bL;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.an);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.an);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.dR),
					$elm$url$Url$Parser$prepareQuery(url.fa),
					url.eB,
					$elm$core$Basics$identity)));
	});
var $author$project$Route$Competition = function (a) {
	return {$: 2, a: a};
};
var $author$project$Route$CreateCompetition = {$: 1};
var $author$project$Route$Home = {$: 0};
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.b1;
			var unvisited = _v0.bL;
			var params = _v0.bT;
			var frag = _v0.bP;
			var value = _v0.an;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $elm$url$Url$Parser$int = A2($elm$url$Url$Parser$custom, 'NUMBER', $elm$core$String$toInt);
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.b1;
		var unvisited = _v0.bL;
		var params = _v0.bT;
		var frag = _v0.bP;
		var value = _v0.an;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.b1;
			var unvisited = _v1.bL;
			var params = _v1.bT;
			var frag = _v1.bP;
			var value = _v1.an;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.b1;
		var unvisited = _v0.bL;
		var params = _v0.bT;
		var frag = _v0.bP;
		var value = _v0.an;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Route$parser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, $author$project$Route$Home, $elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$CreateCompetition,
			$elm$url$Url$Parser$s('create-competition')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Competition,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('competitions'),
				$elm$url$Url$Parser$int))
		]));
var $author$project$Route$fromUrl = $elm$url$Url$Parser$parse($author$project$Route$parser);
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Session$User = F4(
	function (username, token, picture, authId) {
		return {bA: authId, ha: picture, fq: token, ac: username};
	});
var $author$project$Session$userDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Session$User,
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'token', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'picture', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'sub', $elm$json$Json$Decode$string));
var $author$project$Main$WindowSize = F2(
	function (width, height) {
		return {eD: height, fA: width};
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $author$project$Main$windowSizeDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Main$WindowSize,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['windowSize', 'width']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['windowSize', 'height']),
		$elm$json$Json$Decode$int));
var $author$project$Main$init = F3(
	function (flags, url, navKey) {
		var _v0 = $author$project$Route$fromUrl(url);
		if (_v0.$ === 1) {
			return _Utils_Tuple2(
				$author$project$Main$NotFound(navKey),
				$elm$core$Platform$Cmd$none);
		} else {
			var route = _v0.a;
			var windowSizeResult = A2($elm$json$Json$Decode$decodeValue, $author$project$Main$windowSizeDecoder, flags);
			var maybeUser = $elm$core$Result$toMaybe(
				A2(
					$elm$json$Json$Decode$decodeValue,
					A2($elm$json$Json$Decode$field, 'user', $author$project$Session$userDecoder),
					flags));
			var device = function () {
				if (windowSizeResult.$ === 1) {
					return {ga: 2, g7: 0};
				} else {
					var windowSize = windowSizeResult.a;
					return $mdgriffith$elm_ui$Element$classifyDevice(windowSize);
				}
			}();
			return A4($author$project$Main$checkAuth, maybeUser, route, navKey, device);
		}
	});
var $author$project$Main$WindowChanged = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $elm$browser$Browser$Events$Window = 1;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {e4: pids, fn: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {ew: event, dF: key};
	});
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.e4,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.dF;
		var event = _v0.ew;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.fn);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onResize = function (func) {
	return A3(
		$elm$browser$Browser$Events$on,
		1,
		'resize',
		A2(
			$elm$json$Json$Decode$field,
			'target',
			A3(
				$elm$json$Json$Decode$map2,
				func,
				A2($elm$json$Json$Decode$field, 'innerWidth', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'innerHeight', $elm$json$Json$Decode$int))));
};
var $author$project$Page$Competition$Tick = function (a) {
	return {$: 11, a: a};
};
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {e8: processes, fp: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.e8;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.fp);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $author$project$Page$Competition$subscriptions = function (_v0) {
	return A2($elm$time$Time$every, 1000, $author$project$Page$Competition$Tick);
};
var $author$project$Main$subscriptions = function (model) {
	if (!model.$) {
		var content = model.a;
		var contentSub = function () {
			if (content.$ === 1) {
				var competitionModel = content.a;
				return A2(
					$elm$core$Platform$Sub$map,
					$author$project$Main$GotCompetitionMsg,
					$author$project$Page$Competition$subscriptions(competitionModel));
			} else {
				return $elm$core$Platform$Sub$none;
			}
		}();
		return $elm$core$Platform$Sub$batch(
			_List_fromArray(
				[
					$elm$browser$Browser$Events$onResize($author$project$Main$WindowChanged),
					contentSub
				]));
	} else {
		return $elm$core$Platform$Sub$none;
	}
};
var $author$project$Main$GotLoginMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$GotHeaderMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Main$ToggleSidebar = {$: 8};
var $author$project$Header$translator = F2(
	function (_v0, msg) {
		var onInternalMsg = _v0.e_;
		var onToggleSidebar = _v0.e0;
		if (!msg.$) {
			var internal = msg.a;
			return onInternalMsg(internal);
		} else {
			var _v2 = msg.a;
			return onToggleSidebar;
		}
	});
var $author$project$Main$headerTranslator = $author$project$Header$translator(
	{e_: $author$project$Main$GotHeaderMsg, e0: $author$project$Main$ToggleSidebar});
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.e9;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.eB,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.fa,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.e5,
					_Utils_ap(http, url.eI)),
				url.dR)));
};
var $author$project$Session$sendMessage = _Platform_outgoingPort('sendMessage', $elm$json$Json$Encode$string);
var $author$project$Header$update = function (msg) {
	return $author$project$Session$sendMessage('logout');
};
var $author$project$Page$Competition$Race = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Page$Competition$Table = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Competition$Timeline = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Competition$GotProgressions = function (a) {
	return {$: 3, a: a};
};
var $author$project$Gambling$InputObject$Gambling_competition_progression_bool_exp = $elm$core$Basics$identity;
var $author$project$Gambling$InputObject$buildGambling_competition_progression_bool_exp = function (fillOptionals____) {
	var optionals____ = fillOptionals____(
		{p: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, v: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, b: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, q: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, r: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, bi: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, y: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, E: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
	return {p: optionals____.p, v: optionals____.v, b: optionals____.b, q: optionals____.q, r: optionals____.r, bi: optionals____.bi, y: optionals____.y, E: optionals____.E};
};
var $author$project$Gambling$InputObject$encodeJson_comparison_exp = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_eq',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.F,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.bD;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'_gt',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.Q,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.bD;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'_gte',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.R,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.bD;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'_in',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.G,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						A2(
							$author$project$Gambling$Scalar$unwrapEncoder,
							function ($) {
								return $.bD;
							},
							$author$project$ScalarCodecs$codecs)))),
				_Utils_Tuple2(
				'_is_null',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.H, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'_lt',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.S,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.bD;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'_lte',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.T,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.bD;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'_neq',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.I,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.bD;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'_nin',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.J,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						A2(
							$author$project$Gambling$Scalar$unwrapEncoder,
							function ($) {
								return $.bD;
							},
							$author$project$ScalarCodecs$codecs))))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competition_progression_bool_exp = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_and',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.p,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_competition_progression_bool_exp))),
				_Utils_Tuple2(
				'_not',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.q, $author$project$Gambling$InputObject$encodeGambling_competition_progression_bool_exp)),
				_Utils_Tuple2(
				'_or',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.r,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_competition_progression_bool_exp))),
				_Utils_Tuple2(
				'competition',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.v, $author$project$Gambling$InputObject$encodeGambling_competitions_bool_exp)),
				_Utils_Tuple2(
				'competition_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.b, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'stats',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bi, $author$project$Gambling$InputObject$encodeJson_comparison_exp)),
				_Utils_Tuple2(
				'user',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.y, $author$project$Gambling$InputObject$encodeGambling_users_bool_exp)),
				_Utils_Tuple2(
				'user_auth0_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.E, $author$project$Gambling$InputObject$encodeString_comparison_exp))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competition_progression_order_by = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.v, $author$project$Gambling$InputObject$encodeGambling_competitions_order_by)),
				_Utils_Tuple2(
				'competition_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.b,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'stats',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.bi,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'user',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.y, $author$project$Gambling$InputObject$encodeGambling_users_order_by)),
				_Utils_Tuple2(
				'user_auth0_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.E,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$Enum$Gambling_competition_progression_select_column$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'competition_id';
		case 1:
			return 'stats';
		default:
			return 'user_auth0_id';
	}
};
var $author$project$Gambling$Query$gambling_competition_progression = F2(
	function (fillInOptionals____, object____) {
		var filledInOptionals____ = fillInOptionals____(
			{ax: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ay: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, az: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aA: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, c: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'distinct_on',
					filledInOptionals____.ax,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Gambling_competition_progression_select_column$toString))),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'limit', filledInOptionals____.ay, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'offset', filledInOptionals____.az, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'order_by',
					filledInOptionals____.aA,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_competition_progression_order_by)),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'where', filledInOptionals____.c, $author$project$Gambling$InputObject$encodeGambling_competition_progression_bool_exp)
				]));
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'gambling_competition_progression',
			optionalArgs____,
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
	});
var $author$project$Page$Competition$UserProgression = F2(
	function (maybeUser, points) {
		return {c7: maybeUser, bh: points};
	});
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (!result.$) {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $author$project$Page$Competition$decodeStats = function (maybeJson) {
	if (maybeJson.$ === 1) {
		return $elm$core$Result$Err('Invalid stats JSON');
	} else {
		var jsonValue = maybeJson.a;
		var keyToInt = function (_v2) {
			var k = _v2.a;
			var v = _v2.b;
			return A2(
				$elm$core$Maybe$andThen,
				function (intK) {
					return $elm$core$Maybe$Just(
						_Utils_Tuple2(intK, v));
				},
				$elm$core$String$toInt(k));
		};
		return A2(
			$elm$core$Result$mapError,
			function (_v1) {
				return 'Invalid stats JSON';
			},
			A2(
				$elm$core$Result$andThen,
				A2($elm$core$Basics$composeR, $elm$core$Dict$fromList, $elm$core$Result$Ok),
				A2(
					$elm$core$Result$andThen,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$List$filterMap(keyToInt),
						$elm$core$Result$Ok),
					A2(
						$elm$json$Json$Decode$decodeValue,
						$elm$json$Json$Decode$keyValuePairs($elm$json$Json$Decode$int),
						jsonValue))));
	}
};
var $dillonkearns$elm_graphql$Graphql$SelectionSet$mapOrFail = F2(
	function (mapFunction, _v0) {
		var field = _v0.a;
		var decoder = _v0.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			field,
			A2(
				$elm$json$Json$Decode$andThen,
				function (result) {
					if (!result.$) {
						var value = result.a;
						return $elm$json$Json$Decode$succeed(value);
					} else {
						var errorMessage = result.a;
						return $elm$json$Json$Decode$fail('Check your code for calls to mapOrFail, your map function returned an `Err` with the message: ' + errorMessage);
					}
				},
				A2($elm$json$Json$Decode$map, mapFunction, decoder)));
	});
var $author$project$Gambling$Object$Gambling_competition_progression$stats = function (fillInOptionals____) {
	var filledInOptionals____ = fillInOptionals____(
		{dR: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
	var optionalArgs____ = A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		_List_fromArray(
			[
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'path', filledInOptionals____.dR, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)
			]));
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
		'(Maybe ScalarCodecs.Json)',
		'stats',
		optionalArgs____,
		$elm$json$Json$Decode$nullable(
			$author$project$Gambling$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).bD.gm));
};
var $author$project$Gambling$Object$Gambling_competition_progression$user = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'user',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Page$Competition$userProgressionSelection = A3(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$map2,
	$author$project$Page$Competition$UserProgression,
	$author$project$Gambling$Object$Gambling_competition_progression$user($author$project$Page$Competition$userSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$mapOrFail,
		$author$project$Page$Competition$decodeStats,
		$author$project$Gambling$Object$Gambling_competition_progression$stats($elm$core$Basics$identity)));
var $author$project$Page$Competition$getProgressions = F2(
	function (user, competitionId) {
		var query = A2(
			$author$project$Gambling$Query$gambling_competition_progression,
			function (args) {
				return _Utils_update(
					args,
					{
						c: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
							$author$project$Gambling$InputObject$buildGambling_competition_progression_bool_exp(
								function (whereArgs) {
									return _Utils_update(
										whereArgs,
										{
											b: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
												$author$project$Gambling$InputObject$buildInt_comparison_exp(
													function (idArgs) {
														return _Utils_update(
															idArgs,
															{
																F: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(competitionId)
															});
													}))
										});
								}))
					});
			},
			$author$project$Page$Competition$userProgressionSelection);
		return A3($author$project$Api$sendQuery, user, $author$project$Page$Competition$GotProgressions, query);
	});
var $author$project$Page$Competition$GotStandings = function (a) {
	return {$: 2, a: a};
};
var $author$project$Gambling$InputObject$Gambling_standings_bool_exp = $elm$core$Basics$identity;
var $author$project$Gambling$InputObject$buildGambling_standings_bool_exp = function (fillOptionals____) {
	var optionals____ = fillOptionals____(
		{p: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, C: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aZ: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, a_: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, v: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, bc: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, bd: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, a: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, q: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, r: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, bj: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, bk: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, y: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, bn: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, bo: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
	return {p: optionals____.p, C: optionals____.C, aZ: optionals____.aZ, a_: optionals____.a_, v: optionals____.v, bc: optionals____.bc, bd: optionals____.bd, a: optionals____.a, q: optionals____.q, r: optionals____.r, bj: optionals____.bj, bk: optionals____.bk, y: optionals____.y, bn: optionals____.bn, bo: optionals____.bo};
};
var $author$project$Gambling$InputObject$encodeGambling_standings_bool_exp = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_and',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.p,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_standings_bool_exp))),
				_Utils_Tuple2(
				'_not',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.q, $author$project$Gambling$InputObject$encodeGambling_standings_bool_exp)),
				_Utils_Tuple2(
				'_or',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.r,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_standings_bool_exp))),
				_Utils_Tuple2(
				'auth0_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.C, $author$project$Gambling$InputObject$encodeString_comparison_exp)),
				_Utils_Tuple2(
				'away_success',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aZ, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'away_total',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a_, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'competition',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.v, $author$project$Gambling$InputObject$encodeGambling_competitions_bool_exp)),
				_Utils_Tuple2(
				'home_success',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bc, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'home_total',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bd, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'success',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bj, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'total',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bk, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'user',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.y, $author$project$Gambling$InputObject$encodeGambling_users_bool_exp)),
				_Utils_Tuple2(
				'x_success',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bn, $author$project$Gambling$InputObject$encodeInt_comparison_exp)),
				_Utils_Tuple2(
				'x_total',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bo, $author$project$Gambling$InputObject$encodeInt_comparison_exp))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_standings_order_by = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'auth0_id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.C,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'away_success',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.aZ,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'away_total',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a_,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'competition',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.v, $author$project$Gambling$InputObject$encodeGambling_competitions_order_by)),
				_Utils_Tuple2(
				'home_success',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.bc,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'home_total',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.bd,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'id',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'success',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.bj,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'total',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.bk,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'user',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.y, $author$project$Gambling$InputObject$encodeGambling_users_order_by)),
				_Utils_Tuple2(
				'x_success',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.bn,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString))),
				_Utils_Tuple2(
				'x_total',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.bo,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Order_by$toString)))
			]));
};
var $author$project$Gambling$Enum$Gambling_standings_select_column$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'auth0_id';
		case 1:
			return 'away_success';
		case 2:
			return 'away_total';
		case 3:
			return 'home_success';
		case 4:
			return 'home_total';
		case 5:
			return 'id';
		case 6:
			return 'success';
		case 7:
			return 'total';
		case 8:
			return 'x_success';
		default:
			return 'x_total';
	}
};
var $author$project$Gambling$Query$gambling_standings = F2(
	function (fillInOptionals____, object____) {
		var filledInOptionals____ = fillInOptionals____(
			{ax: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ay: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, az: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aA: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, c: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'distinct_on',
					filledInOptionals____.ax,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Gambling_standings_select_column$toString))),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'limit', filledInOptionals____.ay, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'offset', filledInOptionals____.az, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'order_by',
					filledInOptionals____.aA,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_standings_order_by)),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'where', filledInOptionals____.c, $author$project$Gambling$InputObject$encodeGambling_standings_bool_exp)
				]));
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'gambling_standings',
			optionalArgs____,
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
	});
var $author$project$Page$Competition$UserStanding = F9(
	function (maybeUser, total, success, homeTotal, homeSuccess, xTotal, xSuccess, awayTotal, awaySuccess) {
		return {ek: awaySuccess, el: awayTotal, eG: homeSuccess, eH: homeTotal, c7: maybeUser, bj: success, bk: total, fD: xSuccess, fE: xTotal};
	});
var $author$project$Gambling$Object$Gambling_standings$away_success = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Int)',
	'away_success',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $author$project$Gambling$Object$Gambling_standings$away_total = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Int)',
	'away_total',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $author$project$Gambling$Object$Gambling_standings$home_success = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Int)',
	'home_success',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $author$project$Gambling$Object$Gambling_standings$home_total = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Int)',
	'home_total',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed = function (constructor) {
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
		_List_Nil,
		$elm$json$Json$Decode$succeed(constructor));
};
var $author$project$Gambling$Object$Gambling_standings$success = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Int)',
	'success',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $author$project$Gambling$Object$Gambling_standings$total = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Int)',
	'total',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $author$project$Gambling$Object$Gambling_standings$user = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'user',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $dillonkearns$elm_graphql$Graphql$SelectionSet$with = F2(
	function (_v0, _v1) {
		var selectionFields1 = _v0.a;
		var selectionDecoder1 = _v0.b;
		var selectionFields2 = _v1.a;
		var selectionDecoder2 = _v1.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_Utils_ap(selectionFields1, selectionFields2),
			A3($elm$json$Json$Decode$map2, $elm$core$Basics$apR, selectionDecoder1, selectionDecoder2));
	});
var $author$project$Gambling$Object$Gambling_standings$x_success = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Int)',
	'x_success',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $author$project$Gambling$Object$Gambling_standings$x_total = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Int)',
	'x_total',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $author$project$Page$Competition$userStandingSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, 0, $author$project$Gambling$Object$Gambling_standings$away_success),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, 0, $author$project$Gambling$Object$Gambling_standings$away_total),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, 0, $author$project$Gambling$Object$Gambling_standings$x_success),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, 0, $author$project$Gambling$Object$Gambling_standings$x_total),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, 0, $author$project$Gambling$Object$Gambling_standings$home_success),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, 0, $author$project$Gambling$Object$Gambling_standings$home_total),
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, 0, $author$project$Gambling$Object$Gambling_standings$success),
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, 0, $author$project$Gambling$Object$Gambling_standings$total),
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
									$author$project$Gambling$Object$Gambling_standings$user($author$project$Page$Competition$userSelection),
									$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Page$Competition$UserStanding))))))))));
var $author$project$Page$Competition$getStandings = F2(
	function (user, competitionId) {
		var query = A2(
			$author$project$Gambling$Query$gambling_standings,
			function (args) {
				return _Utils_update(
					args,
					{
						c: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
							$author$project$Gambling$InputObject$buildGambling_standings_bool_exp(
								function (whereArgs) {
									return _Utils_update(
										whereArgs,
										{
											a: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
												$author$project$Gambling$InputObject$buildInt_comparison_exp(
													function (idArgs) {
														return _Utils_update(
															idArgs,
															{
																F: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(competitionId)
															});
													}))
										});
								}))
					});
			},
			$author$project$Page$Competition$userStandingSelection);
		return A3($author$project$Api$sendQuery, user, $author$project$Page$Competition$GotStandings, query);
	});
var $author$project$Page$Competition$GotTimeline = function (a) {
	return {$: 1, a: a};
};
var $author$project$Gambling$Enum$Order_by$Asc = 0;
var $author$project$Page$Competition$TimelineData = F2(
	function (users, matches) {
		return {c6: matches, cz: users};
	});
var $author$project$Gambling$InputObject$Gambling_football_matches_bool_exp = $elm$core$Basics$identity;
var $author$project$Gambling$InputObject$buildGambling_football_matches_bool_exp = function (fillOptionals____) {
	var optionals____ = fillOptionals____(
		{p: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aH: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, f: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, N: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, D: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aK: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, j: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, a: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, n: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, U: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, k: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, q: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, r: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aM: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, l: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, m: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, Y: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
	return {p: optionals____.p, aH: optionals____.aH, f: optionals____.f, N: optionals____.N, D: optionals____.D, aK: optionals____.aK, j: optionals____.j, a: optionals____.a, n: optionals____.n, U: optionals____.U, k: optionals____.k, q: optionals____.q, r: optionals____.r, aM: optionals____.aM, l: optionals____.l, m: optionals____.m, Y: optionals____.Y};
};
var $author$project$Gambling$InputObject$Gambling_football_matches_order_by = $elm$core$Basics$identity;
var $author$project$Gambling$InputObject$buildGambling_football_matches_order_by = function (fillOptionals____) {
	var optionals____ = fillOptionals____(
		{aH: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, f: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, N: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, D: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aK: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, j: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, a: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, n: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aL: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, k: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aM: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, l: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, m: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, Y: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
	return {aH: optionals____.aH, f: optionals____.f, N: optionals____.N, D: optionals____.D, aK: optionals____.aK, j: optionals____.j, a: optionals____.a, n: optionals____.n, aL: optionals____.aL, k: optionals____.k, aM: optionals____.aM, l: optionals____.l, m: optionals____.m, Y: optionals____.Y};
};
var $author$project$Gambling$InputObject$buildTimestamptz_comparison_exp = function (fillOptionals____) {
	var optionals____ = fillOptionals____(
		{F: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, Q: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, R: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, G: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, H: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, S: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, T: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, I: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, J: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
	return {F: optionals____.F, Q: optionals____.Q, R: optionals____.R, G: optionals____.G, H: optionals____.H, S: optionals____.S, T: optionals____.T, I: optionals____.I, J: optionals____.J};
};
var $author$project$Gambling$Object$Gambling_football_seasons$football_matches = F2(
	function (fillInOptionals____, object____) {
		var filledInOptionals____ = fillInOptionals____(
			{ax: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, ay: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, az: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, aA: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, c: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'distinct_on',
					filledInOptionals____.ax,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Gambling_football_matches_select_column$toString))),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'limit', filledInOptionals____.ay, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'offset', filledInOptionals____.az, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional,
					'order_by',
					filledInOptionals____.aA,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_football_matches_order_by)),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'where', filledInOptionals____.c, $author$project$Gambling$InputObject$encodeGambling_football_matches_bool_exp)
				]));
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'football_matches',
			optionalArgs____,
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
	});
var $author$project$Page$Competition$timelineSelection = F2(
	function (id, time) {
		var twoDaysAgo = $elm$time$Time$millisToPosix(
			$elm$time$Time$posixToMillis(time) - ((((1000 * 60) * 60) * 24) * 2));
		return A3(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$map2,
			$author$project$Page$Competition$TimelineData,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault,
				_List_Nil,
				A2($author$project$Gambling$Object$Gambling_competitions$users, $elm$core$Basics$identity, $author$project$Page$Competition$userSelection)),
			$author$project$Gambling$Object$Gambling_competitions$football_season(
				A2(
					$author$project$Gambling$Object$Gambling_football_seasons$football_matches,
					function (optArgs) {
						return _Utils_update(
							optArgs,
							{
								ay: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(20),
								aA: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
									_List_fromArray(
										[
											$author$project$Gambling$InputObject$buildGambling_football_matches_order_by(
											function (orderArgs) {
												return _Utils_update(
													orderArgs,
													{
														N: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(0)
													});
											})
										])),
								c: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
									$author$project$Gambling$InputObject$buildGambling_football_matches_bool_exp(
										function (whereArgs) {
											return _Utils_update(
												whereArgs,
												{
													N: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
														$author$project$Gambling$InputObject$buildTimestamptz_comparison_exp(
															function (dateArgs) {
																return _Utils_update(
																	dateArgs,
																	{
																		R: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(twoDaysAgo)
																	});
															}))
												});
										}))
							});
					},
					$author$project$Page$Competition$footballMatchesSelection(id))));
	});
var $author$project$Page$Competition$getTimeline = F3(
	function (user, id, time) {
		var query = A2(
			$author$project$Gambling$Query$gambling_competitions_by_pk,
			{a: id},
			A2($author$project$Page$Competition$timelineSelection, id, time));
		return A3($author$project$Api$sendQuery, user, $author$project$Page$Competition$GotTimeline, query);
	});
var $author$project$Page$Competition$GotBet = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$Gambling$InputObject$Gambling_match_bets_insert_input = $elm$core$Basics$identity;
var $author$project$Gambling$InputObject$buildGambling_match_bets_insert_input = function (fillOptionals____) {
	var optionals____ = fillOptionals____(
		{ap: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, v: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, b: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, s: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, h: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, al: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, a: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, u: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, y: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, E: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
	return {ap: optionals____.ap, v: optionals____.v, b: optionals____.b, s: optionals____.s, h: optionals____.h, al: optionals____.al, a: optionals____.a, u: optionals____.u, y: optionals____.y, E: optionals____.E};
};
var $author$project$Gambling$Enum$Gambling_competition_user_constraint$toString = function (enum____) {
	if (!enum____) {
		return 'competition_user_id_key';
	} else {
		return 'competition_user_pkey';
	}
};
var $author$project$Gambling$Enum$Gambling_competition_user_update_column$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'competition_id';
		case 1:
			return 'id';
		default:
			return 'user_id';
	}
};
var $author$project$Gambling$InputObject$encodeGambling_competition_user_on_conflict = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'constraint',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gambling$Enum$Gambling_competition_user_constraint$toString, input____.A))),
				_Utils_Tuple2(
				'update_columns',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Gambling_competition_user_update_column$toString))(input____.B))),
				_Utils_Tuple2(
				'where',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.c, $author$project$Gambling$InputObject$encodeGambling_competition_user_bool_exp))
			]));
};
var $author$project$Gambling$Enum$Gambling_competitions_constraint$toString = function (enum____) {
	return 'competitions_pkey';
};
var $author$project$Gambling$Enum$Gambling_competitions_update_column$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'created_at';
		case 1:
			return 'football_season_id';
		case 2:
			return 'id';
		case 3:
			return 'name';
		default:
			return 'updated_at';
	}
};
var $author$project$Gambling$InputObject$encodeGambling_competitions_on_conflict = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'constraint',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gambling$Enum$Gambling_competitions_constraint$toString, input____.A))),
				_Utils_Tuple2(
				'update_columns',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Gambling_competitions_update_column$toString))(input____.B))),
				_Utils_Tuple2(
				'where',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.c, $author$project$Gambling$InputObject$encodeGambling_competitions_bool_exp))
			]));
};
var $author$project$Gambling$Enum$Gambling_football_competitions_constraint$toString = function (enum____) {
	return 'football_competitions_pkey';
};
var $author$project$Gambling$Enum$Gambling_football_competitions_update_column$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'area_id';
		case 1:
			return 'code';
		case 2:
			return 'current_season_id';
		case 3:
			return 'id';
		case 4:
			return 'last_updated';
		default:
			return 'name';
	}
};
var $author$project$Gambling$InputObject$encodeGambling_football_competitions_on_conflict = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'constraint',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gambling$Enum$Gambling_football_competitions_constraint$toString, input____.A))),
				_Utils_Tuple2(
				'update_columns',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Gambling_football_competitions_update_column$toString))(input____.B))),
				_Utils_Tuple2(
				'where',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.c, $author$project$Gambling$InputObject$encodeGambling_football_competitions_bool_exp))
			]));
};
var $author$project$Gambling$Enum$Gambling_football_matches_constraint$toString = function (enum____) {
	return 'football_matches_pkey';
};
var $author$project$Gambling$Enum$Gambling_football_matches_update_column$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'away_team_id';
		case 1:
			return 'date';
		case 2:
			return 'home_team_id';
		case 3:
			return 'id';
		case 4:
			return 'last_updated';
		case 5:
			return 'matchday';
		case 6:
			return 'result_id';
		case 7:
			return 'season_id';
		default:
			return 'status';
	}
};
var $author$project$Gambling$InputObject$encodeGambling_football_matches_on_conflict = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'constraint',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gambling$Enum$Gambling_football_matches_constraint$toString, input____.A))),
				_Utils_Tuple2(
				'update_columns',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Gambling_football_matches_update_column$toString))(input____.B))),
				_Utils_Tuple2(
				'where',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.c, $author$project$Gambling$InputObject$encodeGambling_football_matches_bool_exp))
			]));
};
var $author$project$Gambling$Enum$Gambling_football_results_constraint$toString = function (enum____) {
	return 'football_results_pkey';
};
var $author$project$Gambling$Enum$Gambling_football_results_update_column$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'away_team_goals';
		case 1:
			return 'home_team_goals';
		default:
			return 'id';
	}
};
var $author$project$Gambling$InputObject$encodeGambling_football_results_on_conflict = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'constraint',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gambling$Enum$Gambling_football_results_constraint$toString, input____.A))),
				_Utils_Tuple2(
				'update_columns',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Gambling_football_results_update_column$toString))(input____.B))),
				_Utils_Tuple2(
				'where',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.c, $author$project$Gambling$InputObject$encodeGambling_football_results_bool_exp))
			]));
};
var $author$project$Gambling$Enum$Gambling_football_seasons_constraint$toString = function (enum____) {
	return 'football_seasons_pkey';
};
var $author$project$Gambling$Enum$Gambling_football_seasons_update_column$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'current_matchday';
		case 1:
			return 'end_date';
		case 2:
			return 'football_competition_id';
		case 3:
			return 'id';
		default:
			return 'start_date';
	}
};
var $author$project$Gambling$InputObject$encodeGambling_football_seasons_on_conflict = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'constraint',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gambling$Enum$Gambling_football_seasons_constraint$toString, input____.A))),
				_Utils_Tuple2(
				'update_columns',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Gambling_football_seasons_update_column$toString))(input____.B))),
				_Utils_Tuple2(
				'where',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.c, $author$project$Gambling$InputObject$encodeGambling_football_seasons_bool_exp))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_teams_insert_input = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'crest_url',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.at, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'last_updated',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.n,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.ar;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'name',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.o, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'short_name',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.au, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'tla',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.av, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string))
			]));
};
var $author$project$Gambling$Enum$Gambling_football_teams_constraint$toString = function (enum____) {
	return 'football_teams_pkey';
};
var $author$project$Gambling$Enum$Gambling_football_teams_update_column$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'crest_url';
		case 1:
			return 'id';
		case 2:
			return 'last_updated';
		case 3:
			return 'name';
		case 4:
			return 'short_name';
		default:
			return 'tla';
	}
};
var $author$project$Gambling$InputObject$encodeGambling_football_teams_on_conflict = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'constraint',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gambling$Enum$Gambling_football_teams_constraint$toString, input____.A))),
				_Utils_Tuple2(
				'update_columns',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Gambling_football_teams_update_column$toString))(input____.B))),
				_Utils_Tuple2(
				'where',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.c, $author$project$Gambling$InputObject$encodeGambling_football_teams_bool_exp))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_teams_obj_rel_insert_input = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'data',
				$elm$core$Maybe$Just(
					$author$project$Gambling$InputObject$encodeGambling_football_teams_insert_input(input____.w))),
				_Utils_Tuple2(
				'on_conflict',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.t, $author$project$Gambling$InputObject$encodeGambling_football_teams_on_conflict))
			]));
};
var $author$project$Gambling$Enum$Gambling_match_bets_constraint$toString = function (enum____) {
	if (!enum____) {
		return 'match_bets_pkey';
	} else {
		return 'match_bets_user_auth0_id_footbal_match_id_competition_id_key';
	}
};
var $author$project$Gambling$Enum$Gambling_match_bets_update_column$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'bet';
		case 1:
			return 'competition_id';
		case 2:
			return 'created_at';
		case 3:
			return 'footbal_match_id';
		case 4:
			return 'id';
		case 5:
			return 'updated_at';
		default:
			return 'user_auth0_id';
	}
};
var $author$project$Gambling$InputObject$encodeGambling_match_bets_on_conflict = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'constraint',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gambling$Enum$Gambling_match_bets_constraint$toString, input____.A))),
				_Utils_Tuple2(
				'update_columns',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Gambling_match_bets_update_column$toString))(input____.B))),
				_Utils_Tuple2(
				'where',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.c, $author$project$Gambling$InputObject$encodeGambling_match_bets_bool_exp))
			]));
};
var $author$project$Gambling$Enum$Gambling_users_constraint$toString = function (enum____) {
	if (!enum____) {
		return 'users_auth0_id_key';
	} else {
		return 'users_pkey';
	}
};
var $author$project$Gambling$Enum$Gambling_users_update_column$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'auth0_id';
		case 1:
			return 'avatar';
		case 2:
			return 'email';
		case 3:
			return 'id';
		default:
			return 'username';
	}
};
var $author$project$Gambling$InputObject$encodeGambling_users_on_conflict = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'constraint',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gambling$Enum$Gambling_users_constraint$toString, input____.A))),
				_Utils_Tuple2(
				'update_columns',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list(
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Gambling_users_update_column$toString))(input____.B))),
				_Utils_Tuple2(
				'where',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.c, $author$project$Gambling$InputObject$encodeGambling_users_bool_exp))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competition_user_arr_rel_insert_input = function (_v18) {
	var input____ = _v18;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'data',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_competition_user_insert_input)(input____.w))),
				_Utils_Tuple2(
				'on_conflict',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.t, $author$project$Gambling$InputObject$encodeGambling_competition_user_on_conflict))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competition_user_insert_input = function (_v17) {
	var input____ = _v17;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.v, $author$project$Gambling$InputObject$encodeGambling_competitions_obj_rel_insert_input)),
				_Utils_Tuple2(
				'competition_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.b, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'user',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.y, $author$project$Gambling$InputObject$encodeGambling_users_obj_rel_insert_input)),
				_Utils_Tuple2(
				'user_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.e, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competitions_arr_rel_insert_input = function (_v16) {
	var input____ = _v16;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'data',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_competitions_insert_input)(input____.w))),
				_Utils_Tuple2(
				'on_conflict',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.t, $author$project$Gambling$InputObject$encodeGambling_competitions_on_conflict))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competitions_insert_input = function (_v15) {
	var input____ = _v15;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition_users',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.as, $author$project$Gambling$InputObject$encodeGambling_competition_user_arr_rel_insert_input)),
				_Utils_Tuple2(
				'created_at',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.s,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.ar;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'football_season',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.D, $author$project$Gambling$InputObject$encodeGambling_football_seasons_obj_rel_insert_input)),
				_Utils_Tuple2(
				'football_season_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.d, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'match_bets',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.U, $author$project$Gambling$InputObject$encodeGambling_match_bets_arr_rel_insert_input)),
				_Utils_Tuple2(
				'name',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.o, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'updated_at',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.u,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.ar;
						},
						$author$project$ScalarCodecs$codecs)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_competitions_obj_rel_insert_input = function (_v14) {
	var input____ = _v14;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'data',
				$elm$core$Maybe$Just(
					$author$project$Gambling$InputObject$encodeGambling_competitions_insert_input(input____.w))),
				_Utils_Tuple2(
				'on_conflict',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.t, $author$project$Gambling$InputObject$encodeGambling_competitions_on_conflict))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_competitions_insert_input = function (_v13) {
	var input____ = _v13;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'area_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ah, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'code',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aq, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'current_season',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aI, $author$project$Gambling$InputObject$encodeGambling_football_seasons_obj_rel_insert_input)),
				_Utils_Tuple2(
				'current_season_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ak, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'football_seasons',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a8, $author$project$Gambling$InputObject$encodeGambling_football_seasons_arr_rel_insert_input)),
				_Utils_Tuple2(
				'id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'last_updated',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.n,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.ar;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'name',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.o, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_competitions_obj_rel_insert_input = function (_v12) {
	var input____ = _v12;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'data',
				$elm$core$Maybe$Just(
					$author$project$Gambling$InputObject$encodeGambling_football_competitions_insert_input(input____.w))),
				_Utils_Tuple2(
				'on_conflict',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.t, $author$project$Gambling$InputObject$encodeGambling_football_competitions_on_conflict))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_matches_arr_rel_insert_input = function (_v11) {
	var input____ = _v11;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'data',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_football_matches_insert_input)(input____.w))),
				_Utils_Tuple2(
				'on_conflict',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.t, $author$project$Gambling$InputObject$encodeGambling_football_matches_on_conflict))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_matches_insert_input = function (_v10) {
	var input____ = _v10;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'away_team',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aH, $author$project$Gambling$InputObject$encodeGambling_football_teams_obj_rel_insert_input)),
				_Utils_Tuple2(
				'away_team_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.f, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'date',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.N,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.ar;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'football_season',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.D, $author$project$Gambling$InputObject$encodeGambling_football_seasons_obj_rel_insert_input)),
				_Utils_Tuple2(
				'home_team',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aK, $author$project$Gambling$InputObject$encodeGambling_football_teams_obj_rel_insert_input)),
				_Utils_Tuple2(
				'home_team_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.j, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'last_updated',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.n,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.ar;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'match_bets',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.U, $author$project$Gambling$InputObject$encodeGambling_match_bets_arr_rel_insert_input)),
				_Utils_Tuple2(
				'matchday',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.k, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'result',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aM, $author$project$Gambling$InputObject$encodeGambling_football_results_obj_rel_insert_input)),
				_Utils_Tuple2(
				'result_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.l, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'season_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.m, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'status',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.Y, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_matches_obj_rel_insert_input = function (_v9) {
	var input____ = _v9;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'data',
				$elm$core$Maybe$Just(
					$author$project$Gambling$InputObject$encodeGambling_football_matches_insert_input(input____.w))),
				_Utils_Tuple2(
				'on_conflict',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.t, $author$project$Gambling$InputObject$encodeGambling_football_matches_on_conflict))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_results_insert_input = function (_v8) {
	var input____ = _v8;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'away_team_goals',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ai, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'football_match',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.al, $author$project$Gambling$InputObject$encodeGambling_football_matches_arr_rel_insert_input)),
				_Utils_Tuple2(
				'home_team_goals',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.am, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_results_obj_rel_insert_input = function (_v7) {
	var input____ = _v7;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'data',
				$elm$core$Maybe$Just(
					$author$project$Gambling$InputObject$encodeGambling_football_results_insert_input(input____.w))),
				_Utils_Tuple2(
				'on_conflict',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.t, $author$project$Gambling$InputObject$encodeGambling_football_results_on_conflict))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_seasons_arr_rel_insert_input = function (_v6) {
	var input____ = _v6;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'data',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_football_seasons_insert_input)(input____.w))),
				_Utils_Tuple2(
				'on_conflict',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.t, $author$project$Gambling$InputObject$encodeGambling_football_seasons_on_conflict))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_seasons_insert_input = function (_v5) {
	var input____ = _v5;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competitions',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a1, $author$project$Gambling$InputObject$encodeGambling_competitions_arr_rel_insert_input)),
				_Utils_Tuple2(
				'current_matchday',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.g, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'end_date',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.P,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.a$;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'football_competition',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.aJ, $author$project$Gambling$InputObject$encodeGambling_football_competitions_obj_rel_insert_input)),
				_Utils_Tuple2(
				'football_competition_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.i, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'football_matches',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a7, $author$project$Gambling$InputObject$encodeGambling_football_matches_arr_rel_insert_input)),
				_Utils_Tuple2(
				'id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'start_date',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.X,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.a$;
						},
						$author$project$ScalarCodecs$codecs)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_football_seasons_obj_rel_insert_input = function (_v4) {
	var input____ = _v4;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'data',
				$elm$core$Maybe$Just(
					$author$project$Gambling$InputObject$encodeGambling_football_seasons_insert_input(input____.w))),
				_Utils_Tuple2(
				'on_conflict',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.t, $author$project$Gambling$InputObject$encodeGambling_football_seasons_on_conflict))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_match_bets_arr_rel_insert_input = function (_v3) {
	var input____ = _v3;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'data',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gambling$InputObject$encodeGambling_match_bets_insert_input)(input____.w))),
				_Utils_Tuple2(
				'on_conflict',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.t, $author$project$Gambling$InputObject$encodeGambling_match_bets_on_conflict))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_match_bets_insert_input = function (_v2) {
	var input____ = _v2;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'bet',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.ap,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Gambling_bet_enum$toString))),
				_Utils_Tuple2(
				'competition',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.v, $author$project$Gambling$InputObject$encodeGambling_competitions_obj_rel_insert_input)),
				_Utils_Tuple2(
				'competition_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.b, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'created_at',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.s,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.ar;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'footbal_match_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.h, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'football_match',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.al, $author$project$Gambling$InputObject$encodeGambling_football_matches_obj_rel_insert_input)),
				_Utils_Tuple2(
				'id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'updated_at',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.u,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.ar;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'user',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.y, $author$project$Gambling$InputObject$encodeGambling_users_obj_rel_insert_input)),
				_Utils_Tuple2(
				'user_auth0_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.E, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_users_insert_input = function (_v1) {
	var input____ = _v1;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'auth0_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.C, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'avatar',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.K, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'competition_users',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.as, $author$project$Gambling$InputObject$encodeGambling_competition_user_arr_rel_insert_input)),
				_Utils_Tuple2(
				'email',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.O, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'match_bets',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.U, $author$project$Gambling$InputObject$encodeGambling_match_bets_arr_rel_insert_input)),
				_Utils_Tuple2(
				'username',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ac, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_users_obj_rel_insert_input = function (_v0) {
	var input____ = _v0;
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'data',
				$elm$core$Maybe$Just(
					$author$project$Gambling$InputObject$encodeGambling_users_insert_input(input____.w))),
				_Utils_Tuple2(
				'on_conflict',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.t, $author$project$Gambling$InputObject$encodeGambling_users_on_conflict))
			]));
};
var $author$project$Gambling$Mutation$insert_gambling_match_bets_one = F3(
	function (fillInOptionals____, requiredArgs____, object____) {
		var filledInOptionals____ = fillInOptionals____(
			{t: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'on_conflict', filledInOptionals____.t, $author$project$Gambling$InputObject$encodeGambling_match_bets_on_conflict)
				]));
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'insert_gambling_match_bets_one',
			_Utils_ap(
				optionalArgs____,
				_List_fromArray(
					[
						A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'object', requiredArgs____.aB, $author$project$Gambling$InputObject$encodeGambling_match_bets_insert_input)
					])),
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
	});
var $dillonkearns$elm_graphql$Graphql$Http$Mutation = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$mutationRequest = F2(
	function (baseUrl, mutationSelectionSet) {
		return {
			cF: baseUrl,
			c2: $dillonkearns$elm_graphql$Graphql$Http$Mutation(mutationSelectionSet),
			ca: $dillonkearns$elm_graphql$Graphql$Document$decoder(mutationSelectionSet),
			aT: _List_Nil,
			cr: $elm$core$Maybe$Nothing,
			bV: _List_Nil,
			aY: $elm$core$Maybe$Nothing,
			b3: false
		};
	});
var $author$project$Api$sendMutation = F3(
	function (user, toMsg, query) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, toMsg),
			A3(
				$dillonkearns$elm_graphql$Graphql$Http$withHeader,
				'Authorization',
				'Bearer ' + user.fq,
				A2($dillonkearns$elm_graphql$Graphql$Http$mutationRequest, '/v1/graphql', query)));
	});
var $author$project$Page$Competition$insertBet = F4(
	function (user, bet, competitionId, matchId) {
		var mutation = A3(
			$author$project$Gambling$Mutation$insert_gambling_match_bets_one,
			$elm$core$Basics$identity,
			{
				aB: $author$project$Gambling$InputObject$buildGambling_match_bets_insert_input(
					function (args) {
						return _Utils_update(
							args,
							{
								ap: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(bet),
								b: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(competitionId),
								h: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(matchId)
							});
					})
			},
			$author$project$Page$Competition$matchBetSelection);
		return A3(
			$author$project$Api$sendMutation,
			user,
			$author$project$Page$Competition$GotBet(matchId),
			mutation);
	});
var $author$project$Gambling$InputObject$buildGambling_match_bets_set_input = function (fillOptionals____) {
	var optionals____ = fillOptionals____(
		{ap: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, b: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, s: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, h: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, a: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, u: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, E: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
	return {ap: optionals____.ap, b: optionals____.b, s: optionals____.s, h: optionals____.h, a: optionals____.a, u: optionals____.u, E: optionals____.E};
};
var $author$project$Gambling$InputObject$encodeGambling_match_bets_inc_input = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'competition_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.b, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'footbal_match_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.h, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_match_bets_pk_columns_input = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$int(input____.a)))
			]));
};
var $author$project$Gambling$InputObject$encodeGambling_match_bets_set_input = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'bet',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.ap,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gambling$Enum$Gambling_bet_enum$toString))),
				_Utils_Tuple2(
				'competition_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.b, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'created_at',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.s,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.ar;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'footbal_match_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.h, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'updated_at',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.u,
					A2(
						$author$project$Gambling$Scalar$unwrapEncoder,
						function ($) {
							return $.ar;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'user_auth0_id',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.E, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string))
			]));
};
var $author$project$Gambling$Mutation$update_gambling_match_bets_by_pk = F3(
	function (fillInOptionals____, requiredArgs____, object____) {
		var filledInOptionals____ = fillInOptionals____(
			{z: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, x: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, '_inc', filledInOptionals____.z, $author$project$Gambling$InputObject$encodeGambling_match_bets_inc_input),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, '_set', filledInOptionals____.x, $author$project$Gambling$InputObject$encodeGambling_match_bets_set_input)
				]));
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'update_gambling_match_bets_by_pk',
			_Utils_ap(
				optionalArgs____,
				_List_fromArray(
					[
						A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'pk_columns', requiredArgs____.aD, $author$project$Gambling$InputObject$encodeGambling_match_bets_pk_columns_input)
					])),
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
	});
var $author$project$Page$Competition$updateBet = F4(
	function (user, bet, betId, matchId) {
		var mutation = A3(
			$author$project$Gambling$Mutation$update_gambling_match_bets_by_pk,
			function (optArgs) {
				return _Utils_update(
					optArgs,
					{
						x: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
							$author$project$Gambling$InputObject$buildGambling_match_bets_set_input(
								function (setArgs) {
									return _Utils_update(
										setArgs,
										{
											ap: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(bet)
										});
								}))
					});
			},
			{
				aD: {a: betId}
			},
			$author$project$Page$Competition$matchBetSelection);
		return A3(
			$author$project$Api$sendMutation,
			user,
			$author$project$Page$Competition$GotBet(matchId),
			mutation);
	});
var $elm_community$list_extra$List$Extra$find = F2(
	function (predicate, list) {
		find:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = list.a;
				var rest = list.b;
				if (predicate(first)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$predicate = predicate,
						$temp$list = rest;
					predicate = $temp$predicate;
					list = $temp$list;
					continue find;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$remove = F2(
	function (x, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var y = xs.a;
			var ys = xs.b;
			return _Utils_eq(x, y) ? ys : A2(
				$elm$core$List$cons,
				y,
				A2($elm_community$list_extra$List$Extra$remove, x, ys));
		}
	});
var $author$project$Page$Competition$updateMatchesWithBet = F3(
	function (matches, b, matchId) {
		var maybeMatch = A2(
			$elm_community$list_extra$List$Extra$find,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.a;
				},
				$elm$core$Basics$eq(matchId)),
			matches);
		if (!maybeMatch.$) {
			var match = maybeMatch.a;
			var updatedBets = A3($elm$core$Dict$insert, b.y.bA, b, match.cV);
			var updatedMatch = _Utils_update(
				match,
				{cV: updatedBets});
			return A2(
				$elm$core$List$cons,
				updatedMatch,
				A2($elm_community$list_extra$List$Extra$remove, match, matches));
		} else {
			return matches;
		}
	});
var $krisajenkins$remotedata$RemoteData$withDefault = F2(
	function (_default, data) {
		if (data.$ === 3) {
			var x = data.a;
			return x;
		} else {
			return _default;
		}
	});
var $author$project$Page$Competition$update = F2(
	function (msg, model) {
		var _v0 = _Utils_Tuple2(msg, model.aR);
		_v0$13:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					var _v1 = _v0.a;
					var maybeMatchday = _v1.a;
					var matchdayData = _v1.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: A2($author$project$Page$Competition$Matchday, maybeMatchday, matchdayData)
							}),
						$elm$core$Platform$Cmd$none);
				case 1:
					var maybeTimeline = _v0.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$Competition$Timeline(maybeTimeline)
							}),
						$elm$core$Platform$Cmd$none);
				case 2:
					var standings = _v0.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: $author$project$Page$Competition$Table(standings)
							}),
						$elm$core$Platform$Cmd$none);
				case 3:
					var progression = _v0.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aR: A2($author$project$Page$Competition$Race, progression, _List_Nil)
							}),
						$elm$core$Platform$Cmd$none);
				case 4:
					var t = _v0.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{cM: t}),
						$elm$core$Platform$Cmd$none);
				case 6:
					var t = _v0.a.a;
					switch (t) {
						case 0:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aR: A2($author$project$Page$Competition$Matchday, $elm$core$Maybe$Nothing, $krisajenkins$remotedata$RemoteData$Loading)
									}),
								A2($author$project$Page$Competition$getMatchdayAndTimezone, model.y, model.bx));
						case 1:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aR: $author$project$Page$Competition$Table($krisajenkins$remotedata$RemoteData$Loading)
									}),
								A2($author$project$Page$Competition$getStandings, model.y, model.bx));
						case 2:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aR: $author$project$Page$Competition$Timeline($krisajenkins$remotedata$RemoteData$Loading)
									}),
								A3($author$project$Page$Competition$getTimeline, model.y, model.bx, model.cx));
						default:
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aR: A2($author$project$Page$Competition$Race, $krisajenkins$remotedata$RemoteData$Loading, _List_Nil)
									}),
								A2($author$project$Page$Competition$getProgressions, model.y, model.bx));
					}
				case 5:
					if (_v0.b.$ === 3) {
						var hinted = _v0.a.a;
						var _v3 = _v0.b;
						var progressionData = _v3.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aR: A2($author$project$Page$Competition$Race, progressionData, hinted)
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						break _v0$13;
					}
				case 7:
					if (((!_v0.b.$) && (_v0.b.b.$ === 3)) && (!_v0.b.b.a.$)) {
						var _v4 = _v0.a;
						var _v5 = _v0.b;
						var maybeMatchday = _v5.a;
						var data = _v5.b.a.a;
						var previousMatchday = function () {
							if (maybeMatchday.$ === 1) {
								return data.c0 - 1;
							} else {
								var matchday = maybeMatchday.a;
								return matchday - 1;
							}
						}();
						return _Utils_Tuple2(
							model,
							A3(
								$author$project$Page$Competition$getMatchday,
								model.y,
								model.bx,
								$elm$core$Maybe$Just(previousMatchday)));
					} else {
						break _v0$13;
					}
				case 8:
					if (((!_v0.b.$) && (_v0.b.b.$ === 3)) && (!_v0.b.b.a.$)) {
						var _v7 = _v0.a;
						var _v8 = _v0.b;
						var maybeMatchday = _v8.a;
						var data = _v8.b.a.a;
						var nextMatchday = function () {
							if (maybeMatchday.$ === 1) {
								return data.c0 + 1;
							} else {
								var matchday = maybeMatchday.a;
								return matchday + 1;
							}
						}();
						return _Utils_Tuple2(
							model,
							A3(
								$author$project$Page$Competition$getMatchday,
								model.y,
								model.bx,
								$elm$core$Maybe$Just(nextMatchday)));
					} else {
						break _v0$13;
					}
				case 9:
					var _v10 = _v0.a;
					var bet = _v10.a;
					var matchId = _v10.b;
					var maybeBet = _v10.c;
					if (maybeBet.$ === 1) {
						return _Utils_Tuple2(
							model,
							A4($author$project$Page$Competition$insertBet, model.y, bet, model.bx, matchId));
					} else {
						var b = maybeBet.a;
						return _Utils_Tuple2(
							model,
							A4($author$project$Page$Competition$updateBet, model.y, bet, b.a, matchId));
					}
				case 10:
					switch (_v0.b.$) {
						case 0:
							if ((_v0.b.b.$ === 3) && (!_v0.b.b.a.$)) {
								var _v12 = _v0.a;
								var matchId = _v12.a;
								var betRemoteData = _v12.b;
								var _v13 = _v0.b;
								var maybeMatchday = _v13.a;
								var data = _v13.b.a.a;
								var maybeNewBet = A2($krisajenkins$remotedata$RemoteData$withDefault, $elm$core$Maybe$Nothing, betRemoteData);
								if (maybeNewBet.$ === 1) {
									return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
								} else {
									var newBet = maybeNewBet.a;
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{
												aR: A2(
													$author$project$Page$Competition$Matchday,
													maybeMatchday,
													$krisajenkins$remotedata$RemoteData$Success(
														$elm$core$Maybe$Just(
															_Utils_update(
																data,
																{
																	c1: A3($author$project$Page$Competition$updateMatchesWithBet, data.c1, newBet, matchId)
																}))))
											}),
										$elm$core$Platform$Cmd$none);
								}
							} else {
								break _v0$13;
							}
						case 2:
							if ((_v0.b.a.$ === 3) && (!_v0.b.a.a.$)) {
								var _v15 = _v0.a;
								var matchId = _v15.a;
								var betRemoteData = _v15.b;
								var data = _v0.b.a.a.a;
								var maybeNewBet = A2($krisajenkins$remotedata$RemoteData$withDefault, $elm$core$Maybe$Nothing, betRemoteData);
								if (maybeNewBet.$ === 1) {
									return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
								} else {
									var newBet = maybeNewBet.a;
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{
												aR: $author$project$Page$Competition$Timeline(
													$krisajenkins$remotedata$RemoteData$Success(
														$elm$core$Maybe$Just(
															_Utils_update(
																data,
																{
																	c6: A3($author$project$Page$Competition$updateMatchesWithBet, data.c6, newBet, matchId)
																}))))
											}),
										$elm$core$Platform$Cmd$none);
								}
							} else {
								break _v0$13;
							}
						default:
							break _v0$13;
					}
				default:
					var t = _v0.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{cx: t}),
						$elm$core$Platform$Cmd$none);
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Page$CreateCompetition$CompetitionSelectMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$CreateCompetition$UsersSelectMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Ui$Select$Config = F2(
	function (optToString, selectType) {
		return {da: optToString, bZ: selectType};
	});
var $author$project$Ui$Select$Single = 0;
var $author$project$Page$CreateCompetition$competitionSelectConfig = A2(
	$author$project$Ui$Select$Config,
	function ($) {
		return $.o;
	},
	0);
var $author$project$Ui$Select$NoOp = {$: 0};
var $author$project$Ui$Select$OptionsWrapperScrollPosition = F3(
	function (wrapperViewport, wrapperElement, focusedElement) {
		return {cb: focusedElement, dh: wrapperElement, ed: wrapperViewport};
	});
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm$browser$Browser$Dom$blur = _Browser_call('blur');
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm_community$list_extra$List$Extra$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? $elm$core$Maybe$Nothing : $elm$core$List$head(
			A2($elm$core$List$drop, idx, xs));
	});
var $elm$browser$Browser$Dom$getElement = _Browser_getElement;
var $elm$browser$Browser$Dom$getViewportOf = _Browser_getViewportOf;
var $author$project$Ui$Select$inputId = function (id) {
	return id + '-input';
};
var $elm$core$Task$map3 = F4(
	function (func, taskA, taskB, taskC) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return A2(
							$elm$core$Task$andThen,
							function (c) {
								return $elm$core$Task$succeed(
									A3(func, a, b, c));
							},
							taskC);
					},
					taskB);
			},
			taskA);
	});
var $author$project$Ui$Select$optionId = F2(
	function (id, index) {
		return id + ('-select-option-' + $elm$core$String$fromInt(index));
	});
var $author$project$Ui$Select$optionsWrapperId = function (id) {
	return id + '-opiotns-wrapper';
};
var $elm$browser$Browser$Dom$setViewportOf = _Browser_setViewportOf;
var $author$project$Ui$Select$scrollOptionsWrapper = F2(
	function (wrapperHtmlId, wrapperScrollPos) {
		var needsToScrollUp = _Utils_cmp(wrapperScrollPos.cb.bG.h8, wrapperScrollPos.dh.bG.h8) < 0;
		var needsToScrollDown = _Utils_cmp(wrapperScrollPos.cb.bG.h8 + wrapperScrollPos.cb.bG.eD, wrapperScrollPos.dh.bG.h8 + wrapperScrollPos.dh.bG.eD) > 0;
		return needsToScrollDown ? A3($elm$browser$Browser$Dom$setViewportOf, wrapperHtmlId, 0, wrapperScrollPos.ed.fx.h8 + wrapperScrollPos.cb.bG.eD) : (needsToScrollUp ? A3($elm$browser$Browser$Dom$setViewportOf, wrapperHtmlId, 0, wrapperScrollPos.ed.fx.h8 - wrapperScrollPos.cb.bG.eD) : $elm$core$Task$succeed(0));
	});
var $author$project$Ui$Select$update = F3(
	function (config, msg, _v0) {
		var internalState = _v0;
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(internalState, $elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						internalState,
						{bI: true}),
					$elm$core$Platform$Cmd$none);
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						internalState,
						{bI: false}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var text = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						internalState,
						{hE: text}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var opt = msg.a;
				var text = function () {
					var _v3 = config.bZ;
					if (!_v3) {
						return config.da(opt);
					} else {
						return '';
					}
				}();
				var selected = function () {
					var _v2 = config.bZ;
					if (!_v2) {
						return _List_fromArray(
							[opt]);
					} else {
						return _Utils_ap(
							internalState.bq,
							_List_fromArray(
								[opt]));
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						internalState,
						{bI: false, bq: selected, hE: text}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var opt = msg.a;
				var selected = A2($elm_community$list_extra$List$Extra$remove, opt, internalState.bq);
				return _Utils_Tuple2(
					_Utils_update(
						internalState,
						{bq: selected}),
					$elm$core$Platform$Cmd$none);
			default:
				var displayedOptions = msg.a;
				var key = msg.b;
				if (!key.$) {
					var arrowKey = key.a;
					var wrapperHtmlId = $author$project$Ui$Select$optionsWrapperId(internalState.a);
					var optionsLength = $elm$core$List$length(displayedOptions);
					var focusedId = function () {
						if (arrowKey === 1) {
							return _Utils_eq(optionsLength - 1, internalState.bs) ? (optionsLength - 1) : (internalState.bs + 1);
						} else {
							return (!internalState.bs) ? 0 : (internalState.bs - 1);
						}
					}();
					var focusedHtmlId = A2($author$project$Ui$Select$optionId, internalState.a, focusedId);
					return _Utils_Tuple2(
						_Utils_update(
							internalState,
							{bs: focusedId}),
						A2(
							$elm$core$Task$attempt,
							function (_v5) {
								return $author$project$Ui$Select$NoOp;
							},
							A2(
								$elm$core$Task$andThen,
								$author$project$Ui$Select$scrollOptionsWrapper(wrapperHtmlId),
								A4(
									$elm$core$Task$map3,
									$author$project$Ui$Select$OptionsWrapperScrollPosition,
									$elm$browser$Browser$Dom$getViewportOf(wrapperHtmlId),
									$elm$browser$Browser$Dom$getElement(
										$author$project$Ui$Select$optionsWrapperId(internalState.a)),
									$elm$browser$Browser$Dom$getElement(focusedHtmlId)))));
				} else {
					var selectedItem = A2($elm_community$list_extra$List$Extra$getAt, internalState.bs, displayedOptions);
					var text = function () {
						if (selectedItem.$ === 1) {
							return internalState.hE;
						} else {
							var opt = selectedItem.a;
							var _v11 = config.bZ;
							if (!_v11) {
								return config.da(opt);
							} else {
								return '';
							}
						}
					}();
					var selected = function () {
						if (selectedItem.$ === 1) {
							return internalState.bq;
						} else {
							var opt = selectedItem.a;
							var _v9 = config.bZ;
							if (!_v9) {
								return _List_fromArray(
									[opt]);
							} else {
								return _Utils_ap(
									internalState.bq,
									_List_fromArray(
										[opt]));
							}
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							internalState,
							{bs: 0, bI: false, bq: selected, hE: text}),
						A2(
							$elm$core$Task$attempt,
							function (_v7) {
								return $author$project$Ui$Select$NoOp;
							},
							$elm$browser$Browser$Dom$blur(
								$author$project$Ui$Select$inputId(internalState.a))));
				}
		}
	});
var $author$project$Ui$Select$Multi = 1;
var $author$project$Page$CreateCompetition$usersSelectConfig = A2($author$project$Ui$Select$Config, $elm$core$Basics$identity, 1);
var $author$project$Page$CreateCompetition$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var name = msg.a;
				var form = model.bb;
				var newForm = _Utils_update(
					form,
					{o: name});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bb: newForm}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var subMsg = msg.a;
				var form = model.bb;
				var _v1 = A3($author$project$Ui$Select$update, $author$project$Page$CreateCompetition$competitionSelectConfig, subMsg, model.bb.cG);
				var newCompetitionSelectState = _v1.a;
				var cmd = _v1.b;
				var newForm = _Utils_update(
					form,
					{cG: newCompetitionSelectState});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bb: newForm}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$CreateCompetition$CompetitionSelectMsg, cmd));
			case 2:
				var subMsg = msg.a;
				var form = model.bb;
				var _v2 = A3($author$project$Ui$Select$update, $author$project$Page$CreateCompetition$usersSelectConfig, subMsg, model.bb.cR);
				var newUsersSelectState = _v2.a;
				var cmd = _v2.b;
				var newForm = _Utils_update(
					form,
					{cR: newUsersSelectState});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bb: newForm}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$CreateCompetition$UsersSelectMsg, cmd));
			default:
				var competitions = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a1: competitions}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Login$update = function (msg) {
	return $author$project$Session$sendMessage('login');
};
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$absolute = F2(
	function (pathSegments, parameters) {
		return '/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters));
	});
var $author$project$Sidebar$getNavigationCmd = F2(
	function (navKey, competitionList) {
		if (competitionList.$ === 3) {
			var competitions = competitionList.a;
			var newsetCompetition = $elm$core$List$head(competitions);
			if (newsetCompetition.$ === 1) {
				return $elm$core$Platform$Cmd$none;
			} else {
				var comp = newsetCompetition.a;
				return A2(
					$elm$browser$Browser$Navigation$pushUrl,
					navKey,
					A2(
						$elm$url$Url$Builder$absolute,
						_List_fromArray(
							[
								'competitions',
								$elm$core$String$fromInt(comp.a)
							]),
						_List_Nil));
			}
		} else {
			return $elm$core$Platform$Cmd$none;
		}
	});
var $author$project$Sidebar$update = F2(
	function (msg, model) {
		var competitionList = msg;
		var newModel = _Utils_update(
			model,
			{a1: competitionList});
		var cmd = A2($author$project$Sidebar$getNavigationCmd, model.dF, competitionList);
		return _Utils_Tuple2(newModel, cmd);
	});
var $author$project$Sidebar$updateActiveCompetition = F2(
	function (model, activeId) {
		return _Utils_update(
			model,
			{cS: activeId});
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		var _v0 = _Utils_Tuple2(msg, model);
		_v0$9:
		while (true) {
			switch (_v0.a.$) {
				case 2:
					if (!_v0.b.$) {
						var _v1 = _v0.a;
						var width = _v1.a;
						var height = _v1.b;
						var _v2 = _v0.b;
						var content = _v2.a;
						var authModel = _v2.b;
						return _Utils_Tuple2(
							A2(
								$author$project$Main$Auth,
								content,
								_Utils_update(
									authModel,
									{
										bF: $mdgriffith$elm_ui$Element$classifyDevice(
											{eD: height, fA: width})
									})),
							$elm$core$Platform$Cmd$none);
					} else {
						break _v0$9;
					}
				case 0:
					if (!_v0.b.$) {
						var url = _v0.a.a;
						var _v3 = _v0.b;
						var authModel = _v3.b;
						var _v4 = $author$project$Route$fromUrl(url);
						if (_v4.$ === 1) {
							return _Utils_Tuple2(
								$author$project$Main$NotFound(authModel.c9),
								$elm$core$Platform$Cmd$none);
						} else {
							var route = _v4.a;
							var activeCompetitionId = function () {
								if (route.$ === 2) {
									var id = route.a;
									return $elm$core$Maybe$Just(id);
								} else {
									return $elm$core$Maybe$Nothing;
								}
							}();
							var _v5 = A2($author$project$Main$changeRouteTo, route, authModel.y);
							var newContent = _v5.a;
							var cmdMsg = _v5.b;
							return _Utils_Tuple2(
								A2(
									$author$project$Main$Auth,
									newContent,
									_Utils_update(
										authModel,
										{
											b_: A2($author$project$Sidebar$updateActiveCompetition, authModel.b_, activeCompetitionId),
											cu: false
										})),
								cmdMsg);
						}
					} else {
						break _v0$9;
					}
				case 1:
					if (!_v0.b.$) {
						var urlRequest = _v0.a.a;
						var _v7 = _v0.b;
						var authModel = _v7.b;
						if (urlRequest.$ === 1) {
							var url = urlRequest.a;
							return _Utils_Tuple2(
								model,
								$elm$browser$Browser$Navigation$load(url));
						} else {
							var url = urlRequest.a;
							return _Utils_Tuple2(
								model,
								A2(
									$elm$browser$Browser$Navigation$pushUrl,
									authModel.c9,
									$elm$url$Url$toString(url)));
						}
					} else {
						break _v0$9;
					}
				case 8:
					if (!_v0.b.$) {
						var _v9 = _v0.a;
						var _v10 = _v0.b;
						var content = _v10.a;
						var authModel = _v10.b;
						return _Utils_Tuple2(
							A2(
								$author$project$Main$Auth,
								content,
								_Utils_update(
									authModel,
									{cu: !authModel.cu})),
							$elm$core$Platform$Cmd$none);
					} else {
						break _v0$9;
					}
				case 4:
					if (!_v0.b.$) {
						var subMsg = _v0.a.a;
						var _v11 = _v0.b;
						var content = _v11.a;
						var authModel = _v11.b;
						var _v12 = A2($author$project$Sidebar$update, subMsg, authModel.b_);
						var sidebar = _v12.a;
						var cmdMsg = _v12.b;
						return _Utils_Tuple2(
							A2(
								$author$project$Main$Auth,
								content,
								_Utils_update(
									authModel,
									{b_: sidebar})),
							A2($elm$core$Platform$Cmd$map, $author$project$Main$GotSidebarMsg, cmdMsg));
					} else {
						break _v0$9;
					}
				case 3:
					var subMsg = _v0.a.a;
					return _Utils_Tuple2(
						model,
						A2(
							$elm$core$Platform$Cmd$map,
							$author$project$Main$headerTranslator,
							$author$project$Header$update(subMsg)));
				case 5:
					if (_v0.b.$ === 1) {
						var subMsg = _v0.a.a;
						var _v13 = _v0.b;
						return _Utils_Tuple2(
							model,
							A2(
								$elm$core$Platform$Cmd$map,
								$author$project$Main$GotLoginMsg,
								$author$project$Page$Login$update(subMsg)));
					} else {
						break _v0$9;
					}
				case 6:
					if ((!_v0.b.$) && (_v0.b.a.$ === 1)) {
						var subMsg = _v0.a.a;
						var _v14 = _v0.b;
						var subModel = _v14.a.a;
						var authModel = _v14.b;
						var _v15 = A2($author$project$Page$Competition$update, subMsg, subModel);
						var newSubModel = _v15.a;
						var newCmdMsg = _v15.b;
						return _Utils_Tuple2(
							A2(
								$author$project$Main$Auth,
								$author$project$Main$Competition(newSubModel),
								authModel),
							A2($elm$core$Platform$Cmd$map, $author$project$Main$GotCompetitionMsg, newCmdMsg));
					} else {
						break _v0$9;
					}
				default:
					if ((!_v0.b.$) && (_v0.b.a.$ === 2)) {
						var subMsg = _v0.a.a;
						var _v16 = _v0.b;
						var subModel = _v16.a.a;
						var authModel = _v16.b;
						var _v17 = A2($author$project$Page$CreateCompetition$update, subMsg, subModel);
						var newSubModel = _v17.a;
						var newCmdMsg = _v17.b;
						return _Utils_Tuple2(
							A2(
								$author$project$Main$Auth,
								$author$project$Main$CreateCompetition(newSubModel),
								authModel),
							A2($elm$core$Platform$Cmd$map, $author$project$Main$GotCreateCompetitionMsg, newCmdMsg));
					} else {
						break _v0$9;
					}
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $mdgriffith$elm_ui$Internal$Model$Colored = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Model$StyleClass = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$Flag = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$Second = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$flag = function (i) {
	return (i > 31) ? $mdgriffith$elm_ui$Internal$Flag$Second(1 << (i - 32)) : $mdgriffith$elm_ui$Internal$Flag$Flag(1 << i);
};
var $mdgriffith$elm_ui$Internal$Flag$fontColor = $mdgriffith$elm_ui$Internal$Flag$flag(14);
var $mdgriffith$elm_ui$Internal$Model$floatClass = function (x) {
	return $elm$core$String$fromInt(
		$elm$core$Basics$round(x * 255));
};
var $mdgriffith$elm_ui$Internal$Model$formatColorClass = function (_v0) {
	var red = _v0.a;
	var green = _v0.b;
	var blue = _v0.c;
	var alpha = _v0.d;
	return $mdgriffith$elm_ui$Internal$Model$floatClass(red) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(green) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(blue) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(alpha))))));
};
var $mdgriffith$elm_ui$Element$Font$color = function (fontColor) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$fontColor,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Colored,
			'fc-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(fontColor),
			'color',
			fontColor));
};
var $mdgriffith$elm_ui$Internal$Model$FontFamily = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$fontFamily = $mdgriffith$elm_ui$Internal$Flag$flag(5);
var $elm$core$String$toLower = _String_toLower;
var $elm$core$String$words = _String_words;
var $mdgriffith$elm_ui$Internal$Model$renderFontClassName = F2(
	function (font, current) {
		return _Utils_ap(
			current,
			function () {
				switch (font.$) {
					case 0:
						return 'serif';
					case 1:
						return 'sans-serif';
					case 2:
						return 'monospace';
					case 3:
						var name = font.a;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
					case 4:
						var name = font.a;
						var url = font.b;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
					default:
						var name = font.a.o;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
				}
			}());
	});
var $mdgriffith$elm_ui$Element$Font$family = function (families) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$fontFamily,
		A2(
			$mdgriffith$elm_ui$Internal$Model$FontFamily,
			A3($elm$core$List$foldl, $mdgriffith$elm_ui$Internal$Model$renderFontClassName, 'ff-', families),
			families));
};
var $mdgriffith$elm_ui$Internal$Model$Rgba = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $mdgriffith$elm_ui$Element$rgb255 = F3(
	function (red, green, blue) {
		return A4($mdgriffith$elm_ui$Internal$Model$Rgba, red / 255, green / 255, blue / 255, 1);
	});
var $author$project$Colors$gray600 = A3($mdgriffith$elm_ui$Element$rgb255, 75, 85, 99);
var $mdgriffith$elm_ui$Internal$Style$classes = {fH: 'a', di: 'atv', fJ: 'ab', fK: 'cx', fL: 'cy', fM: 'acb', fN: 'accx', fO: 'accy', fP: 'acr', eh: 'al', ei: 'ar', fQ: 'at', dj: 'ah', dk: 'av', fT: 's', fY: 'bh', fZ: 'b', f0: 'w7', f2: 'bd', f3: 'bdt', cW: 'bn', f4: 'bs', cX: 'cpe', gb: 'cp', gc: 'cpx', gd: 'cpy', gf: 'c', gh: 'ctr', cZ: 'cb', c_: 'ccx', bE: 'ccy', cH: 'cl', c$: 'cr', gi: 'ct', gk: 'cptr', gl: 'ctxt', gu: 'fcs', eA: 'focus-within', gv: 'fs', gx: 'g', dt: 'hbh', du: 'hc', eE: 'he', dv: 'hf', eF: 'hfp', gA: 'hv', gC: 'ic', gE: 'fr', c4: 'lbl', gH: 'iml', gI: 'imlf', gJ: 'imlp', gK: 'implw', gL: 'it', gS: 'i', eS: 'lnk', cl: 'nb', eY: 'notxt', g3: 'ol', g4: 'or', bS: 'oq', g8: 'oh', e2: 'pg', e3: 'p', g9: 'ppe', hh: 'ui', ff: 'r', hj: 'sb', hk: 'sbx', hl: 'sby', hm: 'sbt', hp: 'e', hr: 'cap', hs: 'sev', hA: 'sk', hE: 't', hF: 'tc', hG: 'w8', hH: 'w2', hI: 'w9', hJ: 'tj', df: 'tja', hK: 'tl', hL: 'w3', hM: 'w5', hN: 'w4', hO: 'tr', hP: 'w6', hQ: 'w1', hR: 'tun', ft: 'ts', b$: 'clr', h1: 'u', ea: 'wc', fB: 'we', eb: 'wf', fC: 'wfp', ec: 'wrp'};
var $mdgriffith$elm_ui$Internal$Model$Attr = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $mdgriffith$elm_ui$Internal$Model$htmlClass = function (cls) {
	return $mdgriffith$elm_ui$Internal$Model$Attr(
		$elm$html$Html$Attributes$class(cls));
};
var $mdgriffith$elm_ui$Internal$Model$OnlyDynamic = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$StaticRootAndDynamic = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$Unkeyed = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$AsEl = 2;
var $mdgriffith$elm_ui$Internal$Model$asEl = 2;
var $mdgriffith$elm_ui$Internal$Model$Generic = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$div = $mdgriffith$elm_ui$Internal$Model$Generic;
var $mdgriffith$elm_ui$Internal$Model$NoNearbyChildren = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$columnClass = $mdgriffith$elm_ui$Internal$Style$classes.fT + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.gf);
var $mdgriffith$elm_ui$Internal$Model$gridClass = $mdgriffith$elm_ui$Internal$Style$classes.fT + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.gx);
var $mdgriffith$elm_ui$Internal$Model$pageClass = $mdgriffith$elm_ui$Internal$Style$classes.fT + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.e2);
var $mdgriffith$elm_ui$Internal$Model$paragraphClass = $mdgriffith$elm_ui$Internal$Style$classes.fT + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.e3);
var $mdgriffith$elm_ui$Internal$Model$rowClass = $mdgriffith$elm_ui$Internal$Style$classes.fT + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.ff);
var $mdgriffith$elm_ui$Internal$Model$singleClass = $mdgriffith$elm_ui$Internal$Style$classes.fT + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.hp);
var $mdgriffith$elm_ui$Internal$Model$contextClasses = function (context) {
	switch (context) {
		case 0:
			return $mdgriffith$elm_ui$Internal$Model$rowClass;
		case 1:
			return $mdgriffith$elm_ui$Internal$Model$columnClass;
		case 2:
			return $mdgriffith$elm_ui$Internal$Model$singleClass;
		case 3:
			return $mdgriffith$elm_ui$Internal$Model$gridClass;
		case 4:
			return $mdgriffith$elm_ui$Internal$Model$paragraphClass;
		default:
			return $mdgriffith$elm_ui$Internal$Model$pageClass;
	}
};
var $mdgriffith$elm_ui$Internal$Model$Keyed = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$NoStyleSheet = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$Styled = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Unstyled = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$addChildren = F2(
	function (existing, nearbyChildren) {
		switch (nearbyChildren.$) {
			case 0:
				return existing;
			case 1:
				var behind = nearbyChildren.a;
				return _Utils_ap(behind, existing);
			case 2:
				var inFront = nearbyChildren.a;
				return _Utils_ap(existing, inFront);
			default:
				var behind = nearbyChildren.a;
				var inFront = nearbyChildren.b;
				return _Utils_ap(
					behind,
					_Utils_ap(existing, inFront));
		}
	});
var $mdgriffith$elm_ui$Internal$Model$addKeyedChildren = F3(
	function (key, existing, nearbyChildren) {
		switch (nearbyChildren.$) {
			case 0:
				return existing;
			case 1:
				var behind = nearbyChildren.a;
				return _Utils_ap(
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						behind),
					existing);
			case 2:
				var inFront = nearbyChildren.a;
				return _Utils_ap(
					existing,
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						inFront));
			default:
				var behind = nearbyChildren.a;
				var inFront = nearbyChildren.b;
				return _Utils_ap(
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						behind),
					_Utils_ap(
						existing,
						A2(
							$elm$core$List$map,
							function (x) {
								return _Utils_Tuple2(key, x);
							},
							inFront)));
		}
	});
var $mdgriffith$elm_ui$Internal$Model$AsParagraph = 4;
var $mdgriffith$elm_ui$Internal$Model$asParagraph = 4;
var $mdgriffith$elm_ui$Internal$Flag$alignBottom = $mdgriffith$elm_ui$Internal$Flag$flag(41);
var $mdgriffith$elm_ui$Internal$Flag$alignRight = $mdgriffith$elm_ui$Internal$Flag$flag(40);
var $mdgriffith$elm_ui$Internal$Flag$centerX = $mdgriffith$elm_ui$Internal$Flag$flag(42);
var $mdgriffith$elm_ui$Internal$Flag$centerY = $mdgriffith$elm_ui$Internal$Flag$flag(43);
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $mdgriffith$elm_ui$Internal$Model$lengthClassName = function (x) {
	switch (x.$) {
		case 0:
			var px = x.a;
			return $elm$core$String$fromInt(px) + 'px';
		case 1:
			return 'auto';
		case 2:
			var i = x.a;
			return $elm$core$String$fromInt(i) + 'fr';
		case 3:
			var min = x.a;
			var len = x.b;
			return 'min' + ($elm$core$String$fromInt(min) + $mdgriffith$elm_ui$Internal$Model$lengthClassName(len));
		default:
			var max = x.a;
			var len = x.b;
			return 'max' + ($elm$core$String$fromInt(max) + $mdgriffith$elm_ui$Internal$Model$lengthClassName(len));
	}
};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $mdgriffith$elm_ui$Internal$Model$transformClass = function (transform) {
	switch (transform.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var _v1 = transform.a;
			var x = _v1.a;
			var y = _v1.b;
			var z = _v1.c;
			return $elm$core$Maybe$Just(
				'mv-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(x) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(y) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(z))))));
		default:
			var _v2 = transform.a;
			var tx = _v2.a;
			var ty = _v2.b;
			var tz = _v2.c;
			var _v3 = transform.b;
			var sx = _v3.a;
			var sy = _v3.b;
			var sz = _v3.c;
			var _v4 = transform.c;
			var ox = _v4.a;
			var oy = _v4.b;
			var oz = _v4.c;
			var angle = transform.d;
			return $elm$core$Maybe$Just(
				'tfrm-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(tx) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(ty) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(tz) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sx) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sy) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sz) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(ox) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(oy) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(oz) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(angle))))))))))))))))))));
	}
};
var $mdgriffith$elm_ui$Internal$Model$getStyleName = function (style) {
	switch (style.$) {
		case 13:
			var name = style.a;
			return name;
		case 12:
			var name = style.a;
			var o = style.b;
			return name;
		case 0:
			var _class = style.a;
			return _class;
		case 1:
			var name = style.a;
			return name;
		case 2:
			var i = style.a;
			return 'font-size-' + $elm$core$String$fromInt(i);
		case 3:
			var _class = style.a;
			return _class;
		case 4:
			var _class = style.a;
			return _class;
		case 5:
			var cls = style.a;
			var x = style.b;
			var y = style.c;
			return cls;
		case 7:
			var cls = style.a;
			var top = style.b;
			var right = style.c;
			var bottom = style.d;
			var left = style.e;
			return cls;
		case 6:
			var cls = style.a;
			var top = style.b;
			var right = style.c;
			var bottom = style.d;
			var left = style.e;
			return cls;
		case 8:
			var template = style.a;
			return 'grid-rows-' + (A2(
				$elm$core$String$join,
				'-',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.hi)) + ('-cols-' + (A2(
				$elm$core$String$join,
				'-',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.gg)) + ('-space-x-' + ($mdgriffith$elm_ui$Internal$Model$lengthClassName(template.ht.a) + ('-space-y-' + $mdgriffith$elm_ui$Internal$Model$lengthClassName(template.ht.b)))))));
		case 9:
			var pos = style.a;
			return 'gp grid-pos-' + ($elm$core$String$fromInt(pos.ff) + ('-' + ($elm$core$String$fromInt(pos.eq) + ('-' + ($elm$core$String$fromInt(pos.fA) + ('-' + $elm$core$String$fromInt(pos.eD)))))));
		case 11:
			var selector = style.a;
			var subStyle = style.b;
			var name = function () {
				switch (selector) {
					case 0:
						return 'fs';
					case 1:
						return 'hv';
					default:
						return 'act';
				}
			}();
			return A2(
				$elm$core$String$join,
				' ',
				A2(
					$elm$core$List$map,
					function (sty) {
						var _v1 = $mdgriffith$elm_ui$Internal$Model$getStyleName(sty);
						if (_v1 === '') {
							return '';
						} else {
							var styleName = _v1;
							return styleName + ('-' + name);
						}
					},
					subStyle));
		default:
			var x = style.a;
			return A2(
				$elm$core$Maybe$withDefault,
				'',
				$mdgriffith$elm_ui$Internal$Model$transformClass(x));
	}
};
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $mdgriffith$elm_ui$Internal$Model$reduceStyles = F2(
	function (style, nevermind) {
		var cache = nevermind.a;
		var existing = nevermind.b;
		var styleName = $mdgriffith$elm_ui$Internal$Model$getStyleName(style);
		return A2($elm$core$Set$member, styleName, cache) ? nevermind : _Utils_Tuple2(
			A2($elm$core$Set$insert, styleName, cache),
			A2($elm$core$List$cons, style, existing));
	});
var $mdgriffith$elm_ui$Internal$Model$Property = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$Style = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$dot = function (c) {
	return '.' + c;
};
var $mdgriffith$elm_ui$Internal$Model$formatColor = function (_v0) {
	var red = _v0.a;
	var green = _v0.b;
	var blue = _v0.c;
	var alpha = _v0.d;
	return 'rgba(' + ($elm$core$String$fromInt(
		$elm$core$Basics$round(red * 255)) + ((',' + $elm$core$String$fromInt(
		$elm$core$Basics$round(green * 255))) + ((',' + $elm$core$String$fromInt(
		$elm$core$Basics$round(blue * 255))) + (',' + ($elm$core$String$fromFloat(alpha) + ')')))));
};
var $mdgriffith$elm_ui$Internal$Model$formatBoxShadow = function (shadow) {
	return A2(
		$elm$core$String$join,
		' ',
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					shadow.eN ? $elm$core$Maybe$Just('inset') : $elm$core$Maybe$Nothing,
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.az.a) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.az.b) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.f_) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.hq) + 'px'),
					$elm$core$Maybe$Just(
					$mdgriffith$elm_ui$Internal$Model$formatColor(shadow.ge))
				])));
};
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $mdgriffith$elm_ui$Internal$Model$renderFocusStyle = function (focus) {
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Internal$Model$Style,
			$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eA) + ':focus-within',
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'border-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.f1),
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'background-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.fW),
						A2(
						$elm$core$Maybe$map,
						function (shadow) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'box-shadow',
								$mdgriffith$elm_ui$Internal$Model$formatBoxShadow(
									{
										f_: shadow.f_,
										ge: shadow.ge,
										eN: false,
										az: A2(
											$elm$core$Tuple$mapSecond,
											$elm$core$Basics$toFloat,
											A2($elm$core$Tuple$mapFirst, $elm$core$Basics$toFloat, shadow.az)),
										hq: shadow.hq
									}));
						},
						focus.ho),
						$elm$core$Maybe$Just(
						A2($mdgriffith$elm_ui$Internal$Model$Property, 'outline', 'none'))
					]))),
			A2(
			$mdgriffith$elm_ui$Internal$Model$Style,
			($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fT) + ':focus .focusable, ') + (($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fT) + '.focusable:focus, ') + ('.ui-slide-bar:focus + ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fT) + ' .focusable-thumb'))),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'border-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.f1),
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'background-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.fW),
						A2(
						$elm$core$Maybe$map,
						function (shadow) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'box-shadow',
								$mdgriffith$elm_ui$Internal$Model$formatBoxShadow(
									{
										f_: shadow.f_,
										ge: shadow.ge,
										eN: false,
										az: A2(
											$elm$core$Tuple$mapSecond,
											$elm$core$Basics$toFloat,
											A2($elm$core$Tuple$mapFirst, $elm$core$Basics$toFloat, shadow.az)),
										hq: shadow.hq
									}));
						},
						focus.ho),
						$elm$core$Maybe$Just(
						A2($mdgriffith$elm_ui$Internal$Model$Property, 'outline', 'none'))
					])))
		]);
};
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $mdgriffith$elm_ui$Internal$Style$AllChildren = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Batch = function (a) {
	return {$: 6, a: a};
};
var $mdgriffith$elm_ui$Internal$Style$Child = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Class = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Descriptor = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Left = 3;
var $mdgriffith$elm_ui$Internal$Style$Prop = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Right = 2;
var $mdgriffith$elm_ui$Internal$Style$Self = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Internal$Style$Supports = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Content = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Internal$Style$Bottom = 1;
var $mdgriffith$elm_ui$Internal$Style$CenterX = 4;
var $mdgriffith$elm_ui$Internal$Style$CenterY = 5;
var $mdgriffith$elm_ui$Internal$Style$Top = 0;
var $mdgriffith$elm_ui$Internal$Style$alignments = _List_fromArray(
	[0, 1, 2, 3, 4, 5]);
var $mdgriffith$elm_ui$Internal$Style$contentName = function (desc) {
	switch (desc) {
		case 0:
			var _v1 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gi);
		case 1:
			var _v2 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cZ);
		case 2:
			var _v3 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.c$);
		case 3:
			var _v4 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cH);
		case 4:
			var _v5 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.c_);
		default:
			var _v6 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bE);
	}
};
var $mdgriffith$elm_ui$Internal$Style$selfName = function (desc) {
	switch (desc) {
		case 0:
			var _v1 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fQ);
		case 1:
			var _v2 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fJ);
		case 2:
			var _v3 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ei);
		case 3:
			var _v4 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eh);
		case 4:
			var _v5 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fK);
		default:
			var _v6 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fL);
	}
};
var $mdgriffith$elm_ui$Internal$Style$describeAlignment = function (values) {
	var createDescription = function (alignment) {
		var _v0 = values(alignment);
		var content = _v0.a;
		var indiv = _v0.b;
		return _List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$contentName(alignment),
				content),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fT),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$selfName(alignment),
						indiv)
					]))
			]);
	};
	return $mdgriffith$elm_ui$Internal$Style$Batch(
		A2($elm$core$List$concatMap, createDescription, $mdgriffith$elm_ui$Internal$Style$alignments));
};
var $mdgriffith$elm_ui$Internal$Style$elDescription = _List_fromArray(
	[
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'column'),
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre'),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Descriptor,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dt),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fY),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '-1')
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Descriptor,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hm),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hE),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dv),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eb),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'auto !important')
							]))
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.du),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dv),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eb),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fC),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ea),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
			])),
		$mdgriffith$elm_ui$Internal$Style$describeAlignment(
		function (alignment) {
			switch (alignment) {
				case 0:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', '0 !important')
							]));
				case 1:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', '0 !important')
							]));
				case 2:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
							]));
				case 3:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
							]));
				case 4:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
							]));
				default:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fT),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto')
									]))
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
							]));
			}
		})
	]);
var $mdgriffith$elm_ui$Internal$Style$gridAlignments = function (values) {
	var createDescription = function (alignment) {
		return _List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fT),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$selfName(alignment),
						values(alignment))
					]))
			]);
	};
	return $mdgriffith$elm_ui$Internal$Style$Batch(
		A2($elm$core$List$concatMap, createDescription, $mdgriffith$elm_ui$Internal$Style$alignments));
};
var $mdgriffith$elm_ui$Internal$Style$Above = 0;
var $mdgriffith$elm_ui$Internal$Style$Behind = 5;
var $mdgriffith$elm_ui$Internal$Style$Below = 1;
var $mdgriffith$elm_ui$Internal$Style$OnLeft = 3;
var $mdgriffith$elm_ui$Internal$Style$OnRight = 2;
var $mdgriffith$elm_ui$Internal$Style$Within = 4;
var $mdgriffith$elm_ui$Internal$Style$locations = function () {
	var loc = 0;
	var _v0 = function () {
		switch (loc) {
			case 0:
				return 0;
			case 1:
				return 0;
			case 2:
				return 0;
			case 3:
				return 0;
			case 4:
				return 0;
			default:
				return 0;
		}
	}();
	return _List_fromArray(
		[0, 1, 2, 3, 4, 5]);
}();
var $mdgriffith$elm_ui$Internal$Style$baseSheet = _List_fromArray(
	[
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		'html,body',
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'padding', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		_Utils_ap(
			$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fT),
			_Utils_ap(
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hp),
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gC))),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dv),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'img',
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'max-height', '100%'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'object-fit', 'cover')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eb),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'img',
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'max-width', '100%'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'object-fit', 'cover')
							]))
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fT) + ':focus',
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'outline', 'none')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hh),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'min-height', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				_Utils_ap(
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fT),
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dv)),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dv),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gE),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cl),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'fixed'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20')
							]))
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cl),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'relative'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hp),
				$mdgriffith$elm_ui$Internal$Style$elDescription),
				$mdgriffith$elm_ui$Internal$Style$Batch(
				function (fn) {
					return A2($elm$core$List$map, fn, $mdgriffith$elm_ui$Internal$Style$locations);
				}(
					function (loc) {
						switch (loc) {
							case 0:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fH),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'bottom', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dv),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
												])),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eb),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
												])),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 1:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fZ),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'bottom', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												])),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dv),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
												]))
										]));
							case 2:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.g4),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 3:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.g3),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'right', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 4:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gE),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							default:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fY),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
						}
					}))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fT),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'relative'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'resize', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'box-sizing', 'border-box'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'padding', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-width', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'solid'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-size', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'color', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-family', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'line-height', '1'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'inherit'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ec),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-wrap', 'wrap')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eY),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-moz-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-webkit-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-ms-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'user-select', 'none')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gk),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'pointer')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gl),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'text')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.g9),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none !important')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cX),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto !important')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.b$),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bS),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.gA, $mdgriffith$elm_ui$Internal$Style$classes.b$)) + ':hover',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.gA, $mdgriffith$elm_ui$Internal$Style$classes.bS)) + ':hover',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.gu, $mdgriffith$elm_ui$Internal$Style$classes.b$)) + ':focus',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.gu, $mdgriffith$elm_ui$Internal$Style$classes.bS)) + ':focus',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.di, $mdgriffith$elm_ui$Internal$Style$classes.b$)) + ':active',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.di, $mdgriffith$elm_ui$Internal$Style$classes.bS)) + ':active',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ft),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Prop,
						'transition',
						A2(
							$elm$core$String$join,
							', ',
							A2(
								$elm$core$List$map,
								function (x) {
									return x + ' 160ms';
								},
								_List_fromArray(
									['transform', 'opacity', 'filter', 'background-color', 'color', 'font-size']))))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hj),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hk),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-x', 'auto'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ff),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hl),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-y', 'auto'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gf),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hp),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gb),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gc),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-x', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gd),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-y', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ea),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', 'auto')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cW),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-width', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f2),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'dashed')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f3),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'dotted')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f4),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'solid')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hE),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-block')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gL),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'line-height', '1.05'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'background', 'transparent'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'inherit')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hp),
				$mdgriffith$elm_ui$Internal$Style$elDescription),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ff),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fT),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', '0%'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fB),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eS),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dv),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eF),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eb),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gh),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.fP,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.fN,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fK),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-left', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.fN,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fK),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-right', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:only-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.fN,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fL),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.fN + ' ~ u'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.fP + (' ~ s.' + $mdgriffith$elm_ui$Internal$Style$classes.fN)),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
											]));
								case 1:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
											]));
								case 2:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
											]),
										_List_Nil);
								case 3:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
											]),
										_List_Nil);
								case 4:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
											]),
										_List_Nil);
								default:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
											]));
							}
						}),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hs),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'space-between')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.c4),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'baseline')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gf),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'column'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fT),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', '0px'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'min-height', 'min-content'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eE),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dv),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eb),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fC),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ea),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.fM,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.fO,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fL),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', '0 !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.fO,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fL),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', '0 !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:only-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.fO,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fL),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.fO + ' ~ u'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.fM + (' ~ s.' + $mdgriffith$elm_ui$Internal$Style$classes.fO)),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto')
											]));
								case 1:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto')
											]));
								case 2:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
											]));
								case 3:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
											]));
								case 4:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
											]));
								default:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
											]),
										_List_Nil);
							}
						}),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gh),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hs),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'space-between')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gx),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', '-ms-grid'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'.gp',
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fT),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Supports,
						_Utils_Tuple2('display', 'grid'),
						_List_fromArray(
							[
								_Utils_Tuple2('display', 'grid')
							])),
						$mdgriffith$elm_ui$Internal$Style$gridAlignments(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
										]);
								case 1:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
										]);
								case 2:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
										]);
								case 3:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
										]);
								case 4:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
										]);
								default:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
										]);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e2),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fT + ':first-child'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot(
							$mdgriffith$elm_ui$Internal$Style$classes.fT + ($mdgriffith$elm_ui$Internal$Style$selfName(3) + (':first-child + .' + $mdgriffith$elm_ui$Internal$Style$classes.fT))),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot(
							$mdgriffith$elm_ui$Internal$Style$classes.fT + ($mdgriffith$elm_ui$Internal$Style$selfName(2) + (':first-child + .' + $mdgriffith$elm_ui$Internal$Style$classes.fT))),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 1:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 2:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'right'),
												A2(
												$mdgriffith$elm_ui$Internal$Style$Descriptor,
												'::after',
												_List_fromArray(
													[
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', '\"\"'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'table'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'clear', 'both')
													]))
											]));
								case 3:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'left'),
												A2(
												$mdgriffith$elm_ui$Internal$Style$Descriptor,
												'::after',
												_List_fromArray(
													[
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', '\"\"'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'table'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'clear', 'both')
													]))
											]));
								case 4:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								default:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gH),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre-wrap !important'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'background-color', 'transparent')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gK),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hp),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gJ),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre-wrap !important'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'text'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gI),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre-wrap !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'color', 'transparent')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e3),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-wrap', 'break-word'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dt),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fY),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '-1')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$AllChildren,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hE),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$AllChildren,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.e3),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								'::after',
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', 'none')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								'::before',
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', 'none')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$AllChildren,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hp),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fB),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-block')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gE),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fY),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fH),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fZ),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.g4),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.g3),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hE),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ff),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gf),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-flex')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gx),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-grid')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 1:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 2:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'right')
											]));
								case 3:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'left')
											]));
								case 4:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								default:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				'.hidden',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'none')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hQ),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '100')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hH),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '200')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hL),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '300')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hN),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '400')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hM),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '500')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hP),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '600')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f0),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '700')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hG),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '800')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hI),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '900')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gS),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'italic')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hA),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'line-through')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.h1),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'underline'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip-ink', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip', 'ink')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				_Utils_ap(
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.h1),
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hA)),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'line-through underline'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip-ink', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip', 'ink')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hR),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'normal')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hJ),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'justify')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.df),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'justify-all')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hF),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'center')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hO),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'right')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hK),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'left')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				'.modal',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'fixed'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none')
					]))
			]))
	]);
var $mdgriffith$elm_ui$Internal$Style$fontVariant = function (_var) {
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Internal$Style$Class,
			'.v-' + _var,
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', '\"' + (_var + '\"'))
				])),
			A2(
			$mdgriffith$elm_ui$Internal$Style$Class,
			'.v-' + (_var + '-off'),
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', '\"' + (_var + '\" 0'))
				]))
		]);
};
var $mdgriffith$elm_ui$Internal$Style$commonValues = $elm$core$List$concat(
	_List_fromArray(
		[
			A2(
			$elm$core$List$map,
			function (x) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.border-' + $elm$core$String$fromInt(x),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'border-width',
							$elm$core$String$fromInt(x) + 'px')
						]));
			},
			A2($elm$core$List$range, 0, 6)),
			A2(
			$elm$core$List$map,
			function (i) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.font-size-' + $elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'font-size',
							$elm$core$String$fromInt(i) + 'px')
						]));
			},
			A2($elm$core$List$range, 8, 32)),
			A2(
			$elm$core$List$map,
			function (i) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.p-' + $elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'padding',
							$elm$core$String$fromInt(i) + 'px')
						]));
			},
			A2($elm$core$List$range, 0, 24)),
			_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Class,
				'.v-smcp',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-variant', 'small-caps')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Class,
				'.v-smcp-off',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-variant', 'normal')
					]))
			]),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('zero'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('onum'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('liga'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('dlig'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('ordn'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('tnum'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('afrc'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('frac')
		]));
var $mdgriffith$elm_ui$Internal$Style$explainer = '\n.explain {\n    border: 6px solid rgb(174, 121, 15) !important;\n}\n.explain > .' + ($mdgriffith$elm_ui$Internal$Style$classes.fT + (' {\n    border: 4px dashed rgb(0, 151, 167) !important;\n}\n\n.ctr {\n    border: none !important;\n}\n.explain > .ctr > .' + ($mdgriffith$elm_ui$Internal$Style$classes.fT + ' {\n    border: 4px dashed rgb(0, 151, 167) !important;\n}\n\n')));
var $mdgriffith$elm_ui$Internal$Style$inputTextReset = '\ninput[type="search"],\ninput[type="search"]::-webkit-search-decoration,\ninput[type="search"]::-webkit-search-cancel-button,\ninput[type="search"]::-webkit-search-results-button,\ninput[type="search"]::-webkit-search-results-decoration {\n  -webkit-appearance:none;\n}\n';
var $mdgriffith$elm_ui$Internal$Style$sliderReset = '\ninput[type=range] {\n  -webkit-appearance: none; \n  background: transparent;\n  position:absolute;\n  left:0;\n  top:0;\n  z-index:10;\n  width: 100%;\n  outline: dashed 1px;\n  height: 100%;\n  opacity: 0;\n}\n';
var $mdgriffith$elm_ui$Internal$Style$thumbReset = '\ninput[type=range]::-webkit-slider-thumb {\n    -webkit-appearance: none;\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range]::-moz-range-thumb {\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range]::-ms-thumb {\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range][orient=vertical]{\n    writing-mode: bt-lr; /* IE */\n    -webkit-appearance: slider-vertical;  /* WebKit */\n}\n';
var $mdgriffith$elm_ui$Internal$Style$trackReset = '\ninput[type=range]::-moz-range-track {\n    background: transparent;\n    cursor: pointer;\n}\ninput[type=range]::-ms-track {\n    background: transparent;\n    cursor: pointer;\n}\ninput[type=range]::-webkit-slider-runnable-track {\n    background: transparent;\n    cursor: pointer;\n}\n';
var $mdgriffith$elm_ui$Internal$Style$overrides = '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fT) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ff) + (' > ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fT) + (' { flex-basis: auto !important; } ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fT) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ff) + (' > ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fT) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gh) + (' { flex-basis: auto !important; }}' + ($mdgriffith$elm_ui$Internal$Style$inputTextReset + ($mdgriffith$elm_ui$Internal$Style$sliderReset + ($mdgriffith$elm_ui$Internal$Style$trackReset + ($mdgriffith$elm_ui$Internal$Style$thumbReset + $mdgriffith$elm_ui$Internal$Style$explainer)))))))))))))));
var $mdgriffith$elm_ui$Internal$Style$Intermediate = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Internal$Style$emptyIntermediate = F2(
	function (selector, closing) {
		return {cY: closing, aQ: _List_Nil, bK: _List_Nil, bv: selector};
	});
var $mdgriffith$elm_ui$Internal$Style$renderRules = F2(
	function (_v0, rulesToRender) {
		var parent = _v0;
		var generateIntermediates = F2(
			function (rule, rendered) {
				switch (rule.$) {
					case 0:
						var name = rule.a;
						var val = rule.b;
						return _Utils_update(
							rendered,
							{
								bK: A2(
									$elm$core$List$cons,
									_Utils_Tuple2(name, val),
									rendered.bK)
							});
					case 3:
						var _v2 = rule.a;
						var prop = _v2.a;
						var value = _v2.b;
						var props = rule.b;
						return _Utils_update(
							rendered,
							{
								aQ: A2(
									$elm$core$List$cons,
									{cY: '\n}', aQ: _List_Nil, bK: props, bv: '@supports (' + (prop + (':' + (value + (') {' + parent.bv))))},
									rendered.aQ)
							});
					case 5:
						var selector = rule.a;
						var adjRules = rule.b;
						return _Utils_update(
							rendered,
							{
								aQ: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.bv + (' + ' + selector), ''),
										adjRules),
									rendered.aQ)
							});
					case 1:
						var child = rule.a;
						var childRules = rule.b;
						return _Utils_update(
							rendered,
							{
								aQ: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.bv + (' > ' + child), ''),
										childRules),
									rendered.aQ)
							});
					case 2:
						var child = rule.a;
						var childRules = rule.b;
						return _Utils_update(
							rendered,
							{
								aQ: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.bv + (' ' + child), ''),
										childRules),
									rendered.aQ)
							});
					case 4:
						var descriptor = rule.a;
						var descriptorRules = rule.b;
						return _Utils_update(
							rendered,
							{
								aQ: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2(
											$mdgriffith$elm_ui$Internal$Style$emptyIntermediate,
											_Utils_ap(parent.bv, descriptor),
											''),
										descriptorRules),
									rendered.aQ)
							});
					default:
						var batched = rule.a;
						return _Utils_update(
							rendered,
							{
								aQ: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.bv, ''),
										batched),
									rendered.aQ)
							});
				}
			});
		return A3($elm$core$List$foldr, generateIntermediates, parent, rulesToRender);
	});
var $mdgriffith$elm_ui$Internal$Style$renderCompact = function (styleClasses) {
	var renderValues = function (values) {
		return $elm$core$String$concat(
			A2(
				$elm$core$List$map,
				function (_v3) {
					var x = _v3.a;
					var y = _v3.b;
					return x + (':' + (y + ';'));
				},
				values));
	};
	var renderClass = function (rule) {
		var _v2 = rule.bK;
		if (!_v2.b) {
			return '';
		} else {
			return rule.bv + ('{' + (renderValues(rule.bK) + (rule.cY + '}')));
		}
	};
	var renderIntermediate = function (_v0) {
		var rule = _v0;
		return _Utils_ap(
			renderClass(rule),
			$elm$core$String$concat(
				A2($elm$core$List$map, renderIntermediate, rule.aQ)));
	};
	return $elm$core$String$concat(
		A2(
			$elm$core$List$map,
			renderIntermediate,
			A3(
				$elm$core$List$foldr,
				F2(
					function (_v1, existing) {
						var name = _v1.a;
						var styleRules = _v1.b;
						return A2(
							$elm$core$List$cons,
							A2(
								$mdgriffith$elm_ui$Internal$Style$renderRules,
								A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, name, ''),
								styleRules),
							existing);
					}),
				_List_Nil,
				styleClasses)));
};
var $mdgriffith$elm_ui$Internal$Style$rules = _Utils_ap(
	$mdgriffith$elm_ui$Internal$Style$overrides,
	$mdgriffith$elm_ui$Internal$Style$renderCompact(
		_Utils_ap($mdgriffith$elm_ui$Internal$Style$baseSheet, $mdgriffith$elm_ui$Internal$Style$commonValues)));
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $mdgriffith$elm_ui$Internal$Model$staticRoot = function (opts) {
	var _v0 = opts.g_;
	switch (_v0) {
		case 0:
			return A3(
				$elm$virtual_dom$VirtualDom$node,
				'div',
				_List_Nil,
				_List_fromArray(
					[
						A3(
						$elm$virtual_dom$VirtualDom$node,
						'style',
						_List_Nil,
						_List_fromArray(
							[
								$elm$virtual_dom$VirtualDom$text($mdgriffith$elm_ui$Internal$Style$rules)
							]))
					]));
		case 1:
			return $elm$virtual_dom$VirtualDom$text('');
		default:
			return A3(
				$elm$virtual_dom$VirtualDom$node,
				'elm-ui-static-rules',
				_List_fromArray(
					[
						A2(
						$elm$virtual_dom$VirtualDom$property,
						'rules',
						$elm$json$Json$Encode$string($mdgriffith$elm_ui$Internal$Style$rules))
					]),
				_List_Nil);
	}
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$fontName = function (font) {
	switch (font.$) {
		case 0:
			return 'serif';
		case 1:
			return 'sans-serif';
		case 2:
			return 'monospace';
		case 3:
			var name = font.a;
			return '\"' + (name + '\"');
		case 4:
			var name = font.a;
			var url = font.b;
			return '\"' + (name + '\"');
		default:
			var name = font.a.o;
			return '\"' + (name + '\"');
	}
};
var $mdgriffith$elm_ui$Internal$Model$isSmallCaps = function (_var) {
	switch (_var.$) {
		case 0:
			var name = _var.a;
			return name === 'smcp';
		case 1:
			var name = _var.a;
			return false;
		default:
			var name = _var.a;
			var index = _var.b;
			return (name === 'smcp') && (index === 1);
	}
};
var $mdgriffith$elm_ui$Internal$Model$hasSmallCaps = function (typeface) {
	if (typeface.$ === 5) {
		var font = typeface.a;
		return A2($elm$core$List$any, $mdgriffith$elm_ui$Internal$Model$isSmallCaps, font.fv);
	} else {
		return false;
	}
};
var $mdgriffith$elm_ui$Internal$Model$renderProps = F3(
	function (force, _v0, existing) {
		var key = _v0.a;
		var val = _v0.b;
		return force ? (existing + ('\n  ' + (key + (': ' + (val + ' !important;'))))) : (existing + ('\n  ' + (key + (': ' + (val + ';')))));
	});
var $mdgriffith$elm_ui$Internal$Model$renderStyle = F4(
	function (options, maybePseudo, selector, props) {
		if (maybePseudo.$ === 1) {
			return _List_fromArray(
				[
					selector + ('{' + (A3(
					$elm$core$List$foldl,
					$mdgriffith$elm_ui$Internal$Model$renderProps(false),
					'',
					props) + '\n}'))
				]);
		} else {
			var pseudo = maybePseudo.a;
			switch (pseudo) {
				case 1:
					var _v2 = options.gA;
					switch (_v2) {
						case 0:
							return _List_Nil;
						case 2:
							return _List_fromArray(
								[
									selector + ('-hv {' + (A3(
									$elm$core$List$foldl,
									$mdgriffith$elm_ui$Internal$Model$renderProps(true),
									'',
									props) + '\n}'))
								]);
						default:
							return _List_fromArray(
								[
									selector + ('-hv:hover {' + (A3(
									$elm$core$List$foldl,
									$mdgriffith$elm_ui$Internal$Model$renderProps(false),
									'',
									props) + '\n}'))
								]);
					}
				case 0:
					var renderedProps = A3(
						$elm$core$List$foldl,
						$mdgriffith$elm_ui$Internal$Model$renderProps(false),
						'',
						props);
					return _List_fromArray(
						[
							selector + ('-fs:focus {' + (renderedProps + '\n}')),
							('.' + ($mdgriffith$elm_ui$Internal$Style$classes.fT + (':focus ' + (selector + '-fs  {')))) + (renderedProps + '\n}'),
							(selector + '-fs:focus-within {') + (renderedProps + '\n}'),
							('.ui-slide-bar:focus + ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.fT) + (' .focusable-thumb' + (selector + '-fs {')))) + (renderedProps + '\n}')
						]);
				default:
					return _List_fromArray(
						[
							selector + ('-act:active {' + (A3(
							$elm$core$List$foldl,
							$mdgriffith$elm_ui$Internal$Model$renderProps(false),
							'',
							props) + '\n}'))
						]);
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$renderVariant = function (_var) {
	switch (_var.$) {
		case 0:
			var name = _var.a;
			return '\"' + (name + '\"');
		case 1:
			var name = _var.a;
			return '\"' + (name + '\" 0');
		default:
			var name = _var.a;
			var index = _var.b;
			return '\"' + (name + ('\" ' + $elm$core$String$fromInt(index)));
	}
};
var $mdgriffith$elm_ui$Internal$Model$renderVariants = function (typeface) {
	if (typeface.$ === 5) {
		var font = typeface.a;
		return $elm$core$Maybe$Just(
			A2(
				$elm$core$String$join,
				', ',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$renderVariant, font.fv)));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Internal$Model$transformValue = function (transform) {
	switch (transform.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var _v1 = transform.a;
			var x = _v1.a;
			var y = _v1.b;
			var z = _v1.c;
			return $elm$core$Maybe$Just(
				'translate3d(' + ($elm$core$String$fromFloat(x) + ('px, ' + ($elm$core$String$fromFloat(y) + ('px, ' + ($elm$core$String$fromFloat(z) + 'px)'))))));
		default:
			var _v2 = transform.a;
			var tx = _v2.a;
			var ty = _v2.b;
			var tz = _v2.c;
			var _v3 = transform.b;
			var sx = _v3.a;
			var sy = _v3.b;
			var sz = _v3.c;
			var _v4 = transform.c;
			var ox = _v4.a;
			var oy = _v4.b;
			var oz = _v4.c;
			var angle = transform.d;
			var translate = 'translate3d(' + ($elm$core$String$fromFloat(tx) + ('px, ' + ($elm$core$String$fromFloat(ty) + ('px, ' + ($elm$core$String$fromFloat(tz) + 'px)')))));
			var scale = 'scale3d(' + ($elm$core$String$fromFloat(sx) + (', ' + ($elm$core$String$fromFloat(sy) + (', ' + ($elm$core$String$fromFloat(sz) + ')')))));
			var rotate = 'rotate3d(' + ($elm$core$String$fromFloat(ox) + (', ' + ($elm$core$String$fromFloat(oy) + (', ' + ($elm$core$String$fromFloat(oz) + (', ' + ($elm$core$String$fromFloat(angle) + 'rad)')))))));
			return $elm$core$Maybe$Just(translate + (' ' + (scale + (' ' + rotate))));
	}
};
var $mdgriffith$elm_ui$Internal$Model$renderStyleRule = F3(
	function (options, rule, maybePseudo) {
		switch (rule.$) {
			case 0:
				var selector = rule.a;
				var props = rule.b;
				return A4($mdgriffith$elm_ui$Internal$Model$renderStyle, options, maybePseudo, selector, props);
			case 13:
				var name = rule.a;
				var prop = rule.b;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + name,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Internal$Model$Property, 'box-shadow', prop)
						]));
			case 12:
				var name = rule.a;
				var transparency = rule.b;
				var opacity = A2(
					$elm$core$Basics$max,
					0,
					A2($elm$core$Basics$min, 1, 1 - transparency));
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + name,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'opacity',
							$elm$core$String$fromFloat(opacity))
						]));
			case 2:
				var i = rule.a;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.font-size-' + $elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'font-size',
							$elm$core$String$fromInt(i) + 'px')
						]));
			case 1:
				var name = rule.a;
				var typefaces = rule.b;
				var features = A2(
					$elm$core$String$join,
					', ',
					A2($elm$core$List$filterMap, $mdgriffith$elm_ui$Internal$Model$renderVariants, typefaces));
				var families = _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Model$Property,
						'font-family',
						A2(
							$elm$core$String$join,
							', ',
							A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$fontName, typefaces))),
						A2($mdgriffith$elm_ui$Internal$Model$Property, 'font-feature-settings', features),
						A2(
						$mdgriffith$elm_ui$Internal$Model$Property,
						'font-variant',
						A2($elm$core$List$any, $mdgriffith$elm_ui$Internal$Model$hasSmallCaps, typefaces) ? 'small-caps' : 'normal')
					]);
				return A4($mdgriffith$elm_ui$Internal$Model$renderStyle, options, maybePseudo, '.' + name, families);
			case 3:
				var _class = rule.a;
				var prop = rule.b;
				var val = rule.c;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + _class,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Internal$Model$Property, prop, val)
						]));
			case 4:
				var _class = rule.a;
				var prop = rule.b;
				var color = rule.c;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + _class,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							prop,
							$mdgriffith$elm_ui$Internal$Model$formatColor(color))
						]));
			case 5:
				var cls = rule.a;
				var x = rule.b;
				var y = rule.c;
				var yPx = $elm$core$String$fromInt(y) + 'px';
				var xPx = $elm$core$String$fromInt(x) + 'px';
				var single = '.' + $mdgriffith$elm_ui$Internal$Style$classes.hp;
				var row = '.' + $mdgriffith$elm_ui$Internal$Style$classes.ff;
				var wrappedRow = '.' + ($mdgriffith$elm_ui$Internal$Style$classes.ec + row);
				var right = '.' + $mdgriffith$elm_ui$Internal$Style$classes.ei;
				var paragraph = '.' + $mdgriffith$elm_ui$Internal$Style$classes.e3;
				var page = '.' + $mdgriffith$elm_ui$Internal$Style$classes.e2;
				var left = '.' + $mdgriffith$elm_ui$Internal$Style$classes.eh;
				var halfY = $elm$core$String$fromFloat(y / 2) + 'px';
				var halfX = $elm$core$String$fromFloat(x / 2) + 'px';
				var column = '.' + $mdgriffith$elm_ui$Internal$Style$classes.gf;
				var _class = '.' + cls;
				var any = '.' + $mdgriffith$elm_ui$Internal$Style$classes.fT;
				return $elm$core$List$concat(
					_List_fromArray(
						[
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (row + (' > ' + (any + (' + ' + any)))),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (wrappedRow + (' > ' + any)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin', halfY + (' ' + halfX))
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (column + (' > ' + (any + (' + ' + any)))),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-top', yPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (page + (' > ' + (any + (' + ' + any)))),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-top', yPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (page + (' > ' + left)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-right', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (page + (' > ' + right)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_Utils_ap(_class, paragraph),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'line-height',
									'calc(1em + ' + ($elm$core$String$fromInt(y) + 'px)'))
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							'textarea' + (any + _class),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'line-height',
									'calc(1em + ' + ($elm$core$String$fromInt(y) + 'px)')),
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'height',
									'calc(100% + ' + ($elm$core$String$fromInt(y) + 'px)'))
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + (' > ' + left)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-right', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + (' > ' + right)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + '::after'),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'content', '\'\''),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'display', 'block'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'height', '0'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'width', '0'),
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'margin-top',
									$elm$core$String$fromInt((-1) * ((y / 2) | 0)) + 'px')
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + '::before'),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'content', '\'\''),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'display', 'block'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'height', '0'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'width', '0'),
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'margin-bottom',
									$elm$core$String$fromInt((-1) * ((y / 2) | 0)) + 'px')
								]))
						]));
			case 7:
				var cls = rule.a;
				var top = rule.b;
				var right = rule.c;
				var bottom = rule.d;
				var left = rule.e;
				var _class = '.' + cls;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					_class,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'padding',
							$elm$core$String$fromFloat(top) + ('px ' + ($elm$core$String$fromFloat(right) + ('px ' + ($elm$core$String$fromFloat(bottom) + ('px ' + ($elm$core$String$fromFloat(left) + 'px')))))))
						]));
			case 6:
				var cls = rule.a;
				var top = rule.b;
				var right = rule.c;
				var bottom = rule.d;
				var left = rule.e;
				var _class = '.' + cls;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					_class,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'border-width',
							$elm$core$String$fromInt(top) + ('px ' + ($elm$core$String$fromInt(right) + ('px ' + ($elm$core$String$fromInt(bottom) + ('px ' + ($elm$core$String$fromInt(left) + 'px')))))))
						]));
			case 8:
				var template = rule.a;
				var toGridLengthHelper = F3(
					function (minimum, maximum, x) {
						toGridLengthHelper:
						while (true) {
							switch (x.$) {
								case 0:
									var px = x.a;
									return $elm$core$String$fromInt(px) + 'px';
								case 1:
									var _v2 = _Utils_Tuple2(minimum, maximum);
									if (_v2.a.$ === 1) {
										if (_v2.b.$ === 1) {
											var _v3 = _v2.a;
											var _v4 = _v2.b;
											return 'max-content';
										} else {
											var _v6 = _v2.a;
											var maxSize = _v2.b.a;
											return 'minmax(max-content, ' + ($elm$core$String$fromInt(maxSize) + 'px)');
										}
									} else {
										if (_v2.b.$ === 1) {
											var minSize = _v2.a.a;
											var _v5 = _v2.b;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + 'max-content)'));
										} else {
											var minSize = _v2.a.a;
											var maxSize = _v2.b.a;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(maxSize) + 'px)')));
										}
									}
								case 2:
									var i = x.a;
									var _v7 = _Utils_Tuple2(minimum, maximum);
									if (_v7.a.$ === 1) {
										if (_v7.b.$ === 1) {
											var _v8 = _v7.a;
											var _v9 = _v7.b;
											return $elm$core$String$fromInt(i) + 'fr';
										} else {
											var _v11 = _v7.a;
											var maxSize = _v7.b.a;
											return 'minmax(max-content, ' + ($elm$core$String$fromInt(maxSize) + 'px)');
										}
									} else {
										if (_v7.b.$ === 1) {
											var minSize = _v7.a.a;
											var _v10 = _v7.b;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(i) + ('fr' + 'fr)'))));
										} else {
											var minSize = _v7.a.a;
											var maxSize = _v7.b.a;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(maxSize) + 'px)')));
										}
									}
								case 3:
									var m = x.a;
									var len = x.b;
									var $temp$minimum = $elm$core$Maybe$Just(m),
										$temp$maximum = maximum,
										$temp$x = len;
									minimum = $temp$minimum;
									maximum = $temp$maximum;
									x = $temp$x;
									continue toGridLengthHelper;
								default:
									var m = x.a;
									var len = x.b;
									var $temp$minimum = minimum,
										$temp$maximum = $elm$core$Maybe$Just(m),
										$temp$x = len;
									minimum = $temp$minimum;
									maximum = $temp$maximum;
									x = $temp$x;
									continue toGridLengthHelper;
							}
						}
					});
				var toGridLength = function (x) {
					return A3(toGridLengthHelper, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, x);
				};
				var xSpacing = toGridLength(template.ht.a);
				var ySpacing = toGridLength(template.ht.b);
				var rows = function (x) {
					return 'grid-template-rows: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						' ',
						A2($elm$core$List$map, toGridLength, template.hi)));
				var msRows = function (x) {
					return '-ms-grid-rows: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						ySpacing,
						A2($elm$core$List$map, toGridLength, template.gg)));
				var msColumns = function (x) {
					return '-ms-grid-columns: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						ySpacing,
						A2($elm$core$List$map, toGridLength, template.gg)));
				var gapY = 'grid-row-gap:' + (toGridLength(template.ht.b) + ';');
				var gapX = 'grid-column-gap:' + (toGridLength(template.ht.a) + ';');
				var columns = function (x) {
					return 'grid-template-columns: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						' ',
						A2($elm$core$List$map, toGridLength, template.gg)));
				var _class = '.grid-rows-' + (A2(
					$elm$core$String$join,
					'-',
					A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.hi)) + ('-cols-' + (A2(
					$elm$core$String$join,
					'-',
					A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.gg)) + ('-space-x-' + ($mdgriffith$elm_ui$Internal$Model$lengthClassName(template.ht.a) + ('-space-y-' + $mdgriffith$elm_ui$Internal$Model$lengthClassName(template.ht.b)))))));
				var modernGrid = _class + ('{' + (columns + (rows + (gapX + (gapY + '}')))));
				var supports = '@supports (display:grid) {' + (modernGrid + '}');
				var base = _class + ('{' + (msColumns + (msRows + '}')));
				return _List_fromArray(
					[base, supports]);
			case 9:
				var position = rule.a;
				var msPosition = A2(
					$elm$core$String$join,
					' ',
					_List_fromArray(
						[
							'-ms-grid-row: ' + ($elm$core$String$fromInt(position.ff) + ';'),
							'-ms-grid-row-span: ' + ($elm$core$String$fromInt(position.eD) + ';'),
							'-ms-grid-column: ' + ($elm$core$String$fromInt(position.eq) + ';'),
							'-ms-grid-column-span: ' + ($elm$core$String$fromInt(position.fA) + ';')
						]));
				var modernPosition = A2(
					$elm$core$String$join,
					' ',
					_List_fromArray(
						[
							'grid-row: ' + ($elm$core$String$fromInt(position.ff) + (' / ' + ($elm$core$String$fromInt(position.ff + position.eD) + ';'))),
							'grid-column: ' + ($elm$core$String$fromInt(position.eq) + (' / ' + ($elm$core$String$fromInt(position.eq + position.fA) + ';')))
						]));
				var _class = '.grid-pos-' + ($elm$core$String$fromInt(position.ff) + ('-' + ($elm$core$String$fromInt(position.eq) + ('-' + ($elm$core$String$fromInt(position.fA) + ('-' + $elm$core$String$fromInt(position.eD)))))));
				var modernGrid = _class + ('{' + (modernPosition + '}'));
				var supports = '@supports (display:grid) {' + (modernGrid + '}');
				var base = _class + ('{' + (msPosition + '}'));
				return _List_fromArray(
					[base, supports]);
			case 11:
				var _class = rule.a;
				var styles = rule.b;
				var renderPseudoRule = function (style) {
					return A3(
						$mdgriffith$elm_ui$Internal$Model$renderStyleRule,
						options,
						style,
						$elm$core$Maybe$Just(_class));
				};
				return A2($elm$core$List$concatMap, renderPseudoRule, styles);
			default:
				var transform = rule.a;
				var val = $mdgriffith$elm_ui$Internal$Model$transformValue(transform);
				var _class = $mdgriffith$elm_ui$Internal$Model$transformClass(transform);
				var _v12 = _Utils_Tuple2(_class, val);
				if ((!_v12.a.$) && (!_v12.b.$)) {
					var cls = _v12.a.a;
					var v = _v12.b.a;
					return A4(
						$mdgriffith$elm_ui$Internal$Model$renderStyle,
						options,
						maybePseudo,
						'.' + cls,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Model$Property, 'transform', v)
							]));
				} else {
					return _List_Nil;
				}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$encodeStyles = F2(
	function (options, stylesheet) {
		return $elm$json$Json$Encode$object(
			A2(
				$elm$core$List$map,
				function (style) {
					var styled = A3($mdgriffith$elm_ui$Internal$Model$renderStyleRule, options, style, $elm$core$Maybe$Nothing);
					return _Utils_Tuple2(
						$mdgriffith$elm_ui$Internal$Model$getStyleName(style),
						A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, styled));
				},
				stylesheet));
	});
var $mdgriffith$elm_ui$Internal$Model$bracket = F2(
	function (selector, rules) {
		var renderPair = function (_v0) {
			var name = _v0.a;
			var val = _v0.b;
			return name + (': ' + (val + ';'));
		};
		return selector + (' {' + (A2(
			$elm$core$String$join,
			'',
			A2($elm$core$List$map, renderPair, rules)) + '}'));
	});
var $mdgriffith$elm_ui$Internal$Model$fontRule = F3(
	function (name, modifier, _v0) {
		var parentAdj = _v0.a;
		var textAdjustment = _v0.b;
		return _List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Model$bracket, '.' + (name + ('.' + (modifier + (', ' + ('.' + (name + (' .' + modifier))))))), parentAdj),
				A2($mdgriffith$elm_ui$Internal$Model$bracket, '.' + (name + ('.' + (modifier + ('> .' + ($mdgriffith$elm_ui$Internal$Style$classes.hE + (', .' + (name + (' .' + (modifier + (' > .' + $mdgriffith$elm_ui$Internal$Style$classes.hE)))))))))), textAdjustment)
			]);
	});
var $mdgriffith$elm_ui$Internal$Model$renderFontAdjustmentRule = F3(
	function (fontToAdjust, _v0, otherFontName) {
		var full = _v0.a;
		var capital = _v0.b;
		var name = _Utils_eq(fontToAdjust, otherFontName) ? fontToAdjust : (otherFontName + (' .' + fontToAdjust));
		return A2(
			$elm$core$String$join,
			' ',
			_Utils_ap(
				A3($mdgriffith$elm_ui$Internal$Model$fontRule, name, $mdgriffith$elm_ui$Internal$Style$classes.hr, capital),
				A3($mdgriffith$elm_ui$Internal$Model$fontRule, name, $mdgriffith$elm_ui$Internal$Style$classes.gv, full)));
	});
var $mdgriffith$elm_ui$Internal$Model$renderNullAdjustmentRule = F2(
	function (fontToAdjust, otherFontName) {
		var name = _Utils_eq(fontToAdjust, otherFontName) ? fontToAdjust : (otherFontName + (' .' + fontToAdjust));
		return A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Internal$Model$bracket,
					'.' + (name + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.hr + (', ' + ('.' + (name + (' .' + $mdgriffith$elm_ui$Internal$Style$classes.hr))))))),
					_List_fromArray(
						[
							_Utils_Tuple2('line-height', '1')
						])),
					A2(
					$mdgriffith$elm_ui$Internal$Model$bracket,
					'.' + (name + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.hr + ('> .' + ($mdgriffith$elm_ui$Internal$Style$classes.hE + (', .' + (name + (' .' + ($mdgriffith$elm_ui$Internal$Style$classes.hr + (' > .' + $mdgriffith$elm_ui$Internal$Style$classes.hE)))))))))),
					_List_fromArray(
						[
							_Utils_Tuple2('vertical-align', '0'),
							_Utils_Tuple2('line-height', '1')
						]))
				]));
	});
var $mdgriffith$elm_ui$Internal$Model$adjust = F3(
	function (size, height, vertical) {
		return {eD: height / size, hq: size, fw: vertical};
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$List$maximum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$max, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$List$minimum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$min, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Internal$Model$convertAdjustment = function (adjustment) {
	var lines = _List_fromArray(
		[adjustment.f7, adjustment.fX, adjustment.gn, adjustment.gX]);
	var lineHeight = 1.5;
	var normalDescender = (lineHeight - 1) / 2;
	var oldMiddle = lineHeight / 2;
	var descender = A2(
		$elm$core$Maybe$withDefault,
		adjustment.gn,
		$elm$core$List$minimum(lines));
	var newBaseline = A2(
		$elm$core$Maybe$withDefault,
		adjustment.fX,
		$elm$core$List$minimum(
			A2(
				$elm$core$List$filter,
				function (x) {
					return !_Utils_eq(x, descender);
				},
				lines)));
	var base = lineHeight;
	var ascender = A2(
		$elm$core$Maybe$withDefault,
		adjustment.f7,
		$elm$core$List$maximum(lines));
	var capitalSize = 1 / (ascender - newBaseline);
	var capitalVertical = 1 - ascender;
	var fullSize = 1 / (ascender - descender);
	var fullVertical = 1 - ascender;
	var newCapitalMiddle = ((ascender - newBaseline) / 2) + newBaseline;
	var newFullMiddle = ((ascender - descender) / 2) + descender;
	return {
		f7: A3($mdgriffith$elm_ui$Internal$Model$adjust, capitalSize, ascender - newBaseline, capitalVertical),
		eC: A3($mdgriffith$elm_ui$Internal$Model$adjust, fullSize, ascender - descender, fullVertical)
	};
};
var $mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules = function (converted) {
	return _Utils_Tuple2(
		_List_fromArray(
			[
				_Utils_Tuple2('display', 'block')
			]),
		_List_fromArray(
			[
				_Utils_Tuple2('display', 'inline-block'),
				_Utils_Tuple2(
				'line-height',
				$elm$core$String$fromFloat(converted.eD)),
				_Utils_Tuple2(
				'vertical-align',
				$elm$core$String$fromFloat(converted.fw) + 'em'),
				_Utils_Tuple2(
				'font-size',
				$elm$core$String$fromFloat(converted.hq) + 'em')
			]));
};
var $mdgriffith$elm_ui$Internal$Model$typefaceAdjustment = function (typefaces) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (face, found) {
				if (found.$ === 1) {
					if (face.$ === 5) {
						var _with = face.a;
						var _v2 = _with.fI;
						if (_v2.$ === 1) {
							return found;
						} else {
							var adjustment = _v2.a;
							return $elm$core$Maybe$Just(
								_Utils_Tuple2(
									$mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules(
										function ($) {
											return $.eC;
										}(
											$mdgriffith$elm_ui$Internal$Model$convertAdjustment(adjustment))),
									$mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules(
										function ($) {
											return $.f7;
										}(
											$mdgriffith$elm_ui$Internal$Model$convertAdjustment(adjustment)))));
						}
					} else {
						return found;
					}
				} else {
					return found;
				}
			}),
		$elm$core$Maybe$Nothing,
		typefaces);
};
var $mdgriffith$elm_ui$Internal$Model$renderTopLevelValues = function (rules) {
	var withImport = function (font) {
		if (font.$ === 4) {
			var url = font.b;
			return $elm$core$Maybe$Just('@import url(\'' + (url + '\');'));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	var fontImports = function (_v2) {
		var name = _v2.a;
		var typefaces = _v2.b;
		var imports = A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$filterMap, withImport, typefaces));
		return imports;
	};
	var allNames = A2($elm$core$List$map, $elm$core$Tuple$first, rules);
	var fontAdjustments = function (_v1) {
		var name = _v1.a;
		var typefaces = _v1.b;
		var _v0 = $mdgriffith$elm_ui$Internal$Model$typefaceAdjustment(typefaces);
		if (_v0.$ === 1) {
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$map,
					$mdgriffith$elm_ui$Internal$Model$renderNullAdjustmentRule(name),
					allNames));
		} else {
			var adjustment = _v0.a;
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$map,
					A2($mdgriffith$elm_ui$Internal$Model$renderFontAdjustmentRule, name, adjustment),
					allNames));
		}
	};
	return _Utils_ap(
		A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$map, fontImports, rules)),
		A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$map, fontAdjustments, rules)));
};
var $mdgriffith$elm_ui$Internal$Model$topLevelValue = function (rule) {
	if (rule.$ === 1) {
		var name = rule.a;
		var typefaces = rule.b;
		return $elm$core$Maybe$Just(
			_Utils_Tuple2(name, typefaces));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Internal$Model$toStyleSheetString = F2(
	function (options, stylesheet) {
		var combine = F2(
			function (style, rendered) {
				return {
					dc: _Utils_ap(
						rendered.dc,
						A3($mdgriffith$elm_ui$Internal$Model$renderStyleRule, options, style, $elm$core$Maybe$Nothing)),
					cO: function () {
						var _v1 = $mdgriffith$elm_ui$Internal$Model$topLevelValue(style);
						if (_v1.$ === 1) {
							return rendered.cO;
						} else {
							var topLevel = _v1.a;
							return A2($elm$core$List$cons, topLevel, rendered.cO);
						}
					}()
				};
			});
		var _v0 = A3(
			$elm$core$List$foldl,
			combine,
			{dc: _List_Nil, cO: _List_Nil},
			stylesheet);
		var topLevel = _v0.cO;
		var rules = _v0.dc;
		return _Utils_ap(
			$mdgriffith$elm_ui$Internal$Model$renderTopLevelValues(topLevel),
			$elm$core$String$concat(rules));
	});
var $mdgriffith$elm_ui$Internal$Model$toStyleSheet = F2(
	function (options, styleSheet) {
		var _v0 = options.g_;
		switch (_v0) {
			case 0:
				return A3(
					$elm$virtual_dom$VirtualDom$node,
					'div',
					_List_Nil,
					_List_fromArray(
						[
							A3(
							$elm$virtual_dom$VirtualDom$node,
							'style',
							_List_Nil,
							_List_fromArray(
								[
									$elm$virtual_dom$VirtualDom$text(
									A2($mdgriffith$elm_ui$Internal$Model$toStyleSheetString, options, styleSheet))
								]))
						]));
			case 1:
				return A3(
					$elm$virtual_dom$VirtualDom$node,
					'div',
					_List_Nil,
					_List_fromArray(
						[
							A3(
							$elm$virtual_dom$VirtualDom$node,
							'style',
							_List_Nil,
							_List_fromArray(
								[
									$elm$virtual_dom$VirtualDom$text(
									A2($mdgriffith$elm_ui$Internal$Model$toStyleSheetString, options, styleSheet))
								]))
						]));
			default:
				return A3(
					$elm$virtual_dom$VirtualDom$node,
					'elm-ui-rules',
					_List_fromArray(
						[
							A2(
							$elm$virtual_dom$VirtualDom$property,
							'rules',
							A2($mdgriffith$elm_ui$Internal$Model$encodeStyles, options, styleSheet))
						]),
					_List_Nil);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$embedKeyed = F4(
	function (_static, opts, styles, children) {
		var dynamicStyleSheet = A2(
			$mdgriffith$elm_ui$Internal$Model$toStyleSheet,
			opts,
			A3(
				$elm$core$List$foldl,
				$mdgriffith$elm_ui$Internal$Model$reduceStyles,
				_Utils_Tuple2(
					$elm$core$Set$empty,
					$mdgriffith$elm_ui$Internal$Model$renderFocusStyle(opts.gu)),
				styles).b);
		return _static ? A2(
			$elm$core$List$cons,
			_Utils_Tuple2(
				'static-stylesheet',
				$mdgriffith$elm_ui$Internal$Model$staticRoot(opts)),
			A2(
				$elm$core$List$cons,
				_Utils_Tuple2('dynamic-stylesheet', dynamicStyleSheet),
				children)) : A2(
			$elm$core$List$cons,
			_Utils_Tuple2('dynamic-stylesheet', dynamicStyleSheet),
			children);
	});
var $mdgriffith$elm_ui$Internal$Model$embedWith = F4(
	function (_static, opts, styles, children) {
		var dynamicStyleSheet = A2(
			$mdgriffith$elm_ui$Internal$Model$toStyleSheet,
			opts,
			A3(
				$elm$core$List$foldl,
				$mdgriffith$elm_ui$Internal$Model$reduceStyles,
				_Utils_Tuple2(
					$elm$core$Set$empty,
					$mdgriffith$elm_ui$Internal$Model$renderFocusStyle(opts.gu)),
				styles).b);
		return _static ? A2(
			$elm$core$List$cons,
			$mdgriffith$elm_ui$Internal$Model$staticRoot(opts),
			A2($elm$core$List$cons, dynamicStyleSheet, children)) : A2($elm$core$List$cons, dynamicStyleSheet, children);
	});
var $mdgriffith$elm_ui$Internal$Flag$heightBetween = $mdgriffith$elm_ui$Internal$Flag$flag(45);
var $mdgriffith$elm_ui$Internal$Flag$heightFill = $mdgriffith$elm_ui$Internal$Flag$flag(37);
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$p = _VirtualDom_node('p');
var $mdgriffith$elm_ui$Internal$Flag$present = F2(
	function (myFlag, _v0) {
		var fieldOne = _v0.a;
		var fieldTwo = _v0.b;
		if (!myFlag.$) {
			var first = myFlag.a;
			return _Utils_eq(first & fieldOne, first);
		} else {
			var second = myFlag.a;
			return _Utils_eq(second & fieldTwo, second);
		}
	});
var $elm$html$Html$s = _VirtualDom_node('s');
var $elm$html$Html$u = _VirtualDom_node('u');
var $mdgriffith$elm_ui$Internal$Flag$widthBetween = $mdgriffith$elm_ui$Internal$Flag$flag(44);
var $mdgriffith$elm_ui$Internal$Flag$widthFill = $mdgriffith$elm_ui$Internal$Flag$flag(39);
var $mdgriffith$elm_ui$Internal$Model$finalizeNode = F6(
	function (has, node, attributes, children, embedMode, parentContext) {
		var createNode = F2(
			function (nodeName, attrs) {
				if (children.$ === 1) {
					var keyed = children.a;
					return A3(
						$elm$virtual_dom$VirtualDom$keyedNode,
						nodeName,
						attrs,
						function () {
							switch (embedMode.$) {
								case 0:
									return keyed;
								case 2:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedKeyed, false, opts, styles, keyed);
								default:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedKeyed, true, opts, styles, keyed);
							}
						}());
				} else {
					var unkeyed = children.a;
					return A2(
						function () {
							switch (nodeName) {
								case 'div':
									return $elm$html$Html$div;
								case 'p':
									return $elm$html$Html$p;
								default:
									return $elm$virtual_dom$VirtualDom$node(nodeName);
							}
						}(),
						attrs,
						function () {
							switch (embedMode.$) {
								case 0:
									return unkeyed;
								case 2:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedWith, false, opts, styles, unkeyed);
								default:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedWith, true, opts, styles, unkeyed);
							}
						}());
				}
			});
		var html = function () {
			switch (node.$) {
				case 0:
					return A2(createNode, 'div', attributes);
				case 1:
					var nodeName = node.a;
					return A2(createNode, nodeName, attributes);
				default:
					var nodeName = node.a;
					var internal = node.b;
					return A3(
						$elm$virtual_dom$VirtualDom$node,
						nodeName,
						attributes,
						_List_fromArray(
							[
								A2(
								createNode,
								internal,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Style$classes.fT + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.hp))
									]))
							]));
			}
		}();
		switch (parentContext) {
			case 0:
				return (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$widthFill, has) && (!A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$widthBetween, has))) ? html : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$alignRight, has) ? A2(
					$elm$html$Html$u,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.fT, $mdgriffith$elm_ui$Internal$Style$classes.hp, $mdgriffith$elm_ui$Internal$Style$classes.gh, $mdgriffith$elm_ui$Internal$Style$classes.bE, $mdgriffith$elm_ui$Internal$Style$classes.fP])))
						]),
					_List_fromArray(
						[html])) : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$centerX, has) ? A2(
					$elm$html$Html$s,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.fT, $mdgriffith$elm_ui$Internal$Style$classes.hp, $mdgriffith$elm_ui$Internal$Style$classes.gh, $mdgriffith$elm_ui$Internal$Style$classes.bE, $mdgriffith$elm_ui$Internal$Style$classes.fN])))
						]),
					_List_fromArray(
						[html])) : html));
			case 1:
				return (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$heightFill, has) && (!A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$heightBetween, has))) ? html : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$centerY, has) ? A2(
					$elm$html$Html$s,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.fT, $mdgriffith$elm_ui$Internal$Style$classes.hp, $mdgriffith$elm_ui$Internal$Style$classes.gh, $mdgriffith$elm_ui$Internal$Style$classes.fO])))
						]),
					_List_fromArray(
						[html])) : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$alignBottom, has) ? A2(
					$elm$html$Html$u,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.fT, $mdgriffith$elm_ui$Internal$Style$classes.hp, $mdgriffith$elm_ui$Internal$Style$classes.gh, $mdgriffith$elm_ui$Internal$Style$classes.fM])))
						]),
					_List_fromArray(
						[html])) : html));
			default:
				return html;
		}
	});
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $mdgriffith$elm_ui$Internal$Model$textElementClasses = $mdgriffith$elm_ui$Internal$Style$classes.fT + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.hE + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.ea + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.du)))));
var $mdgriffith$elm_ui$Internal$Model$textElement = function (str) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Model$textElementClasses)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $mdgriffith$elm_ui$Internal$Model$textElementFillClasses = $mdgriffith$elm_ui$Internal$Style$classes.fT + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.hE + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.eb + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.dv)))));
var $mdgriffith$elm_ui$Internal$Model$textElementFill = function (str) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Model$textElementFillClasses)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $mdgriffith$elm_ui$Internal$Model$createElement = F3(
	function (context, children, rendered) {
		var gatherKeyed = F2(
			function (_v8, _v9) {
				var key = _v8.a;
				var child = _v8.b;
				var htmls = _v9.a;
				var existingStyles = _v9.b;
				switch (child.$) {
					case 0:
						var html = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									html(context)),
								htmls),
							existingStyles) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									html(context)),
								htmls),
							existingStyles);
					case 1:
						var styled = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									A2(styled.gB, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context)),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.hB : _Utils_ap(styled.hB, existingStyles)) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									A2(styled.gB, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context)),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.hB : _Utils_ap(styled.hB, existingStyles));
					case 2:
						var str = child.a;
						return _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									_Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asEl) ? $mdgriffith$elm_ui$Internal$Model$textElementFill(str) : $mdgriffith$elm_ui$Internal$Model$textElement(str)),
								htmls),
							existingStyles);
					default:
						return _Utils_Tuple2(htmls, existingStyles);
				}
			});
		var gather = F2(
			function (child, _v6) {
				var htmls = _v6.a;
				var existingStyles = _v6.b;
				switch (child.$) {
					case 0:
						var html = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								html(context),
								htmls),
							existingStyles) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								html(context),
								htmls),
							existingStyles);
					case 1:
						var styled = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								A2(styled.gB, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.hB : _Utils_ap(styled.hB, existingStyles)) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								A2(styled.gB, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.hB : _Utils_ap(styled.hB, existingStyles));
					case 2:
						var str = child.a;
						return _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asEl) ? $mdgriffith$elm_ui$Internal$Model$textElementFill(str) : $mdgriffith$elm_ui$Internal$Model$textElement(str),
								htmls),
							existingStyles);
					default:
						return _Utils_Tuple2(htmls, existingStyles);
				}
			});
		if (children.$ === 1) {
			var keyedChildren = children.a;
			var _v1 = A3(
				$elm$core$List$foldr,
				gatherKeyed,
				_Utils_Tuple2(_List_Nil, _List_Nil),
				keyedChildren);
			var keyed = _v1.a;
			var styles = _v1.b;
			var newStyles = $elm$core$List$isEmpty(styles) ? rendered.hB : _Utils_ap(rendered.hB, styles);
			if (!newStyles.b) {
				return $mdgriffith$elm_ui$Internal$Model$Unstyled(
					A5(
						$mdgriffith$elm_ui$Internal$Model$finalizeNode,
						rendered.bQ,
						rendered.bR,
						rendered.bM,
						$mdgriffith$elm_ui$Internal$Model$Keyed(
							A3($mdgriffith$elm_ui$Internal$Model$addKeyedChildren, 'nearby-element-pls', keyed, rendered.bN)),
						$mdgriffith$elm_ui$Internal$Model$NoStyleSheet));
			} else {
				var allStyles = newStyles;
				return $mdgriffith$elm_ui$Internal$Model$Styled(
					{
						gB: A4(
							$mdgriffith$elm_ui$Internal$Model$finalizeNode,
							rendered.bQ,
							rendered.bR,
							rendered.bM,
							$mdgriffith$elm_ui$Internal$Model$Keyed(
								A3($mdgriffith$elm_ui$Internal$Model$addKeyedChildren, 'nearby-element-pls', keyed, rendered.bN))),
						hB: allStyles
					});
			}
		} else {
			var unkeyedChildren = children.a;
			var _v3 = A3(
				$elm$core$List$foldr,
				gather,
				_Utils_Tuple2(_List_Nil, _List_Nil),
				unkeyedChildren);
			var unkeyed = _v3.a;
			var styles = _v3.b;
			var newStyles = $elm$core$List$isEmpty(styles) ? rendered.hB : _Utils_ap(rendered.hB, styles);
			if (!newStyles.b) {
				return $mdgriffith$elm_ui$Internal$Model$Unstyled(
					A5(
						$mdgriffith$elm_ui$Internal$Model$finalizeNode,
						rendered.bQ,
						rendered.bR,
						rendered.bM,
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							A2($mdgriffith$elm_ui$Internal$Model$addChildren, unkeyed, rendered.bN)),
						$mdgriffith$elm_ui$Internal$Model$NoStyleSheet));
			} else {
				var allStyles = newStyles;
				return $mdgriffith$elm_ui$Internal$Model$Styled(
					{
						gB: A4(
							$mdgriffith$elm_ui$Internal$Model$finalizeNode,
							rendered.bQ,
							rendered.bR,
							rendered.bM,
							$mdgriffith$elm_ui$Internal$Model$Unkeyed(
								A2($mdgriffith$elm_ui$Internal$Model$addChildren, unkeyed, rendered.bN))),
						hB: allStyles
					});
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Single = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Model$Transform = function (a) {
	return {$: 10, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$add = F2(
	function (myFlag, _v0) {
		var one = _v0.a;
		var two = _v0.b;
		if (!myFlag.$) {
			var first = myFlag.a;
			return A2($mdgriffith$elm_ui$Internal$Flag$Field, first | one, two);
		} else {
			var second = myFlag.a;
			return A2($mdgriffith$elm_ui$Internal$Flag$Field, one, second | two);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$ChildrenBehind = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$ChildrenInFront = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$nearbyElement = F2(
	function (location, elem) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(
					function () {
						switch (location) {
							case 0:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.cl, $mdgriffith$elm_ui$Internal$Style$classes.hp, $mdgriffith$elm_ui$Internal$Style$classes.fH]));
							case 1:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.cl, $mdgriffith$elm_ui$Internal$Style$classes.hp, $mdgriffith$elm_ui$Internal$Style$classes.fZ]));
							case 2:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.cl, $mdgriffith$elm_ui$Internal$Style$classes.hp, $mdgriffith$elm_ui$Internal$Style$classes.g4]));
							case 3:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.cl, $mdgriffith$elm_ui$Internal$Style$classes.hp, $mdgriffith$elm_ui$Internal$Style$classes.g3]));
							case 4:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.cl, $mdgriffith$elm_ui$Internal$Style$classes.hp, $mdgriffith$elm_ui$Internal$Style$classes.gE]));
							default:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.cl, $mdgriffith$elm_ui$Internal$Style$classes.hp, $mdgriffith$elm_ui$Internal$Style$classes.fY]));
						}
					}())
				]),
			_List_fromArray(
				[
					function () {
					switch (elem.$) {
						case 3:
							return $elm$virtual_dom$VirtualDom$text('');
						case 2:
							var str = elem.a;
							return $mdgriffith$elm_ui$Internal$Model$textElement(str);
						case 0:
							var html = elem.a;
							return html($mdgriffith$elm_ui$Internal$Model$asEl);
						default:
							var styled = elem.a;
							return A2(styled.gB, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, $mdgriffith$elm_ui$Internal$Model$asEl);
					}
				}()
				]));
	});
var $mdgriffith$elm_ui$Internal$Model$addNearbyElement = F3(
	function (location, elem, existing) {
		var nearby = A2($mdgriffith$elm_ui$Internal$Model$nearbyElement, location, elem);
		switch (existing.$) {
			case 0:
				if (location === 5) {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenBehind(
						_List_fromArray(
							[nearby]));
				} else {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenInFront(
						_List_fromArray(
							[nearby]));
				}
			case 1:
				var existingBehind = existing.a;
				if (location === 5) {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenBehind(
						A2($elm$core$List$cons, nearby, existingBehind));
				} else {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						existingBehind,
						_List_fromArray(
							[nearby]));
				}
			case 2:
				var existingInFront = existing.a;
				if (location === 5) {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						_List_fromArray(
							[nearby]),
						existingInFront);
				} else {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenInFront(
						A2($elm$core$List$cons, nearby, existingInFront));
				}
			default:
				var existingBehind = existing.a;
				var existingInFront = existing.b;
				if (location === 5) {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						A2($elm$core$List$cons, nearby, existingBehind),
						existingInFront);
				} else {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						existingBehind,
						A2($elm$core$List$cons, nearby, existingInFront));
				}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Embedded = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$NodeName = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$addNodeName = F2(
	function (newNode, old) {
		switch (old.$) {
			case 0:
				return $mdgriffith$elm_ui$Internal$Model$NodeName(newNode);
			case 1:
				var name = old.a;
				return A2($mdgriffith$elm_ui$Internal$Model$Embedded, name, newNode);
			default:
				var x = old.a;
				var y = old.b;
				return A2($mdgriffith$elm_ui$Internal$Model$Embedded, x, y);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$alignXName = function (align) {
	switch (align) {
		case 0:
			return $mdgriffith$elm_ui$Internal$Style$classes.dj + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.eh);
		case 2:
			return $mdgriffith$elm_ui$Internal$Style$classes.dj + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.ei);
		default:
			return $mdgriffith$elm_ui$Internal$Style$classes.dj + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.fK);
	}
};
var $mdgriffith$elm_ui$Internal$Model$alignYName = function (align) {
	switch (align) {
		case 0:
			return $mdgriffith$elm_ui$Internal$Style$classes.dk + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.fQ);
		case 2:
			return $mdgriffith$elm_ui$Internal$Style$classes.dk + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.fJ);
		default:
			return $mdgriffith$elm_ui$Internal$Style$classes.dk + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.fL);
	}
};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $mdgriffith$elm_ui$Internal$Model$FullTransform = F4(
	function (a, b, c, d) {
		return {$: 2, a: a, b: b, c: c, d: d};
	});
var $mdgriffith$elm_ui$Internal$Model$Moved = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$composeTransformation = F2(
	function (transform, component) {
		switch (transform.$) {
			case 0:
				switch (component.$) {
					case 0:
						var x = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, 0, 0));
					case 1:
						var y = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(0, y, 0));
					case 2:
						var z = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(0, 0, z));
					case 3:
						var xyz = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(xyz);
					case 4:
						var xyz = component.a;
						var angle = component.b;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(0, 0, 0),
							_Utils_Tuple3(1, 1, 1),
							xyz,
							angle);
					default:
						var xyz = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(0, 0, 0),
							xyz,
							_Utils_Tuple3(0, 0, 1),
							0);
				}
			case 1:
				var moved = transform.a;
				var x = moved.a;
				var y = moved.b;
				var z = moved.c;
				switch (component.$) {
					case 0:
						var newX = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(newX, y, z));
					case 1:
						var newY = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, newY, z));
					case 2:
						var newZ = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, y, newZ));
					case 3:
						var xyz = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(xyz);
					case 4:
						var xyz = component.a;
						var angle = component.b;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							moved,
							_Utils_Tuple3(1, 1, 1),
							xyz,
							angle);
					default:
						var scale = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							moved,
							scale,
							_Utils_Tuple3(0, 0, 1),
							0);
				}
			default:
				var moved = transform.a;
				var x = moved.a;
				var y = moved.b;
				var z = moved.c;
				var scaled = transform.b;
				var origin = transform.c;
				var angle = transform.d;
				switch (component.$) {
					case 0:
						var newX = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(newX, y, z),
							scaled,
							origin,
							angle);
					case 1:
						var newY = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(x, newY, z),
							scaled,
							origin,
							angle);
					case 2:
						var newZ = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(x, y, newZ),
							scaled,
							origin,
							angle);
					case 3:
						var newMove = component.a;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, newMove, scaled, origin, angle);
					case 4:
						var newOrigin = component.a;
						var newAngle = component.b;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, moved, scaled, newOrigin, newAngle);
					default:
						var newScale = component.a;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, moved, newScale, origin, angle);
				}
		}
	});
var $mdgriffith$elm_ui$Internal$Flag$height = $mdgriffith$elm_ui$Internal$Flag$flag(7);
var $mdgriffith$elm_ui$Internal$Flag$heightContent = $mdgriffith$elm_ui$Internal$Flag$flag(36);
var $mdgriffith$elm_ui$Internal$Flag$merge = F2(
	function (_v0, _v1) {
		var one = _v0.a;
		var two = _v0.b;
		var three = _v1.a;
		var four = _v1.b;
		return A2($mdgriffith$elm_ui$Internal$Flag$Field, one | three, two | four);
	});
var $mdgriffith$elm_ui$Internal$Flag$none = A2($mdgriffith$elm_ui$Internal$Flag$Field, 0, 0);
var $mdgriffith$elm_ui$Internal$Model$renderHeight = function (h) {
	switch (h.$) {
		case 0:
			var px = h.a;
			var val = $elm$core$String$fromInt(px);
			var name = 'height-px-' + val;
			return _Utils_Tuple3(
				$mdgriffith$elm_ui$Internal$Flag$none,
				$mdgriffith$elm_ui$Internal$Style$classes.eE + (' ' + name),
				_List_fromArray(
					[
						A3($mdgriffith$elm_ui$Internal$Model$Single, name, 'height', val + 'px')
					]));
		case 1:
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightContent, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.du,
				_List_Nil);
		case 2:
			var portion = h.a;
			return (portion === 1) ? _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.dv,
				_List_Nil) : _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.eF + (' height-fill-' + $elm$core$String$fromInt(portion)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						$mdgriffith$elm_ui$Internal$Style$classes.fT + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.gf + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
							'height-fill-' + $elm$core$String$fromInt(portion))))),
						'flex-grow',
						$elm$core$String$fromInt(portion * 100000))
					]));
		case 3:
			var minSize = h.a;
			var len = h.b;
			var cls = 'min-height-' + $elm$core$String$fromInt(minSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'min-height',
				$elm$core$String$fromInt(minSize) + 'px !important');
			var _v1 = $mdgriffith$elm_ui$Internal$Model$renderHeight(len);
			var newFlag = _v1.a;
			var newAttrs = _v1.b;
			var newStyle = _v1.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
		default:
			var maxSize = h.a;
			var len = h.b;
			var cls = 'max-height-' + $elm$core$String$fromInt(maxSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'max-height',
				$elm$core$String$fromInt(maxSize) + 'px');
			var _v2 = $mdgriffith$elm_ui$Internal$Model$renderHeight(len);
			var newFlag = _v2.a;
			var newAttrs = _v2.b;
			var newStyle = _v2.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
	}
};
var $mdgriffith$elm_ui$Internal$Flag$widthContent = $mdgriffith$elm_ui$Internal$Flag$flag(38);
var $mdgriffith$elm_ui$Internal$Model$renderWidth = function (w) {
	switch (w.$) {
		case 0:
			var px = w.a;
			return _Utils_Tuple3(
				$mdgriffith$elm_ui$Internal$Flag$none,
				$mdgriffith$elm_ui$Internal$Style$classes.fB + (' width-px-' + $elm$core$String$fromInt(px)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						'width-px-' + $elm$core$String$fromInt(px),
						'width',
						$elm$core$String$fromInt(px) + 'px')
					]));
		case 1:
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthContent, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.ea,
				_List_Nil);
		case 2:
			var portion = w.a;
			return (portion === 1) ? _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.eb,
				_List_Nil) : _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.fC + (' width-fill-' + $elm$core$String$fromInt(portion)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						$mdgriffith$elm_ui$Internal$Style$classes.fT + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.ff + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
							'width-fill-' + $elm$core$String$fromInt(portion))))),
						'flex-grow',
						$elm$core$String$fromInt(portion * 100000))
					]));
		case 3:
			var minSize = w.a;
			var len = w.b;
			var cls = 'min-width-' + $elm$core$String$fromInt(minSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'min-width',
				$elm$core$String$fromInt(minSize) + 'px');
			var _v1 = $mdgriffith$elm_ui$Internal$Model$renderWidth(len);
			var newFlag = _v1.a;
			var newAttrs = _v1.b;
			var newStyle = _v1.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
		default:
			var maxSize = w.a;
			var len = w.b;
			var cls = 'max-width-' + $elm$core$String$fromInt(maxSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'max-width',
				$elm$core$String$fromInt(maxSize) + 'px');
			var _v2 = $mdgriffith$elm_ui$Internal$Model$renderWidth(len);
			var newFlag = _v2.a;
			var newAttrs = _v2.b;
			var newStyle = _v2.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
	}
};
var $mdgriffith$elm_ui$Internal$Flag$borderWidth = $mdgriffith$elm_ui$Internal$Flag$flag(27);
var $mdgriffith$elm_ui$Internal$Model$skippable = F2(
	function (flag, style) {
		if (_Utils_eq(flag, $mdgriffith$elm_ui$Internal$Flag$borderWidth)) {
			if (style.$ === 3) {
				var val = style.c;
				switch (val) {
					case '0px':
						return true;
					case '1px':
						return true;
					case '2px':
						return true;
					case '3px':
						return true;
					case '4px':
						return true;
					case '5px':
						return true;
					case '6px':
						return true;
					default:
						return false;
				}
			} else {
				return false;
			}
		} else {
			switch (style.$) {
				case 2:
					var i = style.a;
					return (i >= 8) && (i <= 32);
				case 7:
					var name = style.a;
					var t = style.b;
					var r = style.c;
					var b = style.d;
					var l = style.e;
					return _Utils_eq(t, b) && (_Utils_eq(t, r) && (_Utils_eq(t, l) && ((t >= 0) && (t <= 24))));
				default:
					return false;
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Flag$width = $mdgriffith$elm_ui$Internal$Flag$flag(6);
var $mdgriffith$elm_ui$Internal$Flag$xAlign = $mdgriffith$elm_ui$Internal$Flag$flag(30);
var $mdgriffith$elm_ui$Internal$Flag$yAlign = $mdgriffith$elm_ui$Internal$Flag$flag(29);
var $mdgriffith$elm_ui$Internal$Model$gatherAttrRecursive = F8(
	function (classes, node, has, transform, styles, attrs, children, elementAttrs) {
		gatherAttrRecursive:
		while (true) {
			if (!elementAttrs.b) {
				var _v1 = $mdgriffith$elm_ui$Internal$Model$transformClass(transform);
				if (_v1.$ === 1) {
					return {
						bM: A2(
							$elm$core$List$cons,
							$elm$html$Html$Attributes$class(classes),
							attrs),
						bN: children,
						bQ: has,
						bR: node,
						hB: styles
					};
				} else {
					var _class = _v1.a;
					return {
						bM: A2(
							$elm$core$List$cons,
							$elm$html$Html$Attributes$class(classes + (' ' + _class)),
							attrs),
						bN: children,
						bQ: has,
						bR: node,
						hB: A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$Transform(transform),
							styles)
					};
				}
			} else {
				var attribute = elementAttrs.a;
				var remaining = elementAttrs.b;
				switch (attribute.$) {
					case 0:
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = styles,
							$temp$attrs = attrs,
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 3:
						var flag = attribute.a;
						var exactClassName = attribute.b;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, flag, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = exactClassName + (' ' + classes),
								$temp$node = node,
								$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
					case 1:
						var actualAttribute = attribute.a;
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = styles,
							$temp$attrs = A2($elm$core$List$cons, actualAttribute, attrs),
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 4:
						var flag = attribute.a;
						var style = attribute.b;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, flag, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							if (A2($mdgriffith$elm_ui$Internal$Model$skippable, flag, style)) {
								var $temp$classes = $mdgriffith$elm_ui$Internal$Model$getStyleName(style) + (' ' + classes),
									$temp$node = node,
									$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							} else {
								var $temp$classes = $mdgriffith$elm_ui$Internal$Model$getStyleName(style) + (' ' + classes),
									$temp$node = node,
									$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
									$temp$transform = transform,
									$temp$styles = A2($elm$core$List$cons, style, styles),
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							}
						}
					case 10:
						var flag = attribute.a;
						var component = attribute.b;
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
							$temp$transform = A2($mdgriffith$elm_ui$Internal$Model$composeTransformation, transform, component),
							$temp$styles = styles,
							$temp$attrs = attrs,
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 7:
						var width = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$width, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							switch (width.$) {
								case 0:
									var px = width.a;
									var $temp$classes = ($mdgriffith$elm_ui$Internal$Style$classes.fB + (' width-px-' + $elm$core$String$fromInt(px))) + (' ' + classes),
										$temp$node = node,
										$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has),
										$temp$transform = transform,
										$temp$styles = A2(
										$elm$core$List$cons,
										A3(
											$mdgriffith$elm_ui$Internal$Model$Single,
											'width-px-' + $elm$core$String$fromInt(px),
											'width',
											$elm$core$String$fromInt(px) + 'px'),
										styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 1:
									var $temp$classes = classes + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.ea),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$add,
										$mdgriffith$elm_ui$Internal$Flag$widthContent,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 2:
									var portion = width.a;
									if (portion === 1) {
										var $temp$classes = classes + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.eb),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$widthFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.fC + (' width-fill-' + $elm$core$String$fromInt(portion)))),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$widthFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
											$temp$transform = transform,
											$temp$styles = A2(
											$elm$core$List$cons,
											A3(
												$mdgriffith$elm_ui$Internal$Model$Single,
												$mdgriffith$elm_ui$Internal$Style$classes.fT + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.ff + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
													'width-fill-' + $elm$core$String$fromInt(portion))))),
												'flex-grow',
												$elm$core$String$fromInt(portion * 100000)),
											styles),
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								default:
									var _v4 = $mdgriffith$elm_ui$Internal$Model$renderWidth(width);
									var addToFlags = _v4.a;
									var newClass = _v4.b;
									var newStyles = _v4.c;
									var $temp$classes = classes + (' ' + newClass),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$merge,
										addToFlags,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
										$temp$transform = transform,
										$temp$styles = _Utils_ap(newStyles, styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
							}
						}
					case 8:
						var height = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$height, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							switch (height.$) {
								case 0:
									var px = height.a;
									var val = $elm$core$String$fromInt(px) + 'px';
									var name = 'height-px-' + val;
									var $temp$classes = $mdgriffith$elm_ui$Internal$Style$classes.eE + (' ' + (name + (' ' + classes))),
										$temp$node = node,
										$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has),
										$temp$transform = transform,
										$temp$styles = A2(
										$elm$core$List$cons,
										A3($mdgriffith$elm_ui$Internal$Model$Single, name, 'height ', val),
										styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 1:
									var $temp$classes = $mdgriffith$elm_ui$Internal$Style$classes.du + (' ' + classes),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$add,
										$mdgriffith$elm_ui$Internal$Flag$heightContent,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 2:
									var portion = height.a;
									if (portion === 1) {
										var $temp$classes = $mdgriffith$elm_ui$Internal$Style$classes.dv + (' ' + classes),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$heightFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.eF + (' height-fill-' + $elm$core$String$fromInt(portion)))),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$heightFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
											$temp$transform = transform,
											$temp$styles = A2(
											$elm$core$List$cons,
											A3(
												$mdgriffith$elm_ui$Internal$Model$Single,
												$mdgriffith$elm_ui$Internal$Style$classes.fT + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.gf + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
													'height-fill-' + $elm$core$String$fromInt(portion))))),
												'flex-grow',
												$elm$core$String$fromInt(portion * 100000)),
											styles),
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								default:
									var _v6 = $mdgriffith$elm_ui$Internal$Model$renderHeight(height);
									var addToFlags = _v6.a;
									var newClass = _v6.b;
									var newStyles = _v6.c;
									var $temp$classes = classes + (' ' + newClass),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$merge,
										addToFlags,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
										$temp$transform = transform,
										$temp$styles = _Utils_ap(newStyles, styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
							}
						}
					case 2:
						var description = attribute.a;
						switch (description.$) {
							case 0:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'main', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 1:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'nav', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 2:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'footer', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 3:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'aside', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 4:
								var i = description.a;
								if (i <= 1) {
									var $temp$classes = classes,
										$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'h1', node),
										$temp$has = has,
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								} else {
									if (i < 7) {
										var $temp$classes = classes,
											$temp$node = A2(
											$mdgriffith$elm_ui$Internal$Model$addNodeName,
											'h' + $elm$core$String$fromInt(i),
											node),
											$temp$has = has,
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes,
											$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'h6', node),
											$temp$has = has,
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								}
							case 9:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 8:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'role', 'button'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 5:
								var label = description.a;
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-label', label),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 6:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-live', 'polite'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							default:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-live', 'assertive'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
						}
					case 9:
						var location = attribute.a;
						var elem = attribute.b;
						var newStyles = function () {
							switch (elem.$) {
								case 3:
									return styles;
								case 2:
									var str = elem.a;
									return styles;
								case 0:
									var html = elem.a;
									return styles;
								default:
									var styled = elem.a;
									return _Utils_ap(styles, styled.hB);
							}
						}();
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = newStyles,
							$temp$attrs = attrs,
							$temp$children = A3($mdgriffith$elm_ui$Internal$Model$addNearbyElement, location, elem, children),
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 6:
						var x = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$xAlign, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = $mdgriffith$elm_ui$Internal$Model$alignXName(x) + (' ' + classes),
								$temp$node = node,
								$temp$has = function (flags) {
								switch (x) {
									case 1:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$centerX, flags);
									case 2:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$alignRight, flags);
									default:
										return flags;
								}
							}(
								A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$xAlign, has)),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
					default:
						var y = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$yAlign, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = $mdgriffith$elm_ui$Internal$Model$alignYName(y) + (' ' + classes),
								$temp$node = node,
								$temp$has = function (flags) {
								switch (y) {
									case 1:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$centerY, flags);
									case 2:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$alignBottom, flags);
									default:
										return flags;
								}
							}(
								A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$yAlign, has)),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
				}
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Untransformed = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$untransformed = $mdgriffith$elm_ui$Internal$Model$Untransformed;
var $mdgriffith$elm_ui$Internal$Model$element = F4(
	function (context, node, attributes, children) {
		return A3(
			$mdgriffith$elm_ui$Internal$Model$createElement,
			context,
			children,
			A8(
				$mdgriffith$elm_ui$Internal$Model$gatherAttrRecursive,
				$mdgriffith$elm_ui$Internal$Model$contextClasses(context),
				node,
				$mdgriffith$elm_ui$Internal$Flag$none,
				$mdgriffith$elm_ui$Internal$Model$untransformed,
				_List_Nil,
				_List_Nil,
				$mdgriffith$elm_ui$Internal$Model$NoNearbyChildren,
				$elm$core$List$reverse(attributes)));
	});
var $mdgriffith$elm_ui$Internal$Model$AllowHover = 1;
var $mdgriffith$elm_ui$Internal$Model$Layout = 0;
var $mdgriffith$elm_ui$Internal$Model$focusDefaultStyle = {
	fW: $elm$core$Maybe$Nothing,
	f1: $elm$core$Maybe$Nothing,
	ho: $elm$core$Maybe$Just(
		{
			f_: 0,
			ge: A4($mdgriffith$elm_ui$Internal$Model$Rgba, 155 / 255, 203 / 255, 1, 1),
			az: _Utils_Tuple2(0, 0),
			hq: 3
		})
};
var $mdgriffith$elm_ui$Internal$Model$optionsToRecord = function (options) {
	var combine = F2(
		function (opt, record) {
			switch (opt.$) {
				case 0:
					var hoverable = opt.a;
					var _v4 = record.gA;
					if (_v4.$ === 1) {
						return _Utils_update(
							record,
							{
								gA: $elm$core$Maybe$Just(hoverable)
							});
					} else {
						return record;
					}
				case 1:
					var focusStyle = opt.a;
					var _v5 = record.gu;
					if (_v5.$ === 1) {
						return _Utils_update(
							record,
							{
								gu: $elm$core$Maybe$Just(focusStyle)
							});
					} else {
						return record;
					}
				default:
					var renderMode = opt.a;
					var _v6 = record.g_;
					if (_v6.$ === 1) {
						return _Utils_update(
							record,
							{
								g_: $elm$core$Maybe$Just(renderMode)
							});
					} else {
						return record;
					}
			}
		});
	var andFinally = function (record) {
		return {
			gu: function () {
				var _v0 = record.gu;
				if (_v0.$ === 1) {
					return $mdgriffith$elm_ui$Internal$Model$focusDefaultStyle;
				} else {
					var focusable = _v0.a;
					return focusable;
				}
			}(),
			gA: function () {
				var _v1 = record.gA;
				if (_v1.$ === 1) {
					return 1;
				} else {
					var hoverable = _v1.a;
					return hoverable;
				}
			}(),
			g_: function () {
				var _v2 = record.g_;
				if (_v2.$ === 1) {
					return 0;
				} else {
					var actualMode = _v2.a;
					return actualMode;
				}
			}()
		};
	};
	return andFinally(
		A3(
			$elm$core$List$foldr,
			combine,
			{gu: $elm$core$Maybe$Nothing, gA: $elm$core$Maybe$Nothing, g_: $elm$core$Maybe$Nothing},
			options));
};
var $mdgriffith$elm_ui$Internal$Model$toHtml = F2(
	function (mode, el) {
		switch (el.$) {
			case 0:
				var html = el.a;
				return html($mdgriffith$elm_ui$Internal$Model$asEl);
			case 1:
				var styles = el.a.hB;
				var html = el.a.gB;
				return A2(
					html,
					mode(styles),
					$mdgriffith$elm_ui$Internal$Model$asEl);
			case 2:
				var text = el.a;
				return $mdgriffith$elm_ui$Internal$Model$textElement(text);
			default:
				return $mdgriffith$elm_ui$Internal$Model$textElement('');
		}
	});
var $mdgriffith$elm_ui$Internal$Model$renderRoot = F3(
	function (optionList, attributes, child) {
		var options = $mdgriffith$elm_ui$Internal$Model$optionsToRecord(optionList);
		var embedStyle = function () {
			var _v0 = options.g_;
			if (_v0 === 1) {
				return $mdgriffith$elm_ui$Internal$Model$OnlyDynamic(options);
			} else {
				return $mdgriffith$elm_ui$Internal$Model$StaticRootAndDynamic(options);
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Internal$Model$toHtml,
			embedStyle,
			A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asEl,
				$mdgriffith$elm_ui$Internal$Model$div,
				attributes,
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[child]))));
	});
var $mdgriffith$elm_ui$Internal$Model$FontSize = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$SansSerif = {$: 1};
var $mdgriffith$elm_ui$Internal$Model$Typeface = function (a) {
	return {$: 3, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$bgColor = $mdgriffith$elm_ui$Internal$Flag$flag(8);
var $mdgriffith$elm_ui$Internal$Flag$fontSize = $mdgriffith$elm_ui$Internal$Flag$flag(4);
var $mdgriffith$elm_ui$Internal$Model$rootStyle = function () {
	var families = _List_fromArray(
		[
			$mdgriffith$elm_ui$Internal$Model$Typeface('Open Sans'),
			$mdgriffith$elm_ui$Internal$Model$Typeface('Helvetica'),
			$mdgriffith$elm_ui$Internal$Model$Typeface('Verdana'),
			$mdgriffith$elm_ui$Internal$Model$SansSerif
		]);
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$bgColor,
			A3(
				$mdgriffith$elm_ui$Internal$Model$Colored,
				'bg-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(
					A4($mdgriffith$elm_ui$Internal$Model$Rgba, 1, 1, 1, 0)),
				'background-color',
				A4($mdgriffith$elm_ui$Internal$Model$Rgba, 1, 1, 1, 0))),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontColor,
			A3(
				$mdgriffith$elm_ui$Internal$Model$Colored,
				'fc-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(
					A4($mdgriffith$elm_ui$Internal$Model$Rgba, 0, 0, 0, 1)),
				'color',
				A4($mdgriffith$elm_ui$Internal$Model$Rgba, 0, 0, 0, 1))),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontSize,
			$mdgriffith$elm_ui$Internal$Model$FontSize(20)),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontFamily,
			A2(
				$mdgriffith$elm_ui$Internal$Model$FontFamily,
				A3($elm$core$List$foldl, $mdgriffith$elm_ui$Internal$Model$renderFontClassName, 'font-', families),
				families))
		]);
}();
var $mdgriffith$elm_ui$Element$layoutWith = F3(
	function (_v0, attrs, child) {
		var options = _v0.e1;
		return A3(
			$mdgriffith$elm_ui$Internal$Model$renderRoot,
			options,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass(
					A2(
						$elm$core$String$join,
						' ',
						_List_fromArray(
							[$mdgriffith$elm_ui$Internal$Style$classes.hh, $mdgriffith$elm_ui$Internal$Style$classes.fT, $mdgriffith$elm_ui$Internal$Style$classes.hp]))),
				_Utils_ap($mdgriffith$elm_ui$Internal$Model$rootStyle, attrs)),
			child);
	});
var $mdgriffith$elm_ui$Element$layout = $mdgriffith$elm_ui$Element$layoutWith(
	{e1: _List_Nil});
var $mdgriffith$elm_ui$Internal$Model$AlignY = function (a) {
	return {$: 5, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Top = 0;
var $mdgriffith$elm_ui$Element$alignTop = $mdgriffith$elm_ui$Internal$Model$AlignY(0);
var $mdgriffith$elm_ui$Internal$Model$Class = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$overflow = $mdgriffith$elm_ui$Internal$Flag$flag(20);
var $mdgriffith$elm_ui$Element$clipX = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.gc);
var $mdgriffith$elm_ui$Element$Background$color = function (clr) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$bgColor,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Colored,
			'bg-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(clr),
			'background-color',
			clr));
};
var $mdgriffith$elm_ui$Internal$Model$AsColumn = 1;
var $mdgriffith$elm_ui$Internal$Model$asColumn = 1;
var $mdgriffith$elm_ui$Internal$Model$Height = function (a) {
	return {$: 8, a: a};
};
var $mdgriffith$elm_ui$Element$height = $mdgriffith$elm_ui$Internal$Model$Height;
var $mdgriffith$elm_ui$Internal$Model$Content = {$: 1};
var $mdgriffith$elm_ui$Element$shrink = $mdgriffith$elm_ui$Internal$Model$Content;
var $mdgriffith$elm_ui$Internal$Model$Width = function (a) {
	return {$: 7, a: a};
};
var $mdgriffith$elm_ui$Element$width = $mdgriffith$elm_ui$Internal$Model$Width;
var $mdgriffith$elm_ui$Element$column = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asColumn,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.gi + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.cH)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						attrs))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $mdgriffith$elm_ui$Element$el = F2(
	function (attrs, child) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					attrs)),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[child])));
	});
var $mdgriffith$elm_ui$Internal$Model$Fill = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Element$fill = $mdgriffith$elm_ui$Internal$Model$Fill(1);
var $author$project$Colors$gray100 = A3($mdgriffith$elm_ui$Element$rgb255, 243, 244, 246);
var $mdgriffith$elm_ui$Element$htmlAttribute = $mdgriffith$elm_ui$Internal$Model$Attr;
var $mdgriffith$elm_ui$Internal$Model$Empty = {$: 3};
var $mdgriffith$elm_ui$Internal$Model$Text = function (a) {
	return {$: 2, a: a};
};
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $mdgriffith$elm_ui$Internal$Model$map = F2(
	function (fn, el) {
		switch (el.$) {
			case 1:
				var styled = el.a;
				return $mdgriffith$elm_ui$Internal$Model$Styled(
					{
						gB: F2(
							function (add, context) {
								return A2(
									$elm$virtual_dom$VirtualDom$map,
									fn,
									A2(styled.gB, add, context));
							}),
						hB: styled.hB
					});
			case 0:
				var html = el.a;
				return $mdgriffith$elm_ui$Internal$Model$Unstyled(
					A2(
						$elm$core$Basics$composeL,
						$elm$virtual_dom$VirtualDom$map(fn),
						html));
			case 2:
				var str = el.a;
				return $mdgriffith$elm_ui$Internal$Model$Text(str);
			default:
				return $mdgriffith$elm_ui$Internal$Model$Empty;
		}
	});
var $mdgriffith$elm_ui$Element$map = $mdgriffith$elm_ui$Internal$Model$map;
var $mdgriffith$elm_ui$Internal$Model$PaddingStyle = F5(
	function (a, b, c, d, e) {
		return {$: 7, a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_ui$Internal$Flag$padding = $mdgriffith$elm_ui$Internal$Flag$flag(2);
var $mdgriffith$elm_ui$Element$padding = function (x) {
	var f = x;
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$padding,
		A5(
			$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
			'p-' + $elm$core$String$fromInt(x),
			f,
			f,
			f,
			f));
};
var $mdgriffith$elm_ui$Internal$Model$AsRow = 0;
var $mdgriffith$elm_ui$Internal$Model$asRow = 0;
var $mdgriffith$elm_ui$Element$row = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asRow,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.cH + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.bE)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
						attrs))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $author$project$Header$ForParent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Header$ForSelf = function (a) {
	return {$: 0, a: a};
};
var $author$project$Header$LogoutClicked = 0;
var $author$project$Header$ToggleSidebar = 0;
var $mdgriffith$elm_ui$Internal$Model$AlignX = function (a) {
	return {$: 6, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Right = 2;
var $mdgriffith$elm_ui$Element$alignRight = $mdgriffith$elm_ui$Internal$Model$AlignX(2);
var $mdgriffith$elm_ui$Element$clip = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.gb);
var $elm$html$Html$Attributes$alt = $elm$html$Html$Attributes$stringProperty('alt');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $mdgriffith$elm_ui$Element$image = F2(
	function (attrs, _v0) {
		var src = _v0.hv;
		var description = _v0.go;
		var imageAttributes = A2(
			$elm$core$List$filter,
			function (a) {
				switch (a.$) {
					case 7:
						return true;
					case 8:
						return true;
					default:
						return false;
				}
			},
			attrs);
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.gC),
				attrs),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[
						A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asEl,
						$mdgriffith$elm_ui$Internal$Model$NodeName('img'),
						_Utils_ap(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Internal$Model$Attr(
									$elm$html$Html$Attributes$src(src)),
									$mdgriffith$elm_ui$Internal$Model$Attr(
									$elm$html$Html$Attributes$alt(description))
								]),
							imageAttributes),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(_List_Nil))
					])));
	});
var $mdgriffith$elm_ui$Internal$Model$Px = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Element$px = $mdgriffith$elm_ui$Internal$Model$Px;
var $mdgriffith$elm_ui$Internal$Flag$borderRound = $mdgriffith$elm_ui$Internal$Flag$flag(17);
var $mdgriffith$elm_ui$Element$Border$rounded = function (radius) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderRound,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Single,
			'br-' + $elm$core$String$fromInt(radius),
			'border-radius',
			$elm$core$String$fromInt(radius) + 'px'));
};
var $author$project$Ui$avatar = F2(
	function (username, avatarSrc) {
		return A2(
			$mdgriffith$elm_ui$Element$image,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(40)),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(40)),
					$mdgriffith$elm_ui$Element$Border$rounded(20),
					$mdgriffith$elm_ui$Element$clip
				]),
			{go: 'Avatar of ' + username, hv: avatarSrc});
	});
var $mdgriffith$elm_ui$Internal$Model$Button = {$: 8};
var $mdgriffith$elm_ui$Internal$Model$Describe = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $mdgriffith$elm_ui$Element$Input$enter = 'Enter';
var $mdgriffith$elm_ui$Internal$Model$NoAttribute = {$: 0};
var $mdgriffith$elm_ui$Element$Input$hasFocusStyle = function (attr) {
	if (((attr.$ === 4) && (attr.b.$ === 11)) && (!attr.b.a)) {
		var _v1 = attr.b;
		var _v2 = _v1.a;
		return true;
	} else {
		return false;
	}
};
var $mdgriffith$elm_ui$Element$Input$focusDefault = function (attrs) {
	return A2($elm$core$List$any, $mdgriffith$elm_ui$Element$Input$hasFocusStyle, attrs) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Internal$Model$htmlClass('focusable');
};
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $mdgriffith$elm_ui$Element$Events$onClick = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Events$onClick);
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $mdgriffith$elm_ui$Element$Input$onKeyLookup = function (lookup) {
	var decode = function (code) {
		var _v0 = lookup(code);
		if (_v0.$ === 1) {
			return $elm$json$Json$Decode$fail('No key matched');
		} else {
			var msg = _v0.a;
			return $elm$json$Json$Decode$succeed(msg);
		}
	};
	var isKey = A2(
		$elm$json$Json$Decode$andThen,
		decode,
		A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string));
	return $mdgriffith$elm_ui$Internal$Model$Attr(
		A2(
			$elm$html$Html$Events$preventDefaultOn,
			'keydown',
			A2(
				$elm$json$Json$Decode$map,
				function (fired) {
					return _Utils_Tuple2(fired, true);
				},
				isKey)));
};
var $mdgriffith$elm_ui$Internal$Flag$cursor = $mdgriffith$elm_ui$Internal$Flag$flag(21);
var $mdgriffith$elm_ui$Element$pointer = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$cursor, $mdgriffith$elm_ui$Internal$Style$classes.gk);
var $mdgriffith$elm_ui$Element$Input$space = ' ';
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $mdgriffith$elm_ui$Element$Input$button = F2(
	function (attrs, _v0) {
		var onPress = _v0.e$;
		var label = _v0.dG;
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.c_ + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.bE + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.hm + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.eY)))))),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$pointer,
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$Input$focusDefault(attrs),
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Internal$Model$Describe($mdgriffith$elm_ui$Internal$Model$Button),
									A2(
										$elm$core$List$cons,
										$mdgriffith$elm_ui$Internal$Model$Attr(
											$elm$html$Html$Attributes$tabindex(0)),
										function () {
											if (onPress.$ === 1) {
												return A2(
													$elm$core$List$cons,
													$mdgriffith$elm_ui$Internal$Model$Attr(
														$elm$html$Html$Attributes$disabled(true)),
													attrs);
											} else {
												var msg = onPress.a;
												return A2(
													$elm$core$List$cons,
													$mdgriffith$elm_ui$Element$Events$onClick(msg),
													A2(
														$elm$core$List$cons,
														$mdgriffith$elm_ui$Element$Input$onKeyLookup(
															function (code) {
																return _Utils_eq(code, $mdgriffith$elm_ui$Element$Input$enter) ? $elm$core$Maybe$Just(msg) : (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$space) ? $elm$core$Maybe$Just(msg) : $elm$core$Maybe$Nothing);
															}),
														attrs));
											}
										}()))))))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[label])));
	});
var $mdgriffith$elm_ui$Internal$Model$CenterX = 1;
var $mdgriffith$elm_ui$Element$centerX = $mdgriffith$elm_ui$Internal$Model$AlignX(1);
var $mdgriffith$elm_ui$Internal$Model$CenterY = 1;
var $mdgriffith$elm_ui$Element$centerY = $mdgriffith$elm_ui$Internal$Model$AlignY(1);
var $author$project$Colors$gray700 = A3($mdgriffith$elm_ui$Element$rgb255, 55, 65, 81);
var $author$project$Header$headerHeight = 65;
var $mdgriffith$elm_ui$Internal$Model$Heading = function (a) {
	return {$: 4, a: a};
};
var $mdgriffith$elm_ui$Element$Region$heading = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Describe, $mdgriffith$elm_ui$Internal$Model$Heading);
var $mdgriffith$elm_ui$Internal$Flag$fontWeight = $mdgriffith$elm_ui$Internal$Flag$flag(13);
var $mdgriffith$elm_ui$Element$Font$heavy = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontWeight, $mdgriffith$elm_ui$Internal$Style$classes.hI);
var $mdgriffith$elm_ui$Internal$Model$unstyled = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Unstyled, $elm$core$Basics$always);
var $mdgriffith$elm_ui$Element$html = $mdgriffith$elm_ui$Internal$Model$unstyled;
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$html$Html$Attributes$rel = _VirtualDom_attribute('rel');
var $mdgriffith$elm_ui$Element$link = F2(
	function (attrs, _v0) {
		var url = _v0.h3;
		var label = _v0.dG;
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$NodeName('a'),
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$Attr(
					$elm$html$Html$Attributes$href(url)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Internal$Model$Attr(
						$elm$html$Html$Attributes$rel('noopener noreferrer')),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.c_ + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.bE + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.eS)))),
								attrs))))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[label])));
	});
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$line = $elm$svg$Svg$trustedNode('line');
var $feathericons$elm_feather$FeatherIcons$Icon = $elm$core$Basics$identity;
var $feathericons$elm_feather$FeatherIcons$defaultAttributes = function (name) {
	return {
		ga: $elm$core$Maybe$Just('feather feather-' + name),
		hq: 24,
		cK: '',
		de: 2,
		dg: '0 0 24 24'
	};
};
var $feathericons$elm_feather$FeatherIcons$makeBuilder = F2(
	function (name, src) {
		return {
			aW: $feathericons$elm_feather$FeatherIcons$defaultAttributes(name),
			hv: src
		};
	});
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $elm$svg$Svg$Attributes$points = _VirtualDom_attribute('points');
var $elm$svg$Svg$polyline = $elm$svg$Svg$trustedNode('polyline');
var $elm$svg$Svg$Attributes$x1 = _VirtualDom_attribute('x1');
var $elm$svg$Svg$Attributes$x2 = _VirtualDom_attribute('x2');
var $elm$svg$Svg$Attributes$y1 = _VirtualDom_attribute('y1');
var $elm$svg$Svg$Attributes$y2 = _VirtualDom_attribute('y2');
var $feathericons$elm_feather$FeatherIcons$logOut = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'log-out',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$polyline,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$points('16 17 21 12 16 7')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('21'),
					$elm$svg$Svg$Attributes$y1('12'),
					$elm$svg$Svg$Attributes$x2('9'),
					$elm$svg$Svg$Attributes$y2('12')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$menu = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'menu',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('3'),
					$elm$svg$Svg$Attributes$y1('12'),
					$elm$svg$Svg$Attributes$x2('21'),
					$elm$svg$Svg$Attributes$y2('12')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('3'),
					$elm$svg$Svg$Attributes$y1('6'),
					$elm$svg$Svg$Attributes$x2('21'),
					$elm$svg$Svg$Attributes$y2('6')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('3'),
					$elm$svg$Svg$Attributes$y1('18'),
					$elm$svg$Svg$Attributes$x2('21'),
					$elm$svg$Svg$Attributes$y2('18')
				]),
			_List_Nil)
		]));
var $mdgriffith$elm_ui$Element$none = $mdgriffith$elm_ui$Internal$Model$Empty;
var $mdgriffith$elm_ui$Element$paddingXY = F2(
	function (x, y) {
		if (_Utils_eq(x, y)) {
			var f = x;
			return A2(
				$mdgriffith$elm_ui$Internal$Model$StyleClass,
				$mdgriffith$elm_ui$Internal$Flag$padding,
				A5(
					$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
					'p-' + $elm$core$String$fromInt(x),
					f,
					f,
					f,
					f));
		} else {
			var yFloat = y;
			var xFloat = x;
			return A2(
				$mdgriffith$elm_ui$Internal$Model$StyleClass,
				$mdgriffith$elm_ui$Internal$Flag$padding,
				A5(
					$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
					'p-' + ($elm$core$String$fromInt(x) + ('-' + $elm$core$String$fromInt(y))),
					yFloat,
					xFloat,
					yFloat,
					xFloat));
		}
	});
var $author$project$Sidebar$sideBarWidth = 280;
var $mdgriffith$elm_ui$Internal$Model$SpacingStyle = F3(
	function (a, b, c) {
		return {$: 5, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Flag$spacing = $mdgriffith$elm_ui$Internal$Flag$flag(3);
var $mdgriffith$elm_ui$Internal$Model$spacingName = F2(
	function (x, y) {
		return 'spacing-' + ($elm$core$String$fromInt(x) + ('-' + $elm$core$String$fromInt(y)));
	});
var $mdgriffith$elm_ui$Element$spacing = function (x) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$spacing,
		A3(
			$mdgriffith$elm_ui$Internal$Model$SpacingStyle,
			A2($mdgriffith$elm_ui$Internal$Model$spacingName, x, x),
			x,
			x));
};
var $mdgriffith$elm_ui$Element$text = function (content) {
	return $mdgriffith$elm_ui$Internal$Model$Text(content);
};
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$svg$Svg$map = $elm$virtual_dom$VirtualDom$map;
var $elm$svg$Svg$Attributes$stroke = _VirtualDom_attribute('stroke');
var $elm$svg$Svg$Attributes$strokeLinecap = _VirtualDom_attribute('stroke-linecap');
var $elm$svg$Svg$Attributes$strokeLinejoin = _VirtualDom_attribute('stroke-linejoin');
var $elm$svg$Svg$Attributes$strokeWidth = _VirtualDom_attribute('stroke-width');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $feathericons$elm_feather$FeatherIcons$toHtml = F2(
	function (attributes, _v0) {
		var src = _v0.hv;
		var attrs = _v0.aW;
		var strSize = $elm$core$String$fromFloat(attrs.hq);
		var baseAttributes = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$fill('none'),
				$elm$svg$Svg$Attributes$height(
				_Utils_ap(strSize, attrs.cK)),
				$elm$svg$Svg$Attributes$width(
				_Utils_ap(strSize, attrs.cK)),
				$elm$svg$Svg$Attributes$stroke('currentColor'),
				$elm$svg$Svg$Attributes$strokeLinecap('round'),
				$elm$svg$Svg$Attributes$strokeLinejoin('round'),
				$elm$svg$Svg$Attributes$strokeWidth(
				$elm$core$String$fromFloat(attrs.de)),
				$elm$svg$Svg$Attributes$viewBox(attrs.dg)
			]);
		var combinedAttributes = _Utils_ap(
			function () {
				var _v1 = attrs.ga;
				if (!_v1.$) {
					var c = _v1.a;
					return A2(
						$elm$core$List$cons,
						$elm$svg$Svg$Attributes$class(c),
						baseAttributes);
				} else {
					return baseAttributes;
				}
			}(),
			attributes);
		return A2(
			$elm$svg$Svg$svg,
			combinedAttributes,
			A2(
				$elm$core$List$map,
				$elm$svg$Svg$map($elm$core$Basics$never),
				src));
	});
var $author$project$Colors$white = A3($mdgriffith$elm_ui$Element$rgb255, 255, 255, 255);
var $author$project$Header$view = F2(
	function (user, device) {
		var _v0 = function () {
			var _v1 = device.ga;
			if (!_v1) {
				return _Utils_Tuple3($mdgriffith$elm_ui$Element$shrink, true, 10);
			} else {
				return _Utils_Tuple3(
					$mdgriffith$elm_ui$Element$px($author$project$Sidebar$sideBarWidth),
					false,
					20);
			}
		}();
		var logoWidth = _v0.a;
		var isPhone = _v0.b;
		var xPadding = _v0.c;
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px($author$project$Header$headerHeight)),
					$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$gray700)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width(logoWidth),
							A2($mdgriffith$elm_ui$Element$paddingXY, xPadding, 0),
							$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$gray700)
						]),
					A2(
						$mdgriffith$elm_ui$Element$link,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerY, $mdgriffith$elm_ui$Element$centerX]),
						{
							dG: A2(
								$mdgriffith$elm_ui$Element$row,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$spacing(10),
										$mdgriffith$elm_ui$Element$Region$heading(1),
										$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$white),
										$mdgriffith$elm_ui$Element$Font$heavy
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$image,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width(
												$mdgriffith$elm_ui$Element$px(30))
											]),
										{go: 'Gambling logo', hv: '/images/football-white.svg'}),
										$mdgriffith$elm_ui$Element$text('Gambling')
									])),
							h3: '/'
						})),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							A2($mdgriffith$elm_ui$Element$paddingXY, xPadding, 0)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$alignRight,
									$mdgriffith$elm_ui$Element$centerY,
									$mdgriffith$elm_ui$Element$spacing(20),
									$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$white)
								]),
							_List_fromArray(
								[
									A2($author$project$Ui$avatar, user.ac, user.ha),
									isPhone ? A2(
									$mdgriffith$elm_ui$Element$Input$button,
									_List_Nil,
									{
										dG: A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$width(
													$mdgriffith$elm_ui$Element$px(24)),
													$mdgriffith$elm_ui$Element$height(
													$mdgriffith$elm_ui$Element$px(18))
												]),
											$mdgriffith$elm_ui$Element$html(
												A2($feathericons$elm_feather$FeatherIcons$toHtml, _List_Nil, $feathericons$elm_feather$FeatherIcons$menu))),
										e$: $elm$core$Maybe$Just(
											$author$project$Header$ForParent(0))
									}) : $mdgriffith$elm_ui$Element$none,
									isPhone ? $mdgriffith$elm_ui$Element$none : $mdgriffith$elm_ui$Element$text(user.ac),
									A2(
									$mdgriffith$elm_ui$Element$Input$button,
									_List_Nil,
									{
										dG: A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$width(
													$mdgriffith$elm_ui$Element$px(24)),
													$mdgriffith$elm_ui$Element$height(
													$mdgriffith$elm_ui$Element$px(18))
												]),
											$mdgriffith$elm_ui$Element$html(
												A2($feathericons$elm_feather$FeatherIcons$toHtml, _List_Nil, $feathericons$elm_feather$FeatherIcons$logOut))),
										e$: $elm$core$Maybe$Just(
											$author$project$Header$ForSelf(0))
									})
								]))
						]))
				]));
	});
var $author$project$Main$layout = F3(
	function (authModel, sidebar, mainContent) {
		var isPhone = function () {
			var _v0 = authModel.bF.ga;
			if (!_v0) {
				return true;
			} else {
				return false;
			}
		}();
		var mainWrapped = A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$padding(
						isPhone ? 0 : 20),
						$mdgriffith$elm_ui$Element$alignTop,
						$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$gray100),
						$mdgriffith$elm_ui$Element$clipX
					]),
				isPhone ? _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$htmlAttribute(
						A2($elm$html$Html$Attributes$style, 'flex-basis', 'auto'))
					]) : _List_Nil),
			mainContent);
		var content = isPhone ? (authModel.cu ? sidebar : mainWrapped) : A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[sidebar, mainWrapped]));
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Main$headerTranslator,
					A2($author$project$Header$view, authModel.y, authModel.bF)),
					content
				]));
	});
var $mdgriffith$elm_ui$Element$Font$typeface = $mdgriffith$elm_ui$Internal$Model$Typeface;
var $elm_community$list_extra$List$Extra$groupWhile = F2(
	function (isSameGroup, items) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					if (!acc.b) {
						return _List_fromArray(
							[
								_Utils_Tuple2(x, _List_Nil)
							]);
					} else {
						var _v1 = acc.a;
						var y = _v1.a;
						var restOfGroup = _v1.b;
						var groups = acc.b;
						return A2(isSameGroup, x, y) ? A2(
							$elm$core$List$cons,
							_Utils_Tuple2(
								x,
								A2($elm$core$List$cons, y, restOfGroup)),
							groups) : A2(
							$elm$core$List$cons,
							_Utils_Tuple2(x, _List_Nil),
							acc);
					}
				}),
			_List_Nil,
			items);
	});
var $author$project$Page$Competition$isTheSameDate = F3(
	function (z, t1, t2) {
		var toDate = function (t) {
			return _Utils_Tuple3(
				A2($elm$time$Time$toYear, z, t),
				A2($elm$time$Time$toMonth, z, t),
				A2($elm$time$Time$toDay, z, t));
		};
		return _Utils_eq(
			toDate(t1),
			toDate(t2));
	});
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$Page$Competition$combineMatchesByDate = function (tz) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$List$sortBy(
			function (m) {
				return _Utils_Tuple2(
					$elm$time$Time$posixToMillis(m.bO),
					m.a);
			}),
		$elm_community$list_extra$List$Extra$groupWhile(
			F2(
				function (x, y) {
					return A3(
						$author$project$Page$Competition$isTheSameDate,
						tz,
						function ($) {
							return $.bO;
						}(x),
						function ($) {
							return $.bO;
						}(y));
				})));
};
var $mdgriffith$elm_ui$Element$scrollbarX = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.hk);
var $mdgriffith$elm_ui$Internal$Flag$borderColor = $mdgriffith$elm_ui$Internal$Flag$flag(28);
var $mdgriffith$elm_ui$Element$Border$color = function (clr) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderColor,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Colored,
			'bc-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(clr),
			'border-color',
			clr));
};
var $author$project$Colors$gray500 = A3($mdgriffith$elm_ui$Element$rgb255, 107, 114, 128);
var $author$project$Page$Competition$matchInfoWidth = function (isPhone) {
	return $mdgriffith$elm_ui$Element$width(
		$mdgriffith$elm_ui$Element$px(
			isPhone ? 170 : 270));
};
var $author$project$Page$Competition$sortUsers = F2(
	function (authUser, users) {
		var sort = $elm$core$List$sortBy(
			function ($) {
				return $.a;
			});
		var maybeMe = A2(
			$elm_community$list_extra$List$Extra$find,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.bA;
				},
				$elm$core$Basics$eq(authUser.bA)),
			users);
		if (maybeMe.$ === 1) {
			return sort(users);
		} else {
			var me = maybeMe.a;
			return A2(
				$elm$core$List$cons,
				me,
				sort(
					A2($elm_community$list_extra$List$Extra$remove, me, users)));
		}
	});
var $mdgriffith$elm_ui$Element$Font$medium = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontWeight, $mdgriffith$elm_ui$Internal$Style$classes.hM);
var $author$project$Page$Competition$monthToString = F2(
	function (month, isPhone) {
		switch (month) {
			case 0:
				return isPhone ? 'Jan' : 'January';
			case 1:
				return isPhone ? 'Feb' : 'February';
			case 2:
				return isPhone ? 'Mar' : 'March';
			case 3:
				return isPhone ? 'Apr' : 'April';
			case 4:
				return isPhone ? 'May' : 'May';
			case 5:
				return isPhone ? 'Jun' : 'June';
			case 6:
				return isPhone ? 'Jul' : 'July';
			case 7:
				return isPhone ? 'Aug' : 'August';
			case 8:
				return isPhone ? 'Sep' : 'September';
			case 9:
				return isPhone ? 'Oct' : 'October';
			case 10:
				return isPhone ? 'Nov' : 'November';
			default:
				return isPhone ? 'Dec' : 'December';
		}
	});
var $mdgriffith$elm_ui$Element$Font$size = function (i) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$fontSize,
		$mdgriffith$elm_ui$Internal$Model$FontSize(i));
};
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Sun = 6;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $elm$time$Time$toWeekday = F2(
	function (zone, time) {
		var _v0 = A2(
			$elm$core$Basics$modBy,
			7,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60 * 24));
		switch (_v0) {
			case 0:
				return 3;
			case 1:
				return 4;
			case 2:
				return 5;
			case 3:
				return 6;
			case 4:
				return 0;
			case 5:
				return 1;
			default:
				return 2;
		}
	});
var $author$project$Page$Competition$weekdayToString = F2(
	function (weekday, isPhone) {
		switch (weekday) {
			case 0:
				return isPhone ? 'Mon' : 'Monday';
			case 1:
				return isPhone ? 'Tue' : 'Tuesday';
			case 2:
				return isPhone ? 'Wed' : 'Wednesday';
			case 3:
				return isPhone ? 'Thu' : 'Thursday';
			case 4:
				return isPhone ? 'Fri' : 'Friday';
			case 5:
				return isPhone ? 'Sat' : 'Saturday';
			default:
				return isPhone ? 'Sun' : 'Sunday';
		}
	});
var $author$project$Page$Competition$viewDate = F3(
	function (device, tz, dateTime) {
		var year = A2($elm$time$Time$toYear, tz, dateTime);
		var weekday = A2($elm$time$Time$toWeekday, tz, dateTime);
		var month = A2($elm$time$Time$toMonth, tz, dateTime);
		var isPhone = function () {
			var _v0 = device.ga;
			if (!_v0) {
				return true;
			} else {
				return false;
			}
		}();
		var day = A2($elm$time$Time$toDay, tz, dateTime);
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$medium,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(5),
					$mdgriffith$elm_ui$Element$Font$size(20)
				]),
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$text(
					A2($author$project$Page$Competition$weekdayToString, weekday, isPhone)),
					$mdgriffith$elm_ui$Element$text(
					$elm$core$String$fromInt(day)),
					$mdgriffith$elm_ui$Element$text(
					A2($author$project$Page$Competition$monthToString, month, isPhone)),
					$mdgriffith$elm_ui$Element$text(
					$elm$core$String$fromInt(year))
				]));
	});
var $mdgriffith$elm_ui$Internal$Model$Left = 0;
var $mdgriffith$elm_ui$Element$alignLeft = $mdgriffith$elm_ui$Internal$Model$AlignX(0);
var $author$project$Page$Competition$matchTeamCrestHeight = function (isPhone) {
	return $mdgriffith$elm_ui$Element$height(
		$mdgriffith$elm_ui$Element$px(
			isPhone ? 20 : 25));
};
var $author$project$Page$Competition$matchTeamTlaWidth = function (isPhone) {
	return $mdgriffith$elm_ui$Element$width(
		$mdgriffith$elm_ui$Element$px(
			isPhone ? 50 : 70));
};
var $mdgriffith$elm_ui$Element$spacingXY = F2(
	function (x, y) {
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$spacing,
			A3(
				$mdgriffith$elm_ui$Internal$Model$SpacingStyle,
				A2($mdgriffith$elm_ui$Internal$Model$spacingName, x, y),
				x,
				y));
	});
var $author$project$Page$Competition$matchTeamRow = F2(
	function (isPhone, children) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$author$project$Page$Competition$matchTeamTlaWidth(isPhone),
					A2($mdgriffith$elm_ui$Element$spacingXY, 5, 0),
					$mdgriffith$elm_ui$Element$Font$size(
					isPhone ? 14 : 18)
				]),
			children);
	});
var $mdgriffith$elm_ui$Element$spaceEvenly = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$spacing, $mdgriffith$elm_ui$Internal$Style$classes.hs);
var $author$project$Page$Competition$betColumnWidth = 90;
var $author$project$Page$Competition$BetClicked = F3(
	function (a, b, c) {
		return {$: 9, a: a, b: b, c: c};
	});
var $author$project$Page$Competition$betValueToString = function (betValue) {
	switch (betValue) {
		case 1:
			return '1';
		case 2:
			return 'X';
		default:
			return '2';
	}
};
var $author$project$Colors$blue400 = A3($mdgriffith$elm_ui$Element$rgb255, 96, 165, 250);
var $mdgriffith$elm_ui$Internal$Model$BorderWidth = F5(
	function (a, b, c, d, e) {
		return {$: 6, a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_ui$Element$Border$width = function (v) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderWidth,
		A5(
			$mdgriffith$elm_ui$Internal$Model$BorderWidth,
			'b-' + $elm$core$String$fromInt(v),
			v,
			v,
			v,
			v));
};
var $author$project$Page$Competition$viewBetChoice = F3(
	function (match, maybeBet, betValue) {
		var isActive = function () {
			if (maybeBet.$ === 1) {
				return false;
			} else {
				var bet = maybeBet.a;
				return _Utils_eq(bet.an, betValue);
			}
		}();
		var element = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(22)),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(25)),
					$mdgriffith$elm_ui$Element$Border$width(1),
					$mdgriffith$elm_ui$Element$Border$color(
					isActive ? $author$project$Colors$blue400 : $author$project$Colors$gray500),
					$mdgriffith$elm_ui$Element$Background$color(
					isActive ? $author$project$Colors$blue400 : $author$project$Colors$white)
				]),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$centerY,
						$mdgriffith$elm_ui$Element$Font$size(16),
						$mdgriffith$elm_ui$Element$Font$medium,
						$mdgriffith$elm_ui$Element$Font$color(
						isActive ? $author$project$Colors$white : $author$project$Colors$gray600)
					]),
				$mdgriffith$elm_ui$Element$text(
					$author$project$Page$Competition$betValueToString(betValue))));
		return isActive ? element : A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_List_Nil,
			{
				dG: element,
				e$: $elm$core$Maybe$Just(
					A3($author$project$Page$Competition$BetClicked, betValue, match.a, maybeBet))
			});
	});
var $author$project$Page$Competition$viewBetChoices = F2(
	function (match, maybeBet) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(5)
				]),
			_List_fromArray(
				[
					A3($author$project$Page$Competition$viewBetChoice, match, maybeBet, 1),
					A3($author$project$Page$Competition$viewBetChoice, match, maybeBet, 2),
					A3($author$project$Page$Competition$viewBetChoice, match, maybeBet, 0)
				]));
	});
var $author$project$Colors$gray400 = A3($mdgriffith$elm_ui$Element$rgb255, 156, 163, 175);
var $author$project$Colors$green400 = A3($mdgriffith$elm_ui$Element$rgb255, 52, 211, 153);
var $author$project$Page$Competition$isBetAWin = F2(
	function (betValue, result) {
		return ((_Utils_cmp(result.cJ, result.cE) > 0) && (betValue === 1)) || ((_Utils_eq(result.cJ, result.cE) && (betValue === 2)) || ((_Utils_cmp(result.cJ, result.cE) < 0) && (!betValue)));
	});
var $author$project$Colors$red400 = A3($mdgriffith$elm_ui$Element$rgb255, 248, 113, 113);
var $author$project$Page$Competition$viewReadOnlyBet = F2(
	function (maybeBet, maybeResult) {
		if (maybeBet.$ === 1) {
			return $mdgriffith$elm_ui$Element$text('');
		} else {
			var bet = maybeBet.a;
			var c = function () {
				if (maybeResult.$ === 1) {
					return $author$project$Colors$gray400;
				} else {
					var result = maybeResult.a;
					return A2($author$project$Page$Competition$isBetAWin, bet.an, result) ? $author$project$Colors$green400 : $author$project$Colors$red400;
				}
			}();
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$color(c)
					]),
				$mdgriffith$elm_ui$Element$text(
					$author$project$Page$Competition$betValueToString(bet.an)));
		}
	});
var $author$project$Page$Competition$viewBet = F4(
	function (time, authUser, match, user) {
		var userBet = A2($elm$core$Dict$get, user.bA, match.cV);
		var timeMillis = $elm$time$Time$posixToMillis(time);
		var matchTimeMillis = $elm$time$Time$posixToMillis(match.bO);
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px($author$project$Page$Competition$betColumnWidth))
				]),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				(_Utils_eq(authUser.bA, user.bA) && ((!(!timeMillis)) && (_Utils_cmp(timeMillis, matchTimeMillis) < 1))) ? A2($author$project$Page$Competition$viewBetChoices, match, userBet) : A2($author$project$Page$Competition$viewReadOnlyBet, userBet, match.aM)));
	});
var $author$project$Page$Competition$viewBets = F4(
	function (time, authUser, users, match) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spaceEvenly
				]),
			A2(
				$elm$core$List$map,
				A3($author$project$Page$Competition$viewBet, time, authUser, match),
				users));
	});
var $author$project$Page$Competition$viewScore = F2(
	function (result, isPhone) {
		var scoreBadgeWidth = $mdgriffith$elm_ui$Element$width(
			$mdgriffith$elm_ui$Element$px(
				isPhone ? 18 : 22));
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(
						isPhone ? 40 : 50)),
					$author$project$Page$Competition$matchTeamCrestHeight(isPhone),
					$mdgriffith$elm_ui$Element$Font$size(
					isPhone ? 14 : 16),
					$mdgriffith$elm_ui$Element$Font$medium,
					$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$white)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							scoreBadgeWidth,
							$mdgriffith$elm_ui$Element$alignLeft,
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$blue400)
						]),
					A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
						$mdgriffith$elm_ui$Element$text(
							$elm$core$String$fromInt(result.cJ)))),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							scoreBadgeWidth,
							$mdgriffith$elm_ui$Element$alignRight,
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$blue400)
						]),
					A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
						$mdgriffith$elm_ui$Element$text(
							$elm$core$String$fromInt(result.cE))))
				]));
	});
var $author$project$Page$Competition$viewTime = F3(
	function (tz, dateTime, isPhone) {
		var minute = $elm$core$String$fromInt(
			A2($elm$time$Time$toMinute, tz, dateTime));
		var paddedMinute = ($elm$core$String$length(minute) === 1) ? ('0' + minute) : minute;
		var hour = $elm$core$String$fromInt(
			A2($elm$time$Time$toHour, tz, dateTime));
		var paddedHour = ($elm$core$String$length(hour) === 1) ? ('0' + hour) : hour;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(
						isPhone ? 40 : 50)),
					$author$project$Page$Competition$matchTeamCrestHeight(isPhone),
					$mdgriffith$elm_ui$Element$Font$size(14),
					$mdgriffith$elm_ui$Element$Border$width(1),
					$mdgriffith$elm_ui$Element$Border$color($author$project$Colors$gray500)
				]),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
				$mdgriffith$elm_ui$Element$text(paddedHour + (':' + paddedMinute))));
	});
var $mdgriffith$elm_ui$Element$Border$widthXY = F2(
	function (x, y) {
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$borderWidth,
			A5(
				$mdgriffith$elm_ui$Internal$Model$BorderWidth,
				'b-' + ($elm$core$String$fromInt(x) + ('-' + $elm$core$String$fromInt(y))),
				y,
				x,
				y,
				x));
	});
var $mdgriffith$elm_ui$Element$Border$widthEach = function (_v0) {
	var bottom = _v0.dn;
	var top = _v0.d6;
	var left = _v0.dH;
	var right = _v0.dV;
	return (_Utils_eq(top, bottom) && _Utils_eq(left, right)) ? (_Utils_eq(top, right) ? $mdgriffith$elm_ui$Element$Border$width(top) : A2($mdgriffith$elm_ui$Element$Border$widthXY, left, top)) : A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderWidth,
		A5(
			$mdgriffith$elm_ui$Internal$Model$BorderWidth,
			'b-' + ($elm$core$String$fromInt(top) + ('-' + ($elm$core$String$fromInt(right) + ('-' + ($elm$core$String$fromInt(bottom) + ('-' + $elm$core$String$fromInt(left))))))),
			top,
			right,
			bottom,
			left));
};
var $author$project$Page$Competition$viewMatchRow = F6(
	function (time, tz, device, authUser, users, match) {
		var isPhone = function () {
			var _v1 = device.ga;
			if (!_v1) {
				return true;
			} else {
				return false;
			}
		}();
		var timeOrScore = function () {
			var _v0 = match.aM;
			if (_v0.$ === 1) {
				return A3($author$project$Page$Competition$viewTime, tz, match.bO, isPhone);
			} else {
				var r = _v0.a;
				return A2($author$project$Page$Competition$viewScore, r, isPhone);
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(60)),
					$mdgriffith$elm_ui$Element$Border$widthEach(
					{dn: 0, dH: 0, dV: 0, d6: 1}),
					$mdgriffith$elm_ui$Element$Border$color($author$project$Colors$gray500)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(
							isPhone ? 10 : 20),
							$author$project$Page$Competition$matchInfoWidth(isPhone)
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Page$Competition$matchTeamRow,
							isPhone,
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$alignRight, $mdgriffith$elm_ui$Element$centerY]),
									$mdgriffith$elm_ui$Element$text(match.dw.av)),
									A2(
									$mdgriffith$elm_ui$Element$image,
									_List_fromArray(
										[
											$author$project$Page$Competition$matchTeamCrestHeight(isPhone),
											$mdgriffith$elm_ui$Element$alignRight
										]),
									{go: '', hv: match.dw.$7})
								])),
							timeOrScore,
							A2(
							$author$project$Page$Competition$matchTeamRow,
							isPhone,
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$image,
									_List_fromArray(
										[
											$author$project$Page$Competition$matchTeamCrestHeight(isPhone),
											$mdgriffith$elm_ui$Element$alignLeft
										]),
									{go: '', hv: match.dl.$7}),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$alignLeft, $mdgriffith$elm_ui$Element$centerY]),
									$mdgriffith$elm_ui$Element$text(match.dl.av))
								]))
						])),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					A4($author$project$Page$Competition$viewBets, time, authUser, users, match))
				]));
	});
var $author$project$Page$Competition$shortUsername = function (username) {
	return A2($elm$core$String$contains, ' ', username) ? A2(
		$elm$core$Maybe$withDefault,
		username,
		$elm$core$List$head(
			A2($elm$core$String$split, ' ', username))) : (A2($elm$core$String$contains, '.', username) ? A2(
		$elm$core$Maybe$withDefault,
		username,
		$elm$core$List$head(
			A2($elm$core$String$split, '.', username))) : A2($elm$core$String$left, 6, username));
};
var $author$project$Page$Competition$viewUser = function (user) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				$mdgriffith$elm_ui$Element$px($author$project$Page$Competition$betColumnWidth)),
				$mdgriffith$elm_ui$Element$spacing(5)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				A2($author$project$Ui$avatar, user.ac, user.K)),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				$mdgriffith$elm_ui$Element$text(
					$author$project$Page$Competition$shortUsername(user.ac)))
			]));
};
var $author$project$Page$Competition$viewUsers = function (users) {
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$spaceEvenly
			]),
		A2($elm$core$List$map, $author$project$Page$Competition$viewUser, users));
};
var $author$project$Page$Competition$viewMatchDateGroup = F7(
	function (device, time, tz, authUser, users, index, _v0) {
		var first = _v0.a;
		var rest = _v0.b;
		var sortedUsers = A2($author$project$Page$Competition$sortUsers, authUser, users);
		var isPhone = function () {
			var _v1 = device.ga;
			if (!_v1) {
				return true;
			} else {
				return false;
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(10),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					A2($elm$html$Html$Attributes$style, 'min-width', 'min-content')),
					$mdgriffith$elm_ui$Element$Border$color($author$project$Colors$gray500),
					$mdgriffith$elm_ui$Element$Border$widthEach(
					{dn: 1, dH: 0, dV: 0, d6: 0})
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$author$project$Page$Competition$matchInfoWidth(isPhone)
								]),
							A3($author$project$Page$Competition$viewDate, device, tz, first.bO)),
						(!index) ? _List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								$author$project$Page$Competition$viewUsers(sortedUsers))
							]) : _List_Nil)),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					A2(
						$elm$core$List$map,
						A5($author$project$Page$Competition$viewMatchRow, time, tz, device, authUser, sortedUsers),
						A2($elm$core$List$cons, first, rest)))
				]));
	});
var $author$project$Page$Competition$viewMatchTable = F6(
	function (device, time, tz, authUser, users, matches) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					A2($elm$html$Html$Attributes$style, 'flex-basis', 'auto')),
					$mdgriffith$elm_ui$Element$spacing(60),
					$mdgriffith$elm_ui$Element$scrollbarX
				]),
			A2(
				$elm$core$List$indexedMap,
				A5($author$project$Page$Competition$viewMatchDateGroup, device, time, tz, authUser, users),
				A2($author$project$Page$Competition$combineMatchesByDate, tz, matches)));
	});
var $author$project$Page$Competition$NextMatchdayClicked = {$: 8};
var $author$project$Page$Competition$PreviousMatchdayClicked = {$: 7};
var $feathericons$elm_feather$FeatherIcons$chevronLeft = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'chevron-left',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$polyline,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$points('15 18 9 12 15 6')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$chevronRight = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'chevron-right',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$polyline,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$points('9 18 15 12 9 6')
				]),
			_List_Nil)
		]));
var $mdgriffith$elm_ui$Element$Font$bold = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontWeight, $mdgriffith$elm_ui$Internal$Style$classes.f0);
var $author$project$Ui$h1 = F2(
	function (child, isPhone) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Region$heading(1),
					$mdgriffith$elm_ui$Element$Font$size(
					isPhone ? 18 : 24),
					$mdgriffith$elm_ui$Element$Font$bold
				]),
			child);
	});
var $feathericons$elm_feather$FeatherIcons$withSize = F2(
	function (size, _v0) {
		var attrs = _v0.aW;
		var src = _v0.hv;
		return {
			aW: _Utils_update(
				attrs,
				{hq: size}),
			hv: src
		};
	});
var $author$project$Page$Competition$viewMatchdayButton = F3(
	function (icon, msg, disabled) {
		var styledIcon = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_Nil,
			$mdgriffith$elm_ui$Element$html(
				A2(
					$feathericons$elm_feather$FeatherIcons$toHtml,
					_List_Nil,
					A2($feathericons$elm_feather$FeatherIcons$withSize, 28, icon))));
		return disabled ? A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$gray100)
				]),
			styledIcon) : A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_List_Nil,
			{
				dG: styledIcon,
				e$: $elm$core$Maybe$Just(msg)
			});
	});
var $author$project$Page$Competition$viewMatchday = F6(
	function (device, time, tz, authUser, maybeMatchday, md) {
		var isPhone = function () {
			var _v1 = device.ga;
			if (!_v1) {
				return true;
			} else {
				return false;
			}
		}();
		var displayedMatchday = function () {
			if (maybeMatchday.$ === 1) {
				return md.c0;
			} else {
				var matchday = maybeMatchday.a;
				return matchday;
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(
					isPhone ? 20 : 40)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(10)
						]),
					_List_fromArray(
						[
							A3($author$project$Page$Competition$viewMatchdayButton, $feathericons$elm_feather$FeatherIcons$chevronLeft, $author$project$Page$Competition$PreviousMatchdayClicked, displayedMatchday <= 1),
							A2(
							$author$project$Ui$h1,
							$mdgriffith$elm_ui$Element$text(
								'Matchday ' + $elm$core$String$fromInt(displayedMatchday)),
							isPhone),
							A3($author$project$Page$Competition$viewMatchdayButton, $feathericons$elm_feather$FeatherIcons$chevronRight, $author$project$Page$Competition$NextMatchdayClicked, displayedMatchday >= 38)
						])),
					A6($author$project$Page$Competition$viewMatchTable, device, time, tz, authUser, md.cz, md.c1)
				]));
	});
var $author$project$Page$Competition$calculateCumulativePoints = F2(
	function (range, up) {
		var userCumulativeProgression = A2(
			$elm$core$List$map,
			function (_v0) {
				var x = _v0.a;
				var y = _v0.b;
				return _Utils_Tuple2(x, y);
			},
			A3(
				$elm$core$List$map2,
				$elm$core$Tuple$pair,
				range,
				$elm$core$List$reverse(
					A3(
						$elm$core$List$foldl,
						F2(
							function (i, l) {
								var previousPoints = A2(
									$elm$core$Maybe$withDefault,
									0,
									$elm$core$List$head(l));
								var matchdayPoints = A2(
									$elm$core$Maybe$withDefault,
									0,
									A2($elm$core$Dict$get, i, up.bh));
								var sum = matchdayPoints + previousPoints;
								return A2($elm$core$List$cons, sum, l);
							}),
						_List_Nil,
						range))));
		return A2(
			$elm$core$Maybe$map,
			function (user) {
				return _Utils_Tuple2(user, userCumulativeProgression);
			},
			function ($) {
				return $.c7;
			}(up));
	});
var $avh4$elm_color$Color$RgbaSpace = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $avh4$elm_color$Color$scaleFrom255 = function (c) {
	return c / 255;
};
var $avh4$elm_color$Color$rgb255 = F3(
	function (r, g, b) {
		return A4(
			$avh4$elm_color$Color$RgbaSpace,
			$avh4$elm_color$Color$scaleFrom255(r),
			$avh4$elm_color$Color$scaleFrom255(g),
			$avh4$elm_color$Color$scaleFrom255(b),
			1.0);
	});
var $terezka$line_charts$LineChart$Colors$blue = A3($avh4$elm_color$Color$rgb255, 3, 169, 244);
var $terezka$line_charts$LineChart$Colors$cyan = A3($avh4$elm_color$Color$rgb255, 0, 229, 255);
var $terezka$line_charts$LineChart$Colors$gold = A3($avh4$elm_color$Color$rgb255, 205, 145, 60);
var $terezka$line_charts$LineChart$Colors$green = A3($avh4$elm_color$Color$rgb255, 67, 160, 71);
var $terezka$line_charts$LineChart$Colors$pink = A3($avh4$elm_color$Color$rgb255, 245, 105, 215);
var $terezka$line_charts$LineChart$Colors$purple = A3($avh4$elm_color$Color$rgb255, 156, 39, 176);
var $terezka$line_charts$LineChart$Colors$red = A3($avh4$elm_color$Color$rgb255, 216, 27, 96);
var $terezka$line_charts$LineChart$Colors$rust = A3($avh4$elm_color$Color$rgb255, 205, 102, 51);
var $terezka$line_charts$LineChart$Colors$strongBlue = A3($avh4$elm_color$Color$rgb255, 89, 51, 204);
var $terezka$line_charts$LineChart$Colors$teal = A3($avh4$elm_color$Color$rgb255, 29, 233, 182);
var $author$project$Page$Competition$graphColors = _List_fromArray(
	[$terezka$line_charts$LineChart$Colors$blue, $terezka$line_charts$LineChart$Colors$cyan, $terezka$line_charts$LineChart$Colors$gold, $terezka$line_charts$LineChart$Colors$green, $terezka$line_charts$LineChart$Colors$pink, $terezka$line_charts$LineChart$Colors$purple, $terezka$line_charts$LineChart$Colors$red, $terezka$line_charts$LineChart$Colors$rust, $terezka$line_charts$LineChart$Colors$strongBlue, $terezka$line_charts$LineChart$Colors$teal]);
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $terezka$line_charts$Internal$Line$Series = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Line$SeriesConfig = F5(
	function (color, shape, dashing, label, data) {
		return {ge: color, er: dashing, w: data, dG: label, fk: shape};
	});
var $terezka$line_charts$Internal$Line$line = F4(
	function (color_, shape_, label_, data_) {
		return A5($terezka$line_charts$Internal$Line$SeriesConfig, color_, shape_, _List_Nil, label_, data_);
	});
var $terezka$line_charts$LineChart$line = $terezka$line_charts$Internal$Line$line;
var $author$project$Page$Competition$RaceChartHovered = function (a) {
	return {$: 5, a: a};
};
var $terezka$line_charts$Internal$Dots$Config = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Dots$custom = function (style_) {
	return {
		dE: function (_v0) {
			return style_;
		},
		dI: function (_v1) {
			return style_;
		}
	};
};
var $terezka$line_charts$LineChart$Dots$custom = $terezka$line_charts$Internal$Dots$custom;
var $terezka$line_charts$Internal$Area$None = {$: 0};
var $terezka$line_charts$Internal$Area$none = $terezka$line_charts$Internal$Area$None;
var $terezka$line_charts$LineChart$Area$default = $terezka$line_charts$Internal$Area$none;
var $terezka$line_charts$Internal$Axis$Intersection$Config = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Data$Point = F2(
	function (x, y) {
		return {h7: x, h8: y};
	});
var $terezka$line_charts$Internal$Axis$Intersection$custom = F2(
	function (toX, toY) {
		return function (_v0) {
			var x = _v0.h7;
			var y = _v0.h8;
			return A2(
				$terezka$line_charts$Internal$Data$Point,
				toX(x),
				toY(y));
		};
	});
var $terezka$line_charts$Internal$Axis$Intersection$default = A2(
	$terezka$line_charts$Internal$Axis$Intersection$custom,
	function ($) {
		return $.W;
	},
	function ($) {
		return $.W;
	});
var $terezka$line_charts$LineChart$Axis$Intersection$default = $terezka$line_charts$Internal$Axis$Intersection$default;
var $terezka$line_charts$LineChart$Colors$grayLightest = A3($avh4$elm_color$Color$rgb255, 243, 243, 243);
var $terezka$line_charts$Internal$Grid$Lines = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $terezka$line_charts$Internal$Grid$lines = $terezka$line_charts$Internal$Grid$Lines;
var $terezka$line_charts$Internal$Grid$default = A2($terezka$line_charts$Internal$Grid$lines, 1, $terezka$line_charts$LineChart$Colors$grayLightest);
var $terezka$line_charts$LineChart$Grid$default = $terezka$line_charts$Internal$Grid$default;
var $terezka$line_charts$Internal$Legends$Grouped = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$svg$Svg$g = $elm$svg$Svg$trustedNode('g');
var $elm$svg$Svg$Attributes$style = _VirtualDom_attribute('style');
var $elm$svg$Svg$text = $elm$virtual_dom$VirtualDom$text;
var $elm$svg$Svg$text_ = $elm$svg$Svg$trustedNode('text');
var $elm$svg$Svg$tspan = $elm$svg$Svg$trustedNode('tspan');
var $terezka$line_charts$Internal$Svg$label = F2(
	function (color, string) {
		return A2(
			$elm$svg$Svg$text_,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$fill(color),
					$elm$svg$Svg$Attributes$style('pointer-events: none;')
				]),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$tspan,
					_List_Nil,
					_List_fromArray(
						[
							$elm$svg$Svg$text(string)
						]))
				]));
	});
var $terezka$line_charts$Internal$Svg$Transfrom = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $terezka$line_charts$Internal$Svg$offset = F2(
	function (x, y) {
		return A2($terezka$line_charts$Internal$Svg$Transfrom, x, y);
	});
var $terezka$line_charts$Internal$Svg$addPosition = F2(
	function (_v0, _v1) {
		var x = _v0.a;
		var y = _v0.b;
		var xf = _v1.a;
		var yf = _v1.b;
		return A2($terezka$line_charts$Internal$Svg$Transfrom, xf + x, yf + y);
	});
var $terezka$line_charts$Internal$Svg$toPosition = A2(
	$elm$core$List$foldr,
	$terezka$line_charts$Internal$Svg$addPosition,
	A2($terezka$line_charts$Internal$Svg$Transfrom, 0, 0));
var $elm$svg$Svg$Attributes$transform = _VirtualDom_attribute('transform');
var $terezka$line_charts$Internal$Svg$transform = function (translations) {
	var _v0 = $terezka$line_charts$Internal$Svg$toPosition(translations);
	var x = _v0.a;
	var y = _v0.b;
	return $elm$svg$Svg$Attributes$transform(
		'translate(' + ($elm$core$String$fromFloat(x) + (', ' + ($elm$core$String$fromFloat(y) + ')'))));
};
var $terezka$line_charts$Internal$Legends$defaultLegend = F2(
	function (index, _v0) {
		var sample = _v0.dX;
		var label = _v0.dG;
		return A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__legend'),
					$terezka$line_charts$Internal$Svg$transform(
					_List_fromArray(
						[
							A2($terezka$line_charts$Internal$Svg$offset, 20, index * 20)
						]))
				]),
			_List_fromArray(
				[
					sample,
					A2(
					$elm$svg$Svg$g,
					_List_fromArray(
						[
							$terezka$line_charts$Internal$Svg$transform(
							_List_fromArray(
								[
									A2($terezka$line_charts$Internal$Svg$offset, 40, 4)
								]))
						]),
					_List_fromArray(
						[
							A2($terezka$line_charts$Internal$Svg$label, 'inherit', label)
						]))
				]));
	});
var $terezka$line_charts$Internal$Coordinate$lengthX = function (system) {
	return A2($elm$core$Basics$max, 1, (system.c3.hq.fA - system.c3.c5.dH) - system.c3.c5.dV);
};
var $terezka$line_charts$Internal$Coordinate$reachX = function (system) {
	var diff = system.h7.V - system.h7.W;
	return (diff > 0) ? diff : 1;
};
var $terezka$line_charts$LineChart$Coordinate$scaleSvgX = F2(
	function (system, value) {
		return (value * $terezka$line_charts$Internal$Coordinate$lengthX(system)) / $terezka$line_charts$Internal$Coordinate$reachX(system);
	});
var $terezka$line_charts$LineChart$Coordinate$toSvgX = F2(
	function (system, value) {
		return A2($terezka$line_charts$LineChart$Coordinate$scaleSvgX, system, value - system.h7.W) + system.c3.c5.dH;
	});
var $terezka$line_charts$Internal$Coordinate$lengthY = function (system) {
	return A2($elm$core$Basics$max, 1, (system.c3.hq.eD - system.c3.c5.dn) - system.c3.c5.d6);
};
var $terezka$line_charts$Internal$Coordinate$reachY = function (system) {
	var diff = system.h8.V - system.h8.W;
	return (diff > 0) ? diff : 1;
};
var $terezka$line_charts$LineChart$Coordinate$scaleSvgY = F2(
	function (system, value) {
		return (value * $terezka$line_charts$Internal$Coordinate$lengthY(system)) / $terezka$line_charts$Internal$Coordinate$reachY(system);
	});
var $terezka$line_charts$LineChart$Coordinate$toSvgY = F2(
	function (system, value) {
		return A2($terezka$line_charts$LineChart$Coordinate$scaleSvgY, system, system.h8.V - value) + system.c3.c5.d6;
	});
var $terezka$line_charts$Internal$Svg$move = F3(
	function (system, x, y) {
		return A2(
			$terezka$line_charts$Internal$Svg$Transfrom,
			A2($terezka$line_charts$LineChart$Coordinate$toSvgX, system, x),
			A2($terezka$line_charts$LineChart$Coordinate$toSvgY, system, y));
	});
var $terezka$line_charts$Internal$Legends$defaultLegends = F8(
	function (toX, toY, offsetX, offsetY, hovered, _arguments, system, legends) {
		return A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__legends'),
					$terezka$line_charts$Internal$Svg$transform(
					_List_fromArray(
						[
							A3(
							$terezka$line_charts$Internal$Svg$move,
							system,
							toX(system.h7),
							toY(system.h8)),
							A2($terezka$line_charts$Internal$Svg$offset, offsetX, offsetY)
						]))
				]),
			A2($elm$core$List$indexedMap, $terezka$line_charts$Internal$Legends$defaultLegend, legends));
	});
var $terezka$line_charts$Internal$Legends$hover = function (data) {
	return A2(
		$terezka$line_charts$Internal$Legends$Grouped,
		30,
		A5(
			$terezka$line_charts$Internal$Legends$defaultLegends,
			function ($) {
				return $.V;
			},
			function ($) {
				return $.V;
			},
			0,
			10,
			data));
};
var $terezka$line_charts$Internal$Legends$default = $terezka$line_charts$Internal$Legends$hover(_List_Nil);
var $terezka$line_charts$LineChart$Legends$default = $terezka$line_charts$Internal$Legends$default;
var $terezka$line_charts$Internal$Line$Config = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Line$Style = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Line$style = F2(
	function (width, color_) {
		return {ge: color_, fA: width};
	});
var $terezka$line_charts$Internal$Line$default = function (_v0) {
	return A2($terezka$line_charts$Internal$Line$style, 1, $elm$core$Basics$identity);
};
var $terezka$line_charts$LineChart$Line$default = $terezka$line_charts$Internal$Line$default;
var $terezka$line_charts$Internal$Dots$Empty = function (a) {
	return {$: 0, a: a};
};
var $terezka$line_charts$Internal$Dots$Style = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Dots$style = F2(
	function (radius, variety) {
		return {dT: radius, d8: variety};
	});
var $terezka$line_charts$Internal$Dots$empty = F2(
	function (radius, border) {
		return A2(
			$terezka$line_charts$Internal$Dots$style,
			radius,
			$terezka$line_charts$Internal$Dots$Empty(border));
	});
var $terezka$line_charts$LineChart$Dots$empty = $terezka$line_charts$Internal$Dots$empty;
var $author$project$Page$Competition$formatX = A2(
	$elm$core$Basics$composeR,
	$elm$core$Tuple$first,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$String$fromFloat,
		$elm$core$Basics$append('Matchday: ')));
var $author$project$Page$Competition$formatY = A2($elm$core$Basics$composeR, $elm$core$Tuple$second, $elm$core$String$fromFloat);
var $terezka$line_charts$Internal$Events$Config = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Events$custom = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Events$Decoder = $elm$core$Basics$identity;
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $terezka$line_charts$Internal$Events$distanceX = F3(
	function (system, searched, dot) {
		return $elm$core$Basics$abs(
			A2($terezka$line_charts$LineChart$Coordinate$toSvgX, system, dot.h7) - A2($terezka$line_charts$LineChart$Coordinate$toSvgX, system, searched.h7));
	});
var $terezka$line_charts$Internal$Events$getNearestXHelp = F3(
	function (points, system, searched) {
		var distanceX_ = A2($terezka$line_charts$Internal$Events$distanceX, system, searched);
		var getClosest = F2(
			function (point, allClosest) {
				var _v0 = $elm$core$List$head(allClosest);
				if (!_v0.$) {
					var closest = _v0.a;
					return _Utils_eq(closest.hc.h7, point.hc.h7) ? A2($elm$core$List$cons, point, allClosest) : ((_Utils_cmp(
						distanceX_(closest.hc),
						distanceX_(point.hc)) > 0) ? _List_fromArray(
						[point]) : allClosest);
				} else {
					return _List_fromArray(
						[point]);
				}
			});
		return A3($elm$core$List$foldl, getClosest, _List_Nil, points);
	});
var $terezka$line_charts$LineChart$Coordinate$scaleDataX = F2(
	function (system, value) {
		return (value * $terezka$line_charts$Internal$Coordinate$reachX(system)) / $terezka$line_charts$Internal$Coordinate$lengthX(system);
	});
var $terezka$line_charts$LineChart$Coordinate$toDataX = F2(
	function (system, value) {
		return system.h7.W + A2($terezka$line_charts$LineChart$Coordinate$scaleDataX, system, value - system.c3.c5.dH);
	});
var $terezka$line_charts$LineChart$Coordinate$scaleDataY = F2(
	function (system, value) {
		return (value * $terezka$line_charts$Internal$Coordinate$reachY(system)) / $terezka$line_charts$Internal$Coordinate$lengthY(system);
	});
var $terezka$line_charts$LineChart$Coordinate$toDataY = F2(
	function (system, value) {
		return system.h8.V - A2($terezka$line_charts$LineChart$Coordinate$scaleDataY, system, value - system.c3.c5.d6);
	});
var $terezka$line_charts$LineChart$Coordinate$toData = F2(
	function (system, point) {
		return {
			h7: A2($terezka$line_charts$LineChart$Coordinate$toDataX, system, point.h7),
			h8: A2($terezka$line_charts$LineChart$Coordinate$toDataY, system, point.h8)
		};
	});
var $terezka$line_charts$Internal$Events$getNearestX = F3(
	function (points, system, searchedSvg) {
		var searched = A2($terezka$line_charts$LineChart$Coordinate$toData, system, searchedSvg);
		return A2(
			$elm$core$List$map,
			function ($) {
				return $.y;
			},
			A3($terezka$line_charts$Internal$Events$getNearestXHelp, points, system, searched));
	});
var $terezka$line_charts$Internal$Events$Event = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$svg$Svg$Events$on = $elm$html$Html$Events$on;
var $terezka$line_charts$Internal$Events$onMouseLeave = function (msg) {
	return A2(
		$terezka$line_charts$Internal$Events$Event,
		false,
		F2(
			function (_v0, _v1) {
				return A2(
					$elm$svg$Svg$Events$on,
					'mouseleave',
					$elm$json$Json$Decode$succeed(msg));
			}));
};
var $terezka$line_charts$Internal$Events$Options = F3(
	function (stopPropagation, preventDefault, catchOutsideChart) {
		return {en: catchOutsideChart, dS: preventDefault, d5: stopPropagation};
	});
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $elm$svg$Svg$Events$custom = $elm$html$Html$Events$custom;
var $terezka$line_charts$Internal$Events$map = F2(
	function (f, _v0) {
		var a = _v0;
		return F3(
			function (ps, s, p) {
				return f(
					A3(a, ps, s, p));
			});
	});
var $terezka$line_charts$LineChart$Coordinate$Point = F2(
	function (x, y) {
		return {h7: x, h8: y};
	});
var $debois$elm_dom$DOM$offsetHeight = A2($elm$json$Json$Decode$field, 'offsetHeight', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$offsetWidth = A2($elm$json$Json$Decode$field, 'offsetWidth', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$offsetLeft = A2($elm$json$Json$Decode$field, 'offsetLeft', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$offsetParent = F2(
	function (x, decoder) {
		return $elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$json$Json$Decode$field,
					'offsetParent',
					$elm$json$Json$Decode$null(x)),
					A2($elm$json$Json$Decode$field, 'offsetParent', decoder)
				]));
	});
var $debois$elm_dom$DOM$offsetTop = A2($elm$json$Json$Decode$field, 'offsetTop', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$scrollLeft = A2($elm$json$Json$Decode$field, 'scrollLeft', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$scrollTop = A2($elm$json$Json$Decode$field, 'scrollTop', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$position = F2(
	function (x, y) {
		return A2(
			$elm$json$Json$Decode$andThen,
			function (_v0) {
				var x_ = _v0.a;
				var y_ = _v0.b;
				return A2(
					$debois$elm_dom$DOM$offsetParent,
					_Utils_Tuple2(x_, y_),
					A2($debois$elm_dom$DOM$position, x_, y_));
			},
			A5(
				$elm$json$Json$Decode$map4,
				F4(
					function (scrollLeftP, scrollTopP, offsetLeftP, offsetTopP) {
						return _Utils_Tuple2((x + offsetLeftP) - scrollLeftP, (y + offsetTopP) - scrollTopP);
					}),
				$debois$elm_dom$DOM$scrollLeft,
				$debois$elm_dom$DOM$scrollTop,
				$debois$elm_dom$DOM$offsetLeft,
				$debois$elm_dom$DOM$offsetTop));
	});
var $debois$elm_dom$DOM$boundingClientRect = A4(
	$elm$json$Json$Decode$map3,
	F3(
		function (_v0, width, height) {
			var x = _v0.a;
			var y = _v0.b;
			return {eD: height, dH: x, d6: y, fA: width};
		}),
	A2($debois$elm_dom$DOM$position, 0, 0),
	$debois$elm_dom$DOM$offsetWidth,
	$debois$elm_dom$DOM$offsetHeight);
var $elm$json$Json$Decode$lazy = function (thunk) {
	return A2(
		$elm$json$Json$Decode$andThen,
		thunk,
		$elm$json$Json$Decode$succeed(0));
};
var $debois$elm_dom$DOM$parentElement = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'parentElement', decoder);
};
function $terezka$line_charts$Internal$Events$cyclic$position() {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$debois$elm_dom$DOM$boundingClientRect,
				$elm$json$Json$Decode$lazy(
				function (_v0) {
					return $debois$elm_dom$DOM$parentElement(
						$terezka$line_charts$Internal$Events$cyclic$position());
				})
			]));
}
var $terezka$line_charts$Internal$Events$position = $terezka$line_charts$Internal$Events$cyclic$position();
$terezka$line_charts$Internal$Events$cyclic$position = function () {
	return $terezka$line_charts$Internal$Events$position;
};
var $debois$elm_dom$DOM$target = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'target', decoder);
};
var $terezka$line_charts$Internal$Events$toJsonDecoder = F4(
	function (options, data, system, _v0) {
		var decoder = _v0;
		var withOptions = function (msg) {
			return {gZ: msg, dS: options.dS, d5: options.d5};
		};
		var handle = F3(
			function (mouseX, mouseY, _v1) {
				var left = _v1.dH;
				var top = _v1.d6;
				var height = _v1.eD;
				var width = _v1.fA;
				var y = mouseY - top;
				var x = mouseX - left;
				var widthPercent = width / system.c3.hq.fA;
				var newSize = {eD: height, fA: width};
				var heightPercent = height / system.c3.hq.eD;
				var newMargin = {dn: system.c3.c5.dn * heightPercent, dH: system.c3.c5.dH * widthPercent, dV: system.c3.c5.dV * widthPercent, d6: system.c3.c5.d6 * heightPercent};
				var newSystem = _Utils_update(
					system,
					{
						c3: {c5: newMargin, hq: newSize}
					});
				return A3(
					decoder,
					data,
					newSystem,
					A2($terezka$line_charts$LineChart$Coordinate$Point, x, y));
			});
		return A2(
			$elm$json$Json$Decode$map,
			withOptions,
			A4(
				$elm$json$Json$Decode$map3,
				handle,
				A2($elm$json$Json$Decode$field, 'pageX', $elm$json$Json$Decode$float),
				A2($elm$json$Json$Decode$field, 'pageY', $elm$json$Json$Decode$float),
				$debois$elm_dom$DOM$target($terezka$line_charts$Internal$Events$position)));
	});
var $terezka$line_charts$Internal$Events$on = F3(
	function (event, toMsg, decoder) {
		return A2(
			$terezka$line_charts$Internal$Events$Event,
			false,
			F2(
				function (data, system) {
					var defaultOptions = A3($terezka$line_charts$Internal$Events$Options, false, false, false);
					return A2(
						$elm$svg$Svg$Events$custom,
						event,
						A4(
							$terezka$line_charts$Internal$Events$toJsonDecoder,
							defaultOptions,
							data,
							system,
							A2($terezka$line_charts$Internal$Events$map, toMsg, decoder)));
				}));
	});
var $terezka$line_charts$Internal$Events$onMouseMove = $terezka$line_charts$Internal$Events$on('mousemove');
var $terezka$line_charts$Internal$Events$hoverMany = function (msg) {
	return $terezka$line_charts$Internal$Events$custom(
		_List_fromArray(
			[
				A2($terezka$line_charts$Internal$Events$onMouseMove, msg, $terezka$line_charts$Internal$Events$getNearestX),
				$terezka$line_charts$Internal$Events$onMouseLeave(
				msg(_List_Nil))
			]));
};
var $terezka$line_charts$LineChart$Events$hoverMany = $terezka$line_charts$Internal$Events$hoverMany;
var $terezka$line_charts$Internal$Junk$Config = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Junk$find = F2(
	function (hovered, data) {
		find:
		while (true) {
			if (!hovered.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = hovered.a;
				var rest = hovered.b;
				if (A2(
					$elm$core$List$any,
					$elm$core$Basics$eq(first),
					data)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$hovered = rest,
						$temp$data = data;
					hovered = $temp$hovered;
					data = $temp$data;
					continue find;
				}
			}
		}
	});
var $terezka$line_charts$Internal$Junk$shouldFlip = F2(
	function (system, x) {
		return _Utils_cmp(x - system.h7.W, system.h7.V - x) > 0;
	});
var $terezka$line_charts$Internal$Junk$standardStyles = _List_fromArray(
	[
		_Utils_Tuple2('padding', '5px'),
		_Utils_Tuple2('min-width', '100px'),
		_Utils_Tuple2('background', 'rgba(255,255,255,0.8)'),
		_Utils_Tuple2('border', '1px solid #d3d3d3'),
		_Utils_Tuple2('border-radius', '5px'),
		_Utils_Tuple2('pointer-events', 'none')
	]);
var $terezka$line_charts$Internal$Junk$hoverAt = F5(
	function (system, x, y, styles, view) {
		var yPercentage = (A2($terezka$line_charts$LineChart$Coordinate$toSvgY, system, y) * 100) / system.c3.hq.eD;
		var space = A2($terezka$line_charts$Internal$Junk$shouldFlip, system, x) ? (-15) : 15;
		var xPercentage = ((A2($terezka$line_charts$LineChart$Coordinate$toSvgX, system, x) + space) * 100) / system.c3.hq.fA;
		var positionStyles = _List_fromArray(
			[
				_Utils_Tuple2(
				'left',
				$elm$core$String$fromFloat(xPercentage) + '%'),
				_Utils_Tuple2(
				'top',
				$elm$core$String$fromFloat(yPercentage) + '%'),
				_Utils_Tuple2('margin-right', '-400px'),
				_Utils_Tuple2('position', 'absolute'),
				A2($terezka$line_charts$Internal$Junk$shouldFlip, system, x) ? _Utils_Tuple2('transform', 'translateX(-100%)') : _Utils_Tuple2('transform', 'translateX(0)')
			]);
		var containerStyles = _Utils_ap(
			$terezka$line_charts$Internal$Junk$standardStyles,
			_Utils_ap(positionStyles, styles));
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$map,
				function (_v0) {
					var p = _v0.a;
					var v = _v0.b;
					return A2($elm$html$Html$Attributes$style, p, v);
				},
				containerStyles),
			view);
	});
var $terezka$line_charts$Internal$Junk$middle = F2(
	function (r, system) {
		var range = r(system);
		return range.W + ((range.V - range.W) / 2);
	});
var $terezka$line_charts$Internal$Junk$hover = F3(
	function (system, x, styles) {
		var y = A2(
			$terezka$line_charts$Internal$Junk$middle,
			function ($) {
				return $.h8;
			},
			system);
		var containerStyles = _Utils_ap(
			_List_fromArray(
				[
					A2($terezka$line_charts$Internal$Junk$shouldFlip, system, x) ? _Utils_Tuple2('transform', 'translate(-100%, -50%)') : _Utils_Tuple2('transform', 'translate(0, -50%)')
				]),
			styles);
		return A4($terezka$line_charts$Internal$Junk$hoverAt, system, x, y, containerStyles);
	});
var $avh4$elm_color$Color$toCssString = function (_v0) {
	var r = _v0.a;
	var g = _v0.b;
	var b = _v0.c;
	var a = _v0.d;
	var roundTo = function (x) {
		return $elm$core$Basics$round(x * 1000) / 1000;
	};
	var pct = function (x) {
		return $elm$core$Basics$round(x * 10000) / 100;
	};
	return $elm$core$String$concat(
		_List_fromArray(
			[
				'rgba(',
				$elm$core$String$fromFloat(
				pct(r)),
				'%,',
				$elm$core$String$fromFloat(
				pct(g)),
				'%,',
				$elm$core$String$fromFloat(
				pct(b)),
				'%,',
				$elm$core$String$fromFloat(
				roundTo(a)),
				')'
			]));
};
var $terezka$line_charts$Internal$Junk$viewHeader = $elm$html$Html$p(
	_List_fromArray(
		[
			A2($elm$html$Html$Attributes$style, 'margin-top', '3px'),
			A2($elm$html$Html$Attributes$style, 'margin-bottom', '5px'),
			A2($elm$html$Html$Attributes$style, 'padding', '3px'),
			A2($elm$html$Html$Attributes$style, 'border-bottom', '1px solid rgb(163, 163, 163)')
		]));
var $terezka$line_charts$Internal$Utils$viewMaybe = F2(
	function (a, view) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$svg$Svg$text(''),
			A2($elm$core$Maybe$map, view, a));
	});
var $terezka$line_charts$Internal$Junk$viewRow = F3(
	function (color, label, value) {
		return A2(
			$elm$html$Html$p,
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'margin', '3px'),
					A2($elm$html$Html$Attributes$style, 'color', color)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(label + (': ' + value))
				]));
	});
var $terezka$line_charts$Internal$Junk$hoverManyHtml = F8(
	function (system, toX, toY, formatX, formatY, first, hovered, series) {
		var x = A2(
			$elm$core$Maybe$withDefault,
			A2(
				$terezka$line_charts$Internal$Junk$middle,
				function ($) {
					return $.h7;
				},
				system),
			toX(first));
		var viewValue = function (_v0) {
			var color = _v0.a;
			var label = _v0.b;
			var data = _v0.c;
			return A2(
				$terezka$line_charts$Internal$Utils$viewMaybe,
				A2($terezka$line_charts$Internal$Junk$find, hovered, data),
				function (hovered_) {
					return A3(
						$terezka$line_charts$Internal$Junk$viewRow,
						$avh4$elm_color$Color$toCssString(color),
						label,
						formatY(hovered_));
				});
		};
		return A4(
			$terezka$line_charts$Internal$Junk$hover,
			system,
			x,
			_List_Nil,
			A2(
				$elm$core$List$cons,
				$terezka$line_charts$Internal$Junk$viewHeader(
					_List_fromArray(
						[
							$elm$html$Html$text(
							formatX(first))
						])),
				A2($elm$core$List$map, viewValue, series)));
	});
var $terezka$line_charts$Internal$Junk$Layers = F3(
	function (below, above, html) {
		return {fH: above, fZ: below, gB: html};
	});
var $terezka$line_charts$Internal$Junk$none = F4(
	function (_v0, _v1, _v2, _v3) {
		return A3($terezka$line_charts$Internal$Junk$Layers, _List_Nil, _List_Nil, _List_Nil);
	});
var $terezka$line_charts$Internal$Utils$concat = F3(
	function (first, second, third) {
		return _Utils_ap(
			first,
			_Utils_ap(second, third));
	});
var $terezka$line_charts$LineChart$Colors$gray = A3($avh4$elm_color$Color$rgb255, 163, 163, 163);
var $terezka$line_charts$Internal$Path$Line = function (a) {
	return {$: 1, a: a};
};
var $terezka$line_charts$Internal$Path$Move = function (a) {
	return {$: 0, a: a};
};
var $terezka$line_charts$Internal$Path$join = function (commands) {
	return A2($elm$core$String$join, ' ', commands);
};
var $terezka$line_charts$Internal$Path$bool = function (bool_) {
	return bool_ ? '1' : '0';
};
var $terezka$line_charts$Internal$Path$point = function (point_) {
	return $elm$core$String$fromFloat(point_.h7) + (' ' + $elm$core$String$fromFloat(point_.h8));
};
var $terezka$line_charts$Internal$Path$points = function (points_) {
	return A2(
		$elm$core$String$join,
		',',
		A2($elm$core$List$map, $terezka$line_charts$Internal$Path$point, points_));
};
var $terezka$line_charts$Internal$Path$toString = function (command) {
	switch (command.$) {
		case 9:
			return 'Z';
		case 0:
			var p = command.a;
			return 'M' + $terezka$line_charts$Internal$Path$point(p);
		case 1:
			var p = command.a;
			return 'L' + $terezka$line_charts$Internal$Path$point(p);
		case 2:
			var x = command.a;
			return 'H' + $elm$core$String$fromFloat(x);
		case 3:
			var y = command.a;
			return 'V' + $elm$core$String$fromFloat(y);
		case 4:
			var c1 = command.a;
			var c2 = command.b;
			var p = command.c;
			return 'C' + $terezka$line_charts$Internal$Path$points(
				_List_fromArray(
					[c1, c2, p]));
		case 5:
			var c1 = command.a;
			var p = command.b;
			return 'Q' + $terezka$line_charts$Internal$Path$points(
				_List_fromArray(
					[c1, p]));
		case 6:
			var c1 = command.a;
			var p = command.b;
			return 'Q' + $terezka$line_charts$Internal$Path$points(
				_List_fromArray(
					[c1, p]));
		case 7:
			var p = command.a;
			return 'T' + $terezka$line_charts$Internal$Path$point(p);
		default:
			var rx = command.a;
			var ry = command.b;
			var xAxisRotation = command.c;
			var largeArcFlag = command.d;
			var sweepFlag = command.e;
			var p = command.f;
			return 'A' + $terezka$line_charts$Internal$Path$join(
				_List_fromArray(
					[
						$elm$core$String$fromFloat(rx),
						$elm$core$String$fromFloat(ry),
						$elm$core$String$fromInt(xAxisRotation),
						$terezka$line_charts$Internal$Path$bool(largeArcFlag),
						$terezka$line_charts$Internal$Path$bool(sweepFlag),
						$terezka$line_charts$Internal$Path$point(p)
					]));
	}
};
var $terezka$line_charts$Internal$Path$Arc = F6(
	function (a, b, c, d, e, f) {
		return {$: 8, a: a, b: b, c: c, d: d, e: e, f: f};
	});
var $terezka$line_charts$Internal$Path$Close = {$: 9};
var $terezka$line_charts$Internal$Path$CubicBeziers = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $terezka$line_charts$Internal$Path$CubicBeziersShort = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $terezka$line_charts$Internal$Path$Horizontal = function (a) {
	return {$: 2, a: a};
};
var $terezka$line_charts$Internal$Path$QuadraticBeziers = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $terezka$line_charts$Internal$Path$QuadraticBeziersShort = function (a) {
	return {$: 7, a: a};
};
var $terezka$line_charts$Internal$Path$Vertical = function (a) {
	return {$: 3, a: a};
};
var $terezka$line_charts$LineChart$Coordinate$toSvg = F2(
	function (system, point) {
		return {
			h7: A2($terezka$line_charts$LineChart$Coordinate$toSvgX, system, point.h7),
			h8: A2($terezka$line_charts$LineChart$Coordinate$toSvgY, system, point.h8)
		};
	});
var $terezka$line_charts$Internal$Path$translate = F2(
	function (system, command) {
		switch (command.$) {
			case 0:
				var p = command.a;
				return $terezka$line_charts$Internal$Path$Move(
					A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, p));
			case 1:
				var p = command.a;
				return $terezka$line_charts$Internal$Path$Line(
					A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, p));
			case 2:
				var x = command.a;
				return $terezka$line_charts$Internal$Path$Horizontal(
					A2($terezka$line_charts$LineChart$Coordinate$toSvgX, system, x));
			case 3:
				var y = command.a;
				return $terezka$line_charts$Internal$Path$Vertical(
					A2($terezka$line_charts$LineChart$Coordinate$toSvgY, system, y));
			case 4:
				var c1 = command.a;
				var c2 = command.b;
				var p = command.c;
				return A3(
					$terezka$line_charts$Internal$Path$CubicBeziers,
					A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, c1),
					A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, c2),
					A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, p));
			case 5:
				var c1 = command.a;
				var p = command.b;
				return A2(
					$terezka$line_charts$Internal$Path$CubicBeziersShort,
					A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, c1),
					A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, p));
			case 6:
				var c1 = command.a;
				var p = command.b;
				return A2(
					$terezka$line_charts$Internal$Path$QuadraticBeziers,
					A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, c1),
					A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, p));
			case 7:
				var p = command.a;
				return $terezka$line_charts$Internal$Path$QuadraticBeziersShort(
					A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, p));
			case 8:
				var rx = command.a;
				var ry = command.b;
				var xAxisRotation = command.c;
				var largeArcFlag = command.d;
				var sweepFlag = command.e;
				var p = command.f;
				return A6(
					$terezka$line_charts$Internal$Path$Arc,
					rx,
					ry,
					xAxisRotation,
					largeArcFlag,
					sweepFlag,
					A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, p));
			default:
				return $terezka$line_charts$Internal$Path$Close;
		}
	});
var $terezka$line_charts$Internal$Path$description = F2(
	function (system, commands) {
		return $terezka$line_charts$Internal$Path$join(
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					$terezka$line_charts$Internal$Path$translate(system),
					$terezka$line_charts$Internal$Path$toString),
				commands));
	});
var $terezka$line_charts$Internal$Path$viewPath = function (attributes) {
	return A2($elm$svg$Svg$path, attributes, _List_Nil);
};
var $terezka$line_charts$Internal$Path$view = F3(
	function (system, attributes, commands) {
		return $terezka$line_charts$Internal$Path$viewPath(
			_Utils_ap(
				attributes,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$d(
						A2($terezka$line_charts$Internal$Path$description, system, commands))
					])));
	});
var $terezka$line_charts$Internal$Svg$vertical = F5(
	function (system, userAttributes, x, y1, y2) {
		var attributes = A3(
			$terezka$line_charts$Internal$Utils$concat,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$stroke(
					$avh4$elm_color$Color$toCssString($terezka$line_charts$LineChart$Colors$gray)),
					$elm$svg$Svg$Attributes$style('pointer-events: none;')
				]),
			userAttributes,
			_List_Nil);
		return A3(
			$terezka$line_charts$Internal$Path$view,
			system,
			attributes,
			_List_fromArray(
				[
					$terezka$line_charts$Internal$Path$Move(
					{h7: x, h8: y1}),
					$terezka$line_charts$Internal$Path$Line(
					{h7: x, h8: y1}),
					$terezka$line_charts$Internal$Path$Line(
					{h7: x, h8: y2})
				]));
	});
var $terezka$line_charts$Internal$Svg$verticalGrid = F3(
	function (system, userAttributes, x) {
		var attributes = A3(
			$terezka$line_charts$Internal$Utils$concat,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$stroke(
					$avh4$elm_color$Color$toCssString($terezka$line_charts$LineChart$Colors$gray)),
					$elm$svg$Svg$Attributes$style('pointer-events: none;')
				]),
			userAttributes,
			_List_Nil);
		return A5($terezka$line_charts$Internal$Svg$vertical, system, attributes, x, system.h8.W, system.h8.V);
	});
var $terezka$line_charts$Internal$Junk$hoverMany = F3(
	function (hovered, formatX, formatY) {
		if (!hovered.b) {
			return $terezka$line_charts$Internal$Junk$none;
		} else {
			var first = hovered.a;
			var rest = hovered.b;
			return F4(
				function (series, toX, toY, system) {
					var xValue = A2(
						$elm$core$Maybe$withDefault,
						0,
						toX(first));
					return {
						fH: _List_Nil,
						fZ: _List_fromArray(
							[
								A3($terezka$line_charts$Internal$Svg$verticalGrid, system, _List_Nil, xValue)
							]),
						gB: _List_fromArray(
							[
								A8($terezka$line_charts$Internal$Junk$hoverManyHtml, system, toX, toY, formatX, formatY, first, hovered, series)
							])
					};
				});
		}
	});
var $terezka$line_charts$LineChart$Junk$hoverMany = $terezka$line_charts$Internal$Junk$hoverMany;
var $terezka$line_charts$Internal$Interpolation$Linear = 0;
var $terezka$line_charts$LineChart$Interpolation$linear = 0;
var $terezka$line_charts$Internal$Axis$Title$Config = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Axis$Title$custom = F4(
	function (position, x, y, title) {
		return {
			az: _Utils_Tuple2(x, y),
			db: position,
			h4: title
		};
	});
var $terezka$line_charts$Internal$Axis$Title$atPosition = F3(
	function (position, x, y) {
		return A2(
			$elm$core$Basics$composeL,
			A3($terezka$line_charts$Internal$Axis$Title$custom, position, x, y),
			$terezka$line_charts$Internal$Svg$label('inherit'));
	});
var $terezka$line_charts$Internal$Axis$Title$atAxisMax = function () {
	var position = F2(
		function (data, range) {
			return range.V;
		});
	return $terezka$line_charts$Internal$Axis$Title$atPosition(position);
}();
var $terezka$line_charts$Internal$Axis$Config = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Axis$custom = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Axis$Ticks$Config = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Axis$Ticks$custom = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Axis$Line$Config = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Axis$Line$custom = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Axis$Line$full = function (color) {
	return $terezka$line_charts$Internal$Axis$Line$custom(
		F2(
			function (data, range) {
				return {ge: color, ev: range.V, gr: _List_Nil, d4: range.W, fA: 1};
			}));
};
var $terezka$line_charts$Internal$Axis$Line$default = $terezka$line_charts$Internal$Axis$Line$full($terezka$line_charts$LineChart$Colors$gray);
var $terezka$line_charts$Internal$Axis$Tick$Negative = 0;
var $terezka$line_charts$Internal$Axis$Tick$Config = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Axis$Tick$custom = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Axis$Tick$float = function (n) {
	return $terezka$line_charts$Internal$Axis$Tick$custom(
		{
			ge: $terezka$line_charts$LineChart$Colors$gray,
			dq: 0,
			gx: true,
			dG: $elm$core$Maybe$Just(
				A2(
					$terezka$line_charts$Internal$Svg$label,
					'inherit',
					$elm$core$String$fromFloat(n))),
			dJ: 5,
			db: n,
			fA: 1
		});
};
var $terezka$line_charts$LineChart$Axis$Tick$float = $terezka$line_charts$Internal$Axis$Tick$float;
var $terezka$line_charts$Internal$Axis$Range$Padded = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $terezka$line_charts$Internal$Axis$Range$padded = $terezka$line_charts$Internal$Axis$Range$Padded;
var $terezka$line_charts$Internal$Axis$picky = F4(
	function (pixels_, title_, variable_, ticks_) {
		return $terezka$line_charts$Internal$Axis$custom(
			{
				bB: $terezka$line_charts$Internal$Axis$Line$default,
				bU: pixels_,
				bW: A2($terezka$line_charts$Internal$Axis$Range$padded, 20, 20),
				aU: $terezka$line_charts$Internal$Axis$Ticks$custom(
					F2(
						function (_v0, _v1) {
							return A2($elm$core$List$map, $terezka$line_charts$LineChart$Axis$Tick$float, ticks_);
						})),
				hU: A3($terezka$line_charts$Internal$Axis$Title$atAxisMax, 0, 0, title_),
				b0: A2($elm$core$Basics$composeL, $elm$core$Maybe$Just, variable_)
			});
	});
var $terezka$line_charts$LineChart$Axis$picky = $terezka$line_charts$Internal$Axis$picky;
var $terezka$line_charts$Internal$Container$Margin = F4(
	function (top, right, bottom, left) {
		return {dn: bottom, dH: left, dV: right, d6: top};
	});
var $terezka$line_charts$Internal$Container$Config = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Container$custom = $elm$core$Basics$identity;
var $terezka$line_charts$Internal$Container$Relative = 1;
var $terezka$line_charts$Internal$Container$relative = 1;
var $terezka$line_charts$Internal$Container$responsive = function (id) {
	return $terezka$line_charts$Internal$Container$custom(
		{
			cT: _List_Nil,
			cU: _List_Nil,
			a: id,
			c5: A4($terezka$line_charts$Internal$Container$Margin, 60, 140, 60, 80),
			hq: $terezka$line_charts$Internal$Container$relative
		});
};
var $terezka$line_charts$LineChart$Container$responsive = $terezka$line_charts$Internal$Container$responsive;
var $author$project$Page$Competition$raceChartConfig = F3(
	function (xPoints, yPoints, hinted) {
		return {
			fU: $terezka$line_charts$LineChart$Area$default,
			gh: $terezka$line_charts$LineChart$Container$responsive('race-chart'),
			gp: $terezka$line_charts$LineChart$Dots$custom(
				A2($terezka$line_charts$LineChart$Dots$empty, 5, 1)),
			gr: $terezka$line_charts$LineChart$Events$hoverMany($author$project$Page$Competition$RaceChartHovered),
			gx: $terezka$line_charts$LineChart$Grid$default,
			gM: $terezka$line_charts$LineChart$Interpolation$linear,
			gN: $terezka$line_charts$LineChart$Axis$Intersection$default,
			gT: A3($terezka$line_charts$LineChart$Junk$hoverMany, hinted, $author$project$Page$Competition$formatX, $author$project$Page$Competition$formatY),
			gU: $terezka$line_charts$LineChart$Legends$default,
			gV: $terezka$line_charts$LineChart$Line$default,
			h7: A4($terezka$line_charts$LineChart$Axis$picky, 1270, 'Matchday', $elm$core$Tuple$first, xPoints),
			h8: A4($terezka$line_charts$LineChart$Axis$picky, 450, 'Points', $elm$core$Tuple$second, yPoints)
		};
	});
var $terezka$line_charts$Internal$Dots$Square = 3;
var $terezka$line_charts$LineChart$Dots$square = 3;
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $terezka$line_charts$Internal$Junk$addBelow = F2(
	function (below, layers) {
		return _Utils_update(
			layers,
			{
				fZ: _Utils_ap(below, layers.fZ)
			});
	});
var $elm$svg$Svg$Attributes$x = _VirtualDom_attribute('x');
var $elm$svg$Svg$Attributes$y = _VirtualDom_attribute('y');
var $terezka$line_charts$LineChart$chartAreaAttributes = function (system) {
	return _List_fromArray(
		[
			$elm$svg$Svg$Attributes$x(
			$elm$core$String$fromFloat(system.c3.c5.dH)),
			$elm$svg$Svg$Attributes$y(
			$elm$core$String$fromFloat(system.c3.c5.d6)),
			$elm$svg$Svg$Attributes$width(
			$elm$core$String$fromFloat(
				$terezka$line_charts$Internal$Coordinate$lengthX(system))),
			$elm$svg$Svg$Attributes$height(
			$elm$core$String$fromFloat(
				$terezka$line_charts$Internal$Coordinate$lengthY(system)))
		]);
};
var $elm$svg$Svg$rect = $elm$svg$Svg$trustedNode('rect');
var $terezka$line_charts$Internal$Events$toChartAttributes = F3(
	function (data, system, _v0) {
		var events = _v0;
		var order = function (_v1) {
			var outside = _v1.a;
			var event = _v1.b;
			return outside ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
				A2(event, data, system));
		};
		return A2($elm$core$List$filterMap, order, events);
	});
var $terezka$line_charts$LineChart$chartAreaPlatform = F3(
	function (config, data, system) {
		var attributes = $elm$core$List$concat(
			_List_fromArray(
				[
					_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill('transparent')
					]),
					$terezka$line_charts$LineChart$chartAreaAttributes(system),
					A3($terezka$line_charts$Internal$Events$toChartAttributes, data, system, config.gr)
				]));
		return A2($elm$svg$Svg$rect, attributes, _List_Nil);
	});
var $elm$svg$Svg$clipPath = $elm$svg$Svg$trustedNode('clipPath');
var $elm$svg$Svg$Attributes$id = _VirtualDom_attribute('id');
var $terezka$line_charts$Internal$Utils$toChartAreaId = function (id) {
	return 'chart__chart-area--' + id;
};
var $terezka$line_charts$LineChart$clipPath = function (system) {
	return A2(
		$elm$svg$Svg$clipPath,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$id(
				$terezka$line_charts$Internal$Utils$toChartAreaId(system.a))
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$rect,
				$terezka$line_charts$LineChart$chartAreaAttributes(system),
				_List_Nil)
			]));
};
var $terezka$line_charts$Internal$Line$color = F3(
	function (_v0, _v1, data_) {
		var config = _v0;
		var line_ = _v1;
		var _v2 = config(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.y;
				},
				data_));
		var style_ = _v2;
		return style_.ge(line_.ge);
	});
var $terezka$line_charts$Internal$Container$properties = F2(
	function (f, _v0) {
		var properties_ = _v0;
		return f(properties_);
	});
var $terezka$line_charts$Internal$Container$sizeStyles = F3(
	function (_v0, width, height) {
		var properties_ = _v0;
		var _v1 = properties_.hq;
		if (!_v1) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$Attributes$style,
					'height',
					$elm$core$String$fromFloat(height) + 'px'),
					A2(
					$elm$html$Html$Attributes$style,
					'width',
					$elm$core$String$fromFloat(width) + 'px')
				]);
		} else {
			return _List_Nil;
		}
	});
var $terezka$line_charts$LineChart$container = F4(
	function (config, _v0, junkHtml, plot) {
		var frame = _v0.c3;
		var userAttributes = A2(
			$terezka$line_charts$Internal$Container$properties,
			function ($) {
				return $.cT;
			},
			config.gh);
		var sizeStyles = A3($terezka$line_charts$Internal$Container$sizeStyles, config.gh, frame.hq.fA, frame.hq.eD);
		var styles = A2(
			$elm$core$List$cons,
			A2($elm$html$Html$Attributes$style, 'position', 'relative'),
			sizeStyles);
		return A2(
			$elm$html$Html$div,
			_Utils_ap(styles, userAttributes),
			A2($elm$core$List$cons, plot, junkHtml));
	});
var $terezka$line_charts$Internal$Line$data = function (_v0) {
	var config = _v0;
	return config.w;
};
var $elm$svg$Svg$defs = $elm$svg$Svg$trustedNode('defs');
var $terezka$line_charts$Internal$Junk$getLayers = F5(
	function (series, toX, toY, system, _v0) {
		var toLayers = _v0;
		return A4(toLayers, series, toX, toY, system);
	});
var $terezka$line_charts$Internal$Line$label = function (_v0) {
	var config = _v0;
	return config.dG;
};
var $terezka$line_charts$Internal$Events$toContainerAttributes = F3(
	function (data, system, _v0) {
		var events = _v0;
		var order = function (_v1) {
			var outside = _v1.a;
			var event = _v1.b;
			return outside ? $elm$core$Maybe$Just(
				A2(event, data, system)) : $elm$core$Maybe$Nothing;
		};
		return A2($elm$core$List$filterMap, order, events);
	});
var $terezka$line_charts$Internal$Data$Data = F3(
	function (user, point, isReal) {
		return {gR: isReal, hc: point, y: user};
	});
var $terezka$line_charts$LineChart$setY = F2(
	function (datum, y) {
		return A3(
			$terezka$line_charts$Internal$Data$Data,
			datum.y,
			A2($terezka$line_charts$Internal$Data$Point, datum.hc.h7, y),
			datum.gR);
	});
var $terezka$line_charts$LineChart$normalize = function (datasets) {
	if (datasets.b) {
		var highest = datasets.a;
		var belows = datasets.b;
		var toPercentage = F2(
			function (highest_, datum) {
				return A2($terezka$line_charts$LineChart$setY, datum, (100 * datum.hc.h8) / highest_.hc.h8);
			});
		return A2(
			$elm$core$List$map,
			A2($elm$core$List$map2, toPercentage, highest),
			A2($elm$core$List$cons, highest, belows));
	} else {
		return datasets;
	}
};
var $terezka$line_charts$Internal$Utils$withFirst = F2(
	function (stuff, process) {
		if (stuff.b) {
			var first = stuff.a;
			var rest = stuff.b;
			return $elm$core$Maybe$Just(
				A2(process, first, rest));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $terezka$line_charts$LineChart$addBelows = F2(
	function (alldata, dataBelowAll) {
		var add = F2(
			function (below, datum) {
				return A2($terezka$line_charts$LineChart$setY, below, below.hc.h8 + datum.hc.h8);
			});
		var iterate = F4(
			function (datum0, dataTop, dataBelowTop, result) {
				iterate:
				while (true) {
					var _v0 = _Utils_Tuple2(dataTop, dataBelowTop);
					if (_v0.a.b) {
						if (_v0.b.b) {
							var _v1 = _v0.a;
							var datum1 = _v1.a;
							var data = _v1.b;
							var _v2 = _v0.b;
							var datumBelow = _v2.a;
							var dataBelow = _v2.b;
							if (_Utils_cmp(datum1.hc.h7, datumBelow.hc.h7) > 0) {
								if (datumBelow.gR) {
									var $temp$datum0 = datum0,
										$temp$dataTop = A2($elm$core$List$cons, datum1, data),
										$temp$dataBelowTop = dataBelow,
										$temp$result = A2(
										$elm$core$List$cons,
										A2(add, datumBelow, datum0),
										result);
									datum0 = $temp$datum0;
									dataTop = $temp$dataTop;
									dataBelowTop = $temp$dataBelowTop;
									result = $temp$result;
									continue iterate;
								} else {
									var breakdata = _Utils_update(
										datum0,
										{gR: false});
									var $temp$datum0 = datum0,
										$temp$dataTop = A2($elm$core$List$cons, datum1, data),
										$temp$dataBelowTop = dataBelow,
										$temp$result = A2(
										$elm$core$List$cons,
										A2(add, datumBelow, datum0),
										result);
									datum0 = $temp$datum0;
									dataTop = $temp$dataTop;
									dataBelowTop = $temp$dataBelowTop;
									result = $temp$result;
									continue iterate;
								}
							} else {
								var $temp$datum0 = datum1,
									$temp$dataTop = data,
									$temp$dataBelowTop = A2($elm$core$List$cons, datumBelow, dataBelow),
									$temp$result = result;
								datum0 = $temp$datum0;
								dataTop = $temp$dataTop;
								dataBelowTop = $temp$dataBelowTop;
								result = $temp$result;
								continue iterate;
							}
						} else {
							var _v4 = _v0.a;
							var datum1 = _v4.a;
							var data = _v4.b;
							return result;
						}
					} else {
						if (_v0.b.b) {
							var _v3 = _v0.b;
							var datumBelow = _v3.a;
							var dataBelow = _v3.b;
							if (_Utils_cmp(datum0.hc.h7, datumBelow.hc.h7) < 1) {
								var $temp$datum0 = datum0,
									$temp$dataTop = _List_Nil,
									$temp$dataBelowTop = dataBelow,
									$temp$result = A2(
									$elm$core$List$cons,
									A2(add, datumBelow, datum0),
									result);
								datum0 = $temp$datum0;
								dataTop = $temp$dataTop;
								dataBelowTop = $temp$dataBelowTop;
								result = $temp$result;
								continue iterate;
							} else {
								var $temp$datum0 = datum0,
									$temp$dataTop = _List_Nil,
									$temp$dataBelowTop = dataBelow,
									$temp$result = A2($elm$core$List$cons, datumBelow, result);
								datum0 = $temp$datum0;
								dataTop = $temp$dataTop;
								dataBelowTop = $temp$dataBelowTop;
								result = $temp$result;
								continue iterate;
							}
						} else {
							return result;
						}
					}
				}
			});
		return $elm$core$List$reverse(
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2(
					$terezka$line_charts$Internal$Utils$withFirst,
					alldata,
					F2(
						function (first, rest) {
							return A4(iterate, first, rest, dataBelowAll, _List_Nil);
						}))));
	});
var $terezka$line_charts$LineChart$stack = function (dataset) {
	var stackBelows = F2(
		function (dataset_, result) {
			if (dataset_.b) {
				var data = dataset_.a;
				var belows = dataset_.b;
				return A2(
					stackBelows,
					belows,
					A2(
						$elm$core$List$cons,
						A3($elm$core$List$foldl, $terezka$line_charts$LineChart$addBelows, data, belows),
						result));
			} else {
				return result;
			}
		});
	return $elm$core$List$reverse(
		A2(stackBelows, dataset, _List_Nil));
};
var $terezka$line_charts$Internal$Axis$variable = function (_v0) {
	var config = _v0;
	return config.b0;
};
var $terezka$line_charts$LineChart$toDataPoints = F2(
	function (config, lines) {
		var y = $terezka$line_charts$Internal$Axis$variable(config.h8);
		var x = $terezka$line_charts$Internal$Axis$variable(config.h7);
		var addPoint = function (datum) {
			var _v1 = _Utils_Tuple2(
				x(datum),
				y(datum));
			if (!_v1.a.$) {
				if (!_v1.b.$) {
					var x_ = _v1.a.a;
					var y_ = _v1.b.a;
					return $elm$core$Maybe$Just(
						A3(
							$terezka$line_charts$Internal$Data$Data,
							datum,
							A2($terezka$line_charts$Internal$Data$Point, x_, y_),
							true));
				} else {
					var x_ = _v1.a.a;
					var _v2 = _v1.b;
					return $elm$core$Maybe$Just(
						A3(
							$terezka$line_charts$Internal$Data$Data,
							datum,
							A2($terezka$line_charts$Internal$Data$Point, x_, 0),
							false));
				}
			} else {
				if (!_v1.b.$) {
					var _v3 = _v1.a;
					var y_ = _v1.b.a;
					return $elm$core$Maybe$Nothing;
				} else {
					var _v4 = _v1.a;
					var _v5 = _v1.b;
					return $elm$core$Maybe$Nothing;
				}
			}
		};
		var data = A2(
			$elm$core$List$map,
			A2(
				$elm$core$Basics$composeR,
				$terezka$line_charts$Internal$Line$data,
				$elm$core$List$filterMap(addPoint)),
			lines);
		var _v0 = config.fU;
		switch (_v0.$) {
			case 0:
				return data;
			case 1:
				return data;
			case 2:
				return $terezka$line_charts$LineChart$stack(data);
			default:
				return $terezka$line_charts$LineChart$normalize(
					$terezka$line_charts$LineChart$stack(data));
		}
	});
var $terezka$line_charts$Internal$Coordinate$Frame = F2(
	function (margin, size) {
		return {c5: margin, hq: size};
	});
var $terezka$line_charts$Internal$Coordinate$Size = F2(
	function (width, height) {
		return {eD: height, fA: width};
	});
var $terezka$line_charts$LineChart$Coordinate$Range = F2(
	function (min, max) {
		return {V: max, W: min};
	});
var $terezka$line_charts$Internal$Axis$Range$applyX = F2(
	function (range, system) {
		switch (range.$) {
			case 0:
				var padMin = range.a;
				var padMax = range.b;
				var _v1 = system;
				var frame = _v1.c3;
				var _v2 = frame;
				var size = _v2.hq;
				var system_ = _Utils_update(
					system,
					{
						c3: _Utils_update(
							frame,
							{
								hq: _Utils_update(
									size,
									{
										fA: A2($elm$core$Basics$max, 1, (size.fA - padMin) - padMax)
									})
							})
					});
				var scale = $terezka$line_charts$LineChart$Coordinate$scaleDataX(system_);
				return A2(
					$terezka$line_charts$LineChart$Coordinate$Range,
					system.h7.W - scale(padMin),
					system.h7.V + scale(padMax));
			case 1:
				var min = range.a;
				var max = range.b;
				return A2($terezka$line_charts$LineChart$Coordinate$Range, min, max);
			default:
				var toRange = range.a;
				return toRange(system.h7);
		}
	});
var $terezka$line_charts$Internal$Axis$Range$applyY = F2(
	function (range, system) {
		switch (range.$) {
			case 0:
				var padMin = range.a;
				var padMax = range.b;
				var _v1 = system;
				var frame = _v1.c3;
				var _v2 = frame;
				var size = _v2.hq;
				var system_ = _Utils_update(
					system,
					{
						c3: _Utils_update(
							frame,
							{
								hq: _Utils_update(
									size,
									{
										eD: A2($elm$core$Basics$max, 1, (size.eD - padMin) - padMax)
									})
							})
					});
				var scale = $terezka$line_charts$LineChart$Coordinate$scaleDataY(system_);
				return A2(
					$terezka$line_charts$LineChart$Coordinate$Range,
					system.h8.W - scale(padMin),
					system.h8.V + scale(padMax));
			case 1:
				var min = range.a;
				var max = range.b;
				return A2($terezka$line_charts$LineChart$Coordinate$Range, min, max);
			default:
				var toRange = range.a;
				return toRange(system.h8);
		}
	});
var $terezka$line_charts$Internal$Coordinate$ground = function (range_) {
	return _Utils_update(
		range_,
		{
			W: A2($elm$core$Basics$min, range_.W, 0)
		});
};
var $terezka$line_charts$Internal$Area$hasArea = function (config) {
	switch (config.$) {
		case 0:
			return false;
		case 1:
			return true;
		case 2:
			return true;
		default:
			return true;
	}
};
var $terezka$line_charts$Internal$Axis$pixels = function (_v0) {
	var config = _v0;
	return config.bU;
};
var $terezka$line_charts$Internal$Axis$range = function (_v0) {
	var config = _v0;
	return config.bW;
};
var $terezka$line_charts$Internal$Coordinate$maximum = function (toValue) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$List$map(toValue),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$maximum,
			$elm$core$Maybe$withDefault(1)));
};
var $terezka$line_charts$Internal$Coordinate$minimum = function (toValue) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$List$map(toValue),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$minimum,
			$elm$core$Maybe$withDefault(0)));
};
var $terezka$line_charts$Internal$Coordinate$range = F2(
	function (toValue, data) {
		var range_ = {
			V: A2($terezka$line_charts$Internal$Coordinate$maximum, toValue, data),
			W: A2($terezka$line_charts$Internal$Coordinate$minimum, toValue, data)
		};
		return _Utils_eq(range_.W, range_.V) ? _Utils_update(
			range_,
			{V: range_.V + 1}) : range_;
	});
var $terezka$line_charts$LineChart$toSystem = F2(
	function (config, data) {
		var yRange = A2(
			$terezka$line_charts$Internal$Coordinate$range,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.hc;
				},
				function ($) {
					return $.h8;
				}),
			data);
		var xRange = A2(
			$terezka$line_charts$Internal$Coordinate$range,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.hc;
				},
				function ($) {
					return $.h7;
				}),
			data);
		var size = A2(
			$terezka$line_charts$Internal$Coordinate$Size,
			$terezka$line_charts$Internal$Axis$pixels(config.h7),
			$terezka$line_charts$Internal$Axis$pixels(config.h8));
		var hasArea = $terezka$line_charts$Internal$Area$hasArea(config.fU);
		var container_ = A2($terezka$line_charts$Internal$Container$properties, $elm$core$Basics$identity, config.gh);
		var frame = A2($terezka$line_charts$Internal$Coordinate$Frame, container_.c5, size);
		var adjustDomainRange = function (domain) {
			return hasArea ? $terezka$line_charts$Internal$Coordinate$ground(domain) : domain;
		};
		var system = {
			c3: frame,
			a: container_.a,
			h7: xRange,
			ee: xRange,
			h8: adjustDomainRange(yRange),
			ef: yRange
		};
		return _Utils_update(
			system,
			{
				h7: A2(
					$terezka$line_charts$Internal$Axis$Range$applyX,
					$terezka$line_charts$Internal$Axis$range(config.h7),
					system),
				h8: A2(
					$terezka$line_charts$Internal$Axis$Range$applyY,
					$terezka$line_charts$Internal$Axis$range(config.h8),
					system)
			});
	});
var $terezka$line_charts$Internal$Axis$ticks = function (_v0) {
	var config = _v0;
	return config.aU;
};
var $terezka$line_charts$Internal$Axis$Tick$properties = function (_v0) {
	var properties_ = _v0;
	return properties_;
};
var $terezka$line_charts$Internal$Axis$Ticks$ticks = F3(
	function (dataRange, range, _v0) {
		var values = _v0;
		return A2(
			$elm$core$List$map,
			$terezka$line_charts$Internal$Axis$Tick$properties,
			A2(values, dataRange, range));
	});
var $elm$svg$Svg$circle = $elm$svg$Svg$trustedNode('circle');
var $elm$svg$Svg$Attributes$cx = _VirtualDom_attribute('cx');
var $elm$svg$Svg$Attributes$cy = _VirtualDom_attribute('cy');
var $elm$svg$Svg$Attributes$r = _VirtualDom_attribute('r');
var $terezka$line_charts$Internal$Svg$gridDot = F3(
	function (radius, color, point) {
		return A2(
			$elm$svg$Svg$circle,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$cx(
					$elm$core$String$fromFloat(point.h7)),
					$elm$svg$Svg$Attributes$cy(
					$elm$core$String$fromFloat(point.h8)),
					$elm$svg$Svg$Attributes$r(
					$elm$core$String$fromFloat(radius)),
					$elm$svg$Svg$Attributes$fill(
					$avh4$elm_color$Color$toCssString(color))
				]),
			_List_Nil);
	});
var $terezka$line_charts$Internal$Grid$viewDots = F5(
	function (system, verticals, horizontals, radius, color) {
		var dot = F2(
			function (x, y) {
				return A2(
					$terezka$line_charts$LineChart$Coordinate$toSvg,
					system,
					A2($terezka$line_charts$LineChart$Coordinate$Point, x, y));
			});
		var dots_ = function (g) {
			return A2(
				$elm$core$List$map,
				dot(g),
				horizontals);
		};
		var alldots = A2($elm$core$List$concatMap, dots_, verticals);
		return A2(
			$elm$core$List$map,
			A2($terezka$line_charts$Internal$Svg$gridDot, radius, color),
			alldots);
	});
var $terezka$line_charts$Internal$Svg$horizontal = F5(
	function (system, userAttributes, y, x1, x2) {
		var attributes = A3(
			$terezka$line_charts$Internal$Utils$concat,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$stroke(
					$avh4$elm_color$Color$toCssString($terezka$line_charts$LineChart$Colors$gray)),
					$elm$svg$Svg$Attributes$style('pointer-events: none;')
				]),
			userAttributes,
			_List_Nil);
		return A3(
			$terezka$line_charts$Internal$Path$view,
			system,
			attributes,
			_List_fromArray(
				[
					$terezka$line_charts$Internal$Path$Move(
					{h7: x1, h8: y}),
					$terezka$line_charts$Internal$Path$Line(
					{h7: x1, h8: y}),
					$terezka$line_charts$Internal$Path$Line(
					{h7: x2, h8: y})
				]));
	});
var $terezka$line_charts$Internal$Svg$horizontalGrid = F3(
	function (system, userAttributes, y) {
		var attributes = A3(
			$terezka$line_charts$Internal$Utils$concat,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$stroke(
					$avh4$elm_color$Color$toCssString($terezka$line_charts$LineChart$Colors$gray)),
					$elm$svg$Svg$Attributes$style('pointer-events: none;')
				]),
			userAttributes,
			_List_Nil);
		return A5($terezka$line_charts$Internal$Svg$horizontal, system, attributes, y, system.h7.W, system.h7.V);
	});
var $terezka$line_charts$Internal$Grid$viewLines = F5(
	function (system, verticals, horizontals, width, color) {
		var attributes = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$strokeWidth(
				$elm$core$String$fromFloat(width)),
				$elm$svg$Svg$Attributes$stroke(
				$avh4$elm_color$Color$toCssString(color))
			]);
		return _Utils_ap(
			A2(
				$elm$core$List$map,
				A2($terezka$line_charts$Internal$Svg$horizontalGrid, system, attributes),
				horizontals),
			A2(
				$elm$core$List$map,
				A2($terezka$line_charts$Internal$Svg$verticalGrid, system, attributes),
				verticals));
	});
var $terezka$line_charts$Internal$Grid$view = F4(
	function (system, xAxis, yAxis, grid) {
		var hasGrid = function (tick) {
			return tick.gx ? $elm$core$Maybe$Just(tick.db) : $elm$core$Maybe$Nothing;
		};
		var horizontals = A2(
			$elm$core$List$filterMap,
			hasGrid,
			A3(
				$terezka$line_charts$Internal$Axis$Ticks$ticks,
				system.ef,
				system.h8,
				$terezka$line_charts$Internal$Axis$ticks(yAxis)));
		var verticals = A2(
			$elm$core$List$filterMap,
			hasGrid,
			A3(
				$terezka$line_charts$Internal$Axis$Ticks$ticks,
				system.ee,
				system.h7,
				$terezka$line_charts$Internal$Axis$ticks(xAxis)));
		if (!grid.$) {
			var radius = grid.a;
			var color = grid.b;
			return A5($terezka$line_charts$Internal$Grid$viewDots, system, verticals, horizontals, radius, color);
		} else {
			var width = grid.a;
			var color = grid.b;
			return A5($terezka$line_charts$Internal$Grid$viewLines, system, verticals, horizontals, width, color);
		}
	});
var $terezka$line_charts$Internal$Svg$End = 2;
var $terezka$line_charts$Internal$Svg$Start = 0;
var $terezka$line_charts$Internal$Svg$anchorStyle = function (anchor) {
	var anchorString = function () {
		switch (anchor) {
			case 0:
				return 'start';
			case 1:
				return 'middle';
			default:
				return 'end';
		}
	}();
	return $elm$svg$Svg$Attributes$style('text-anchor: ' + (anchorString + ';'));
};
var $terezka$line_charts$Internal$Legends$viewFree = F5(
	function (system, placement, viewLabel, line, data) {
		var _v0 = function () {
			if (!placement) {
				return _Utils_Tuple3(data, 2, -10);
			} else {
				return _Utils_Tuple3(
					$elm$core$List$reverse(data),
					0,
					10);
			}
		}();
		var orderedPoints = _v0.a;
		var anchor = _v0.b;
		var xOffset = _v0.c;
		var transform = function (_v3) {
			var x = _v3.h7;
			var y = _v3.h8;
			return $terezka$line_charts$Internal$Svg$transform(
				_List_fromArray(
					[
						A3($terezka$line_charts$Internal$Svg$move, system, x, y),
						A2($terezka$line_charts$Internal$Svg$offset, xOffset, 3)
					]));
		};
		var viewLegend = function (_v2) {
			var point = _v2.hc;
			return A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[
						transform(point),
						$terezka$line_charts$Internal$Svg$anchorStyle(anchor)
					]),
				_List_fromArray(
					[
						viewLabel(
						$terezka$line_charts$Internal$Line$label(line))
					]));
		};
		return A2(
			$terezka$line_charts$Internal$Utils$viewMaybe,
			$elm$core$List$head(orderedPoints),
			viewLegend);
	});
var $terezka$line_charts$Internal$Legends$viewFrees = F3(
	function (_v0, placement, view_) {
		var system = _v0.fo;
		var lines = _v0.dK;
		var data = _v0.w;
		return A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__legends')
				]),
			A3(
				$elm$core$List$map2,
				A3($terezka$line_charts$Internal$Legends$viewFree, system, placement, view_),
				lines,
				data));
	});
var $terezka$line_charts$Internal$Line$shape = function (_v0) {
	var config = _v0;
	return config.fk;
};
var $elm$core$Basics$pi = _Basics_pi;
var $elm$core$Basics$sqrt = _Basics_sqrt;
var $elm$svg$Svg$Attributes$strokeOpacity = _VirtualDom_attribute('stroke-opacity');
var $terezka$line_charts$Internal$Dots$varietyAttributes = F2(
	function (color, variety) {
		switch (variety.$) {
			case 0:
				var width = variety.a;
				return _List_fromArray(
					[
						$elm$svg$Svg$Attributes$stroke(
						$avh4$elm_color$Color$toCssString(color)),
						$elm$svg$Svg$Attributes$strokeWidth(
						$elm$core$String$fromInt(width)),
						$elm$svg$Svg$Attributes$fill('white')
					]);
			case 2:
				var width = variety.a;
				var opacity = variety.b;
				return _List_fromArray(
					[
						$elm$svg$Svg$Attributes$stroke(
						$avh4$elm_color$Color$toCssString(color)),
						$elm$svg$Svg$Attributes$strokeWidth(
						$elm$core$String$fromInt(width)),
						$elm$svg$Svg$Attributes$strokeOpacity(
						$elm$core$String$fromFloat(opacity)),
						$elm$svg$Svg$Attributes$fill(
						$avh4$elm_color$Color$toCssString(color))
					]);
			case 1:
				var width = variety.a;
				return _List_fromArray(
					[
						$elm$svg$Svg$Attributes$stroke('white'),
						$elm$svg$Svg$Attributes$strokeWidth(
						$elm$core$String$fromInt(width)),
						$elm$svg$Svg$Attributes$fill(
						$avh4$elm_color$Color$toCssString(color))
					]);
			default:
				return _List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(
						$avh4$elm_color$Color$toCssString(color))
					]);
		}
	});
var $terezka$line_charts$Internal$Dots$viewCircle = F5(
	function (events, variety, color, area, point) {
		var radius = $elm$core$Basics$sqrt(area / $elm$core$Basics$pi);
		var attributes = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$cx(
				$elm$core$String$fromFloat(point.h7)),
				$elm$svg$Svg$Attributes$cy(
				$elm$core$String$fromFloat(point.h8)),
				$elm$svg$Svg$Attributes$r(
				$elm$core$String$fromFloat(radius))
			]);
		return A2(
			$elm$svg$Svg$circle,
			_Utils_ap(
				events,
				_Utils_ap(
					attributes,
					A2($terezka$line_charts$Internal$Dots$varietyAttributes, color, variety))),
			_List_Nil);
	});
var $terezka$line_charts$Internal$Dots$pathPlus = F2(
	function (area, point) {
		var side = $elm$core$Basics$sqrt(area / 5);
		var r6 = side / 2;
		var r3 = side;
		var commands = _List_fromArray(
			[
				'M' + ($elm$core$String$fromFloat(point.h7 - r6) + (' ' + $elm$core$String$fromFloat((point.h8 - r3) - r6))),
				'v' + $elm$core$String$fromFloat(r3),
				'h' + $elm$core$String$fromFloat(-r3),
				'v' + $elm$core$String$fromFloat(r3),
				'h' + $elm$core$String$fromFloat(r3),
				'v' + $elm$core$String$fromFloat(r3),
				'h' + $elm$core$String$fromFloat(r3),
				'v' + $elm$core$String$fromFloat(-r3),
				'h' + $elm$core$String$fromFloat(r3),
				'v' + $elm$core$String$fromFloat(-r3),
				'h' + $elm$core$String$fromFloat(-r3),
				'v' + $elm$core$String$fromFloat(-r3),
				'h' + $elm$core$String$fromFloat(-r3),
				'v' + $elm$core$String$fromFloat(r3)
			]);
		return A2($elm$core$String$join, ' ', commands);
	});
var $terezka$line_charts$Internal$Dots$viewCross = F5(
	function (events, variety, color, area, point) {
		var rotation = 'rotate(45 ' + ($elm$core$String$fromFloat(point.h7) + (' ' + ($elm$core$String$fromFloat(point.h8) + ')')));
		var attributes = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$d(
				A2($terezka$line_charts$Internal$Dots$pathPlus, area, point)),
				$elm$svg$Svg$Attributes$transform(rotation)
			]);
		return A2(
			$elm$svg$Svg$path,
			_Utils_ap(
				events,
				_Utils_ap(
					attributes,
					A2($terezka$line_charts$Internal$Dots$varietyAttributes, color, variety))),
			_List_Nil);
	});
var $terezka$line_charts$Internal$Dots$viewDiamond = F5(
	function (events, variety, color, area, point) {
		var side = $elm$core$Basics$sqrt(area);
		var rotation = 'rotate(45 ' + ($elm$core$String$fromFloat(point.h7) + (' ' + ($elm$core$String$fromFloat(point.h8) + ')')));
		var attributes = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$x(
				$elm$core$String$fromFloat(point.h7 - (side / 2))),
				$elm$svg$Svg$Attributes$y(
				$elm$core$String$fromFloat(point.h8 - (side / 2))),
				$elm$svg$Svg$Attributes$width(
				$elm$core$String$fromFloat(side)),
				$elm$svg$Svg$Attributes$height(
				$elm$core$String$fromFloat(side)),
				$elm$svg$Svg$Attributes$transform(rotation)
			]);
		return A2(
			$elm$svg$Svg$rect,
			_Utils_ap(
				events,
				_Utils_ap(
					attributes,
					A2($terezka$line_charts$Internal$Dots$varietyAttributes, color, variety))),
			_List_Nil);
	});
var $terezka$line_charts$Internal$Dots$viewPlus = F5(
	function (events, variety, color, area, point) {
		var attributes = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$d(
				A2($terezka$line_charts$Internal$Dots$pathPlus, area, point))
			]);
		return A2(
			$elm$svg$Svg$path,
			_Utils_ap(
				events,
				_Utils_ap(
					attributes,
					A2($terezka$line_charts$Internal$Dots$varietyAttributes, color, variety))),
			_List_Nil);
	});
var $terezka$line_charts$Internal$Dots$viewSquare = F5(
	function (events, variety, color, area, point) {
		var side = $elm$core$Basics$sqrt(area);
		var attributes = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$x(
				$elm$core$String$fromFloat(point.h7 - (side / 2))),
				$elm$svg$Svg$Attributes$y(
				$elm$core$String$fromFloat(point.h8 - (side / 2))),
				$elm$svg$Svg$Attributes$width(
				$elm$core$String$fromFloat(side)),
				$elm$svg$Svg$Attributes$height(
				$elm$core$String$fromFloat(side))
			]);
		return A2(
			$elm$svg$Svg$rect,
			_Utils_ap(
				events,
				_Utils_ap(
					attributes,
					A2($terezka$line_charts$Internal$Dots$varietyAttributes, color, variety))),
			_List_Nil);
	});
var $elm$core$Basics$degrees = function (angleInDegrees) {
	return (angleInDegrees * $elm$core$Basics$pi) / 180;
};
var $elm$core$Basics$tan = _Basics_tan;
var $terezka$line_charts$Internal$Dots$pathTriangle = F2(
	function (area, point) {
		var side = $elm$core$Basics$sqrt(
			(area * 4) / $elm$core$Basics$sqrt(3));
		var height = ($elm$core$Basics$sqrt(3) * side) / 2;
		var fromMiddle = height - (($elm$core$Basics$tan(
			$elm$core$Basics$degrees(30)) * side) / 2);
		var commands = _List_fromArray(
			[
				'M' + ($elm$core$String$fromFloat(point.h7) + (' ' + $elm$core$String$fromFloat(point.h8 - fromMiddle))),
				'l' + ($elm$core$String$fromFloat((-side) / 2) + (' ' + $elm$core$String$fromFloat(height))),
				'h' + $elm$core$String$fromFloat(side),
				'z'
			]);
		return A2($elm$core$String$join, ' ', commands);
	});
var $terezka$line_charts$Internal$Dots$viewTriangle = F5(
	function (events, variety, color, area, point) {
		var attributes = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$d(
				A2($terezka$line_charts$Internal$Dots$pathTriangle, area, point))
			]);
		return A2(
			$elm$svg$Svg$path,
			_Utils_ap(
				events,
				_Utils_ap(
					attributes,
					A2($terezka$line_charts$Internal$Dots$varietyAttributes, color, variety))),
			_List_Nil);
	});
var $terezka$line_charts$Internal$Dots$viewShape = F5(
	function (system, _v0, shape, color, point) {
		var radius = _v0.dT;
		var variety = _v0.d8;
		var view_ = function () {
			switch (shape) {
				case 1:
					return $terezka$line_charts$Internal$Dots$viewCircle;
				case 2:
					return $terezka$line_charts$Internal$Dots$viewTriangle;
				case 3:
					return $terezka$line_charts$Internal$Dots$viewSquare;
				case 4:
					return $terezka$line_charts$Internal$Dots$viewDiamond;
				case 5:
					return $terezka$line_charts$Internal$Dots$viewCross;
				case 6:
					return $terezka$line_charts$Internal$Dots$viewPlus;
				default:
					return F5(
						function (_v2, _v3, _v4, _v5, _v6) {
							return $elm$svg$Svg$text('');
						});
			}
		}();
		var size = (2 * $elm$core$Basics$pi) * radius;
		var pointSvg = A2($terezka$line_charts$LineChart$Coordinate$toSvg, system, point);
		return A5(view_, _List_Nil, variety, color, size, pointSvg);
	});
var $terezka$line_charts$Internal$Dots$viewSample = F5(
	function (_v0, shape, color, system, data) {
		var config = _v0;
		var _v1 = config.dI(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.y;
				},
				data));
		var style_ = _v1;
		return A4($terezka$line_charts$Internal$Dots$viewShape, system, style_, shape, color);
	});
var $elm$svg$Svg$Attributes$fillOpacity = _VirtualDom_attribute('fill-opacity');
var $terezka$line_charts$Internal$Area$opacity = function (config) {
	switch (config.$) {
		case 0:
			return 0;
		case 1:
			var opacity_ = config.a;
			return opacity_;
		case 2:
			var opacity_ = config.a;
			return opacity_;
		default:
			var opacity_ = config.a;
			return opacity_;
	}
};
var $terezka$line_charts$Internal$Line$toAreaAttributes = F3(
	function (_v0, _v1, area) {
		var serie = _v0;
		var style_ = _v1;
		return _List_fromArray(
			[
				$elm$svg$Svg$Attributes$class('chart__interpolation__area__fragment'),
				$elm$svg$Svg$Attributes$fill(
				$avh4$elm_color$Color$toCssString(
					style_.ge(serie.ge)))
			]);
	});
var $elm$svg$Svg$Attributes$strokeDasharray = _VirtualDom_attribute('stroke-dasharray');
var $terezka$line_charts$Internal$Line$toSeriesAttributes = F2(
	function (_v0, _v1) {
		var serie = _v0;
		var style_ = _v1;
		return _List_fromArray(
			[
				$elm$svg$Svg$Attributes$style('pointer-events: none;'),
				$elm$svg$Svg$Attributes$class('chart__interpolation__line__fragment'),
				$elm$svg$Svg$Attributes$stroke(
				$avh4$elm_color$Color$toCssString(
					style_.ge(serie.ge))),
				$elm$svg$Svg$Attributes$strokeWidth(
				$elm$core$String$fromFloat(style_.fA)),
				$elm$svg$Svg$Attributes$strokeDasharray(
				A2(
					$elm$core$String$join,
					' ',
					A2($elm$core$List$map, $elm$core$String$fromFloat, serie.er))),
				$elm$svg$Svg$Attributes$fill('transparent')
			]);
	});
var $terezka$line_charts$Internal$Utils$viewIf = F2(
	function (condition, view) {
		return condition ? view(0) : $elm$svg$Svg$text('');
	});
var $terezka$line_charts$Internal$Line$viewSample = F5(
	function (_v0, line_, area, data_, sampleWidth) {
		var look = _v0;
		var style_ = look(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.y;
				},
				data_));
		var sizeAttributes = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$x1('0'),
				$elm$svg$Svg$Attributes$y1('0'),
				$elm$svg$Svg$Attributes$x2(
				$elm$core$String$fromFloat(sampleWidth)),
				$elm$svg$Svg$Attributes$y2('0')
			]);
		var rectangleAttributes = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$x('0'),
				$elm$svg$Svg$Attributes$y('0'),
				$elm$svg$Svg$Attributes$height('9'),
				$elm$svg$Svg$Attributes$width(
				$elm$core$String$fromFloat(sampleWidth))
			]);
		var lineAttributes = A2($terezka$line_charts$Internal$Line$toSeriesAttributes, line_, style_);
		var areaAttributes = A2(
			$elm$core$List$cons,
			$elm$svg$Svg$Attributes$fillOpacity(
				$elm$core$String$fromFloat(
					$terezka$line_charts$Internal$Area$opacity(area))),
			A3($terezka$line_charts$Internal$Line$toAreaAttributes, line_, style_, area));
		var viewRectangle = function (_v1) {
			return A2(
				$elm$svg$Svg$rect,
				_Utils_ap(areaAttributes, rectangleAttributes),
				_List_Nil);
		};
		return A2(
			$elm$svg$Svg$g,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$line,
					_Utils_ap(lineAttributes, sizeAttributes),
					_List_Nil),
					A2(
					$terezka$line_charts$Internal$Utils$viewIf,
					$terezka$line_charts$Internal$Area$hasArea(area),
					viewRectangle)
				]));
	});
var $terezka$line_charts$Internal$Legends$viewSample = F4(
	function (_v0, sampleWidth, line, data) {
		var system = _v0.fo;
		var lineConfig = _v0.eR;
		var dotsConfig = _v0.eu;
		var area = _v0.fU;
		var shape = $terezka$line_charts$Internal$Line$shape(line);
		var dotPosition = A2(
			$terezka$line_charts$LineChart$Coordinate$toData,
			system,
			A2($terezka$line_charts$Internal$Data$Point, sampleWidth / 2, 0));
		var color = A3($terezka$line_charts$Internal$Line$color, lineConfig, line, data);
		return A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__sample')
				]),
			_List_fromArray(
				[
					A5($terezka$line_charts$Internal$Line$viewSample, lineConfig, line, area, data, sampleWidth),
					A6($terezka$line_charts$Internal$Dots$viewSample, dotsConfig, shape, color, system, data, dotPosition)
				]));
	});
var $terezka$line_charts$Internal$Legends$viewGrouped = F3(
	function (_arguments, sampleWidth, container) {
		var toLegend = F2(
			function (line, data) {
				return {
					dG: $terezka$line_charts$Internal$Line$label(line),
					dX: A4($terezka$line_charts$Internal$Legends$viewSample, _arguments, sampleWidth, line, data)
				};
			});
		var legends = A3($elm$core$List$map2, toLegend, _arguments.dK, _arguments.w);
		return A2(container, _arguments.fo, legends);
	});
var $terezka$line_charts$Internal$Legends$view = function (_arguments) {
	var _v0 = _arguments.gU;
	switch (_v0.$) {
		case 1:
			var placement = _v0.a;
			var view_ = _v0.b;
			return A3($terezka$line_charts$Internal$Legends$viewFrees, _arguments, placement, view_);
		case 2:
			var sampleWidth = _v0.a;
			var container = _v0.b;
			return A3(
				$terezka$line_charts$Internal$Legends$viewGrouped,
				_arguments,
				sampleWidth,
				container(_arguments));
		default:
			return $elm$svg$Svg$text('');
	}
};
var $terezka$line_charts$Internal$Area$opacityContainer = function (config) {
	switch (config.$) {
		case 0:
			return 1;
		case 1:
			var opacity_ = config.a;
			return 1;
		case 2:
			var opacity_ = config.a;
			return opacity_;
		default:
			var opacity_ = config.a;
			return opacity_;
	}
};
var $terezka$line_charts$Internal$Utils$unzip3 = function (pairs) {
	var step = F2(
		function (_v0, _v1) {
			var a = _v0.a;
			var b = _v0.b;
			var c = _v0.c;
			var aas = _v1.a;
			var bs = _v1.b;
			var cs = _v1.c;
			return _Utils_Tuple3(
				A2($elm$core$List$cons, a, aas),
				A2($elm$core$List$cons, b, bs),
				A2($elm$core$List$cons, c, cs));
		});
	return A3(
		$elm$core$List$foldr,
		step,
		_Utils_Tuple3(_List_Nil, _List_Nil, _List_Nil),
		pairs);
};
var $elm$core$List$map3 = _List_map3;
var $terezka$line_charts$Internal$Line$viewNormal = function (_v0) {
	var areas = _v0.a;
	var lines = _v0.b;
	var dots = _v0.c;
	var view_ = F3(
		function (area_, line_, dots_) {
			return A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$class('chart__line')
					]),
				_List_fromArray(
					[area_, line_, dots_]));
		});
	return A4($elm$core$List$map3, view_, areas, lines, dots);
};
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $terezka$line_charts$Internal$Data$isWithinRange = F2(
	function (system, point) {
		return _Utils_eq(
			A3($elm$core$Basics$clamp, system.h7.W, system.h7.V, point.h7),
			point.h7) && _Utils_eq(
			A3($elm$core$Basics$clamp, system.h8.W, system.h8.V, point.h8),
			point.h8);
	});
var $terezka$line_charts$Internal$Utils$part = F4(
	function (isReal, points, current, parts) {
		part:
		while (true) {
			if (points.b) {
				var first = points.a;
				var rest = points.b;
				if (isReal(first)) {
					var $temp$isReal = isReal,
						$temp$points = rest,
						$temp$current = _Utils_ap(
						current,
						_List_fromArray(
							[first])),
						$temp$parts = parts;
					isReal = $temp$isReal;
					points = $temp$points;
					current = $temp$current;
					parts = $temp$parts;
					continue part;
				} else {
					var $temp$isReal = isReal,
						$temp$points = rest,
						$temp$current = _List_Nil,
						$temp$parts = A2(
						$elm$core$List$cons,
						_Utils_Tuple2(
							current,
							$elm$core$Maybe$Just(first)),
						parts);
					isReal = $temp$isReal;
					points = $temp$points;
					current = $temp$current;
					parts = $temp$parts;
					continue part;
				}
			} else {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(current, $elm$core$Maybe$Nothing),
					parts);
			}
		}
	});
var $terezka$line_charts$Internal$Interpolation$linear = $elm$core$List$map(
	$elm$core$List$map($terezka$line_charts$Internal$Path$Line));
var $terezka$line_charts$Internal$Interpolation$First = {$: 0};
var $terezka$line_charts$Internal$Interpolation$Previous = function (a) {
	return {$: 1, a: a};
};
var $terezka$line_charts$Internal$Interpolation$monotoneCurve = F4(
	function (point0, point1, tangent0, tangent1) {
		var dx = (point1.h7 - point0.h7) / 3;
		return A3(
			$terezka$line_charts$Internal$Path$CubicBeziers,
			{h7: point0.h7 + dx, h8: point0.h8 + (dx * tangent0)},
			{h7: point1.h7 - dx, h8: point1.h8 - (dx * tangent1)},
			point1);
	});
var $terezka$line_charts$Internal$Interpolation$slope2 = F3(
	function (point0, point1, t) {
		var h = point1.h7 - point0.h7;
		return (!(!h)) ? ((((3 * (point1.h8 - point0.h8)) / h) - t) / 2) : t;
	});
var $elm$core$Basics$isNaN = _Basics_isNaN;
var $terezka$line_charts$Internal$Interpolation$sign = function (x) {
	return (x < 0) ? (-1) : 1;
};
var $terezka$line_charts$Internal$Interpolation$toH = F2(
	function (h0, h1) {
		return (!h0) ? ((h1 < 0) ? (0 * (-1)) : h1) : h0;
	});
var $terezka$line_charts$Internal$Interpolation$slope3 = F3(
	function (point0, point1, point2) {
		var h1 = point2.h7 - point1.h7;
		var h0 = point1.h7 - point0.h7;
		var s0h = A2($terezka$line_charts$Internal$Interpolation$toH, h0, h1);
		var s0 = (point1.h8 - point0.h8) / s0h;
		var s1h = A2($terezka$line_charts$Internal$Interpolation$toH, h1, h0);
		var s1 = (point2.h8 - point1.h8) / s1h;
		var p = ((s0 * h1) + (s1 * h0)) / (h0 + h1);
		var slope = ($terezka$line_charts$Internal$Interpolation$sign(s0) + $terezka$line_charts$Internal$Interpolation$sign(s1)) * A2(
			$elm$core$Basics$min,
			A2(
				$elm$core$Basics$min,
				$elm$core$Basics$abs(s0),
				$elm$core$Basics$abs(s1)),
			0.5 * $elm$core$Basics$abs(p));
		return $elm$core$Basics$isNaN(slope) ? 0 : slope;
	});
var $terezka$line_charts$Internal$Interpolation$monotonePart = F2(
	function (points, _v0) {
		var tangent = _v0.a;
		var commands = _v0.b;
		var _v1 = _Utils_Tuple2(tangent, points);
		_v1$4:
		while (true) {
			if (!_v1.a.$) {
				if (_v1.b.b && _v1.b.b.b) {
					if (_v1.b.b.b.b) {
						var _v2 = _v1.a;
						var _v3 = _v1.b;
						var p0 = _v3.a;
						var _v4 = _v3.b;
						var p1 = _v4.a;
						var _v5 = _v4.b;
						var p2 = _v5.a;
						var rest = _v5.b;
						var t1 = A3($terezka$line_charts$Internal$Interpolation$slope3, p0, p1, p2);
						var t0 = A3($terezka$line_charts$Internal$Interpolation$slope2, p0, p1, t1);
						return A2(
							$terezka$line_charts$Internal$Interpolation$monotonePart,
							A2(
								$elm$core$List$cons,
								p1,
								A2($elm$core$List$cons, p2, rest)),
							_Utils_Tuple2(
								$terezka$line_charts$Internal$Interpolation$Previous(t1),
								_Utils_ap(
									commands,
									_List_fromArray(
										[
											A4($terezka$line_charts$Internal$Interpolation$monotoneCurve, p0, p1, t0, t1)
										]))));
					} else {
						var _v9 = _v1.a;
						var _v10 = _v1.b;
						var p0 = _v10.a;
						var _v11 = _v10.b;
						var p1 = _v11.a;
						var t1 = A3($terezka$line_charts$Internal$Interpolation$slope3, p0, p1, p1);
						return _Utils_Tuple2(
							$terezka$line_charts$Internal$Interpolation$Previous(t1),
							_Utils_ap(
								commands,
								_List_fromArray(
									[
										A4($terezka$line_charts$Internal$Interpolation$monotoneCurve, p0, p1, t1, t1),
										$terezka$line_charts$Internal$Path$Line(p1)
									])));
					}
				} else {
					break _v1$4;
				}
			} else {
				if (_v1.b.b && _v1.b.b.b) {
					if (_v1.b.b.b.b) {
						var t0 = _v1.a.a;
						var _v6 = _v1.b;
						var p0 = _v6.a;
						var _v7 = _v6.b;
						var p1 = _v7.a;
						var _v8 = _v7.b;
						var p2 = _v8.a;
						var rest = _v8.b;
						var t1 = A3($terezka$line_charts$Internal$Interpolation$slope3, p0, p1, p2);
						return A2(
							$terezka$line_charts$Internal$Interpolation$monotonePart,
							A2(
								$elm$core$List$cons,
								p1,
								A2($elm$core$List$cons, p2, rest)),
							_Utils_Tuple2(
								$terezka$line_charts$Internal$Interpolation$Previous(t1),
								_Utils_ap(
									commands,
									_List_fromArray(
										[
											A4($terezka$line_charts$Internal$Interpolation$monotoneCurve, p0, p1, t0, t1)
										]))));
					} else {
						var t0 = _v1.a.a;
						var _v12 = _v1.b;
						var p0 = _v12.a;
						var _v13 = _v12.b;
						var p1 = _v13.a;
						var t1 = A3($terezka$line_charts$Internal$Interpolation$slope3, p0, p1, p1);
						return _Utils_Tuple2(
							$terezka$line_charts$Internal$Interpolation$Previous(t1),
							_Utils_ap(
								commands,
								_List_fromArray(
									[
										A4($terezka$line_charts$Internal$Interpolation$monotoneCurve, p0, p1, t0, t1),
										$terezka$line_charts$Internal$Path$Line(p1)
									])));
					}
				} else {
					break _v1$4;
				}
			}
		}
		return _Utils_Tuple2(tangent, commands);
	});
var $terezka$line_charts$Internal$Interpolation$monotoneSection = F2(
	function (points, _v0) {
		var tangent = _v0.a;
		var acc = _v0.b;
		var _v1 = function () {
			if (points.b) {
				var p0 = points.a;
				var rest = points.b;
				return A2(
					$terezka$line_charts$Internal$Interpolation$monotonePart,
					A2($elm$core$List$cons, p0, rest),
					_Utils_Tuple2(
						tangent,
						_List_fromArray(
							[
								$terezka$line_charts$Internal$Path$Line(p0)
							])));
			} else {
				return _Utils_Tuple2(tangent, _List_Nil);
			}
		}();
		var t0 = _v1.a;
		var commands = _v1.b;
		return _Utils_Tuple2(
			t0,
			A2($elm$core$List$cons, commands, acc));
	});
var $terezka$line_charts$Internal$Interpolation$monotone = function (sections) {
	return A3(
		$elm$core$List$foldr,
		$terezka$line_charts$Internal$Interpolation$monotoneSection,
		_Utils_Tuple2($terezka$line_charts$Internal$Interpolation$First, _List_Nil),
		sections).b;
};
var $terezka$line_charts$Internal$Interpolation$after = F2(
	function (a, b) {
		return _List_fromArray(
			[
				a,
				A2($terezka$line_charts$Internal$Data$Point, b.h7, a.h8),
				b
			]);
	});
var $terezka$line_charts$Internal$Interpolation$stepped = function (sections) {
	var expand = F2(
		function (result, section) {
			expand:
			while (true) {
				if (section.a.b) {
					if (section.a.b.b) {
						var _v1 = section.a;
						var a = _v1.a;
						var _v2 = _v1.b;
						var b = _v2.a;
						var rest = _v2.b;
						var broken = section.b;
						var $temp$result = _Utils_ap(
							result,
							A2($terezka$line_charts$Internal$Interpolation$after, a, b)),
							$temp$section = _Utils_Tuple2(
							A2($elm$core$List$cons, b, rest),
							broken);
						result = $temp$result;
						section = $temp$section;
						continue expand;
					} else {
						if (!section.b.$) {
							var _v3 = section.a;
							var last = _v3.a;
							var broken = section.b.a;
							return _Utils_ap(
								result,
								_List_fromArray(
									[
										A2($terezka$line_charts$Internal$Data$Point, broken.h7, last.h8)
									]));
						} else {
							var _v4 = section.a;
							var last = _v4.a;
							var _v5 = section.b;
							return result;
						}
					}
				} else {
					return result;
				}
			}
		});
	return A2(
		$elm$core$List$map,
		A2(
			$elm$core$Basics$composeR,
			expand(_List_Nil),
			$elm$core$List$map($terezka$line_charts$Internal$Path$Line)),
		sections);
};
var $terezka$line_charts$Internal$Interpolation$toCommands = F2(
	function (interpolation, data) {
		var pointsSections = $elm$core$List$map(
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Tuple$mapFirst(
					$elm$core$List$map(
						function ($) {
							return $.hc;
						})),
				$elm$core$Tuple$mapSecond(
					$elm$core$Maybe$map(
						function ($) {
							return $.hc;
						}))));
		var points = $elm$core$List$map(
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Tuple$first,
				$elm$core$List$map(
					function ($) {
						return $.hc;
					})));
		switch (interpolation) {
			case 0:
				return $terezka$line_charts$Internal$Interpolation$linear(
					points(data));
			case 1:
				return $terezka$line_charts$Internal$Interpolation$monotone(
					points(data));
			default:
				return $terezka$line_charts$Internal$Interpolation$stepped(
					pointsSections(data));
		}
	});
var $terezka$line_charts$Internal$Area$opacitySingle = function (config) {
	switch (config.$) {
		case 0:
			return 0;
		case 1:
			var opacity_ = config.a;
			return opacity_;
		case 2:
			var opacity_ = config.a;
			return 1;
		default:
			var opacity_ = config.a;
			return 1;
	}
};
var $terezka$line_charts$Internal$Path$toPoint = function (command) {
	switch (command.$) {
		case 9:
			return A2($terezka$line_charts$LineChart$Coordinate$Point, 0, 0);
		case 0:
			var p = command.a;
			return p;
		case 1:
			var p = command.a;
			return p;
		case 2:
			var x = command.a;
			return A2($terezka$line_charts$LineChart$Coordinate$Point, x, 0);
		case 3:
			var y = command.a;
			return A2($terezka$line_charts$LineChart$Coordinate$Point, 0, y);
		case 4:
			var c1 = command.a;
			var c2 = command.b;
			var p = command.c;
			return p;
		case 5:
			var c1 = command.a;
			var p = command.b;
			return p;
		case 6:
			var c1 = command.a;
			var p = command.b;
			return p;
		case 7:
			var p = command.a;
			return p;
		default:
			var rx = command.a;
			var ry = command.b;
			var xAxisRotation = command.c;
			var largeArcFlag = command.d;
			var sweepFlag = command.e;
			var p = command.f;
			return p;
	}
};
var $terezka$line_charts$Internal$Utils$towardsZero = function (_v0) {
	var max = _v0.V;
	var min = _v0.W;
	return A3($elm$core$Basics$clamp, min, max, 0);
};
var $terezka$line_charts$Internal$Utils$last = function (list) {
	return $elm$core$List$head(
		A2(
			$elm$core$List$drop,
			$elm$core$List$length(list) - 1,
			list));
};
var $terezka$line_charts$Internal$Utils$lastSafe = F2(
	function (first, rest) {
		return A2(
			$elm$core$Maybe$withDefault,
			first,
			$terezka$line_charts$Internal$Utils$last(rest));
	});
var $terezka$line_charts$Internal$Utils$viewWithEdges = F2(
	function (stuff, view) {
		if (stuff.b) {
			var first = stuff.a;
			var rest = stuff.b;
			return A3(
				view,
				first,
				rest,
				A2($terezka$line_charts$Internal$Utils$lastSafe, first, rest));
		} else {
			return $elm$svg$Svg$text('');
		}
	});
var $elm$svg$Svg$Attributes$clipPath = _VirtualDom_attribute('clip-path');
var $terezka$line_charts$Internal$Svg$withinChartArea = function (_v0) {
	var id = _v0.a;
	return $elm$svg$Svg$Attributes$clipPath(
		'url(#' + ($terezka$line_charts$Internal$Utils$toChartAreaId(id) + ')'));
};
var $terezka$line_charts$LineChart$Junk$withinChartArea = $terezka$line_charts$Internal$Svg$withinChartArea;
var $terezka$line_charts$Internal$Line$viewArea = F5(
	function (_v0, line_, style_, interpolation, data_) {
		var system = _v0.fo;
		var lineConfig = _v0.eR;
		var area = _v0.fU;
		var ground = function (point) {
			return A2(
				$terezka$line_charts$Internal$Data$Point,
				point.h7,
				$terezka$line_charts$Internal$Utils$towardsZero(system.h8));
		};
		var commands = F3(
			function (first, middle, last) {
				return A3(
					$terezka$line_charts$Internal$Utils$concat,
					_List_fromArray(
						[
							$terezka$line_charts$Internal$Path$Move(
							ground(
								$terezka$line_charts$Internal$Path$toPoint(first))),
							$terezka$line_charts$Internal$Path$Line(
							$terezka$line_charts$Internal$Path$toPoint(first))
						]),
					interpolation,
					_List_fromArray(
						[
							$terezka$line_charts$Internal$Path$Line(
							ground(
								$terezka$line_charts$Internal$Path$toPoint(last)))
						]));
			});
		var attributes = A2(
			$elm$core$List$cons,
			$terezka$line_charts$LineChart$Junk$withinChartArea(system),
			A2(
				$elm$core$List$cons,
				$elm$svg$Svg$Attributes$fillOpacity(
					$elm$core$String$fromFloat(
						$terezka$line_charts$Internal$Area$opacitySingle(area))),
				A3($terezka$line_charts$Internal$Line$toAreaAttributes, line_, style_, area)));
		return A2(
			$terezka$line_charts$Internal$Utils$viewWithEdges,
			interpolation,
			F3(
				function (first, middle, last) {
					return A3(
						$terezka$line_charts$Internal$Path$view,
						system,
						attributes,
						A3(commands, first, middle, last));
				}));
	});
var $terezka$line_charts$Internal$Dots$view = F2(
	function (_v0, data) {
		var system = _v0.fo;
		var dotsConfig = _v0.eu;
		var shape = _v0.fk;
		var color = _v0.ge;
		var _v1 = dotsConfig;
		var config = _v1;
		var _v2 = config.dE(data.y);
		var style_ = _v2;
		return A5($terezka$line_charts$Internal$Dots$viewShape, system, style_, shape, color, data.hc);
	});
var $terezka$line_charts$Internal$Line$viewDot = F3(
	function (_arguments, _v0, _v1) {
		var lineConfig = _v0;
		var style_ = _v1;
		return $terezka$line_charts$Internal$Dots$view(
			{
				ge: style_.ge(lineConfig.ge),
				eu: _arguments.eu,
				fk: lineConfig.fk,
				fo: _arguments.fo
			});
	});
var $terezka$line_charts$Internal$Utils$viewWithFirst = F2(
	function (stuff, view) {
		if (stuff.b) {
			var first = stuff.a;
			var rest = stuff.b;
			return A2(view, first, rest);
		} else {
			return $elm$svg$Svg$text('');
		}
	});
var $terezka$line_charts$Internal$Line$viewSeries = F5(
	function (_v0, line_, style_, interpolation, data_) {
		var system = _v0.fo;
		var lineConfig = _v0.eR;
		var attributes = A2(
			$elm$core$List$cons,
			$terezka$line_charts$LineChart$Junk$withinChartArea(system),
			A2($terezka$line_charts$Internal$Line$toSeriesAttributes, line_, style_));
		return A2(
			$terezka$line_charts$Internal$Utils$viewWithFirst,
			data_,
			F2(
				function (first, _v1) {
					return A3(
						$terezka$line_charts$Internal$Path$view,
						system,
						attributes,
						A2(
							$elm$core$List$cons,
							$terezka$line_charts$Internal$Path$Move(first.hc),
							interpolation));
				}));
	});
var $terezka$line_charts$Internal$Line$viewSingle = F3(
	function (_arguments, line_, data_) {
		var style_ = function (_v1) {
			var look = _v1;
			return look(
				A2(
					$elm$core$List$map,
					function ($) {
						return $.y;
					},
					data_));
		}(_arguments.eR);
		var sections = A4(
			$terezka$line_charts$Internal$Utils$part,
			function ($) {
				return $.gR;
			},
			data_,
			_List_Nil,
			_List_Nil);
		var parts = A2($elm$core$List$map, $elm$core$Tuple$first, sections);
		var viewDots = A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__dots')
				]),
			A2(
				$elm$core$List$map,
				A3($terezka$line_charts$Internal$Line$viewDot, _arguments, line_, style_),
				A2(
					$elm$core$List$filter,
					A2(
						$elm$core$Basics$composeL,
						$terezka$line_charts$Internal$Data$isWithinRange(_arguments.fo),
						function ($) {
							return $.hc;
						}),
					$elm$core$List$concat(parts))));
		var commands = A2($terezka$line_charts$Internal$Interpolation$toCommands, _arguments.gM, sections);
		var viewAreas = function (_v0) {
			return A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$class('chart__interpolation__area')
					]),
				A3(
					$elm$core$List$map2,
					A3($terezka$line_charts$Internal$Line$viewArea, _arguments, line_, style_),
					commands,
					parts));
		};
		var viewSeriess = A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__interpolation__line')
				]),
			A3(
				$elm$core$List$map2,
				A3($terezka$line_charts$Internal$Line$viewSeries, _arguments, line_, style_),
				commands,
				parts));
		return _Utils_Tuple3(
			A2(
				$terezka$line_charts$Internal$Utils$viewIf,
				$terezka$line_charts$Internal$Area$hasArea(_arguments.fU),
				viewAreas),
			viewSeriess,
			viewDots);
	});
var $terezka$line_charts$Internal$Line$viewStacked = F2(
	function (area, _v0) {
		var areas = _v0.a;
		var lines = _v0.b;
		var dots = _v0.c;
		var toList = F2(
			function (l, d) {
				return _List_fromArray(
					[l, d]);
			});
		var opacity = 'opacity: ' + $elm$core$String$fromFloat(
			$terezka$line_charts$Internal$Area$opacityContainer(area));
		var bottoms = $elm$core$List$concat(
			A3($elm$core$List$map2, toList, lines, dots));
		return _List_fromArray(
			[
				A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$class('chart__bottoms'),
						$elm$svg$Svg$Attributes$style(opacity)
					]),
				areas),
				A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$class('chart__tops')
					]),
				bottoms)
			]);
	});
var $terezka$line_charts$Internal$Line$view = F3(
	function (_arguments, lines, datas) {
		var container = $elm$svg$Svg$g(
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__lines')
				]));
		var buildSeriesViews = ($terezka$line_charts$Internal$Area$opacityContainer(_arguments.fU) < 1) ? $terezka$line_charts$Internal$Line$viewStacked(_arguments.fU) : $terezka$line_charts$Internal$Line$viewNormal;
		return container(
			buildSeriesViews(
				$terezka$line_charts$Internal$Utils$unzip3(
					A3(
						$elm$core$List$map2,
						$terezka$line_charts$Internal$Line$viewSingle(_arguments),
						lines,
						datas))));
	});
var $terezka$line_charts$LineChart$viewBoxAttribute = function (_v0) {
	var frame = _v0.c3;
	return $elm$svg$Svg$Attributes$viewBox(
		'0 0 ' + ($elm$core$String$fromFloat(frame.hq.fA) + (' ' + $elm$core$String$fromFloat(frame.hq.eD))));
};
var $terezka$line_charts$Internal$Axis$Line$config = function (_v0) {
	var config_ = _v0;
	return config_;
};
var $terezka$line_charts$Internal$Axis$Title$config = function (_v0) {
	var title = _v0;
	return title;
};
var $terezka$line_charts$Internal$Axis$Intersection$getY = function (_v0) {
	var func = _v0;
	return A2(
		$elm$core$Basics$composeL,
		function ($) {
			return $.h8;
		},
		func);
};
var $terezka$line_charts$Internal$Axis$attributesLine = F2(
	function (system, _v0) {
		var events = _v0.gr;
		var width = _v0.fA;
		var color = _v0.ge;
		return _Utils_ap(
			events,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$strokeWidth(
					$elm$core$String$fromFloat(width)),
					$elm$svg$Svg$Attributes$stroke(
					$avh4$elm_color$Color$toCssString(color)),
					$terezka$line_charts$Internal$Svg$withinChartArea(system)
				]));
	});
var $terezka$line_charts$Internal$Axis$viewHorizontalAxisLine = F3(
	function (system, axisPosition, config) {
		return A5(
			$terezka$line_charts$Internal$Svg$horizontal,
			system,
			A2($terezka$line_charts$Internal$Axis$attributesLine, system, config),
			axisPosition,
			config.d4,
			config.ev);
	});
var $terezka$line_charts$Internal$Axis$attributesTick = function (_v0) {
	var width = _v0.fA;
	var color = _v0.ge;
	return _List_fromArray(
		[
			$elm$svg$Svg$Attributes$strokeWidth(
			$elm$core$String$fromFloat(width)),
			$elm$svg$Svg$Attributes$stroke(
			$avh4$elm_color$Color$toCssString(color))
		]);
};
var $terezka$line_charts$Internal$Axis$Tick$isPositive = function (direction) {
	if (direction === 1) {
		return true;
	} else {
		return false;
	}
};
var $terezka$line_charts$Internal$Axis$lengthOfTick = function (_v0) {
	var length = _v0.dJ;
	var direction = _v0.dq;
	return $terezka$line_charts$Internal$Axis$Tick$isPositive(direction) ? (-length) : length;
};
var $terezka$line_charts$Internal$Svg$Middle = 1;
var $terezka$line_charts$Internal$Axis$viewHorizontalLabel = F4(
	function (system, _v0, position, view) {
		var direction = _v0.dq;
		var length = _v0.dJ;
		var yOffset = $terezka$line_charts$Internal$Axis$Tick$isPositive(direction) ? ((-5) - length) : (15 + length);
		return A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$terezka$line_charts$Internal$Svg$transform(
					_List_fromArray(
						[
							A3($terezka$line_charts$Internal$Svg$move, system, position.h7, position.h8),
							A2($terezka$line_charts$Internal$Svg$offset, 0, yOffset)
						])),
					$terezka$line_charts$Internal$Svg$anchorStyle(1)
				]),
			_List_fromArray(
				[view]));
	});
var $terezka$line_charts$Internal$Svg$xTick = F5(
	function (system, height, userAttributes, y, x) {
		var attributes = A3(
			$terezka$line_charts$Internal$Utils$concat,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$stroke(
					$avh4$elm_color$Color$toCssString($terezka$line_charts$LineChart$Colors$gray))
				]),
			userAttributes,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1(
					$elm$core$String$fromFloat(
						A2($terezka$line_charts$LineChart$Coordinate$toSvgX, system, x))),
					$elm$svg$Svg$Attributes$x2(
					$elm$core$String$fromFloat(
						A2($terezka$line_charts$LineChart$Coordinate$toSvgX, system, x))),
					$elm$svg$Svg$Attributes$y1(
					$elm$core$String$fromFloat(
						A2($terezka$line_charts$LineChart$Coordinate$toSvgY, system, y))),
					$elm$svg$Svg$Attributes$y2(
					$elm$core$String$fromFloat(
						A2($terezka$line_charts$LineChart$Coordinate$toSvgY, system, y) + height))
				]));
		return A2($elm$svg$Svg$line, attributes, _List_Nil);
	});
var $terezka$line_charts$Internal$Axis$viewHorizontalTick = F3(
	function (system, point, tick) {
		var x = point.h7;
		var y = point.h8;
		return A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__tick')
				]),
			_List_fromArray(
				[
					A5(
					$terezka$line_charts$Internal$Svg$xTick,
					system,
					$terezka$line_charts$Internal$Axis$lengthOfTick(tick),
					$terezka$line_charts$Internal$Axis$attributesTick(tick),
					y,
					x),
					A2(
					$terezka$line_charts$Internal$Utils$viewMaybe,
					tick.dG,
					A3($terezka$line_charts$Internal$Axis$viewHorizontalLabel, system, tick, point))
				]));
	});
var $terezka$line_charts$Internal$Axis$viewHorizontalTitle = F3(
	function (system, at, _v0) {
		var title = _v0.hU;
		var position = at(
			A2(title.db, system.ee, system.h7));
		var _v1 = title.az;
		var xOffset = _v1.a;
		var yOffset = _v1.b;
		return A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__title'),
					$terezka$line_charts$Internal$Svg$transform(
					_List_fromArray(
						[
							A3($terezka$line_charts$Internal$Svg$move, system, position.h7, position.h8),
							A2($terezka$line_charts$Internal$Svg$offset, xOffset + 15, yOffset + 5)
						])),
					$terezka$line_charts$Internal$Svg$anchorStyle(0)
				]),
			_List_fromArray(
				[title.h4]));
	});
var $terezka$line_charts$Internal$Axis$viewHorizontal = F3(
	function (system, intersection, _v0) {
		var config = _v0;
		var viewConfig = {
			gN: A2($terezka$line_charts$Internal$Axis$Intersection$getY, intersection, system),
			gV: A3($terezka$line_charts$Internal$Axis$Line$config, config.bB, system.ee, system.h7),
			aU: A3($terezka$line_charts$Internal$Axis$Ticks$ticks, system.ee, system.h7, config.aU),
			hU: $terezka$line_charts$Internal$Axis$Title$config(config.hU)
		};
		var viewAxisLine = A2($terezka$line_charts$Internal$Axis$viewHorizontalAxisLine, system, viewConfig.gN);
		var at = function (x) {
			return {h7: x, h8: viewConfig.gN};
		};
		var viewTick = function (tick) {
			return A3(
				$terezka$line_charts$Internal$Axis$viewHorizontalTick,
				system,
				at(tick.db),
				tick);
		};
		return A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__axis--horizontal')
				]),
			_List_fromArray(
				[
					A3($terezka$line_charts$Internal$Axis$viewHorizontalTitle, system, at, viewConfig),
					viewAxisLine(viewConfig.gV),
					A2(
					$elm$svg$Svg$g,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('chart__ticks')
						]),
					A2($elm$core$List$map, viewTick, viewConfig.aU))
				]));
	});
var $terezka$line_charts$Internal$Axis$Intersection$getX = function (_v0) {
	var func = _v0;
	return A2(
		$elm$core$Basics$composeL,
		function ($) {
			return $.h7;
		},
		func);
};
var $terezka$line_charts$Internal$Axis$viewVerticalAxisLine = F3(
	function (system, axisPosition, config) {
		return A5(
			$terezka$line_charts$Internal$Svg$vertical,
			system,
			A2($terezka$line_charts$Internal$Axis$attributesLine, system, config),
			axisPosition,
			config.d4,
			config.ev);
	});
var $terezka$line_charts$Internal$Axis$viewVerticalLabel = F4(
	function (system, _v0, position, view) {
		var direction = _v0.dq;
		var length = _v0.dJ;
		var xOffset = $terezka$line_charts$Internal$Axis$Tick$isPositive(direction) ? (5 + length) : ((-5) - length);
		var anchor = $terezka$line_charts$Internal$Axis$Tick$isPositive(direction) ? 0 : 2;
		return A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$terezka$line_charts$Internal$Svg$transform(
					_List_fromArray(
						[
							A3($terezka$line_charts$Internal$Svg$move, system, position.h7, position.h8),
							A2($terezka$line_charts$Internal$Svg$offset, xOffset, 5)
						])),
					$terezka$line_charts$Internal$Svg$anchorStyle(anchor)
				]),
			_List_fromArray(
				[view]));
	});
var $terezka$line_charts$Internal$Svg$yTick = F5(
	function (system, width, userAttributes, x, y) {
		var attributes = A3(
			$terezka$line_charts$Internal$Utils$concat,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__tick'),
					$elm$svg$Svg$Attributes$stroke(
					$avh4$elm_color$Color$toCssString($terezka$line_charts$LineChart$Colors$gray))
				]),
			userAttributes,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1(
					$elm$core$String$fromFloat(
						A2($terezka$line_charts$LineChart$Coordinate$toSvgX, system, x))),
					$elm$svg$Svg$Attributes$x2(
					$elm$core$String$fromFloat(
						A2($terezka$line_charts$LineChart$Coordinate$toSvgX, system, x) - width)),
					$elm$svg$Svg$Attributes$y1(
					$elm$core$String$fromFloat(
						A2($terezka$line_charts$LineChart$Coordinate$toSvgY, system, y))),
					$elm$svg$Svg$Attributes$y2(
					$elm$core$String$fromFloat(
						A2($terezka$line_charts$LineChart$Coordinate$toSvgY, system, y)))
				]));
		return A2($elm$svg$Svg$line, attributes, _List_Nil);
	});
var $terezka$line_charts$Internal$Axis$viewVerticalTick = F3(
	function (system, point, tick) {
		var x = point.h7;
		var y = point.h8;
		return A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__tick')
				]),
			_List_fromArray(
				[
					A5(
					$terezka$line_charts$Internal$Svg$yTick,
					system,
					$terezka$line_charts$Internal$Axis$lengthOfTick(tick),
					$terezka$line_charts$Internal$Axis$attributesTick(tick),
					x,
					y),
					A2(
					$terezka$line_charts$Internal$Utils$viewMaybe,
					tick.dG,
					A3($terezka$line_charts$Internal$Axis$viewVerticalLabel, system, tick, point))
				]));
	});
var $terezka$line_charts$Internal$Axis$viewVerticalTitle = F3(
	function (system, at, _v0) {
		var title = _v0.hU;
		var position = at(
			A2(title.db, system.ef, system.h8));
		var _v1 = title.az;
		var xOffset = _v1.a;
		var yOffset = _v1.b;
		return A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__title'),
					$terezka$line_charts$Internal$Svg$transform(
					_List_fromArray(
						[
							A3($terezka$line_charts$Internal$Svg$move, system, position.h7, position.h8),
							A2($terezka$line_charts$Internal$Svg$offset, xOffset + 2, yOffset - 10)
						])),
					$terezka$line_charts$Internal$Svg$anchorStyle(2)
				]),
			_List_fromArray(
				[title.h4]));
	});
var $terezka$line_charts$Internal$Axis$viewVertical = F3(
	function (system, intersection, _v0) {
		var config = _v0;
		var viewConfig = {
			gN: A2($terezka$line_charts$Internal$Axis$Intersection$getX, intersection, system),
			gV: A3($terezka$line_charts$Internal$Axis$Line$config, config.bB, system.ef, system.h8),
			aU: A3($terezka$line_charts$Internal$Axis$Ticks$ticks, system.ef, system.h8, config.aU),
			hU: $terezka$line_charts$Internal$Axis$Title$config(config.hU)
		};
		var viewAxisLine = A2($terezka$line_charts$Internal$Axis$viewVerticalAxisLine, system, viewConfig.gN);
		var at = function (y) {
			return {h7: viewConfig.gN, h8: y};
		};
		var viewTick = function (tick) {
			return A3(
				$terezka$line_charts$Internal$Axis$viewVerticalTick,
				system,
				at(tick.db),
				tick);
		};
		return A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('chart__axis--vertical')
				]),
			_List_fromArray(
				[
					A3($terezka$line_charts$Internal$Axis$viewVerticalTitle, system, at, viewConfig),
					viewAxisLine(viewConfig.gV),
					A2(
					$elm$svg$Svg$g,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('chart__ticks')
						]),
					A2($elm$core$List$map, viewTick, viewConfig.aU))
				]));
	});
var $terezka$line_charts$LineChart$viewCustom = F2(
	function (config, lines) {
		var junkLineInfo = function (line_) {
			return _Utils_Tuple3(
				A3($terezka$line_charts$Internal$Line$color, config.gV, line_, _List_Nil),
				$terezka$line_charts$Internal$Line$label(line_),
				$terezka$line_charts$Internal$Line$data(line_));
		};
		var getJunk = A3(
			$terezka$line_charts$Internal$Junk$getLayers,
			A2($elm$core$List$map, junkLineInfo, lines),
			$terezka$line_charts$Internal$Axis$variable(config.h7),
			$terezka$line_charts$Internal$Axis$variable(config.h8));
		var data = A2($terezka$line_charts$LineChart$toDataPoints, config, lines);
		var dataAll = $elm$core$List$concat(data);
		var dataSafe = A2(
			$elm$core$List$map,
			$elm$core$List$filter(
				function ($) {
					return $.gR;
				}),
			data);
		var dataAllSafe = $elm$core$List$concat(dataSafe);
		var system = A2($terezka$line_charts$LineChart$toSystem, config, dataAllSafe);
		var viewLines = $terezka$line_charts$Internal$Line$view(
			{fU: config.fU, eu: config.gp, gM: config.gM, eR: config.gV, fo: system});
		var viewLegends = $terezka$line_charts$Internal$Legends$view(
			{
				fU: config.fU,
				w: dataSafe,
				eu: config.gp,
				gU: config.gU,
				eR: config.gV,
				dK: lines,
				fo: system,
				h7: $terezka$line_charts$Internal$Axis$variable(config.h7),
				h8: $terezka$line_charts$Internal$Axis$variable(config.h8)
			});
		var attributes = $elm$core$List$concat(
			_List_fromArray(
				[
					A2(
					$terezka$line_charts$Internal$Container$properties,
					function ($) {
						return $.cU;
					},
					config.gh),
					A3($terezka$line_charts$Internal$Events$toContainerAttributes, dataAll, system, config.gr),
					_List_fromArray(
					[
						$terezka$line_charts$LineChart$viewBoxAttribute(system)
					])
				]));
		var addGrid = $terezka$line_charts$Internal$Junk$addBelow(
			A4($terezka$line_charts$Internal$Grid$view, system, config.h7, config.h8, config.gx));
		var junk = addGrid(
			A2(getJunk, system, config.gT));
		return A4(
			$terezka$line_charts$LineChart$container,
			config,
			system,
			junk.gB,
			A2(
				$elm$svg$Svg$svg,
				attributes,
				_List_fromArray(
					[
						A2(
						$elm$svg$Svg$defs,
						_List_Nil,
						_List_fromArray(
							[
								$terezka$line_charts$LineChart$clipPath(system)
							])),
						A2(
						$elm$svg$Svg$g,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$class('chart__junk--below')
							]),
						junk.fZ),
						A2(viewLines, lines, data),
						A3($terezka$line_charts$LineChart$chartAreaPlatform, config, dataAll, system),
						A3($terezka$line_charts$Internal$Axis$viewHorizontal, system, config.gN, config.h7),
						A3($terezka$line_charts$Internal$Axis$viewVertical, system, config.gN, config.h8),
						viewLegends,
						A2(
						$elm$svg$Svg$g,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$class('chart__junk--above')
							]),
						junk.fH)
					])));
	});
var $author$project$Page$Competition$viewRace = F2(
	function (progression, hinted) {
		var presentMatchdays = A2(
			$elm$core$List$concatMap,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.bh;
				},
				$elm$core$Dict$keys),
			progression);
		var minMatchday = A2(
			$elm$core$Maybe$withDefault,
			0,
			$elm$core$List$minimum(presentMatchdays));
		var maxPoints = $elm$core$List$maximum(
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.bh;
					},
					A2($elm$core$Basics$composeR, $elm$core$Dict$values, $elm$core$List$sum)),
				progression));
		var pointsRange = A2(
			$elm$core$List$map,
			$elm$core$Basics$toFloat,
			A2(
				$elm$core$List$range,
				0,
				A2($elm$core$Maybe$withDefault, 0, maxPoints)));
		var maxMatchday = A2(
			$elm$core$Maybe$withDefault,
			0,
			$elm$core$List$maximum(presentMatchdays));
		var range = A2($elm$core$List$range, minMatchday, maxMatchday);
		var cumulativePoints = $elm$core$List$reverse(
			A2(
				$elm$core$List$sortBy,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Tuple$second,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$List$map($elm$core$Tuple$second),
						$elm$core$List$sum)),
				A2(
					$elm$core$List$filterMap,
					$author$project$Page$Competition$calculateCumulativePoints(range),
					progression)));
		var createLine = F2(
			function (_v0, color) {
				var user = _v0.a;
				var points = _v0.b;
				return A4(
					$terezka$line_charts$LineChart$line,
					color,
					$terezka$line_charts$LineChart$Dots$square,
					$author$project$Page$Competition$shortUsername(user.ac),
					points);
			});
		return $mdgriffith$elm_ui$Element$html(
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id('race-chart')
					]),
				$elm$core$List$singleton(
					A2(
						$terezka$line_charts$LineChart$viewCustom,
						A3(
							$author$project$Page$Competition$raceChartConfig,
							A2($elm$core$List$map, $elm$core$Basics$toFloat, range),
							pointsRange,
							hinted),
						A3($elm$core$List$map2, createLine, cumulativePoints, $author$project$Page$Competition$graphColors)))));
	});
var $mdgriffith$elm_ui$Element$fillPortion = $mdgriffith$elm_ui$Internal$Model$Fill;
var $mdgriffith$elm_ui$Element$InternalColumn = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$GridPosition = function (a) {
	return {$: 9, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$GridTemplateStyle = function (a) {
	return {$: 8, a: a};
};
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $mdgriffith$elm_ui$Internal$Model$AsGrid = 3;
var $mdgriffith$elm_ui$Internal$Model$asGrid = 3;
var $mdgriffith$elm_ui$Internal$Model$getSpacing = F2(
	function (attrs, _default) {
		return A2(
			$elm$core$Maybe$withDefault,
			_default,
			A3(
				$elm$core$List$foldr,
				F2(
					function (attr, acc) {
						if (!acc.$) {
							var x = acc.a;
							return $elm$core$Maybe$Just(x);
						} else {
							if ((attr.$ === 4) && (attr.b.$ === 5)) {
								var _v2 = attr.b;
								var x = _v2.b;
								var y = _v2.c;
								return $elm$core$Maybe$Just(
									_Utils_Tuple2(x, y));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}
					}),
				$elm$core$Maybe$Nothing,
				attrs));
	});
var $mdgriffith$elm_ui$Internal$Flag$gridPosition = $mdgriffith$elm_ui$Internal$Flag$flag(35);
var $mdgriffith$elm_ui$Internal$Flag$gridTemplate = $mdgriffith$elm_ui$Internal$Flag$flag(34);
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $mdgriffith$elm_ui$Element$tableHelper = F2(
	function (attrs, config) {
		var onGrid = F3(
			function (rowLevel, columnLevel, elem) {
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asEl,
					$mdgriffith$elm_ui$Internal$Model$div,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$StyleClass,
							$mdgriffith$elm_ui$Internal$Flag$gridPosition,
							$mdgriffith$elm_ui$Internal$Model$GridPosition(
								{eq: columnLevel, eD: 1, ff: rowLevel, fA: 1}))
						]),
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[elem])));
			});
		var columnWidth = function (col) {
			if (!col.$) {
				var colConfig = col.a;
				return colConfig.fA;
			} else {
				var colConfig = col.a;
				return colConfig.fA;
			}
		};
		var columnHeader = function (col) {
			if (!col.$) {
				var colConfig = col.a;
				return colConfig.cg;
			} else {
				var colConfig = col.a;
				return colConfig.cg;
			}
		};
		var maybeHeaders = function (headers) {
			return A2(
				$elm$core$List$all,
				$elm$core$Basics$eq($mdgriffith$elm_ui$Internal$Model$Empty),
				headers) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
				A2(
					$elm$core$List$indexedMap,
					F2(
						function (col, header) {
							return A3(onGrid, 1, col + 1, header);
						}),
					headers));
		}(
			A2($elm$core$List$map, columnHeader, config.gg));
		var add = F3(
			function (cell, columnConfig, cursor) {
				if (!columnConfig.$) {
					var col = columnConfig.a;
					return _Utils_update(
						cursor,
						{
							gf: cursor.gf + 1,
							by: A2(
								$elm$core$List$cons,
								A3(
									onGrid,
									cursor.ff,
									cursor.gf,
									A2(
										col.h4,
										_Utils_eq(maybeHeaders, $elm$core$Maybe$Nothing) ? (cursor.ff - 1) : (cursor.ff - 2),
										cell)),
								cursor.by)
						});
				} else {
					var col = columnConfig.a;
					return {
						gf: cursor.gf + 1,
						by: A2(
							$elm$core$List$cons,
							A3(
								onGrid,
								cursor.ff,
								cursor.gf,
								col.h4(cell)),
							cursor.by),
						ff: cursor.ff
					};
				}
			});
		var build = F3(
			function (columns, rowData, cursor) {
				var newCursor = A3(
					$elm$core$List$foldl,
					add(rowData),
					cursor,
					columns);
				return {gf: 1, by: newCursor.by, ff: cursor.ff + 1};
			});
		var children = A3(
			$elm$core$List$foldl,
			build(config.gg),
			{
				gf: 1,
				by: _List_Nil,
				ff: _Utils_eq(maybeHeaders, $elm$core$Maybe$Nothing) ? 1 : 2
			},
			config.w);
		var _v0 = A2(
			$mdgriffith$elm_ui$Internal$Model$getSpacing,
			attrs,
			_Utils_Tuple2(0, 0));
		var sX = _v0.a;
		var sY = _v0.b;
		var template = A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$gridTemplate,
			$mdgriffith$elm_ui$Internal$Model$GridTemplateStyle(
				{
					gg: A2($elm$core$List$map, columnWidth, config.gg),
					hi: A2(
						$elm$core$List$repeat,
						$elm$core$List$length(config.w),
						$mdgriffith$elm_ui$Internal$Model$Content),
					ht: _Utils_Tuple2(
						$mdgriffith$elm_ui$Element$px(sX),
						$mdgriffith$elm_ui$Element$px(sY))
				}));
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asGrid,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				A2($elm$core$List$cons, template, attrs)),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				function () {
					if (maybeHeaders.$ === 1) {
						return children.by;
					} else {
						var renderedHeaders = maybeHeaders.a;
						return _Utils_ap(
							renderedHeaders,
							$elm$core$List$reverse(children.by));
					}
				}()));
	});
var $mdgriffith$elm_ui$Element$table = F2(
	function (attrs, config) {
		return A2(
			$mdgriffith$elm_ui$Element$tableHelper,
			attrs,
			{
				gg: A2($elm$core$List$map, $mdgriffith$elm_ui$Element$InternalColumn, config.gg),
				w: config.w
			});
	});
var $author$project$Page$Competition$viewStandingsBetStat = F2(
	function (highlighted, _v0) {
		var success = _v0.a;
		var total = _v0.b;
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(5),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(50)),
					$mdgriffith$elm_ui$Element$centerY
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					highlighted ? _List_fromArray(
						[$mdgriffith$elm_ui$Element$Font$bold]) : _List_Nil,
					$mdgriffith$elm_ui$Element$text(
						$elm$core$String$fromInt(success))),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(14),
							$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$gray500)
						]),
					$mdgriffith$elm_ui$Element$text(
						'(' + ($elm$core$String$fromInt(total) + ')')))
				]));
	});
var $author$project$Page$Competition$viewStandingsGambler = function (maybeUser) {
	if (maybeUser.$ === 1) {
		return $mdgriffith$elm_ui$Element$text('Jojo');
	} else {
		var user = maybeUser.a;
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(5),
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(50)),
					$mdgriffith$elm_ui$Element$centerY
				]),
			_List_fromArray(
				[
					A2($author$project$Ui$avatar, user.ac, user.K),
					$mdgriffith$elm_ui$Element$text(
					$author$project$Page$Competition$shortUsername(user.ac))
				]));
	}
};
var $mdgriffith$elm_ui$Internal$Model$paddingName = F4(
	function (top, right, bottom, left) {
		return 'pad-' + ($elm$core$String$fromInt(top) + ('-' + ($elm$core$String$fromInt(right) + ('-' + ($elm$core$String$fromInt(bottom) + ('-' + $elm$core$String$fromInt(left)))))));
	});
var $mdgriffith$elm_ui$Element$paddingEach = function (_v0) {
	var top = _v0.d6;
	var right = _v0.dV;
	var bottom = _v0.dn;
	var left = _v0.dH;
	if (_Utils_eq(top, right) && (_Utils_eq(top, bottom) && _Utils_eq(top, left))) {
		var topFloat = top;
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$padding,
			A5(
				$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
				'p-' + $elm$core$String$fromInt(top),
				topFloat,
				topFloat,
				topFloat,
				topFloat));
	} else {
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$padding,
			A5(
				$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
				A4($mdgriffith$elm_ui$Internal$Model$paddingName, top, right, bottom, left),
				top,
				right,
				bottom,
				left));
	}
};
var $author$project$Page$Competition$viewStandingsHeader = function (title) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$paddingEach(
				{dn: 5, dH: 0, dV: 0, d6: 0}),
				$mdgriffith$elm_ui$Element$Font$size(14)
			]),
		$mdgriffith$elm_ui$Element$text(title));
};
var $author$project$Page$Competition$viewStandings = F2(
	function (_v0, standings) {
		var unghighlightedViewStandingsBetStat = $author$project$Page$Competition$viewStandingsBetStat(false);
		var sorted = A2(
			$elm$core$List$indexedMap,
			F2(
				function (i, s) {
					return _Utils_Tuple2(i + 1, s);
				}),
			$elm$core$List$reverse(
				A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.bj;
					},
					standings)));
		return A2(
			$mdgriffith$elm_ui$Element$table,
			_List_Nil,
			{
				gg: _List_fromArray(
					[
						{
						cg: $author$project$Page$Competition$viewStandingsHeader('Pos'),
						h4: A2(
							$elm$core$Basics$composeR,
							$elm$core$Tuple$first,
							A2(
								$elm$core$Basics$composeR,
								$elm$core$String$fromInt,
								A2(
									$elm$core$Basics$composeR,
									$mdgriffith$elm_ui$Element$text,
									A2(
										$elm$core$Basics$composeR,
										$mdgriffith$elm_ui$Element$el(_List_Nil),
										A2(
											$elm$core$Basics$composeR,
											$elm$core$List$singleton,
											$mdgriffith$elm_ui$Element$row(
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$height(
														$mdgriffith$elm_ui$Element$px(50))
													]))))))),
						fA: $mdgriffith$elm_ui$Element$fillPortion(1)
					},
						{
						cg: $author$project$Page$Competition$viewStandingsHeader('Gambler'),
						h4: A2(
							$elm$core$Basics$composeR,
							$elm$core$Tuple$second,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.c7;
								},
								$author$project$Page$Competition$viewStandingsGambler)),
						fA: $mdgriffith$elm_ui$Element$fillPortion(5)
					},
						{
						cg: $author$project$Page$Competition$viewStandingsHeader('Home'),
						h4: function (rec) {
							return function (us) {
								return unghighlightedViewStandingsBetStat(
									_Utils_Tuple2(
										function ($) {
											return $.eG;
										}(us),
										function ($) {
											return $.eH;
										}(us)));
							}(rec.b);
						},
						fA: $mdgriffith$elm_ui$Element$fillPortion(2)
					},
						{
						cg: $author$project$Page$Competition$viewStandingsHeader('Draw'),
						h4: function (rec) {
							return function (us) {
								return unghighlightedViewStandingsBetStat(
									_Utils_Tuple2(
										function ($) {
											return $.fD;
										}(us),
										function ($) {
											return $.fE;
										}(us)));
							}(rec.b);
						},
						fA: $mdgriffith$elm_ui$Element$fillPortion(2)
					},
						{
						cg: $author$project$Page$Competition$viewStandingsHeader('Away'),
						h4: function (rec) {
							return function (us) {
								return unghighlightedViewStandingsBetStat(
									_Utils_Tuple2(
										function ($) {
											return $.ek;
										}(us),
										function ($) {
											return $.el;
										}(us)));
							}(rec.b);
						},
						fA: $mdgriffith$elm_ui$Element$fillPortion(2)
					},
						{
						cg: $author$project$Page$Competition$viewStandingsHeader('Total'),
						h4: function (rec) {
							return function (us) {
								return A2(
									$author$project$Page$Competition$viewStandingsBetStat,
									true,
									_Utils_Tuple2(
										function ($) {
											return $.bj;
										}(us),
										function ($) {
											return $.bk;
										}(us)));
							}(rec.b);
						},
						fA: $mdgriffith$elm_ui$Element$fillPortion(2)
					}
					]),
				w: sorted
			});
	});
var $author$project$Page$Competition$TabMatchday = 0;
var $author$project$Page$Competition$TabRace = 3;
var $author$project$Page$Competition$TabTable = 1;
var $author$project$Page$Competition$TabTimeline = 2;
var $author$project$Page$Competition$tabToTabName = function (tab) {
	switch (tab.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $author$project$Page$Competition$TabClicked = function (a) {
	return {$: 6, a: a};
};
var $author$project$Colors$gray200 = A3($mdgriffith$elm_ui$Element$rgb255, 229, 231, 235);
var $feathericons$elm_feather$FeatherIcons$barChart = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'bar-chart',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('12'),
					$elm$svg$Svg$Attributes$y1('20'),
					$elm$svg$Svg$Attributes$x2('12'),
					$elm$svg$Svg$Attributes$y2('10')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('18'),
					$elm$svg$Svg$Attributes$y1('20'),
					$elm$svg$Svg$Attributes$x2('18'),
					$elm$svg$Svg$Attributes$y2('4')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('6'),
					$elm$svg$Svg$Attributes$y1('20'),
					$elm$svg$Svg$Attributes$x2('6'),
					$elm$svg$Svg$Attributes$y2('16')
				]),
			_List_Nil)
		]));
var $elm$svg$Svg$Attributes$rx = _VirtualDom_attribute('rx');
var $elm$svg$Svg$Attributes$ry = _VirtualDom_attribute('ry');
var $feathericons$elm_feather$FeatherIcons$calendar = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'calendar',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$rect,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x('3'),
					$elm$svg$Svg$Attributes$y('4'),
					$elm$svg$Svg$Attributes$width('18'),
					$elm$svg$Svg$Attributes$height('18'),
					$elm$svg$Svg$Attributes$rx('2'),
					$elm$svg$Svg$Attributes$ry('2')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('16'),
					$elm$svg$Svg$Attributes$y1('2'),
					$elm$svg$Svg$Attributes$x2('16'),
					$elm$svg$Svg$Attributes$y2('6')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('8'),
					$elm$svg$Svg$Attributes$y1('2'),
					$elm$svg$Svg$Attributes$x2('8'),
					$elm$svg$Svg$Attributes$y2('6')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('3'),
					$elm$svg$Svg$Attributes$y1('10'),
					$elm$svg$Svg$Attributes$x2('21'),
					$elm$svg$Svg$Attributes$y2('10')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$target = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'target',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$circle,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$cx('12'),
					$elm$svg$Svg$Attributes$cy('12'),
					$elm$svg$Svg$Attributes$r('10')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$circle,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$cx('12'),
					$elm$svg$Svg$Attributes$cy('12'),
					$elm$svg$Svg$Attributes$r('6')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$circle,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$cx('12'),
					$elm$svg$Svg$Attributes$cy('12'),
					$elm$svg$Svg$Attributes$r('2')
				]),
			_List_Nil)
		]));
var $feathericons$elm_feather$FeatherIcons$users = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'users',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$circle,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$cx('9'),
					$elm$svg$Svg$Attributes$cy('7'),
					$elm$svg$Svg$Attributes$r('4')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M23 21v-2a4 4 0 0 0-3-3.87')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M16 3.13a4 4 0 0 1 0 7.75')
				]),
			_List_Nil)
		]));
var $author$project$Page$Competition$tabNameToElement = F2(
	function (tabName, isPhone) {
		switch (tabName) {
			case 0:
				return isPhone ? A2(
					$mdgriffith$elm_ui$Element$el,
					_List_Nil,
					$mdgriffith$elm_ui$Element$html(
						A2(
							$feathericons$elm_feather$FeatherIcons$toHtml,
							_List_Nil,
							A2($feathericons$elm_feather$FeatherIcons$withSize, 24, $feathericons$elm_feather$FeatherIcons$calendar)))) : $mdgriffith$elm_ui$Element$text('Matchday');
			case 1:
				return isPhone ? A2(
					$mdgriffith$elm_ui$Element$el,
					_List_Nil,
					$mdgriffith$elm_ui$Element$html(
						A2(
							$feathericons$elm_feather$FeatherIcons$toHtml,
							_List_Nil,
							A2($feathericons$elm_feather$FeatherIcons$withSize, 24, $feathericons$elm_feather$FeatherIcons$users)))) : $mdgriffith$elm_ui$Element$text('Table');
			case 2:
				return isPhone ? A2(
					$mdgriffith$elm_ui$Element$el,
					_List_Nil,
					$mdgriffith$elm_ui$Element$html(
						A2(
							$feathericons$elm_feather$FeatherIcons$toHtml,
							_List_Nil,
							A2($feathericons$elm_feather$FeatherIcons$withSize, 24, $feathericons$elm_feather$FeatherIcons$target)))) : $mdgriffith$elm_ui$Element$text('Timeline');
			default:
				return isPhone ? A2(
					$mdgriffith$elm_ui$Element$el,
					_List_Nil,
					$mdgriffith$elm_ui$Element$html(
						A2(
							$feathericons$elm_feather$FeatherIcons$toHtml,
							_List_Nil,
							A2($feathericons$elm_feather$FeatherIcons$withSize, 24, $feathericons$elm_feather$FeatherIcons$barChart)))) : $mdgriffith$elm_ui$Element$text('Race');
		}
	});
var $author$project$Page$Competition$viewTab = F3(
	function (device, tabName, isActive) {
		var isPhone = function () {
			var _v0 = device.ga;
			if (!_v0) {
				return true;
			} else {
				return false;
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color(
					isActive ? $author$project$Colors$white : $author$project$Colors$gray200),
					$mdgriffith$elm_ui$Element$width(
					isPhone ? $mdgriffith$elm_ui$Element$fill : $mdgriffith$elm_ui$Element$shrink),
					A2(
					$mdgriffith$elm_ui$Element$paddingXY,
					isPhone ? 20 : 40,
					10)
				]),
			{
				dG: A2($author$project$Page$Competition$tabNameToElement, tabName, isPhone),
				e$: $elm$core$Maybe$Just(
					$author$project$Page$Competition$TabClicked(tabName))
			});
	});
var $author$project$Page$Competition$viewTabs = F2(
	function (device, activeTab) {
		var isPhone = function () {
			var _v0 = device.ga;
			if (!_v0) {
				return true;
			} else {
				return false;
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Element$row,
			isPhone ? _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spaceEvenly,
					$mdgriffith$elm_ui$Element$scrollbarX,
					$mdgriffith$elm_ui$Element$htmlAttribute(
					A2($elm$html$Html$Attributes$style, 'flex-basis', 'auto'))
				]) : _List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink)
				]),
			_List_fromArray(
				[
					A3(
					$author$project$Page$Competition$viewTab,
					device,
					0,
					!$author$project$Page$Competition$tabToTabName(activeTab)),
					A3(
					$author$project$Page$Competition$viewTab,
					device,
					2,
					$author$project$Page$Competition$tabToTabName(activeTab) === 2),
					A3(
					$author$project$Page$Competition$viewTab,
					device,
					1,
					$author$project$Page$Competition$tabToTabName(activeTab) === 1),
					A3(
					$author$project$Page$Competition$viewTab,
					device,
					3,
					$author$project$Page$Competition$tabToTabName(activeTab) === 3)
				]));
	});
var $author$project$Page$Competition$view = F2(
	function (device, model) {
		var content = function () {
			var _v0 = model.aR;
			switch (_v0.$) {
				case 0:
					var maybeMatchday = _v0.a;
					var matchdayRemoteData = _v0.b;
					switch (matchdayRemoteData.$) {
						case 1:
							return $mdgriffith$elm_ui$Element$text('Loading...');
						case 3:
							var maybeMatchdayData = matchdayRemoteData.a;
							if (maybeMatchdayData.$ === 1) {
								return $mdgriffith$elm_ui$Element$text('Not found..');
							} else {
								var matchdayData = maybeMatchdayData.a;
								return A6($author$project$Page$Competition$viewMatchday, device, model.cx, model.cM, model.y, maybeMatchday, matchdayData);
							}
						default:
							return $mdgriffith$elm_ui$Element$text('Error...');
					}
				case 1:
					var standingsRemoteData = _v0.a;
					switch (standingsRemoteData.$) {
						case 1:
							return $mdgriffith$elm_ui$Element$text('Loading...');
						case 3:
							var standings = standingsRemoteData.a;
							return A2($author$project$Page$Competition$viewStandings, device, standings);
						default:
							return $mdgriffith$elm_ui$Element$text('Error...');
					}
				case 2:
					var timelineRemoteData = _v0.a;
					switch (timelineRemoteData.$) {
						case 1:
							return $mdgriffith$elm_ui$Element$text('Loading...');
						case 3:
							var maybeTimeline = timelineRemoteData.a;
							if (maybeTimeline.$ === 1) {
								return $mdgriffith$elm_ui$Element$text('Not found..');
							} else {
								var timeline = maybeTimeline.a;
								return A6($author$project$Page$Competition$viewMatchTable, device, model.cx, model.cM, model.y, timeline.cz, timeline.c6);
							}
						default:
							return $mdgriffith$elm_ui$Element$text('Error...');
					}
				default:
					var progressionRemoteData = _v0.a;
					var hinted = _v0.b;
					switch (progressionRemoteData.$) {
						case 1:
							return $mdgriffith$elm_ui$Element$text('Loading...');
						case 3:
							var progression = progressionRemoteData.a;
							return A2($author$project$Page$Competition$viewRace, progression, hinted);
						default:
							return $mdgriffith$elm_ui$Element$text('Error...');
					}
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					A2($author$project$Page$Competition$viewTabs, device, model.aR),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$padding(20),
							$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$white)
						]),
					content)
				]));
	});
var $author$project$Page$CreateCompetition$EnteredName = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Focus = 0;
var $mdgriffith$elm_ui$Internal$Model$PseudoSelector = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$focus = $mdgriffith$elm_ui$Internal$Flag$flag(31);
var $mdgriffith$elm_ui$Internal$Model$Nearby = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$TransformComponent = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $elm$virtual_dom$VirtualDom$mapAttribute = _VirtualDom_mapAttribute;
var $mdgriffith$elm_ui$Internal$Model$mapAttrFromStyle = F2(
	function (fn, attr) {
		switch (attr.$) {
			case 0:
				return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
			case 2:
				var description = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Describe(description);
			case 6:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$AlignX(x);
			case 5:
				var y = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$AlignY(y);
			case 7:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Width(x);
			case 8:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Height(x);
			case 3:
				var x = attr.a;
				var y = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$Class, x, y);
			case 4:
				var flag = attr.a;
				var style = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$StyleClass, flag, style);
			case 9:
				var location = attr.a;
				var elem = attr.b;
				return A2(
					$mdgriffith$elm_ui$Internal$Model$Nearby,
					location,
					A2($mdgriffith$elm_ui$Internal$Model$map, fn, elem));
			case 1:
				var htmlAttr = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Attr(
					A2($elm$virtual_dom$VirtualDom$mapAttribute, fn, htmlAttr));
			default:
				var fl = attr.a;
				var trans = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$TransformComponent, fl, trans);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$removeNever = function (style) {
	return A2($mdgriffith$elm_ui$Internal$Model$mapAttrFromStyle, $elm$core$Basics$never, style);
};
var $mdgriffith$elm_ui$Internal$Model$unwrapDecsHelper = F2(
	function (attr, _v0) {
		var styles = _v0.a;
		var trans = _v0.b;
		var _v1 = $mdgriffith$elm_ui$Internal$Model$removeNever(attr);
		switch (_v1.$) {
			case 4:
				var style = _v1.b;
				return _Utils_Tuple2(
					A2($elm$core$List$cons, style, styles),
					trans);
			case 10:
				var flag = _v1.a;
				var component = _v1.b;
				return _Utils_Tuple2(
					styles,
					A2($mdgriffith$elm_ui$Internal$Model$composeTransformation, trans, component));
			default:
				return _Utils_Tuple2(styles, trans);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$unwrapDecorations = function (attrs) {
	var _v0 = A3(
		$elm$core$List$foldl,
		$mdgriffith$elm_ui$Internal$Model$unwrapDecsHelper,
		_Utils_Tuple2(_List_Nil, $mdgriffith$elm_ui$Internal$Model$Untransformed),
		attrs);
	var styles = _v0.a;
	var transform = _v0.b;
	return A2(
		$elm$core$List$cons,
		$mdgriffith$elm_ui$Internal$Model$Transform(transform),
		styles);
};
var $mdgriffith$elm_ui$Element$focused = function (decs) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$focus,
		A2(
			$mdgriffith$elm_ui$Internal$Model$PseudoSelector,
			0,
			$mdgriffith$elm_ui$Internal$Model$unwrapDecorations(decs)));
};
var $mdgriffith$elm_ui$Element$Input$Above = 2;
var $mdgriffith$elm_ui$Element$Input$Label = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Element$Input$labelAbove = $mdgriffith$elm_ui$Element$Input$Label(2);
var $mdgriffith$elm_ui$Internal$Model$Max = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$maximum = F2(
	function (i, l) {
		return A2($mdgriffith$elm_ui$Internal$Model$Max, i, l);
	});
var $mdgriffith$elm_ui$Element$Input$TextInputNode = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Element$Input$TextArea = {$: 1};
var $mdgriffith$elm_ui$Internal$Model$LivePolite = {$: 6};
var $mdgriffith$elm_ui$Element$Region$announce = $mdgriffith$elm_ui$Internal$Model$Describe($mdgriffith$elm_ui$Internal$Model$LivePolite);
var $mdgriffith$elm_ui$Element$Input$applyLabel = F3(
	function (attrs, label, input) {
		if (label.$ === 1) {
			var labelText = label.a;
			return A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asColumn,
				$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
				attrs,
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[input])));
		} else {
			var position = label.a;
			var labelAttrs = label.b;
			var labelChild = label.c;
			var labelElement = A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asEl,
				$mdgriffith$elm_ui$Internal$Model$div,
				labelAttrs,
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[labelChild])));
			switch (position) {
				case 2:
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asColumn,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.c4),
							attrs),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[labelElement, input])));
				case 3:
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asColumn,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.c4),
							attrs),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[input, labelElement])));
				case 0:
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asRow,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.c4),
							attrs),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[input, labelElement])));
				default:
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asRow,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.c4),
							attrs),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[labelElement, input])));
			}
		}
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $mdgriffith$elm_ui$Element$Input$autofill = A2(
	$elm$core$Basics$composeL,
	$mdgriffith$elm_ui$Internal$Model$Attr,
	$elm$html$Html$Attributes$attribute('autocomplete'));
var $mdgriffith$elm_ui$Internal$Model$Behind = 5;
var $mdgriffith$elm_ui$Element$createNearby = F2(
	function (loc, element) {
		if (element.$ === 3) {
			return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
		} else {
			return A2($mdgriffith$elm_ui$Internal$Model$Nearby, loc, element);
		}
	});
var $mdgriffith$elm_ui$Element$behindContent = function (element) {
	return A2($mdgriffith$elm_ui$Element$createNearby, 5, element);
};
var $mdgriffith$elm_ui$Internal$Model$MoveY = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$moveY = $mdgriffith$elm_ui$Internal$Flag$flag(26);
var $mdgriffith$elm_ui$Element$moveUp = function (y) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$TransformComponent,
		$mdgriffith$elm_ui$Internal$Flag$moveY,
		$mdgriffith$elm_ui$Internal$Model$MoveY(-y));
};
var $mdgriffith$elm_ui$Element$Input$calcMoveToCompensateForPadding = function (attrs) {
	var gatherSpacing = F2(
		function (attr, found) {
			if ((attr.$ === 4) && (attr.b.$ === 5)) {
				var _v2 = attr.b;
				var x = _v2.b;
				var y = _v2.c;
				if (found.$ === 1) {
					return $elm$core$Maybe$Just(y);
				} else {
					return found;
				}
			} else {
				return found;
			}
		});
	var _v0 = A3($elm$core$List$foldr, gatherSpacing, $elm$core$Maybe$Nothing, attrs);
	if (_v0.$ === 1) {
		return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
	} else {
		var vSpace = _v0.a;
		return $mdgriffith$elm_ui$Element$moveUp(
			$elm$core$Basics$floor(vSpace / 2));
	}
};
var $mdgriffith$elm_ui$Element$rgb = F3(
	function (r, g, b) {
		return A4($mdgriffith$elm_ui$Internal$Model$Rgba, r, g, b, 1);
	});
var $mdgriffith$elm_ui$Element$Input$darkGrey = A3($mdgriffith$elm_ui$Element$rgb, 186 / 255, 189 / 255, 182 / 255);
var $mdgriffith$elm_ui$Element$Input$defaultTextPadding = A2($mdgriffith$elm_ui$Element$paddingXY, 12, 12);
var $mdgriffith$elm_ui$Element$Input$white = A3($mdgriffith$elm_ui$Element$rgb, 1, 1, 1);
var $mdgriffith$elm_ui$Element$Input$defaultTextBoxStyle = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$Input$defaultTextPadding,
		$mdgriffith$elm_ui$Element$Border$rounded(3),
		$mdgriffith$elm_ui$Element$Border$color($mdgriffith$elm_ui$Element$Input$darkGrey),
		$mdgriffith$elm_ui$Element$Background$color($mdgriffith$elm_ui$Element$Input$white),
		$mdgriffith$elm_ui$Element$Border$width(1),
		$mdgriffith$elm_ui$Element$spacing(5),
		$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
		$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink)
	]);
var $mdgriffith$elm_ui$Element$Input$getHeight = function (attr) {
	if (attr.$ === 8) {
		var h = attr.a;
		return $elm$core$Maybe$Just(h);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Internal$Model$Label = function (a) {
	return {$: 5, a: a};
};
var $mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute = function (label) {
	if (label.$ === 1) {
		var textLabel = label.a;
		return $mdgriffith$elm_ui$Internal$Model$Describe(
			$mdgriffith$elm_ui$Internal$Model$Label(textLabel));
	} else {
		return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
	}
};
var $mdgriffith$elm_ui$Internal$Model$InFront = 4;
var $mdgriffith$elm_ui$Element$inFront = function (element) {
	return A2($mdgriffith$elm_ui$Element$createNearby, 4, element);
};
var $mdgriffith$elm_ui$Element$Input$isConstrained = function (len) {
	isConstrained:
	while (true) {
		switch (len.$) {
			case 1:
				return false;
			case 0:
				return true;
			case 2:
				return true;
			case 3:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isConstrained;
			default:
				var l = len.b;
				return true;
		}
	}
};
var $mdgriffith$elm_ui$Element$Input$isHiddenLabel = function (label) {
	if (label.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $mdgriffith$elm_ui$Element$Input$isStacked = function (label) {
	if (!label.$) {
		var loc = label.a;
		switch (loc) {
			case 0:
				return false;
			case 1:
				return false;
			case 2:
				return true;
			default:
				return true;
		}
	} else {
		return true;
	}
};
var $mdgriffith$elm_ui$Element$Input$negateBox = function (box) {
	return {dn: -box.dn, dH: -box.dH, dV: -box.dV, d6: -box.d6};
};
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $mdgriffith$elm_ui$Element$Input$isFill = function (len) {
	isFill:
	while (true) {
		switch (len.$) {
			case 2:
				return true;
			case 1:
				return false;
			case 0:
				return false;
			case 3:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isFill;
			default:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isFill;
		}
	}
};
var $mdgriffith$elm_ui$Element$Input$isPixel = function (len) {
	isPixel:
	while (true) {
		switch (len.$) {
			case 1:
				return false;
			case 0:
				return true;
			case 2:
				return false;
			case 3:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isPixel;
			default:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isPixel;
		}
	}
};
var $mdgriffith$elm_ui$Internal$Model$paddingNameFloat = F4(
	function (top, right, bottom, left) {
		return 'pad-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(top) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(right) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(bottom) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(left)))))));
	});
var $mdgriffith$elm_ui$Element$Input$redistributeOver = F4(
	function (isMultiline, stacked, attr, els) {
		switch (attr.$) {
			case 9:
				return _Utils_update(
					els,
					{
						ag: A2($elm$core$List$cons, attr, els.ag)
					});
			case 7:
				var width = attr.a;
				return $mdgriffith$elm_ui$Element$Input$isFill(width) ? _Utils_update(
					els,
					{
						aw: A2($elm$core$List$cons, attr, els.aw),
						aP: A2($elm$core$List$cons, attr, els.aP),
						ag: A2($elm$core$List$cons, attr, els.ag)
					}) : (stacked ? _Utils_update(
					els,
					{
						aw: A2($elm$core$List$cons, attr, els.aw)
					}) : _Utils_update(
					els,
					{
						ag: A2($elm$core$List$cons, attr, els.ag)
					}));
			case 8:
				var height = attr.a;
				return (!stacked) ? _Utils_update(
					els,
					{
						aw: A2($elm$core$List$cons, attr, els.aw),
						ag: A2($elm$core$List$cons, attr, els.ag)
					}) : ($mdgriffith$elm_ui$Element$Input$isFill(height) ? _Utils_update(
					els,
					{
						aw: A2($elm$core$List$cons, attr, els.aw),
						ag: A2($elm$core$List$cons, attr, els.ag)
					}) : ($mdgriffith$elm_ui$Element$Input$isPixel(height) ? _Utils_update(
					els,
					{
						ag: A2($elm$core$List$cons, attr, els.ag)
					}) : _Utils_update(
					els,
					{
						ag: A2($elm$core$List$cons, attr, els.ag)
					})));
			case 6:
				return _Utils_update(
					els,
					{
						aw: A2($elm$core$List$cons, attr, els.aw)
					});
			case 5:
				return _Utils_update(
					els,
					{
						aw: A2($elm$core$List$cons, attr, els.aw)
					});
			case 4:
				switch (attr.b.$) {
					case 5:
						var _v1 = attr.b;
						return _Utils_update(
							els,
							{
								aw: A2($elm$core$List$cons, attr, els.aw),
								aP: A2($elm$core$List$cons, attr, els.aP),
								ag: A2($elm$core$List$cons, attr, els.ag),
								cC: A2($elm$core$List$cons, attr, els.cC)
							});
					case 7:
						var cls = attr.a;
						var _v2 = attr.b;
						var pad = _v2.a;
						var t = _v2.b;
						var r = _v2.c;
						var b = _v2.d;
						var l = _v2.e;
						if (isMultiline) {
							return _Utils_update(
								els,
								{
									a2: A2($elm$core$List$cons, attr, els.a2),
									ag: A2($elm$core$List$cons, attr, els.ag)
								});
						} else {
							var newTop = t - A2($elm$core$Basics$min, t, b);
							var newLineHeight = $mdgriffith$elm_ui$Element$htmlAttribute(
								A2(
									$elm$html$Html$Attributes$style,
									'line-height',
									'calc(1.0em + ' + ($elm$core$String$fromFloat(
										2 * A2($elm$core$Basics$min, t, b)) + 'px)')));
							var newHeight = $mdgriffith$elm_ui$Element$htmlAttribute(
								A2(
									$elm$html$Html$Attributes$style,
									'height',
									'calc(1.0em + ' + ($elm$core$String$fromFloat(
										2 * A2($elm$core$Basics$min, t, b)) + 'px)')));
							var newBottom = b - A2($elm$core$Basics$min, t, b);
							var reducedVerticalPadding = A2(
								$mdgriffith$elm_ui$Internal$Model$StyleClass,
								$mdgriffith$elm_ui$Internal$Flag$padding,
								A5(
									$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
									A4($mdgriffith$elm_ui$Internal$Model$paddingNameFloat, newTop, r, newBottom, l),
									newTop,
									r,
									newBottom,
									l));
							return _Utils_update(
								els,
								{
									a2: A2($elm$core$List$cons, attr, els.a2),
									aP: A2(
										$elm$core$List$cons,
										newHeight,
										A2($elm$core$List$cons, newLineHeight, els.aP)),
									ag: A2($elm$core$List$cons, reducedVerticalPadding, els.ag)
								});
						}
					case 6:
						var _v3 = attr.b;
						return _Utils_update(
							els,
							{
								a2: A2($elm$core$List$cons, attr, els.a2),
								ag: A2($elm$core$List$cons, attr, els.ag)
							});
					case 10:
						return _Utils_update(
							els,
							{
								a2: A2($elm$core$List$cons, attr, els.a2),
								ag: A2($elm$core$List$cons, attr, els.ag)
							});
					case 2:
						return _Utils_update(
							els,
							{
								aw: A2($elm$core$List$cons, attr, els.aw)
							});
					case 1:
						var _v4 = attr.b;
						return _Utils_update(
							els,
							{
								aw: A2($elm$core$List$cons, attr, els.aw)
							});
					default:
						var flag = attr.a;
						var cls = attr.b;
						return _Utils_update(
							els,
							{
								ag: A2($elm$core$List$cons, attr, els.ag)
							});
				}
			case 0:
				return els;
			case 1:
				var a = attr.a;
				return _Utils_update(
					els,
					{
						aP: A2($elm$core$List$cons, attr, els.aP)
					});
			case 2:
				return _Utils_update(
					els,
					{
						aP: A2($elm$core$List$cons, attr, els.aP)
					});
			case 3:
				return _Utils_update(
					els,
					{
						ag: A2($elm$core$List$cons, attr, els.ag)
					});
			default:
				return _Utils_update(
					els,
					{
						aP: A2($elm$core$List$cons, attr, els.aP)
					});
		}
	});
var $mdgriffith$elm_ui$Element$Input$redistribute = F3(
	function (isMultiline, stacked, attrs) {
		return function (redist) {
			return {
				a2: $elm$core$List$reverse(redist.a2),
				aw: $elm$core$List$reverse(redist.aw),
				aP: $elm$core$List$reverse(redist.aP),
				ag: $elm$core$List$reverse(redist.ag),
				cC: $elm$core$List$reverse(redist.cC)
			};
		}(
			A3(
				$elm$core$List$foldl,
				A2($mdgriffith$elm_ui$Element$Input$redistributeOver, isMultiline, stacked),
				{a2: _List_Nil, aw: _List_Nil, aP: _List_Nil, ag: _List_Nil, cC: _List_Nil},
				attrs));
	});
var $mdgriffith$elm_ui$Element$Input$renderBox = function (_v0) {
	var top = _v0.d6;
	var right = _v0.dV;
	var bottom = _v0.dn;
	var left = _v0.dH;
	return $elm$core$String$fromInt(top) + ('px ' + ($elm$core$String$fromInt(right) + ('px ' + ($elm$core$String$fromInt(bottom) + ('px ' + ($elm$core$String$fromInt(left) + 'px'))))));
};
var $mdgriffith$elm_ui$Internal$Model$Transparency = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$transparency = $mdgriffith$elm_ui$Internal$Flag$flag(0);
var $mdgriffith$elm_ui$Element$alpha = function (o) {
	var transparency = function (x) {
		return 1 - x;
	}(
		A2(
			$elm$core$Basics$min,
			1.0,
			A2($elm$core$Basics$max, 0.0, o)));
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$transparency,
		A2(
			$mdgriffith$elm_ui$Internal$Model$Transparency,
			'transparency-' + $mdgriffith$elm_ui$Internal$Model$floatClass(transparency),
			transparency));
};
var $mdgriffith$elm_ui$Element$Input$charcoal = A3($mdgriffith$elm_ui$Element$rgb, 136 / 255, 138 / 255, 133 / 255);
var $mdgriffith$elm_ui$Element$rgba = $mdgriffith$elm_ui$Internal$Model$Rgba;
var $mdgriffith$elm_ui$Element$Input$renderPlaceholder = F3(
	function (_v0, forPlaceholder, on) {
		var placeholderAttrs = _v0.a;
		var placeholderEl = _v0.b;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				forPlaceholder,
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color($mdgriffith$elm_ui$Element$Input$charcoal),
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.eY + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.g9)),
							$mdgriffith$elm_ui$Element$clip,
							$mdgriffith$elm_ui$Element$Border$color(
							A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0)),
							$mdgriffith$elm_ui$Element$Background$color(
							A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0)),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$alpha(
							on ? 1 : 0)
						]),
					placeholderAttrs)),
			placeholderEl);
	});
var $mdgriffith$elm_ui$Element$scrollbarY = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.hl);
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$html$Html$Attributes$spellcheck = $elm$html$Html$Attributes$boolProperty('spellcheck');
var $mdgriffith$elm_ui$Element$Input$spellcheck = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Attributes$spellcheck);
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $mdgriffith$elm_ui$Element$Input$value = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Attributes$value);
var $mdgriffith$elm_ui$Element$Input$textHelper = F3(
	function (textInput, attrs, textOptions) {
		var withDefaults = _Utils_ap($mdgriffith$elm_ui$Element$Input$defaultTextBoxStyle, attrs);
		var redistributed = A3(
			$mdgriffith$elm_ui$Element$Input$redistribute,
			_Utils_eq(textInput.aV, $mdgriffith$elm_ui$Element$Input$TextArea),
			$mdgriffith$elm_ui$Element$Input$isStacked(textOptions.dG),
			withDefaults);
		var onlySpacing = function (attr) {
			if ((attr.$ === 4) && (attr.b.$ === 5)) {
				var _v9 = attr.b;
				return true;
			} else {
				return false;
			}
		};
		var heightConstrained = function () {
			var _v7 = textInput.aV;
			if (!_v7.$) {
				var inputType = _v7.a;
				return false;
			} else {
				return A2(
					$elm$core$Maybe$withDefault,
					false,
					A2(
						$elm$core$Maybe$map,
						$mdgriffith$elm_ui$Element$Input$isConstrained,
						$elm$core$List$head(
							$elm$core$List$reverse(
								A2($elm$core$List$filterMap, $mdgriffith$elm_ui$Element$Input$getHeight, withDefaults)))));
			}
		}();
		var getPadding = function (attr) {
			if ((attr.$ === 4) && (attr.b.$ === 7)) {
				var cls = attr.a;
				var _v6 = attr.b;
				var pad = _v6.a;
				var t = _v6.b;
				var r = _v6.c;
				var b = _v6.d;
				var l = _v6.e;
				return $elm$core$Maybe$Just(
					{
						dn: A2(
							$elm$core$Basics$max,
							0,
							$elm$core$Basics$floor(b - 3)),
						dH: A2(
							$elm$core$Basics$max,
							0,
							$elm$core$Basics$floor(l - 3)),
						dV: A2(
							$elm$core$Basics$max,
							0,
							$elm$core$Basics$floor(r - 3)),
						d6: A2(
							$elm$core$Basics$max,
							0,
							$elm$core$Basics$floor(t - 3))
					});
			} else {
				return $elm$core$Maybe$Nothing;
			}
		};
		var parentPadding = A2(
			$elm$core$Maybe$withDefault,
			{dn: 0, dH: 0, dV: 0, d6: 0},
			$elm$core$List$head(
				$elm$core$List$reverse(
					A2($elm$core$List$filterMap, getPadding, withDefaults))));
		var inputElement = A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			function () {
				var _v3 = textInput.aV;
				if (!_v3.$) {
					var inputType = _v3.a;
					return $mdgriffith$elm_ui$Internal$Model$NodeName('input');
				} else {
					return $mdgriffith$elm_ui$Internal$Model$NodeName('textarea');
				}
			}(),
			_Utils_ap(
				function () {
					var _v4 = textInput.aV;
					if (!_v4.$) {
						var inputType = _v4.a;
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Internal$Model$Attr(
								$elm$html$Html$Attributes$type_(inputType)),
								$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.gL)
							]);
					} else {
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$clip,
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.gH),
								$mdgriffith$elm_ui$Element$Input$calcMoveToCompensateForPadding(withDefaults),
								$mdgriffith$elm_ui$Element$paddingEach(parentPadding),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								A2(
									$elm$html$Html$Attributes$style,
									'margin',
									$mdgriffith$elm_ui$Element$Input$renderBox(
										$mdgriffith$elm_ui$Element$Input$negateBox(parentPadding)))),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								A2($elm$html$Html$Attributes$style, 'box-sizing', 'content-box'))
							]);
					}
				}(),
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Input$value(textOptions.hE),
							$mdgriffith$elm_ui$Internal$Model$Attr(
							$elm$html$Html$Events$onInput(textOptions.g2)),
							$mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute(textOptions.dG),
							$mdgriffith$elm_ui$Element$Input$spellcheck(textInput.bw),
							A2(
							$elm$core$Maybe$withDefault,
							$mdgriffith$elm_ui$Internal$Model$NoAttribute,
							A2($elm$core$Maybe$map, $mdgriffith$elm_ui$Element$Input$autofill, textInput.br))
						]),
					redistributed.aP)),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(_List_Nil));
		var wrappedInput = function () {
			var _v0 = textInput.aV;
			if (_v0.$ === 1) {
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asEl,
					$mdgriffith$elm_ui$Internal$Model$div,
					_Utils_ap(
						(heightConstrained ? $elm$core$List$cons($mdgriffith$elm_ui$Element$scrollbarY) : $elm$core$Basics$identity)(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									A2($elm$core$List$any, $mdgriffith$elm_ui$Element$Input$hasFocusStyle, withDefaults) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.eA),
									$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.gK)
								])),
						redistributed.ag),
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[
								A4(
								$mdgriffith$elm_ui$Internal$Model$element,
								$mdgriffith$elm_ui$Internal$Model$asParagraph,
								$mdgriffith$elm_ui$Internal$Model$div,
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									A2(
										$elm$core$List$cons,
										$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
										A2(
											$elm$core$List$cons,
											$mdgriffith$elm_ui$Element$inFront(inputElement),
											A2(
												$elm$core$List$cons,
												$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.gJ),
												redistributed.cC)))),
								$mdgriffith$elm_ui$Internal$Model$Unkeyed(
									function () {
										if (textOptions.hE === '') {
											var _v1 = textOptions.hb;
											if (_v1.$ === 1) {
												return _List_fromArray(
													[
														$mdgriffith$elm_ui$Element$text('\u00A0')
													]);
											} else {
												var place = _v1.a;
												return _List_fromArray(
													[
														A3($mdgriffith$elm_ui$Element$Input$renderPlaceholder, place, _List_Nil, textOptions.hE === '')
													]);
											}
										} else {
											return _List_fromArray(
												[
													$mdgriffith$elm_ui$Internal$Model$unstyled(
													A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Style$classes.gI)
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(textOptions.hE + '\u00A0')
															])))
												]);
										}
									}()))
							])));
			} else {
				var inputType = _v0.a;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asEl,
					$mdgriffith$elm_ui$Internal$Model$div,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						A2(
							$elm$core$List$cons,
							A2($elm$core$List$any, $mdgriffith$elm_ui$Element$Input$hasFocusStyle, withDefaults) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.eA),
							$elm$core$List$concat(
								_List_fromArray(
									[
										redistributed.ag,
										function () {
										var _v2 = textOptions.hb;
										if (_v2.$ === 1) {
											return _List_Nil;
										} else {
											var place = _v2.a;
											return _List_fromArray(
												[
													$mdgriffith$elm_ui$Element$behindContent(
													A3($mdgriffith$elm_ui$Element$Input$renderPlaceholder, place, redistributed.a2, textOptions.hE === ''))
												]);
										}
									}()
									])))),
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[inputElement])));
			}
		}();
		return A3(
			$mdgriffith$elm_ui$Element$Input$applyLabel,
			A2(
				$elm$core$List$cons,
				A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$cursor, $mdgriffith$elm_ui$Internal$Style$classes.gl),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$Input$isHiddenLabel(textOptions.dG) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Element$spacing(5),
					A2($elm$core$List$cons, $mdgriffith$elm_ui$Element$Region$announce, redistributed.aw))),
			textOptions.dG,
			wrappedInput);
	});
var $mdgriffith$elm_ui$Element$Input$text = $mdgriffith$elm_ui$Element$Input$textHelper(
	{
		br: $elm$core$Maybe$Nothing,
		bw: false,
		aV: $mdgriffith$elm_ui$Element$Input$TextInputNode('text')
	});
var $author$project$Ui$input = F3(
	function (label, text, onChange) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$text,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					A2($mdgriffith$elm_ui$Element$maximum, 300, $mdgriffith$elm_ui$Element$fill)),
					$mdgriffith$elm_ui$Element$Font$size(16),
					$mdgriffith$elm_ui$Element$Border$color($author$project$Colors$gray200),
					$mdgriffith$elm_ui$Element$focused(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Border$color($author$project$Colors$gray500)
						]))
				]),
			{
				dG: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_Nil,
					A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$size(16)
							]),
						$mdgriffith$elm_ui$Element$text(label))),
				g2: onChange,
				hb: $elm$core$Maybe$Nothing,
				hE: text
			});
	});
var $author$project$Page$CreateCompetition$users = _List_fromArray(
	['Gabicek', 'Kubci', 'Kubicek', 'Kubatura', 'Janula', 'Adam', 'Bavino', 'Some', 'Random', 'Word', 'Another', 'One']);
var $author$project$Ui$Select$InputFocused = {$: 1};
var $author$project$Ui$Select$InputLostFocus = {$: 2};
var $author$project$Ui$Select$OptionClicked = function (a) {
	return {$: 4, a: a};
};
var $author$project$Ui$Select$SelectionRemoved = function (a) {
	return {$: 5, a: a};
};
var $author$project$Ui$Select$TextEntered = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$Attributes$autocomplete = function (bool) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'autocomplete',
		bool ? 'on' : 'off');
};
var $mdgriffith$elm_ui$Internal$Model$Below = 1;
var $mdgriffith$elm_ui$Element$below = function (element) {
	return A2($mdgriffith$elm_ui$Element$createNearby, 1, element);
};
var $author$project$Ui$Select$optionClass = function (id) {
	return id + '-select-option';
};
var $author$project$Ui$Select$blurDecoder = F3(
	function (id, noop, l) {
		return $elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$json$Json$Decode$andThen,
					function (cls) {
						return A2(
							$elm$core$String$contains,
							$author$project$Ui$Select$optionClass(id),
							cls) ? $elm$json$Json$Decode$succeed(noop) : $elm$json$Json$Decode$succeed(l);
					},
					A2(
						$elm$json$Json$Decode$at,
						_List_fromArray(
							['relatedTarget', 'className']),
						$elm$json$Json$Decode$string)),
					$elm$json$Json$Decode$succeed(l)
				]));
	});
var $mdgriffith$elm_ui$Element$clipY = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.gd);
var $author$project$Ui$Select$Arrow = function (a) {
	return {$: 0, a: a};
};
var $author$project$Ui$Select$ArrowDown = 1;
var $author$project$Ui$Select$ArrowUp = 0;
var $author$project$Ui$Select$Enter = {$: 1};
var $author$project$Ui$Select$KeyPressed = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Ui$Select$keyDecoder = function (displayedOptions) {
	return A2(
		$elm$json$Json$Decode$map,
		$author$project$Ui$Select$KeyPressed(displayedOptions),
		A2(
			$elm$json$Json$Decode$andThen,
			function (k) {
				switch (k) {
					case 'ArrowDown':
						return $elm$json$Json$Decode$succeed(
							$author$project$Ui$Select$Arrow(1));
					case 'ArrowUp':
						return $elm$json$Json$Decode$succeed(
							$author$project$Ui$Select$Arrow(0));
					case 'Enter':
						return $elm$json$Json$Decode$succeed($author$project$Ui$Select$Enter);
					default:
						return $elm$json$Json$Decode$fail('not used key');
				}
			},
			A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string)));
};
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $mdgriffith$elm_ui$Internal$Model$Hover = 1;
var $mdgriffith$elm_ui$Internal$Flag$hover = $mdgriffith$elm_ui$Internal$Flag$flag(33);
var $mdgriffith$elm_ui$Element$mouseOver = function (decs) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$hover,
		A2(
			$mdgriffith$elm_ui$Internal$Model$PseudoSelector,
			1,
			$mdgriffith$elm_ui$Internal$Model$unwrapDecorations(decs)));
};
var $elm$html$Html$Events$onFocus = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'focus',
		$elm$json$Json$Decode$succeed(msg));
};
var $mdgriffith$elm_ui$Element$Events$onFocus = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Events$onFocus);
var $feathericons$elm_feather$FeatherIcons$xCircle = A2(
	$feathericons$elm_feather$FeatherIcons$makeBuilder,
	'x-circle',
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$circle,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$cx('12'),
					$elm$svg$Svg$Attributes$cy('12'),
					$elm$svg$Svg$Attributes$r('10')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('15'),
					$elm$svg$Svg$Attributes$y1('9'),
					$elm$svg$Svg$Attributes$x2('9'),
					$elm$svg$Svg$Attributes$y2('15')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$x1('9'),
					$elm$svg$Svg$Attributes$y1('9'),
					$elm$svg$Svg$Attributes$x2('15'),
					$elm$svg$Svg$Attributes$y2('15')
				]),
			_List_Nil)
		]));
var $author$project$Ui$Select$viewSelected = F3(
	function (onDelete, optToString, opt) {
		var icon = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_Nil,
			$mdgriffith$elm_ui$Element$html(
				A2(
					$feathericons$elm_feather$FeatherIcons$toHtml,
					_List_Nil,
					A2($feathericons$elm_feather$FeatherIcons$withSize, 16, $feathericons$elm_feather$FeatherIcons$xCircle))));
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(5),
					$mdgriffith$elm_ui$Element$spacing(5),
					$mdgriffith$elm_ui$Element$Border$width(1),
					$mdgriffith$elm_ui$Element$Border$rounded(5),
					$mdgriffith$elm_ui$Element$Border$color($author$project$Colors$gray500),
					$mdgriffith$elm_ui$Element$Font$size(16)
				]),
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$text(
					optToString(opt)),
					A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_Nil,
					{
						dG: icon,
						e$: $elm$core$Maybe$Just(
							onDelete(opt))
					})
				]));
	});
var $mdgriffith$elm_ui$Internal$Model$Padding = F5(
	function (a, b, c, d, e) {
		return {$: 0, a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_ui$Internal$Model$Spaced = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Model$extractSpacingAndPadding = function (attrs) {
	return A3(
		$elm$core$List$foldr,
		F2(
			function (attr, _v0) {
				var pad = _v0.a;
				var spacing = _v0.b;
				return _Utils_Tuple2(
					function () {
						if (!pad.$) {
							var x = pad.a;
							return pad;
						} else {
							if ((attr.$ === 4) && (attr.b.$ === 7)) {
								var _v3 = attr.b;
								var name = _v3.a;
								var t = _v3.b;
								var r = _v3.c;
								var b = _v3.d;
								var l = _v3.e;
								return $elm$core$Maybe$Just(
									A5($mdgriffith$elm_ui$Internal$Model$Padding, name, t, r, b, l));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}
					}(),
					function () {
						if (!spacing.$) {
							var x = spacing.a;
							return spacing;
						} else {
							if ((attr.$ === 4) && (attr.b.$ === 5)) {
								var _v6 = attr.b;
								var name = _v6.a;
								var x = _v6.b;
								var y = _v6.c;
								return $elm$core$Maybe$Just(
									A3($mdgriffith$elm_ui$Internal$Model$Spaced, name, x, y));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}
					}());
			}),
		_Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing),
		attrs);
};
var $mdgriffith$elm_ui$Element$wrappedRow = F2(
	function (attrs, children) {
		var _v0 = $mdgriffith$elm_ui$Internal$Model$extractSpacingAndPadding(attrs);
		var padded = _v0.a;
		var spaced = _v0.b;
		if (spaced.$ === 1) {
			return A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asRow,
				$mdgriffith$elm_ui$Internal$Model$div,
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.cH + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.bE + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.ec)))),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
							attrs))),
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
		} else {
			var _v2 = spaced.a;
			var spaceName = _v2.a;
			var x = _v2.b;
			var y = _v2.c;
			var newPadding = function () {
				if (!padded.$) {
					var _v5 = padded.a;
					var name = _v5.a;
					var t = _v5.b;
					var r = _v5.c;
					var b = _v5.d;
					var l = _v5.e;
					if ((_Utils_cmp(r, x / 2) > -1) && (_Utils_cmp(b, y / 2) > -1)) {
						var newTop = t - (y / 2);
						var newRight = r - (x / 2);
						var newLeft = l - (x / 2);
						var newBottom = b - (y / 2);
						return $elm$core$Maybe$Just(
							A2(
								$mdgriffith$elm_ui$Internal$Model$StyleClass,
								$mdgriffith$elm_ui$Internal$Flag$padding,
								A5(
									$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
									A4($mdgriffith$elm_ui$Internal$Model$paddingNameFloat, newTop, newRight, newBottom, newLeft),
									newTop,
									newRight,
									newBottom,
									newLeft)));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}();
			if (!newPadding.$) {
				var pad = newPadding.a;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asRow,
					$mdgriffith$elm_ui$Internal$Model$div,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.cH + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.bE + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.ec)))),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
								_Utils_ap(
									attrs,
									_List_fromArray(
										[pad]))))),
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
			} else {
				var halfY = -(y / 2);
				var halfX = -(x / 2);
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asEl,
					$mdgriffith$elm_ui$Internal$Model$div,
					attrs,
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[
								A4(
								$mdgriffith$elm_ui$Internal$Model$element,
								$mdgriffith$elm_ui$Internal$Model$asRow,
								$mdgriffith$elm_ui$Internal$Model$div,
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.cH + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.bE + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.ec)))),
									A2(
										$elm$core$List$cons,
										$mdgriffith$elm_ui$Internal$Model$Attr(
											A2(
												$elm$html$Html$Attributes$style,
												'margin',
												$elm$core$String$fromFloat(halfY) + ('px' + (' ' + ($elm$core$String$fromFloat(halfX) + 'px'))))),
										A2(
											$elm$core$List$cons,
											$mdgriffith$elm_ui$Internal$Model$Attr(
												A2(
													$elm$html$Html$Attributes$style,
													'width',
													'calc(100% + ' + ($elm$core$String$fromInt(x) + 'px)'))),
											A2(
												$elm$core$List$cons,
												$mdgriffith$elm_ui$Internal$Model$Attr(
													A2(
														$elm$html$Html$Attributes$style,
														'height',
														'calc(100% + ' + ($elm$core$String$fromInt(y) + 'px)'))),
												A2(
													$elm$core$List$cons,
													A2(
														$mdgriffith$elm_ui$Internal$Model$StyleClass,
														$mdgriffith$elm_ui$Internal$Flag$spacing,
														A3($mdgriffith$elm_ui$Internal$Model$SpacingStyle, spaceName, x, y)),
													_List_Nil))))),
								$mdgriffith$elm_ui$Internal$Model$Unkeyed(children))
							])));
			}
		}
	});
var $author$project$Ui$Select$view = F4(
	function (config, _v0, label, options) {
		var internalState = _v0;
		var optToString = config.da;
		var optionView = F2(
			function (index, opt) {
				return A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$padding(10),
							$mdgriffith$elm_ui$Element$Background$color(
							_Utils_eq(index, internalState.bs) ? $author$project$Colors$gray200 : $author$project$Colors$white),
							$mdgriffith$elm_ui$Element$mouseOver(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$gray200)
								])),
							$mdgriffith$elm_ui$Element$htmlAttribute(
							$elm$html$Html$Attributes$class(
								$author$project$Ui$Select$optionClass(internalState.a))),
							$mdgriffith$elm_ui$Element$htmlAttribute(
							$elm$html$Html$Attributes$id(
								A2($author$project$Ui$Select$optionId, internalState.a, index)))
						]),
					{
						dG: $mdgriffith$elm_ui$Element$text(
							optToString(opt)),
						e$: $elm$core$Maybe$Just(
							$author$project$Ui$Select$OptionClicked(opt))
					});
			});
		var filteredOptions = (internalState.hE === '') ? options : A2(
			$elm$core$List$filter,
			function (o) {
				return A2(
					$elm$core$String$startsWith,
					$elm$core$String$toLower(internalState.hE),
					$elm$core$String$toLower(
						optToString(o)));
			},
			options);
		var displayedOptions = function () {
			var _v2 = config.bZ;
			if (!_v2) {
				return filteredOptions;
			} else {
				return A2(
					$elm$core$List$filter,
					function (x) {
						return !A2($elm$core$List$member, x, internalState.bq);
					},
					options);
			}
		}();
		var optionsWrapper = internalState.bI ? A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height(
					A2($mdgriffith$elm_ui$Element$maximum, 200, $mdgriffith$elm_ui$Element$shrink)),
					$mdgriffith$elm_ui$Element$scrollbarX,
					$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$white),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					$elm$html$Html$Attributes$id(
						$author$project$Ui$Select$optionsWrapperId(internalState.a)))
				]),
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$clipY,
						$mdgriffith$elm_ui$Element$Border$width(1),
						$mdgriffith$elm_ui$Element$Border$color($author$project$Colors$gray200),
						$mdgriffith$elm_ui$Element$Border$rounded(3)
					]),
				A2($elm$core$List$indexedMap, optionView, displayedOptions))) : $mdgriffith$elm_ui$Element$none;
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(10)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$below(optionsWrapper)
						]),
					A2(
						$mdgriffith$elm_ui$Element$Input$text,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$htmlAttribute(
								$elm$html$Html$Attributes$id(
									$author$project$Ui$Select$inputId(internalState.a))),
								$mdgriffith$elm_ui$Element$htmlAttribute(
								$elm$html$Html$Attributes$autocomplete(false)),
								$mdgriffith$elm_ui$Element$Border$color(
								internalState.bI ? $author$project$Colors$gray500 : $author$project$Colors$gray200),
								$mdgriffith$elm_ui$Element$Events$onFocus($author$project$Ui$Select$InputFocused),
								$mdgriffith$elm_ui$Element$Font$size(16),
								$mdgriffith$elm_ui$Element$htmlAttribute(
								A2(
									$elm$html$Html$Events$on,
									'blur',
									A3($author$project$Ui$Select$blurDecoder, internalState.a, $author$project$Ui$Select$NoOp, $author$project$Ui$Select$InputLostFocus))),
								$mdgriffith$elm_ui$Element$htmlAttribute(
								A2(
									$elm$html$Html$Events$on,
									'keydown',
									$author$project$Ui$Select$keyDecoder(displayedOptions)))
							]),
						{
							dG: A2(
								$mdgriffith$elm_ui$Element$Input$labelAbove,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$size(16)
									]),
								$mdgriffith$elm_ui$Element$text(label)),
							g2: $author$project$Ui$Select$TextEntered,
							hb: $elm$core$Maybe$Nothing,
							hE: internalState.hE
						})),
					function () {
					var _v1 = config.bZ;
					if (!_v1) {
						return $mdgriffith$elm_ui$Element$none;
					} else {
						return A2(
							$mdgriffith$elm_ui$Element$wrappedRow,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(5)
								]),
							A2(
								$elm$core$List$map,
								A2($author$project$Ui$Select$viewSelected, $author$project$Ui$Select$SelectionRemoved, optToString),
								internalState.bq));
					}
				}()
				]));
	});
var $author$project$Page$CreateCompetition$view = F2(
	function (device, model) {
		var isPhone = function () {
			var _v1 = device.ga;
			if (!_v1) {
				return true;
			} else {
				return false;
			}
		}();
		var competitions = function () {
			var _v0 = model.a1;
			if (_v0.$ === 3) {
				var cs = _v0.a;
				return cs;
			} else {
				return _List_Nil;
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$padding(20),
					$mdgriffith$elm_ui$Element$spacing(
					isPhone ? 20 : 40),
					$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$white)
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Ui$h1,
					$mdgriffith$elm_ui$Element$text('Create new competition'),
					isPhone),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(20)
						]),
					_List_fromArray(
						[
							A3($author$project$Ui$input, 'Name', model.bb.o, $author$project$Page$CreateCompetition$EnteredName),
							A2(
							$mdgriffith$elm_ui$Element$map,
							$author$project$Page$CreateCompetition$CompetitionSelectMsg,
							A4($author$project$Ui$Select$view, $author$project$Page$CreateCompetition$competitionSelectConfig, model.bb.cG, 'Competition', competitions)),
							A2(
							$mdgriffith$elm_ui$Element$map,
							$author$project$Page$CreateCompetition$UsersSelectMsg,
							A4($author$project$Ui$Select$view, $author$project$Page$CreateCompetition$usersSelectConfig, model.bb.cR, 'Invite users', $author$project$Page$CreateCompetition$users))
						]))
				]));
	});
var $author$project$Page$Home$view = $mdgriffith$elm_ui$Element$text('Home');
var $author$project$Page$Login$LoginCliked = 0;
var $author$project$Ui$btnAttributes = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$Border$color($author$project$Colors$blue400),
		$mdgriffith$elm_ui$Element$Border$width(2),
		$mdgriffith$elm_ui$Element$Border$rounded(5),
		$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$blue400),
		A2($mdgriffith$elm_ui$Element$paddingXY, 16, 7),
		$mdgriffith$elm_ui$Element$Font$size(16),
		$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$white)
	]);
var $author$project$Ui$button = F2(
	function (label, msg) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$button,
			$author$project$Ui$btnAttributes,
			{
				dG: label,
				e$: $elm$core$Maybe$Just(msg)
			});
	});
var $mdgriffith$elm_ui$Element$Background$image = function (src) {
	return $mdgriffith$elm_ui$Internal$Model$Attr(
		A2($elm$virtual_dom$VirtualDom$style, 'background', 'url(\"' + (src + '\") center / cover no-repeat')));
};
var $author$project$Page$Login$view = A2(
	$mdgriffith$elm_ui$Element$el,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$Background$image('/images/login-bg.jpg')
		]),
	A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Element$paddingXY, 0, 200),
				$mdgriffith$elm_ui$Element$centerX
			]),
		A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(20),
					$mdgriffith$elm_ui$Element$padding(40),
					$mdgriffith$elm_ui$Element$Background$color($author$project$Colors$white),
					$mdgriffith$elm_ui$Element$Border$rounded(20)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Region$heading(1),
							$mdgriffith$elm_ui$Element$Font$size(56)
						]),
					$mdgriffith$elm_ui$Element$text('Gambling')),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]),
					A2(
						$author$project$Ui$button,
						$mdgriffith$elm_ui$Element$text('Enter with Auth0'),
						0))
				]))));
var $mdgriffith$elm_ui$Internal$Model$Bottom = 2;
var $mdgriffith$elm_ui$Element$alignBottom = $mdgriffith$elm_ui$Internal$Model$AlignY(2);
var $author$project$Sidebar$competitionEl = F2(
	function (maybeActiveId, comp) {
		var linkColor = function () {
			if (maybeActiveId.$ === 1) {
				return $author$project$Colors$white;
			} else {
				var id = maybeActiveId.a;
				return _Utils_eq(id, comp.a) ? $author$project$Colors$blue400 : $author$project$Colors$white;
			}
		}();
		var compName = ' ' + function () {
			var _v0 = comp.dd.dr.aq;
			if (_v0.$ === 1) {
				return '(' + (comp.dd.dr.o + ')');
			} else {
				var code = _v0.a;
				return '(' + (code + ')');
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Element$link,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					A2($mdgriffith$elm_ui$Element$paddingXY, 20, 0),
					$mdgriffith$elm_ui$Element$Border$widthEach(
					{dn: 0, dH: 4, dV: 0, d6: 0}),
					$mdgriffith$elm_ui$Element$Border$color(linkColor)
				]),
			{
				dG: A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10),
							A2($mdgriffith$elm_ui$Element$spacingXY, 0, 10)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$size(18),
									$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$gray600)
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text(
									function ($) {
										return $.o;
									}(comp)),
									$mdgriffith$elm_ui$Element$text(compName)
								])),
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$size(14),
									A2($mdgriffith$elm_ui$Element$spacingXY, 8, 0)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$row,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$spacing(3)
										]),
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_Nil,
											$mdgriffith$elm_ui$Element$html(
												A2(
													$feathericons$elm_feather$FeatherIcons$toHtml,
													_List_Nil,
													A2($feathericons$elm_feather$FeatherIcons$withSize, 16, $feathericons$elm_feather$FeatherIcons$users)))),
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$alignBottom]),
											$mdgriffith$elm_ui$Element$text(
												$elm$core$String$fromInt(comp.fu)))
										])),
									A2(
									$mdgriffith$elm_ui$Element$row,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$spacing(3)
										]),
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_Nil,
											$mdgriffith$elm_ui$Element$html(
												A2(
													$feathericons$elm_feather$FeatherIcons$toHtml,
													_List_Nil,
													A2($feathericons$elm_feather$FeatherIcons$withSize, 16, $feathericons$elm_feather$FeatherIcons$calendar)))),
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$alignBottom]),
											$mdgriffith$elm_ui$Element$text(
												$elm$core$String$fromInt(comp.dd.c0)))
										])),
									A2(
									$mdgriffith$elm_ui$Element$row,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$spacing(3)
										]),
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_Nil,
											$mdgriffith$elm_ui$Element$html(
												A2(
													$feathericons$elm_feather$FeatherIcons$toHtml,
													_List_Nil,
													A2($feathericons$elm_feather$FeatherIcons$withSize, 16, $feathericons$elm_feather$FeatherIcons$target)))),
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$alignBottom]),
											$mdgriffith$elm_ui$Element$text(
												$elm$core$String$fromInt(comp.eX) + ('/' + $elm$core$String$fromInt(comp.eT))))
										]))
								]))
						])),
				h3: A2(
					$elm$url$Url$Builder$absolute,
					_List_fromArray(
						[
							'competitions',
							$elm$core$String$fromInt(comp.a)
						]),
					_List_Nil)
			});
	});
var $author$project$Colors$gray300 = A3($mdgriffith$elm_ui$Element$rgb255, 209, 213, 219);
var $mdgriffith$elm_ui$Internal$Model$boxShadowClass = function (shadow) {
	return $elm$core$String$concat(
		_List_fromArray(
			[
				shadow.eN ? 'box-inset' : 'box-',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.az.a) + 'px',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.az.b) + 'px',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.f_) + 'px',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.hq) + 'px',
				$mdgriffith$elm_ui$Internal$Model$formatColorClass(shadow.ge)
			]));
};
var $mdgriffith$elm_ui$Internal$Flag$shadows = $mdgriffith$elm_ui$Internal$Flag$flag(19);
var $mdgriffith$elm_ui$Element$Border$shadow = function (almostShade) {
	var shade = {f_: almostShade.f_, ge: almostShade.ge, eN: false, az: almostShade.az, hq: almostShade.hq};
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$shadows,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Single,
			$mdgriffith$elm_ui$Internal$Model$boxShadowClass(shade),
			'box-shadow',
			$mdgriffith$elm_ui$Internal$Model$formatBoxShadow(shade)));
};
var $author$project$Ui$buttonLink = F2(
	function (label, url) {
		return A2(
			$mdgriffith$elm_ui$Element$link,
			$author$project$Ui$btnAttributes,
			{dG: label, h3: url});
	});
var $author$project$Sidebar$viewNewCompetitionBtn = A2(
	$mdgriffith$elm_ui$Element$el,
	_List_fromArray(
		[$mdgriffith$elm_ui$Element$centerX]),
	A2(
		$author$project$Ui$buttonLink,
		$mdgriffith$elm_ui$Element$text('Create'),
		A2(
			$elm$url$Url$Builder$absolute,
			_List_fromArray(
				['create-competition']),
			_List_Nil)));
var $author$project$Sidebar$view = F2(
	function (model, device) {
		var isPhone = function () {
			var _v1 = device.ga;
			if (!_v1) {
				return true;
			} else {
				return false;
			}
		}();
		var children = function () {
			var _v0 = model.a1;
			switch (_v0.$) {
				case 0:
					return $mdgriffith$elm_ui$Element$text('Loading');
				case 1:
					return $mdgriffith$elm_ui$Element$text('Loading');
				case 2:
					return $mdgriffith$elm_ui$Element$text('Error');
				default:
					var competitions = _v0.a;
					return A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						A2(
							$elm$core$List$map,
							$author$project$Sidebar$competitionEl(model.cS),
							competitions));
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					isPhone ? $mdgriffith$elm_ui$Element$fill : $mdgriffith$elm_ui$Element$px($author$project$Sidebar$sideBarWidth)),
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$htmlAttribute(
					A2($elm$html$Html$Attributes$style, 'z-index', '1')),
					$mdgriffith$elm_ui$Element$alignTop,
					A2($mdgriffith$elm_ui$Element$paddingXY, 0, 20),
					$mdgriffith$elm_ui$Element$spacing(30),
					$mdgriffith$elm_ui$Element$Border$shadow(
					{
						f_: 20,
						ge: $author$project$Colors$gray300,
						az: _Utils_Tuple2(2, 15),
						hq: 0
					})
				]),
			_List_fromArray(
				[children, $author$project$Sidebar$viewNewCompetitionBtn]));
	});
var $author$project$Main$view = function (model) {
	var page = function () {
		switch (model.$) {
			case 2:
				return $mdgriffith$elm_ui$Element$text('404 not found');
			case 0:
				var content = model.a;
				var authModel = model.b;
				var authView = function (mainContent) {
					return A3(
						$author$project$Main$layout,
						authModel,
						A2(
							$mdgriffith$elm_ui$Element$map,
							$author$project$Main$GotSidebarMsg,
							A2($author$project$Sidebar$view, authModel.b_, authModel.bF)),
						mainContent);
				};
				switch (content.$) {
					case 0:
						return authView($author$project$Page$Home$view);
					case 2:
						var subModel = content.a;
						return authView(
							A2(
								$mdgriffith$elm_ui$Element$map,
								$author$project$Main$GotCreateCompetitionMsg,
								A2($author$project$Page$CreateCompetition$view, authModel.bF, subModel)));
					default:
						var subModel = content.a;
						return authView(
							A2(
								$mdgriffith$elm_ui$Element$map,
								$author$project$Main$GotCompetitionMsg,
								A2($author$project$Page$Competition$view, authModel.bF, subModel)));
				}
			default:
				return A2($mdgriffith$elm_ui$Element$map, $author$project$Main$GotLoginMsg, $author$project$Page$Login$view);
		}
	}();
	return {
		f$: _List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$layout,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$family(
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$typeface('Rubik')
							])),
						$mdgriffith$elm_ui$Element$Font$color($author$project$Colors$gray600)
					]),
				page)
			]),
		hU: 'Gambling'
	};
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{gG: $author$project$Main$init, g5: $author$project$Main$UrlChanged, g6: $author$project$Main$LinkClicked, hD: $author$project$Main$subscriptions, h2: $author$project$Main$update, h4: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main($elm$json$Json$Decode$value)(0)}});}(this));