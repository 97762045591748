import createAuth0Client from '@auth0/auth0-spa-js';

import './main.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';

const auth0Config = {
    domain: 'gmblng.eu.auth0.com',
    client_id: 'eOXzk7GSQqJaujf8t2tyk6HtiQD8V7HN',
    audience: 'hasura',
}

let auth0 = null;
let app = null;

const configureAuthClient = async () => {
    auth0 = await createAuth0Client(auth0Config);
}

const getUserWithToken = async () => {
    const user = await auth0.getUser();
    const token = await auth0.getTokenSilently();
    const userWithToken = {
        ...user,
        token,
    }
    return userWithToken;
}

window.onload = async () => {
    await configureAuthClient();
    const isAuthenticated = await auth0.isAuthenticated();
    const flags = {
        windowSize: {width: window.innerWidth, height: window.innerHeight},
        user: isAuthenticated ? await getUserWithToken() : null,
    }

    if (!isAuthenticated) {
        const query = window.location.search;
        if (query.includes("code=") && query.includes("state=")) {
            // we got redirect from Auth0
            try {
                await auth0.handleRedirectCallback();
            } catch(e) {
                console.log(e);
                window.history.replaceState({}, document.title, "/");
            }
            window.history.replaceState({}, document.title, "/");
            flags.user = await getUserWithToken();
        }
    }

    app = Elm.Main.init({ flags });
    app.ports.sendMessage.subscribe(async (message) => {
        if (message === 'login') {
            await auth0.loginWithRedirect({
                redirect_uri: window.location.origin
            });
        } else {
            auth0.logout({
                returnTo: window.location.origin
            });
        }
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
